import { useStoreActions, useStoreState } from "easy-peasy";
import React, { useEffect, useState, useCallback } from "react";

import { useForm } from "react-hook-form";
import { Row, Col, Form, Button, Spinner, Container, } from 'react-bootstrap';

import { toast } from 'react-toastify';

import SweetAlert from 'react-bootstrap-sweetalert';
import { BrandUserFormInput } from "../../../../common/interfaces";
import { formatPhoneNumber, isChiefProgramAdmin, isProgramAdmin, setAutoProgrammeWriteAccess, getUserRole } from "../../../../common/functions";
import { formButton } from "../../../../common/components-style";


const AdminUserView: React.FC<any> = ({ selectedUser, close, reload, programmeId, clientId }): JSX.Element => {

    /////////////////////////////////////////Form Declaration/////////////////////////////////////////////////
    // const [form] = Form.useForm();
    const { register, watch, handleSubmit } = useForm<BrandUserFormInput>();


    ///////////////////////////////////Local State Manage///////////////////////////////////////////////////
    const [loading, setLoading] = useState<boolean>(false);
    // const [isBrand, setIsBrand] = useState("");
    const [archivePopop, setArchivePopop] = useState<boolean>(false);
    const [disablePopop, setDisablePopop] = useState<boolean>(false);
    const [adminPopop, setAdminPopop] = useState<boolean>(false);
    const [showUser, setShowUser] = useState<boolean>(false);
    ////////////////////////////Global State Manage//////////////////////////////////////////////////////////
    const updateAccess = useStoreActions<any>((actions) => actions.user.updateAccess);

    const updateAccessSuccess = useStoreState<any>((state) => state.user.updateAccessSuccess);

    /////////////////////////////////////Update Access Response Handle/////////////////////////////////////////////
    useEffect(() => {
        //user update success
        if (updateAccessSuccess) {
            setLoading(false);
            reload();
            // message.success("Permission updated successfully");
            toast.success("Permission updated successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            close();
        }
        // const userRole = getStorage("isBrand");
        // setIsBrand(userRole);
    }, [reload, updateAccessSuccess, close]);

    //////////////////////////////////////Form Submit////////////////////////////////////////////////////////////
    const onSubmit = useCallback((values) => {
        setLoading(true);

        if (watch('userType') === 'disableUser') {
            updateAccess({
                id: selectedUser.arrId,
                userId: selectedUser.userId,
                role:
                    selectedUser.role,
                accessType: "programmeRole",
                isUserDisable: true,
                userData: getUserRole(null, programmeId),
                tab: "Administrators",
                entityId: programmeId,
                clientId: clientId,
            });
        } else if (watch('userType') === 'programme_admin') {
            updateAccess(setAutoProgrammeWriteAccess(selectedUser.arrId, selectedUser.userId));
            updateAccess({
                id: selectedUser.arrId,
                userId: selectedUser.userId,
                role: 'programme_admin',
                accessType: "programmeRole",
                userData: getUserRole(null, programmeId),
                tab: "Administrators",
                entityId: programmeId,
                clientId: clientId,
                existingRole: selectedUser?.role

            });
        } else if (watch('userType') === 'user') {
            updateAccess({
                id: selectedUser.arrId,
                userId: selectedUser.userId,
                role: 'user',
                accessType: "programmeRole",
                userData: getUserRole(null, programmeId),
                tab: "Administrators",
                entityId: programmeId,
                clientId: clientId,
                existingRole: selectedUser?.role

            });
        } else if (watch('userType') === 'booker') {
            updateAccess({
                id: selectedUser.arrId,
                userId: selectedUser.userId,
                role: 'booker',
                accessType: "programmeRole",
                userData: getUserRole(null, programmeId),
                tab: "Administrators",
                entityId: programmeId,
                clientId: clientId,
                existingRole: selectedUser?.role

            });
        } else if (watch('userType') === 'traveler') {
            updateAccess({
                id: selectedUser.arrId,
                userId: selectedUser.userId,
                role: 'traveler',
                accessType: "programmeRole",
                userData: getUserRole(null, programmeId),
                tab: "Administrators",
                entityId: programmeId,
                clientId: clientId,
                existingRole: selectedUser?.role

            });
        }
    },
        [watch, updateAccess, selectedUser.arrId, selectedUser.userId, selectedUser.role, programmeId, clientId]
    );

    return (
        <>
            <Container fluid className="p-0">
                <div className="jumbotron" style={{ width: '100%' }}>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col md={1}></Col>
                            <Col md={10}>
                                <Row className='formSpace'>
                                    <Col md={4} style={{ textAlign: 'right' }}>
                                        <Form.Label> Email</Form.Label>
                                    </Col>
                                    <Col md={8} style={{ textAlign: 'left' }}>
                                        <p className="lead">{selectedUser?.email}</p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={1}></Col>
                        </Row>
                        <Row>
                            <Col md={1}></Col>
                            <Col md={10}>
                                <Row className='formSpace'>
                                    <Col md={4} style={{ textAlign: 'right' }}>
                                        <Form.Label> First name</Form.Label>
                                    </Col>
                                    <Col md={8} style={{ textAlign: 'left' }}>
                                        <p className="lead">{selectedUser?.firstName}</p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={1}></Col>
                        </Row>
                        <Row>
                            <Col md={1}></Col>
                            <Col md={10}>
                                <Row className='formSpace'>
                                    <Col md={4} style={{ textAlign: 'right' }}>
                                        <Form.Label> Last name</Form.Label>
                                    </Col>
                                    <Col md={8} style={{ textAlign: 'left' }}>
                                        <p className="lead">{selectedUser?.lastName}</p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={1}></Col>
                        </Row>
                        <Row>
                            <Col md={1}></Col>
                            <Col md={10}>
                                <Row className='formSpace'>
                                    <Col md={4} style={{ textAlign: 'right' }}>
                                        <Form.Label> Role</Form.Label>
                                    </Col>
                                    <Col md={8} style={{ textAlign: 'left' }}>
                                        <p className="lead">{selectedUser?.role}</p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={1}></Col>
                        </Row>

                        <Row>
                            <Col md={1}></Col>
                            <Col md={10}>
                                <Row className='formSpace'>
                                    <Col md={4} style={{ textAlign: 'right' }}>
                                        <Form.Label> Phone</Form.Label>
                                    </Col>
                                    <Col md={8} style={{ textAlign: 'left' }}>
                                        <p className="lead">{formatPhoneNumber(selectedUser?.phone)}</p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={1}></Col>
                        </Row>
                        <Row>
                            <Col md={1}></Col>
                            <Col md={10}>
                                <Row className='formSpace'>
                                    <Col md={4} style={{ textAlign: 'right' }}>
                                        <Form.Label>Mobile</Form.Label>
                                    </Col>
                                    <Col md={8} style={{ textAlign: 'left' }}>
                                        <p className="lead">{formatPhoneNumber(selectedUser?.mobile)}</p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={1}></Col>
                        </Row>



                        <Row>
                            <Col md={1}></Col>
                            <Col md={10}>
                                <Row className='formSpace'>
                                    <Col md={4} style={{ textAlign: 'right' }}>
                                    </Col>
                                    <Col md={8} className="btn_align_left">
                                        {isChiefProgramAdmin() && !loading &&
                                            <Button id='approve-button' style={formButton} onClick={() => {

                                                setShowUser(true);
                                            }}>Amend User</Button>
                                        }

                                    </Col>

                                </Row>
                            </Col>
                            <Col md={1}></Col>
                        </Row>
                        {showUser && <div>
                            <Row>
                                <Col md={1}></Col>
                                <Col md={10}>
                                    <Row className='formSpace'>
                                        <Col md={4} style={{ textAlign: 'right' }}>
                                            <Form.Label>Change user to</Form.Label>
                                        </Col>

                                    </Row>
                                </Col>
                                <Col md={1}></Col>
                            </Row>

                            {<Row>
                                <Col md={1}></Col>
                                <Col md={10}>
                                    <Row className='formSpace'>
                                        <Col md={4} style={{ textAlign: 'right' }}>
                                        </Col>
                                        <Col md={8} style={{ textAlign: 'left' }}>
                                            {isChiefProgramAdmin() && <Form.Check type="radio" value={'user'} label="User (Please visit permissions once saved)" {...register("userType")} />}
                                            <Form.Check type="radio" value={'booker'} label="Booker" {...register("userType")} />
                                            <Form.Check type="radio" value={'traveler'} label="Standard employee" {...register("userType")} />
                                            <Form.Check type="radio" value={'disableUser'} label="Disable user" {...register("userType")} />
                                            {isChiefProgramAdmin() && <Form.Check type="radio" value={'programme_admin'} label="Chief Programme Admin" {...register("userType")} />}
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={1}></Col>
                            </Row>}
                            <Row>
                                <Col md={1}></Col>
                                <Col md={10}>
                                    <Row className='formSpace'>
                                        <Col md={4} style={{ textAlign: 'right' }}>
                                        </Col>
                                        <Col md={8} className="btn_align_left">
                                            {isProgramAdmin() && !loading &&
                                                <Button id='approve-button' style={formButton} onClick={() => {
                                                    if (watch('userType') === 'user' || watch('userType') === 'booker' || watch('userType') === 'traveler') {
                                                        setArchivePopop(true);
                                                    } else if (watch('userType') === 'disableUser') {

                                                        setDisablePopop(true);
                                                    } else if (watch('userType') === 'programme_admin') {
                                                        setAdminPopop(true);
                                                    }
                                                }}>Save</Button>
                                            }
                                            {isProgramAdmin() && !loading &&
                                                <Button id='edit-button' style={formButton} onClick={() => { close(); }}>Close</Button>
                                            }
                                            {
                                                loading && <div style={{ textAlign: 'center' }}><Spinner animation="border" variant="warning" /></div>
                                            }
                                        </Col>

                                    </Row>
                                </Col>
                                <Col md={1}></Col>
                            </Row>
                        </div>}

                    </Form>
                </div>
            </Container>

            <SweetAlert
                show={archivePopop}
                warning
                showCancel={true}
                focusCancelBtn={true}
                confirmBtnText="Yes"
                confirmBtnBsStyle="danger"
                title="Are you sure you wish to change the permission levels for this user?"
                onConfirm={() => {
                    handleSubmit(onSubmit)();
                    setArchivePopop(false);
                }}
                onCancel={() => {
                    setArchivePopop(false);
                }}
            >
            </SweetAlert>
            <SweetAlert
                show={disablePopop}
                warning
                showCancel={true}
                focusCancelBtn={true}
                confirmBtnText="Yes"
                confirmBtnBsStyle="danger"
                title="Are you sure you wish to disable this user? By doing so, this user will not be able to login to the system?"
                onConfirm={() => {
                    handleSubmit(onSubmit)();
                    setDisablePopop(false);
                }}
                onCancel={() => {
                    setDisablePopop(false);
                }}
            >
            </SweetAlert>
            <SweetAlert
                show={adminPopop}
                warning
                showCancel={true}
                focusCancelBtn={true}
                confirmBtnText="Yes"
                confirmBtnBsStyle="danger"
                title="Are you sure you want to make this user the Chief Programme Admin? This will automatically remove Chief Programme Admin permissions for yourself and you will become an 'Administrator’"
                onConfirm={() => {
                    handleSubmit(onSubmit)();
                    setAdminPopop(false);
                }}
                onCancel={() => {
                    setAdminPopop(false);
                }}
            >
            </SweetAlert>
        </>

    );
};
export default AdminUserView;
