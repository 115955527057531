import React, { useEffect, useState } from 'react';
import { Col, Nav, Row, Tab } from 'react-bootstrap';
import { navContentStyle, navStyle, tabDivStyle } from '../../../../common/components-style';

import PartnerDetailForm from './partnerDetailForm';
import PartnerUsersList from './partner-users/PartnerUsersList';
import { getStorage } from '../../../../common/storage';


const PartnerForm: React.FC<any> = ({ partnerId, setFormOpen, loadData, location }): JSX.Element => {
	const [tabKey, setTabKey] = useState<any>('details');
	const [partnerData, setPartnerData] = useState<any>();


	useEffect(() => {
		if (location?.state?.defaultKey) {
			setTabKey(location?.state?.defaultKey);
		}
		setPartnerData(getStorage('myo_selected_partner'))
	}, [location?.state]);

	

	return (
		<>
			<div style={tabDivStyle}>
				<Tab.Container defaultActiveKey="details" key={tabKey} activeKey={tabKey} onSelect={(tab) => setTabKey(tab)}>
					<Row >
						<Col sm={2}>
							<Nav variant="pills" className="flex-column secondry-menu" style={navStyle}>
								<div style={{ marginTop: '0%' }}>
									<Nav.Item><Nav.Link eventKey="details">Details</Nav.Link></Nav.Item>
									{partnerId && <Nav.Item><Nav.Link eventKey="admins">Admins</Nav.Link></Nav.Item>}
									{partnerId && partnerData?.partnerType?.name !== 'Parent Accommodation Company' && <Nav.Item><Nav.Link eventKey="fee">Fee Management</Nav.Link></Nav.Item>}
									{partnerId && partnerData?.partnerType?.name !== 'Parent Accommodation Company' && <Nav.Item><Nav.Link eventKey="client">Client Management</Nav.Link></Nav.Item>}
									{partnerId && partnerData?.partnerType?.name !== 'Parent Accommodation Company' && <Nav.Item><Nav.Link eventKey="invoice">Invoice Management</Nav.Link></Nav.Item>}
									{partnerId && partnerData?.partnerType?.name === 'Parent Accommodation Company' && <Nav.Item><Nav.Link eventKey="brand">Brand Management</Nav.Link></Nav.Item>}
									{partnerId && partnerData?.partnerType?.name === 'Parent Accommodation Company' && <Nav.Item><Nav.Link eventKey="invoice">Hotel Management</Nav.Link></Nav.Item>}

								</div>
							</Nav>
						</Col>
						<Col sm={10}>
							<Tab.Content >
								<Tab.Pane eventKey="details" >
									<div style={navContentStyle}>
										{<PartnerDetailForm partnerId={partnerId} setFormOpen={setFormOpen} loadData={loadData} />}
									</div>
								</Tab.Pane>
								{partnerId && <Tab.Pane eventKey="admins">
									<div style={navContentStyle}>
										{<PartnerUsersList partnerId={partnerId} />}
									</div>
								</Tab.Pane>}
								{/* {propertyId && <Tab.Pane eventKey="transport">
									<div style={navContentStyle}>
										{isPropertyModuleAvailable(selectedBrand.key, propertyId, UserAccess.property_access.details.code) ? <TransportDetailForm allInOnePropertyBreadCrumb={allInOnePropertyBreadCrumb} propertyId={propertyId} selectedBrand={selectedBrand} setFormOpen={setFormOpen} tab={tab} /> : <AccessDenied />}
									</div>
								</Tab.Pane>}
								{propertyId && <Tab.Pane eventKey="guestComfort">
									<div style={navContentStyle}>
										{isPropertyModuleAvailable(selectedBrand.key, propertyId, UserAccess.property_access.details.code) ? <GuestComfortDetailForm allInOnePropertyBreadCrumb={allInOnePropertyBreadCrumb} propertyId={propertyId} selectedBrand={selectedBrand} setFormOpen={setFormOpen} tab={tab} /> : <AccessDenied />}
									</div>
								</Tab.Pane>}
								{propertyId && <Tab.Pane eventKey="propertySustainability">
									<div style={navContentStyle}>
										{isPropertyModuleAvailable(selectedBrand.key, propertyId, UserAccess.property_access.details.code) ? <PropertySustainability allInOnePropertyBreadCrumb={allInOnePropertyBreadCrumb} brandId={selectedBrand.key} propertyId={propertyId} selectedBrand={selectedBrand} setFormOpen={setFormOpen} tab={tab} /> : <AccessDenied />}
									</div>
								</Tab.Pane>}
								{propertyId && <Tab.Pane eventKey="propertyUsers">
									<div style={navContentStyle}>
										{isPropertyModuleAvailable(selectedBrand.key, propertyId, UserAccess.property_access.users.code) ? <PropertyUsersList allInOnePropertyBreadCrumb={allInOnePropertyBreadCrumb} brandId={selectedBrand.key} propertyId={propertyId} selectedBrand={selectedBrand} setFormOpen={setFormOpen} tab={tab} /> : <AccessDenied />}
									</div>
								</Tab.Pane>}
								{propertyId && <Tab.Pane eventKey="propertyFinance">
									<div style={navContentStyle}>
										{isPropertyModuleAvailable(selectedBrand.key, propertyId, UserAccess.property_access.details.code) ? <PropertyFinance allInOnePropertyBreadCrumb={allInOnePropertyBreadCrumb} brandId={selectedBrand.key} propertyId={propertyId} setFormOpen={setFormOpen} selectedBrand={selectedBrand} tab={tab} /> : <AccessDenied />}
									</div>
								</Tab.Pane>}
								{propertyId && <Tab.Pane eventKey="audit">
									<div style={navContentStyle}>
									
										{isPropertyModuleAvailable(selectedBrand.key, propertyId, UserAccess.property_access.audit.code) ? <AuditQuestions allInOnePropertyBreadCrumb={allInOnePropertyBreadCrumb} selectedBrand={selectedBrand} extId={extId} tab={tab} /> : <AccessDenied />}
									</div>
								</Tab.Pane>}
								{propertyId && <Tab.Pane eventKey="images">
									<div style={navContentStyle}>
										{isPropertyModuleAvailable(selectedBrand.key, propertyId, UserAccess.property_access.details.code) ? <BuildingImages allInOnePropertyBreadCrumb={allInOnePropertyBreadCrumb} brandId={selectedBrand.key} propertyId={propertyId} setFormOpen={setFormOpen} selectedBrand={selectedBrand} tab={tab} /> : <AccessDenied />}
									</div>
								</Tab.Pane>}
								{propertyId && <Tab.Pane eventKey="amenities">
									<div style={navContentStyle}>
										{isPropertyModuleAvailable(selectedBrand.key, propertyId, UserAccess.property_access.details.code) ? <BuildingAmenities allInOnePropertyBreadCrumb={allInOnePropertyBreadCrumb} brandId={selectedBrand.key} propertyId={propertyId} setFormOpen={setFormOpen} selectedBrand={selectedBrand} tab={tab} /> : <AccessDenied />}
									</div>
								</Tab.Pane>}
								{propertyId && <Tab.Pane eventKey="checkIn">
									<div style={navContentStyle}>
										{isPropertyModuleAvailable(selectedBrand.key, propertyId, UserAccess.property_access.details.code) ? <PropertyCheckInProcess allInOnePropertyBreadCrumb={allInOnePropertyBreadCrumb} brandId={selectedBrand.key} propertyId={propertyId} setFormOpen={setFormOpen} selectedBrand={selectedBrand} tab={tab} /> : <AccessDenied />}
									</div>
								</Tab.Pane>}


								{propertyId && isAccess && <Tab.Pane eventKey="inventory">
									<div style={navContentStyle}>
										<AccessDenied />
									</div>
								</Tab.Pane>} */}
							</Tab.Content>
						</Col>
					</Row>
				</Tab.Container>
			</div>
		</>

	)
};

export default PartnerForm;