import { Button, Form, Input, InputNumber, message, Switch, Select } from 'antd';
import { useStoreActions, useStoreState } from 'easy-peasy';
import React, { useCallback, useEffect, useState } from 'react';
const { Option } = Select;

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

const tailLayout = {
  wrapperCol: { offset: 6, span: 16 },
};

const RoomTypeForm: React.FC<any> = ({ setFormOpen, roomTypeDetails, roomTypeId }): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [status, setStatus] = useState<string>('active');
  const [checkValue, setCheckValue] = useState<boolean>(true);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const statusChange = useCallback((value) => {
    setCheckValue(value);
    if (value) {
      setStatus('active');
    }
    else {
      setStatus('inactive');
    }
  }, []);

  ////////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (roomTypeId) {
      setCheckValue((roomTypeDetails.status === 'active') ? true : false);
      setStatus(roomTypeDetails.status)
      form.setFieldsValue({
        name: roomTypeDetails.name,
        propertyType: roomTypeDetails.propertyType || '',
        occupancy: roomTypeDetails.occupancy,
        status: roomTypeDetails.status,

      })
    } else {
      setStatus('active');
      setCheckValue(true);
    }
  }, [form, roomTypeDetails, roomTypeId]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const { createRoomType, updateRoomType, getRoomTypeList } = useStoreActions<any>((actions) => ({
    createRoomType: actions.inventory.createRoomType,
    updateRoomType: actions.inventory.updateRoomType,
    getRoomTypeList: actions.inventory.getRoomTypeList,
  }));

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const {
    createRoomTypeError,
    createRoomTypeSuccess,
    updateRoomTypeError,
    updateRoomTypeSuccess,
    getRoomTypeListSuccess,
    getRoomTypeListError
  } = useStoreState<any>((state) => ({
    createRoomTypeSuccess: state.inventory.createRoomTypeSuccess,
    createRoomTypeError: state.inventory.createRoomTypeError,
    updateRoomTypeError: state.inventory.updateRoomTypeError,
    updateRoomTypeSuccess: state.inventory.updateRoomTypeSuccess,
    getRoomTypeListSuccess: state.inventory.getRoomTypeListSuccess,
    getRoomTypeListError: state.inventory.getRoomTypeListError,
  }));

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    getRoomTypeList();
  }, [getRoomTypeList]);

  //////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (getRoomTypeListSuccess) {

    }
    if (getRoomTypeListError) {
      message.error(getRoomTypeListError.message)
    }
  }, [getRoomTypeListError, getRoomTypeListSuccess]);

  ///////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (createRoomTypeSuccess) {
      message.success('RoomType created successfully');
      setTimeout(() => setFormOpen(false), 1000);
      getRoomTypeList();
    }

    if (updateRoomTypeSuccess) {
      message.success('RoomType updated successfully');
      setTimeout(() => setFormOpen(false), 1000);
      getRoomTypeList();
    }

    if (createRoomTypeError) {
      message.error(createRoomTypeError.message)
    }

    if (updateRoomTypeError) {
      message.error(updateRoomTypeError.message)
    }
    setLoading(false);
  }, [createRoomTypeSuccess, createRoomTypeError, updateRoomTypeSuccess, updateRoomTypeError, getRoomTypeList, setFormOpen]);

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const onFinish = useCallback((values) => {
    setLoading(true);
    if (roomTypeId) {
      values.id = roomTypeId;
      const data = {
        id: roomTypeId,
        name: values.name,
        occupancy: values.occupancy,
        propertyType: values.propertyType,
        status: values.status ? status : 'inactive'
      }
      updateRoomType(data);

    } else {
      values.status = status;
      createRoomType(values);
    }

  }, [createRoomType, roomTypeId, status, updateRoomType]);


  const renderPropertyType = useCallback(() => {
    return <Select >
      <Option value="both">Both</Option>
      <Option value="serviced_accommodation">Serviced Accommodation</Option>
      <Option value="hotels">Hotels </Option>
    </Select>
  }, []);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <div className="user-Form w-1/2">
      <Form
        {...layout}
        form={form}
        onFinish={onFinish}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[
            { required: true, message: 'Please enter a name!' },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="No of bedrooms"
          name="occupancy"
          rules={[
            { required: true, message: 'Please enter a occupancy!' },
          ]}
        >
          <InputNumber min={1} className='w-full' />
        </Form.Item>
        <Form.Item
          label="Property Type"
          name="propertyType"
          rules={[
            { required: true, message: 'Please select property type' },
          ]}
        >
          {renderPropertyType()}
        </Form.Item>

        <Form.Item
          label="Status"
          name="status">
          <Switch size="small" checked={checkValue} onChange={statusChange} />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button loading={loading} type="primary" htmlType="submit">
            {roomTypeId ? 'Update' : 'Create'}
          </Button>
          {/* <Button onClick={() => setFormOpen(false)} className="ml-5">
            Close
          </Button> */}
        </Form.Item>

      </Form>
    </div>
  )
};

export default RoomTypeForm;