/* eslint-disable import/no-anonymous-default-export */
import { action, thunk } from 'easy-peasy';
import { call, callMYOManagement } from '../common/axios';
import { BrandMyoManagementIds } from '../common/constants';
// import { io } from "socket.io-client";
// const socket = io(process.env.REACT_APP_API_URL?? '');

export default {
	brandListSuccess: null,
	brandListError: null,
	loadBrandSuccess: null,
	loadBrandError: null,
	createBrandSuccess: null,
	createBrandError: null,
	updateBrandSuccess: null,
	updateBrandError: null,
	approvalBrandSuccess: null,
	approvalBrandError: null,
	createBrandSignUpSuccess: null,
	createBrandSignUpError: null,
	loadBrandsignupSuccess: null,
	loadBrandsignupError: null,
	updateBrandSignUpSuccess: null,
	updateBrandSignUpError: null,
	getSingleSignUpSuccess: null,
	getSingleSignUpError: null,
	sendBrandDetailSubmitSurveyEmailSuccess: null,
	sendBrandDetailSubmitSurveyEmailError: null,
	createBrandFinanceSuccess: null,
	createBrandFinanceError: null,
	updateBrandFinanceSuccess: null,
	updateBrandFinanceError: null,
	getBrandFinanceDetailSuccess: null,
	getBrandFinanceDetailError: null,
	reset: false,
	sendBrandApplicationReviewRejectedMailSuccess: null,
	sendBrandApplicationReviewRejectedMailError: null,
	getBrandListNewVersionSuccess: null,
	getBrandListNewVersionError: null,
	getBrandListNewSuccess: null,
	getBrandListNewError: null,
	getBrandDetailsSuccess: null,
	getBrandDetailsError: null,

	loadBrandsignupListError: null,
	loadBrandsignupListSuccess:null,
	getBrandSustainabilityByBrandIdSuccess: null,
	getBrandSustainabilityByBrandIdError: null,
	createBrandSustainabilitySuccess: null,
	createBrandSustainabilityError: null,
	updateBrandSustainabilitySuccess: null,
	updateBrandSustainabilityError: null,
	reviewBrandSuccess: null,
	reviewBrandError: null,

	getPropertyInformationSuccess: null,
	getPropertyInformationError: null,
  
	createPropertyInformationSuccess: null,
	createPropertyInformationError: null,
	// actions
	setBrandList: action((state, payload) => ({ brandListSuccess: payload })),
	setBrandListError: action((state, payload) => ({ brandListError: payload })),
	setLoadBrand: action((state, payload) => ({ loadBrandSuccess: payload })),
	setLoadBrandError: action((state, payload) => ({ loadBrandError: payload })),
	setCreateBrand: action((state, payload) => ({ createBrandSuccess: payload })),
	setCreateBrandError: action((state, payload) => ({ createBrandError: payload })),
	setUpdateBrand: action((state, payload) => ({ updateBrandSuccess: payload })),
	setUpdateBrandError: action((state, payload) => ({ updateBrandError: payload })),
	setApprovalBrand: action((state, payload) => ({ approvalBrandSuccess: payload })),
	setApprovalBrandError: action((state, payload) => ({ approvalBrandError: payload })),

	setReviewBrand: action((state, payload) => ({ reviewBrandSuccess: payload })),
	setReviewBrandError: action((state, payload) => ({ reviewBrandError: payload })),

	setCreateBrandSignupSuccess: action((state, payload) => ({ createBrandSignUpSuccess: payload })),
	setCreateBrandSignupError: action((state, payload) => ({ createBrandSignUpError: payload })),
	setLoadBrandsignupSuccess: action((state, payload) => ({ loadBrandSignUpSuccess: payload })),
	setLoadBrandsignupError: action((state, payload) => ({ loadBrandsignupError: payload })),
	setLoadBrandsignupListSuccess: action((state, payload) => ({ loadBrandSignUpListSuccess: payload })),
	setLoadBrandsignupListError: action((state, payload) => ({ loadBrandsignupListError: payload })),
	setUpdateBrandSignUpSuccess: action((state, payload) => ({ updateBrandSignUpSuccess: payload })),
	setUpdateBrandSignUpError: action((state, payload) => ({ updateBrandSignUpError: payload })),
	setGetSingleSignUpSuccess: action((state, payload) => ({ getSingleSignUpSuccess: payload })),
	setGetSingleSignUpError: action((state, payload) => ({ getSingleSignUpError: payload })),
	setSendBrandDetailSubmitSurveyEmail: action((state, payload) => ({ sendBrandDetailSubmitSurveyEmailSuccess: payload })),
	setSendBrandDetailSubmitSurveyEmailError: action((state, payload) => ({ sendBrandDetailSubmitSurveyEmailError: payload })),
	resetBrand: action((state, payload) => ({ reset: true })),
	setCreateBrandFinance: action((state, payload) => ({ createBrandFinanceSuccess: payload })),
	setCreateBrandFinanceError: action((state, payload) => ({ createBrandFinanceError: payload })),
	setUpdateBrandFinance: action((state, payload) => ({ updateBrandFinanceSuccess: payload })),
	setUpdateBrandFinanceError: action((state, payload) => ({ updateBrandFinanceError: payload })),
	setGetBrandFinanceDetail: action((state, payload) => ({ getBrandFinanceDetailSuccess: payload })),
	setGetBrandFinanceDetailError: action((state, payload) => ({ getBrandFinanceDetailError: payload })),
	setSendBrandApplicationReviewRejectedMail: action((state, payload) => ({ sendBrandApplicationReviewRejectedMailSuccess: payload })),
	setSendBrandApplicationReviewRejectedMailError: action((state, payload) => ({ sendBrandApplicationReviewRejectedMailError: payload })),
	setBrandListNewVersionSuccess: action((state, payload) => ({ getBrandListNewVersionSuccess: payload })),
	setBrandListNewVersionError: action((state, payload) => ({ getBrandListNewVersionError: payload })),

	setBrandListNewSuccess: action((state, payload) => ({ getBrandListNewSuccess: payload })),
	setBrandListNewError: action((state, payload) => ({ getBrandListNewError: payload })),

	setBrandDetailsSuccess: action((state, payload) => ({ getBrandDetailsSuccess: payload })),
	setBrandDetailsError: action((state, payload) => ({ getBrandDetailsError: payload })),


	setGetBrandSustainabilityByBrandId: action((state, payload) => ({ getBrandSustainabilityByBrandIdSuccess: payload })),
	setGetBrandSustainabilityByBrandIdError: action((state, payload) => ({ getBrandSustainabilityByBrandIdError: payload })),
	setCreateBrandSustainability: action((state, payload) => ({ createBrandSustainabilitySuccess: payload })),
	setCreateBrandSustainabilityError: action((state, payload) => ({ createBrandSustainabilityError: payload })),
	setUpdateBrandSustainability: action((state, payload) => ({ updateBrandSustainabilitySuccess: payload })),
	setUpdateBrandSustainabilityError: action((state, payload) => ({ updateBrandSustainabilityError: payload })),

	
	setCreatePropertyInformationSuccess: action((state, payload) => ({ createPropertyInformationSuccess: payload })),
	setCreatePropertyInformationError: action((state, payload) => ({ createPropertyInformationError: payload })),
  
	setGetPropertyInformationSuccess: action((state, payload) => ({ getPropertyInformationSuccess: payload })),
	setGetPropertyInformationError: action((state, payload) => ({ getPropertyInformationError: payload })),
  
	// thunks

	getPropertyInformation: thunk(async (actions: any, brandId: any) => {
		try {
		  const result = await call('get', `/brand-sv/brand/propertyInformation/${brandId}`, null, null);
		  actions.setGetPropertyInformationSuccess(result.data);
		} catch (error: any) {
		  actions.setGetPropertyInformationError(error.response.data);
		}
	  }),
	
	
	  createPropertyInformation: thunk(async (actions: any, payload: any) => {
		try {
		  const result = await call('post', `/brand-sv/brand/propertyInformation`, payload, null);
		  actions.setCreatePropertyInformationSuccess(result.data);
		} catch (error: any) {
		  actions.setCreatePropertyInformationError(error.response.data);
		}
	  }),

	onGetBrandList: thunk(async (actions: any, payload: any) => {
		try {
			const result = await call('get', '/brand-sv/brand', payload, null);
			actions.setBrandList(result.data);
		} catch (error: any) {
			actions.setBrandListError(error.response.data);
		}
	}),

	onloadBrand: thunk(async (actions: any, payload: any) => {
		try {
			const result = await call('get', `/brand-sv/brand/${payload}`, null, null);
			actions.setLoadBrand(result.data);
		} catch (error: any) {
			actions.setLoadBrandError(error.response.data);
		}
	}),

	onCreateBrand: thunk(async (actions: any, payload: any) => {
		try {
			const result = await call('post', `/brand-sv/brand`, payload, null);
			actions.setCreateBrand(result.data);
		} catch (error: any) {
			actions.setCreateBrandError(error.response.data);
		}
	}),

	onUpdateBrand: thunk(async (actions: any, payload: any) => {
		try {
			const result = await call('patch', `/brand-sv/brand`, payload, null);
			actions.setUpdateBrand(result.data);
		} catch (error: any) {
			actions.setUpdateBrandError(error.response.data);
		}
	}),

	onApprovalBrand: thunk(async (actions: any, payload: any) => {
		try {
			const result = await call('patch', `/brand-sv/brand`, payload, null);
			actions.setApprovalBrand(result.data);

			//socket Notification
			// let notificationData = `Your brand section has been <b>approved</b>`;
			// await socket.emit("send_message", notificationData);
		} catch (error: any) {
			actions.setApprovalBrandError(error.response.data);
		}
	}),


	

	
	onReviewBrand: thunk(async (actions: any, payload: any) => {
		try {
			const result = await call('patch', `/brand-sv/brand`, payload, null);
			actions.setReviewBrand(result.data);

			//socket Notification
			// let notificationData = `Your brand section has been <b>approved</b>`;
			// await socket.emit("send_message", notificationData);
		} catch (error: any) {
			actions.setReviewBrandError(error.response.data);
		}
	}),

	onBrandUpdateBackground: thunk(async (actions: any, payload: any) => {
		await call('patch', `/brand-sv/brand`, payload, null);
	}),

	onLoadBrandSignUp: thunk(async (actions: any, payload: any) => {
		try {
			const result = await call('get', `/brand-sv/brand-signup?start_date=${payload?.start_date}&end_date=${payload?.end_date}&brand_id=${payload?.brand_id}&sign_up_status=${payload?.sign_up_status}`, null, null);
			actions.setLoadBrandsignupSuccess(result.data);
		} catch (error: any) {
			actions.setLoadBrandsignupError(error.response.data);
		}
	}),

	onLoadBrandSignUpList: thunk(async (actions: any, payload: any) => {
		try {
			const result = await call('get', `/brand-sv/brand-signup?start_date=${payload?.start_date}&end_date=${payload?.end_date}&brand_id=${payload?.brand_id}&sign_up_status=${payload?.sign_up_status}`, null, null);
			actions.setLoadBrandsignupListSuccess(result.data);
		} catch (error: any) {
			actions.setLoadBrandsignupListError(error.response.data);
		}
	}),


	onLoadSingleBrandSignup: thunk(async (actions: any, payload: any) => {
		try {
			const result = await call('get', `/brand-sv/brand-signup/${payload}`, null, null);
			actions.setGetSingleSignUpSuccess(result.data);
		} catch (error: any) {
			actions.setGetSingleSignUpError(error.response.data);
		}
	}),

	onUpdateBrandSignup: thunk(async (actions: any, payload: any) => {
		try {
			const result = await call('patch', `/brand-sv/brand-signup`, payload, null);
			actions.setUpdateBrandSignUpSuccess(result.data);
		} catch (error: any) {
			actions.setUpdateBrandSignUpError(error.response.data);
		}
	}),

	onCreateBrandSignUp: thunk(async (actions: any, payload: any) => {
		try {

			/////////// digital audit
			// const data = {
			// 	clientID: BrandAuditIds.client_id,
			// 	security: {
			// 		apiKey: process.env.REACT_APP_AUDIT_API_KEY,
			// 		user: "superadmin@mysa.global"
			// 	},
			// 	templateID: BrandAuditIds.template_id
			// };
			// const  dataDigitalAuditResponse  = await callAudit('post', 'certificate/create', data, null);

			////////// myo management

			const myoData = {
				clientID: BrandMyoManagementIds.client_id,
				security: {
					apiKey: process.env.REACT_APP_AUDIT_API_KEY,
					user: "superadmin@mysa.global"
				},
				templateID: BrandMyoManagementIds.template_id
			};
			const dataMyoManagementResponse = await callMYOManagement('post', 'certificate/create', myoData, null);

			const result = await call('post', `/brand-sv/brand-signup`, { ...payload, certificateMyoManagementID: dataMyoManagementResponse.data?.certificateID }, null);
			actions.setCreateBrandSignupSuccess(result.data);
		} catch (error: any) {
			actions.setCreateBrandSignupError(error.response.data);
		}
	}),

	// send brand survey email
	sendBrandDetailSubmitSurveyEmail: thunk(async (actions: any, payload: any) => {
		try {
			const result = await call('post', `/brand-sv/brandDetailSubmitSurveyEmail`, payload, null);
			actions.setSendBrandDetailSubmitSurveyEmail(result.data);
		} catch (error: any) {
			actions.setSendBrandDetailSubmitSurveyEmailError(error.response.data);
		}
	}),

	// get brand finance details
	getBrandFinanceDetailByBrandId: thunk(async (actions: any, brandId: any) => {
		try {
			const result = await call('get', `/brand-sv/brand/brandFinance/${brandId}`, null, null);
			actions.setGetBrandFinanceDetail(result.data);
		} catch (error: any) {
			actions.setGetBrandFinanceDetailError(error.response.data);
		}
	}),

	// create new brand finance
	createBrandFinanceDetail: thunk(async (actions: any, payload: any) => {
		try {
			const result = await call('post', '/brand-sv/brand/brandFinance', payload, null);
			actions.setCreateBrandFinance(result.data);
		} catch (error: any) {
			actions.setCreateBrandFinanceError(error.response.data);
		}
	}),

	// update brand finance
	updateBrandFinanceDetail: thunk(async (actions: any, payload: any) => {
		try {
			const result = await call('patch', '/brand-sv/brand/brandFinance', payload, null);
			actions.setUpdateBrandFinance(result.data);
		} catch (error: any) {
			actions.setUpdateBrandFinanceError(error.response.data);
		}
	}),

	////////////////////////////////Send brand application review rejected mail/////////////////////////////////////
	sendBrandApplicationReviewRejectedMail: thunk(async (actions: any, payload: any) => {
		try {
			const result = await call('post', `/brand-sv/brand-application-review-rejected-mail`, payload, null);
			actions.setSendBrandApplicationReviewRejectedMail(result.data);
		} catch (error: any) {
			actions.setSendBrandApplicationReviewRejectedMailError(error.response.data);
		}
	}),

	///////////////// get all brand list
	getBrandListNewVersion: thunk(async (actions: any, payload: any) => {
		try {
			const result = await call('get', `/brand-sv/brand/getAllBrandForUser?brand_status=${payload.brand_status}&property_status=${payload.property_status}&brand_id=${payload.brand_id}`, null, null);
			actions.setBrandListNewVersionSuccess(result.data);
		} catch (error: any) {
			actions.setBrandListNewVersionError(error.response.data);
		}
	}),


	
	///////////////// get all brand list
	getBrandListNew: thunk(async (actions: any, payload: any) => {
		try {
			const result = await call('get', `/brand-sv/brand/getAllBrandForUser?brand_status=${payload.brand_status}&property_status=${payload.property_status}&brand_id=${payload.brand_id}`, null, null);
			actions.setBrandListNewSuccess(result.data);
		} catch (error: any) {
			actions.setBrandListNewError(error.response.data);
		}
	}),


	// get brand details
	getBrandDetails: thunk(async (actions: any, payload: any) => {
		try {
			const result = await call('get', `/brand-sv/brand-details/${payload.brand_id}`, null, null);
			actions.setBrandDetailsSuccess(result.data);
		} catch (error: any) {
			actions.setBrandDetailsError(error.response.data);
		}
	}),


	getBrandSustainabilityByBrandId: thunk(async (actions: any, brandId: any) => {
		try {
			const result = await call('get', `/brand-sv/brand/brandSustainability/${brandId}`, null, null);
			actions.setGetBrandSustainabilityByBrandId(result.data);
		} catch (error: any) {
			actions.setGetBrandSustainabilityByBrandIdError(error.response.data);
		}
	}),

	createBrandSustainability: thunk(async (actions: any, payload: any) => {
		try {
			const result = await call('post', `/brand-sv/brand/brandSustainability`, payload, null);
			actions.setCreateBrandSustainability(result.data);
		} catch (error: any) {
			actions.setCreateBrandSustainabilityError(error.response.data);
		}
	}),

	updateBrandSustainability: thunk(async (actions: any, payload: any) => {
		try {
			const result = await call('patch', `/brand-sv/brand/brandSustainability`, payload, null);
			actions.setUpdateBrandSustainability(result.data);
		} catch (error: any) {
			actions.setUpdateBrandSustainabilityError(error.response.data);
		}
	}),

}