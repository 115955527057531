import { FC, useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment";
import DataTable from "react-data-table-component";
import { $mysaTeal, $mysaYellow, overlayTriggerDelay, tableStyles } from "../../../common/components-style";
import { OverlayTrigger, Stack, Tooltip } from "react-bootstrap";
import ViewEdit from '../../../images/icons/svg/eyeIcon.svg';

import StatusIcon from '../../../images/icons/svg/correct.svg';
import CircleIcon from '../../../images/icons/svg/circle.svg';

import { useStoreActions, useStoreState } from "easy-peasy";
import { toast } from "react-toastify";
import { getStorage } from "../../../common/storage";
import { useHistory } from "react-router-dom";


const ProgrammeActivation: FC<any> = ({ programmeId, selectedClient, selectedProgramme }): JSX.Element => {


    const [temData, setTemData] = useState<any>([]);
    const history = useHistory();

    const {
        getAllSubmittedBrandRfpProcessList,
        updateRfpProgrammeStatus
    } = useStoreActions<any>((actions) => ({
        getAllSubmittedBrandRfpProcessList: actions.rfpProcess.getAllSubmittedBrandRfpProcessList,
        updateRfpProgrammeStatus: actions.rfpProcess.updateRfpProgrammeStatus
    }));


    const {


        getUpdateRfpProgrammeStatusSuccess,
        getUpdateRfpProgrammeStatusError,
        getAllSubmittedBrandRfpProcessListSuccess
    } = useStoreState<any>((state) => ({
        getUpdateRfpProgrammeStatusSuccess: state.rfpProcess.getUpdateRfpProgrammeStatusSuccess,
        getUpdateRfpProgrammeStatusError: state.rfpProcess.getUpdateRfpProgrammeStatusError,
        getAllSubmittedBrandRfpProcessListSuccess: state.rfpProcess.getAllSubmittedBrandRfpProcessListSuccess
    }));

    // refresh 
    useEffect(() => {
        getAllSubmittedBrandRfpProcessList();
    }, [getAllSubmittedBrandRfpProcessList]
    );

    //////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (getUpdateRfpProgrammeStatusSuccess) {
            toast.success("Programme activated successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            getAllSubmittedBrandRfpProcessList();
        }

        toast.error(getUpdateRfpProgrammeStatusError?.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: 'foo-bar'
        });

    }, [getAllSubmittedBrandRfpProcessList, getUpdateRfpProgrammeStatusError, getUpdateRfpProgrammeStatusSuccess])

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (getAllSubmittedBrandRfpProcessListSuccess) {
            const filterData = getAllSubmittedBrandRfpProcessListSuccess?.data.filter((user) => user.rfpId.rfpType === 'primary' && user.rfpId.programmeId?._id === programmeId && user.releaseStatus === true);
            setTemData(filterData);
        }

    }, [getAllSubmittedBrandRfpProcessListSuccess, programmeId])

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const data = useMemo(() => {
        return temData?.map((rfpData) => {
            const { _id, rfpName, accommodationType, rfpProgrammeActivateStatus, rfpProgrammeActivateEndDate, rfpProgrammeActivateStartDate, endDate, locations, clientId, programmeId, overviewForInterview, startDate, questionSubmitDate } = rfpData?.rfpId;
            const brandSpecificLocations = rfpData?.locations;
            const cities = locations.map((item: any) => item.cities)
            const citiesArray = [].concat.apply([], cities);
            const cityNames = citiesArray.map((item: any) => item.cityName);
            // console.log(rfpData, 'rfp');
            if ((rfpData?.accepted !== 0) || (rfpData?.accepted > 0)) {
                return {
                    key: temData?._id,
                    endDate,
                    locations: cityNames?.length,
                    locationsDetails: locations,
                    companyName: clientId?.companyName,
                    clientId: clientId?._id,
                    programmeName: programmeId?.programmeName,
                    programmeId: programmeId?._id,
                    overviewForInterview,
                    startDate,
                    questionSubmitDate,
                    brandId: temData?.brandId,
                    rfpId: _id,
                    brandSpecificLocations: brandSpecificLocations,
                    brandRfpStatus: rfpData?.brandRfpStatus,
                    brandRfpId: rfpData?._id,
                    certificateIdMYOManagement: rfpData?.certificateIdMYOManagement,
                    rfpSubmission: rfpData?.rfpSubmission,
                    brandSurveyDetailSubmission: rfpData?.brandSurveyDetailSubmission,
                    propertySurveyDetailSubmission: rfpData?.propertySurveyDetailSubmission,
                    propertyCount: rfpData?.properties,
                    released: rfpData?.releasedDate,
                    accepted: rfpData?.accepted,
                    declined: rfpData?.decline,
                    negotiate: rfpData?.negotiate,
                    rfpProgrammeActivateStatus: rfpProgrammeActivateStatus,
                    rfpProgrammeActivateEndDate: rfpProgrammeActivateEndDate,
                    rfpProgrammeActivateStartDate: rfpProgrammeActivateStartDate,
                    rfpName: rfpName,
                    accommodationType: accommodationType

                }
            }
        });


    }, [temData]);

    ///////////////////////////////////////////////////////////////////////////////////////////////////////
    const redirectProgramme = () => {
        history.push('/admin/corporate-programme-management', { programmeId: programmeId, selectedClient: selectedClient, selectedProgramme: selectedProgramme })
    }

    const activateRfp = useCallback((data) => {

        const payload = {
            rfpId: data?.rfpId,
            programmeId: getStorage('programmeId')
        }
        updateRfpProgrammeStatus(payload);
    }, [updateRfpProgrammeStatus]);


    const columns1 = [
        {
            name: 'Programme Name',
            selector: row => row.rfpName,
            minWidth: '250px',
        },
        {
            name: 'Start Date',
            selector: row => row.rfpProgrammeActivateStartDate ? moment(row.rfpProgrammeActivateStartDate).format("D MMM YYYY") : '',
            minWidth: '100px',
        },
        {
            name: 'End Date',
            selector: row => row.rfpProgrammeActivateEndDate ? moment(row.rfpProgrammeActivateEndDate).format("D MMM YYYY") : '',
            minWidth: '100px',
        },

        {
            name: 'Accommodation',
            // width: '200px',
            selector: (row) => {
                let status = "";
                if (row.accommodationType === 'both') status = 'Both';
                if (row.accommodationType === 'serviced_apartments') status = 'Serviced Accommodation';
                if (row.accommodationType === 'hotel') status = 'Hotel';
                return (
                    <span >{status}</span>
                )
            }
        },

        {
            name: 'No of Locations',
            // miniWidth: '100px',
            // width: '200px',
            selector: row => row.locations,
        },
        {
            name: "Status",
            // width: '200px',
            selector: (row) => {
                let color = $mysaYellow;
                let text = '';
                const totalAcceptedDeclined = row?.accepted + row?.declined;

                // Check if the total is equal to the properties count
                const isTotalEqual = totalAcceptedDeclined === row?.propertyCount;
     
                if (row.rfpProgrammeActivateStatus === 'Current') {
                    color = $mysaTeal;
                    text = 'Current';
                }

                if (row.rfpProgrammeActivateStatus === 'Expired') {
                    color = $mysaTeal;
                    text = 'Expired';
                }

                if (isTotalEqual && row.rfpProgrammeActivateStatus === 'On Going') {
                    color = $mysaYellow;
                    text = 'Awaiting Activation';
                }


                if ((row.rfpProgrammeActivateStatus === 'On Going' && row.accepted >= 1 && !isTotalEqual)) {
                    color = $mysaYellow;
                    text = 'On Going';
                }
              
                return (
                    <span style={{ color: color, fontWeight: 'bold', textTransform: 'capitalize' }} className='text-capitalize'>{text}</span>
                )
            }
        },

        {
            name: "Action",
            button: true,
            key: 'action',
            wrap: true,
            minWidth: '300px',
            cell: (data: any) => {
                const totalAcceptedDeclined = data?.accepted + data?.declined;

                // Check if the total is equal to the properties count
                const isTotalEqual = totalAcceptedDeclined === data?.propertyCount;
         
                return (
                    <Stack direction="horizontal" gap={3}>
                        {data?.rfpProgrammeActivateStatus !== 'Current' && <OverlayTrigger
                            placement="top"
                            delay={overlayTriggerDelay}
                            overlay={<Tooltip id='tooltip-table-top'>View / Edit</Tooltip>}
                        >
                            <img className='iconSizes mr-2' style={(data?.rfpProgrammeActivateStatus === 'Expired') ? { marginLeft: '0px' } : { marginLeft: '50px' }} src={ViewEdit} alt=""
                                onClick={redirectProgramme}
                            />

                        </OverlayTrigger>}
                        {
                            ((data?.rfpProgrammeActivateStatus === 'On Going') && isTotalEqual) &&
                            <OverlayTrigger
                                placement="top"
                                delay={overlayTriggerDelay}
                                overlay={<Tooltip id='tooltip-table-top'>{'Activate'}</Tooltip>}
                            >
                                <img className='mr-2 iconSizes5' src={CircleIcon} alt=""
                                    onClick={() => {
                                        activateRfp(data);
                                    }}
                                />
                            </OverlayTrigger>}

                        {
                            data?.rfpProgrammeActivateStatus === 'Current' &&
                            <OverlayTrigger
                                placement="top"
                                delay={overlayTriggerDelay}
                                overlay={<Tooltip id='tooltip-table-top'>{'Activated'}</Tooltip>}
                            >
                                <img className='mr-2 iconSize' src={StatusIcon} alt="" />
                            </OverlayTrigger>}
                    </Stack>
                )
            }
        }
    ];

    return (
        <div>
            <DataTable
                columns={columns1}
                data={data}
                pagination
                responsive
                customStyles={tableStyles}
                striped={true}
                theme='mysaTable'
                fixedHeader={true}
                noDataComponent={'Updating records'}
            />
        </div>
    )
}

export default ProgrammeActivation;