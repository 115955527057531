import { Select } from 'antd';
import { useStoreActions, useStoreState } from 'easy-peasy';
import React, { useCallback, useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import currency from '../../../../common/currency.json';
import timezones from '../../../../common/timezone.json';
import GoogleMap from '../../../common/GoogleMap';
import GooglePlaces from '../../../common/GooglePlaces';
import { canBrandHotelModuleWrite, formatPhoneNumber, getLoggedUserType, getMailDomain } from '../../../../common/functions';
import { UserAccess, HotelStatus, UserGroups } from '../../../../common/constants';
import HotelImageUpload from '../../../common/HotelImageUpload';
import { Controller, useForm } from 'react-hook-form';
import { HotelFormInput } from '../../../../common/interfaces';
import { Row, Col, Container, Form, Button, Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { formButton, overlayTriggerDelay, toolTipStyle } from '../../../../common/components-style';
import { toast } from 'react-toastify';
import { ErrorMessage } from '@hookform/error-message';
import BreadCrumbPage from '../../../common/BreadCrumbPage';
import moment from 'moment'
import DatePicker from "react-datepicker";
import { PhoneNumberUtil } from 'google-libphonenumber';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
const { Option } = Select;


const HotelDetailsForm: React.FC<any> = ({ allInOneHotelBreadCrumb, hotelId, selectedBrand, setFormOpen, loadData, tab }): JSX.Element => {
	const { register, watch, handleSubmit, formState: { errors }, control, setValue, clearErrors } = useForm<HotelFormInput>();
	const [editEnable, setEditEnable] = useState<boolean>(false);
	const [hotelData, setHotelData] = useState<any>();
	const [location, setLocation] = useState<any>(null);
	const [coordinates, setCoordinates] = useState<any>(null);
	const [phone, setPhone] = useState<string>();
	const [loading, setLoading] = useState<boolean>(false);
	const [hotelLogo, setHotelLogo] = useState(null);
	const [hotelLogoUpdated, setHotelLogoUpdated] = useState(false);
	const [locationDataList, setLocationDataList] = useState<any>(null);
	const [propertyLocationTypeList, setPropertyLocationTypeList] = useState<any>();
	const [marketTierTypeList, setMarketTierTypeList] = useState<any>();
	const [hotelPropertyTypeList, setHotelPropertyTypeList] = useState<any>();
	const [validPhoneNumber, setValidPhoneNumber] = useState<boolean>(false);
	const [enableApprovalMessage, setEnableApprovalMessage] = useState<boolean>(true);

	const [brandDomains, setBrandDomains] = useState<Array<string>>([]);
	const [hotelInventoryList, setHotelInventoryList] = useState<number>(0);
	const [skipValidation, setSkipValidation] = useState<boolean>(false);
	const watchedFields = watch();
	const country = watch("country");
	////////////////////////////////////////////////////////////////////////////////////////////////////
	const { onloadBrand, loadBrandList, createHotel, updateHotel, getHotel, resetHotel, getHotelPropertyLocationTypes, getHotelMarketTierTypes, getHotelPropertyTypes, getHotelInventoryList } = useStoreActions<any>((actions) => ({
		loadBrandList: actions.common.loadBrandList,
		createHotel: actions.hotel.createHotel,
		updateHotel: actions.hotel.updateHotel,
		getHotel: actions.hotel.getHotel,
		getHotelPropertyLocationTypes: actions.hotel.getHotelPropertyLocationTypes,
		getHotelMarketTierTypes: actions.hotel.getHotelMarketTierTypes,
		getHotelPropertyTypes: actions.hotel.getHotelPropertyTypes,
		resetHotel: actions.hotel.resetHotel,
		onloadBrand: actions.brand.onloadBrand,
		getHotelInventoryList: actions.hotelInventory.getHotelInventoryList
	}));

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const {
		brandList,
		createHotelSuccess,
		createHotelError,
		getHotelSuccess,
		getHotelError,
		updateHotelError,
		updateHotelSuccess,
		getPropertyTypesSuccess,
		getHotelPropertyLocationTypesSuccess,
		getHotelMarketTierTypesSuccess,
		getHotelPropertyTypesSuccess,
		loadBrandSuccess,
		getHotelInventoryListSuccess,
		getHotelInventoryListError,
	} = useStoreState<any>((state) => ({
		brandList: state.common.brandList,
		createHotelSuccess: state.hotel.createHotelSuccess,
		createHotelError: state.hotel.createHotelError,
		getHotelSuccess: state.hotel.getHotelSuccess,
		getHotelError: state.hotel.getHotelError,
		updateHotelError: state.hotel.updateHotelError,
		updateHotelSuccess: state.hotel.updateHotelSuccess,
		getPropertyTypesSuccess: state.property.getPropertyTypesSuccess,
		getHotelPropertyLocationTypesSuccess: state.hotel.getHotelPropertyLocationTypesSuccess,
		getHotelMarketTierTypesSuccess: state.hotel.getHotelMarketTierTypesSuccess,
		hotelReviewSuccess: state.hotel.hotelReviewSuccess,
		getHotelPropertyTypesSuccess: state.hotel.getHotelPropertyTypesSuccess,
		loadBrandSuccess: state.brand.loadBrandSuccess,
		getHotelInventoryListSuccess: state.hotelInventory.getHotelInventoryListSuccess,
		getHotelInventoryListError: state.hotelInventory.getHotelInventoryListError,
	}));

	/**
	 * 
	 */
	////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		(async function () {
			if (hotelId) {
				await getHotel(hotelId)
			} else {
				setEditEnable(true);
			}

			await loadBrandList();
			await getHotelPropertyTypes();
			await getHotelPropertyLocationTypes();
			await getHotelMarketTierTypes();
		}())
	}, [getHotel, getHotelPropertyTypes, getHotelMarketTierTypes, getHotelPropertyLocationTypes, loadBrandList, hotelId]);

	const scrollToTop = () => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	};

	useEffect(() => {
		if (loadBrandSuccess) {
			setBrandDomains(loadBrandSuccess.data?.allowedDomains);
		}
	}, [loadBrandSuccess])

	useEffect(() => {
		if (hotelId) {
			const params: any = {};
			params.hotelId = hotelId;
			getHotelInventoryList(params);
		}

	}, [getHotelInventoryList, hotelId]);

	useEffect(() => {
		if (getHotelInventoryListSuccess) {
			setHotelInventoryList(getHotelInventoryListSuccess?.data?.count?.total);
		}
		if (getHotelInventoryListError) {
			toast.error(getHotelInventoryListError.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});

		}
	}, [getHotelInventoryListError, getHotelInventoryListSuccess]);

	//////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (createHotelSuccess) {
			loadData(createHotelSuccess.data._id);
			resetHotel();
			toast.success("Hotel created successfully", {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
			setEditEnable(false);
			scrollToTop();
			getHotel(createHotelSuccess.data._id);
		}

		if (updateHotelSuccess) {
			resetHotel();
			toast.success("Hotel updated successfully", {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
			setEditEnable(false);
			scrollToTop();
			getHotel(updateHotelSuccess.data._id);
			setEnableApprovalMessage(false);

		}
		if (getHotelPropertyLocationTypesSuccess?.data?.length) {
			const typeOfHotelPropertyLocation = getHotelPropertyLocationTypesSuccess?.data?.map((val: any, key: number) => {
				return (
					<Option key={key} data={val} value={`${val._id}`} >{val.name}</Option>
				)
			});
			setPropertyLocationTypeList(typeOfHotelPropertyLocation)

		}

		if (getHotelMarketTierTypesSuccess?.data?.length) {
			const typeOfHotelMarketTier = getHotelMarketTierTypesSuccess?.data?.map((val: any, key: number) => {
				return (
					<Option key={key} data={val} value={`${val.name}`} >{val.name}</Option>
				)
			});
			setMarketTierTypeList(typeOfHotelMarketTier)

		}

		if (getHotelPropertyTypesSuccess?.data?.length) {
			const typeOfHotelProperty = getHotelPropertyTypesSuccess?.data?.map((val: any, key: number) => {
				return (
					<Option key={key} data={val} value={`${val._id}`} >{val.name}</Option>
				)
			});
			setHotelPropertyTypeList(typeOfHotelProperty)

		}


		if (getHotelSuccess) {
			const {
				latitude,
				longitude,
				hotelLogo: hotelLogoVal,
				countryCode,
				placeId,
				brandId,
			} = getHotelSuccess.data;
			onloadBrand(brandId?._id);
			setHotelData(getHotelSuccess.data);
			setPhone(getHotelSuccess.data?.telephone);
			setLocation({ lat: Number(latitude), lng: Number(longitude) });
			setLocationDataList({ lat: latitude, lng: longitude, countryCode: countryCode, placeId: placeId })
			setHotelLogo(hotelLogoVal);
		}

		if (createHotelError) {
			toast.error(createHotelError.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
		}

		if (getHotelError) {
			toast.error(getHotelError.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
		}

		if (updateHotelError) {
			toast.error(updateHotelError.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
		}
		setLoading(false);
	}, [createHotelError, createHotelSuccess, enableApprovalMessage, getHotel, getHotelError, getHotelSuccess, getPropertyTypesSuccess, loadData, onloadBrand, hotelId, resetHotel, setValue, updateHotelError, updateHotelSuccess, getHotelPropertyLocationTypesSuccess, getHotelMarketTierTypesSuccess, getHotelPropertyTypesSuccess]);

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const renderBrandList = useCallback(() => {
		if (brandList) {
			return brandList.data.map((brand: any, key: number) => {
				return (
					<Option key={key} brand={brand} value={`${brand._id}`} >{brand.name}</Option>
				)
			});
		}

	}, [brandList]);

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const renderCurrency = useCallback(() => {
		if (currency) {
			return currency.map((cur: any, key: number) => {
				return (
					<Option key={cur.cc} value={`${cur.cc}`} >{cur.name} <span className="text-blue-400 font-bold ml-2">({cur.symbol}) - {cur.cc}</span></Option>
				)
			});
		}

	}, []);

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const renderTimezone = useCallback(() => {

		if (timezones) {
			return timezones.map((tz: any, key: number) => {
				return (
					<Option key={tz} value={`${tz}`} >{tz}</Option>
				)
			});
		}

	}, []);

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const setCoords = useCallback((coords) => {
		setCoordinates({ lat: coords.lat, lng: coords.lng });
	}, []);

	const setLocationData = useCallback((location) => {
		setValue("country", location?.country)
		clearErrors("country");
		setLocation(location);
	}, [setValue, clearErrors]);

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const onFinish = (values) => {
		if (getLoggedUserType() !== UserGroups.super_admin) {

			if (!values.hotelChain) {
				toast.error('Please enter a hotel Chain!', {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'foo-bar'
				});
				return;
			}

			if (!values.hotelName) {
				toast.error('Please enter a hotel name!', {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'foo-bar'
				});
				return;
			}

			if (!values.brandId) {
				toast.error('Please enter a brand!', {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'foo-bar'
				});
				return;
			}

			if (values.telephone && !validPhoneNumber) {
				toast.error('Please enter a valid phone number!', {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'foo-bar'
				});
				return;
			}

			if (values.telephone && !validatePhoneNumber(values.telephone)) {
				toast.error('Please enter valid phone number!', {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'foo-bar'
				});
				return;
			}
			if (values.hotelNotificationEmail) {
				const getDomainValidation = getMailDomain(values.hotelNotificationEmail, brandDomains);
				if (!getDomainValidation) {
					toast.error('Invalid brand domain. Please check your notification e-mail address domain with brand domains', {
						position: toast.POSITION.BOTTOM_RIGHT,
						className: 'foo-bar'
					});
					return;
				}
			}

			if (hotelInventoryList > values.noOfRoomTypesOffered) {
				toast.error('The number of inventories is less than the number of inventories created. Please delete inventories in ‘Inventory Management’ first.', {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'foo-bar'
				});
				return;
			}
		}
		delete values.location;
		values.telephone = phone;
		values.placeId = location ? location?.placeId : locationDataList?.placeId;
		values.hotelLogoUpdated = hotelLogoUpdated;
		//////////////////////////whichRateChanelManager submit value manage////////////////////////////
		if (coordinates) {
			values.latitude = coordinates.lat.toString();
			values.longitude = coordinates.lng.toString();
		} else {
			values.latitude = location ? location?.lat.toString() : locationDataList?.lat;
			values.longitude = location ? location?.lng.toString() : locationDataList?.lng;
			values.countryCode = location ? location?.countryCode : locationDataList?.countryCode;
		}
		setLoading(true);
		values.noOfRoomTypesOffered = values.noOfRoomTypesOffered ? values.noOfRoomTypesOffered : 0;
		values.noOfRooms = values.noOfRooms ? values.noOfRooms : 0;
		values.noOfFloors = values.noOfFloors ? values.noOfFloors : 0;

		if (hotelId) {
			delete values.brandId;
			values.id = hotelId;

			values.hotelLogo = hotelLogoUpdated ? hotelLogo : values.hotelLogo;
			values.moderate = {
				noOfRoomTypesOffered: {
					new_value: values.noOfRoomTypesOffered ? values.noOfRoomTypesOffered : 0
				},
				description: {
					new_value: values.description
				}
			};
			if (hotelData?.hotelStatus === HotelStatus.inprogress) {
				values.hotelStatus = HotelStatus.awaiting;
			}
			updateHotel(values);

		} else {
			values.hotelLogo = hotelLogo;
			values.hotelStatus = isFormComplete() ? HotelStatus.awaiting : HotelStatus.inprogress;
			createHotel(values)
		}

	};

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const getFileBlob = useCallback((fileBlob) => {
		setHotelLogo(fileBlob);
		setHotelLogoUpdated(true);
	}, []);

	const changeFormEditable = useCallback(() => {
		scrollToTop();
		if (editEnable) {
			setEditEnable(false);
		} else {
			setEditEnable(true);
		}
	}, [editEnable]);


	///////////////////////////whichRateChanelManager Validation/////////////////////////////////////////

	const validatePhoneNumber = (inputNumber: string) => {
		let number = formatPhoneNumber(inputNumber)

		let valid = false;
		let isPossible = false;
		try {
			const phoneUtil = PhoneNumberUtil.getInstance();
			isPossible = phoneUtil.isPossibleNumber(phoneUtil.parse(number))
			valid = phoneUtil.isValidNumber(phoneUtil.parse(number));
		} catch (e) {
			valid = false;
		}

		if (isPossible && valid) {

			setValidPhoneNumber(true);
			return true;
		} else {
			setValidPhoneNumber(false);
			return false;
		}
	};

	const isFormComplete = () => {
		const requiredFields = [
			'hotelChain',
			'brandId',
			'propertyType',
			'hotelName',
			'yearPropertyWasBuilt',
			'whenWereCommunalAreasLastRenovated',
			'description',
			'telephone',
			'noOfRoomTypesOffered',
			'timezone',
			'currency',
			'country',
			'address1',
			'city',
			'postcode',
			'propertyLocationType',
			'hotelWebsiteAddress',
			'hotelNotificationEmail',
			'marketTierType',
			'noOfRooms',
			'noOfFloors',
		];

		const missingRequiredFields = requiredFields.filter(field => {
			const fieldValue = watchedFields[field];

			return fieldValue == null || (typeof fieldValue === 'string' && fieldValue.trim() === '');
		});
		return missingRequiredFields.length === 0;
	};

	const hasChanges = () => {
		const initialValues = {
			hotelChain: '',
			brandId: '',
			propertyType: '',
			hotelName: '',
			yearPropertyWasBuilt: '',
			whenWereCommunalAreasLastRenovated: '',
			description: '',
			telephone: '',
			noOfRoomTypesOffered: 0,
			timezone: '',
			currency: '',
			country: '',
			address1: '',
			address2: '',
			county: '',
			city: '',
			postcode: '',
			propertyLocationType: '',
			hotelWebsiteAddress: '',
			hotelNotificationEmail: '',
			marketTierType: '',
			noOfRooms: 0,
			noOfFloors: 0,
			province: '',
			hotelLogo: null,
		};

		const ignoredFields = ['hotelChain', 'brandId'];
		const currentValues = watch();

		const normalizeValue = (value) => {
			if (value === 'null') {
				return null;
			}
			return value;
		};

		const isFieldModified = (field, currentValue, initialValue) => {
			const normalizedCurrentValue = normalizeValue(currentValue);
			const normalizedInitialValue = normalizeValue(initialValue);

			if (ignoredFields.includes(field)) return false;

			return (normalizedCurrentValue || '') !== (normalizedInitialValue || '');
		};

		const modifiedFields = Object.keys(currentValues).filter(field => {
			const currentValue = currentValues[field];
			const initialValue = initialValues[field];
			const hasChanged = isFieldModified(field, currentValue, initialValue);

			return hasChanged;
		});
		return modifiedFields.length > 0;
	};

	const handleBreadcrumbClick = (e, breadcrumbType) => {

		const isComplete = isFormComplete();
		const hasFieldChanges = hasChanges();

		if (!hotelId && !isComplete && hasFieldChanges) {
			e.preventDefault();
			const currentValues = watch();
			onFinish(currentValues);
		}
		else {
			if (breadcrumbType === 'hotel') {
				setFormOpen(false);
			}
		}
	};

	/////////////////////////////////////////////////////////////////////////////////////////////////////////////

	return (
		<>
			<div className="content-title fixed_title">
				{
					<BreadCrumbPage allInOneHotelBreadCrumb={allInOneHotelBreadCrumb} isPageName="Hotel" className="mt-1" selectedBrand={selectedBrand} hotelData={hotelData} setFormOpen={setFormOpen} tab={tab} onClick={handleBreadcrumbClick} ></BreadCrumbPage>
				}
				<div className='ml-auto' style={{ position: "relative", zIndex: 9999 }}>
					<div className='d-flex align-items-center justify-content-end gx-2'>
						{
							!loading && !editEnable && hotelId && canBrandHotelModuleWrite(selectedBrand.key ? selectedBrand.key : selectedBrand._id, hotelId, UserAccess.hotel_access.details.code) && <Button id='edit-button' style={formButton} className="mb-0" onClick={changeFormEditable} >Edit</Button>
						}
					</div>
				</div>
			</div>

			<Container fluid className="p-0">
				<div className="jumbotron" style={{ marginTop: "70px" }}>
					<Form onSubmit={handleSubmit(onFinish)}>
						<Row>
							<Col md={1}></Col>
							<Col md={10}>

								<Row className='formSpace'>
									<Col md={6} style={{ textAlign: 'right' }}>
										<Form.Label>{editEnable && <span className='requiredMark'>*</span>} Hotel Chain</Form.Label>
									</Col>
									<Col md={6} style={{ textAlign: 'left' }}>
										{!editEnable && <p className="lead">{selectedBrand?.parentAccommodationCompany}</p>}
										{editEnable && <Form.Control readOnly defaultValue={selectedBrand?.parentAccommodationCompany} className={`${errors.hotelChain ? 'is-invalid' : ''}`} {...register("hotelChain", { required: getLoggedUserType() === UserGroups.super_admin ? false : true })} />}
										{editEnable && getLoggedUserType() !== UserGroups.super_admin && <div className="invalid-feedback">Please input hotel chain!</div>}
									</Col>
								</Row>
								<Row className='formSpace'>
									<Col md={6} style={{ textAlign: 'right' }}>
										<Form.Label>{editEnable && <span className='requiredMark'>*</span>} Brand Name / Management Company</Form.Label>
									</Col>
									<Col md={6} style={{ textAlign: 'left' }}>
										{!editEnable && <p className="lead">{selectedBrand.name}</p>}
										{editEnable &&
											<Controller
												name="brandId"
												defaultValue={selectedBrand.key ? selectedBrand.key : selectedBrand?._id}
												control={control}
												rules={{
													required: getLoggedUserType() === UserGroups.super_admin ? false : true,
												}}
												render={({ field }) =>
													<Select defaultValue={selectedBrand.key ? selectedBrand.key : selectedBrand?._id} className={`${errors.brandId ? 'is-invalid' : ''}`} style={{ width: '100%' }} onChange={(value) => field.onChange(value)}  showSearch>
														{renderBrandList()}
													</Select>
												}
											/>}
										{editEnable && getLoggedUserType() !== UserGroups.super_admin && <div className="invalid-feedback">Please select a brand!</div>}
									</Col>
								</Row>
								<Row className='formSpace'>
									<Col md={6} style={{ textAlign: 'right' }}>
										<Form.Label>{editEnable && <span className='requiredMark'>*</span>} Property type</Form.Label>
									</Col>
									<Col md={6} style={{ textAlign: 'left' }}>
										{!editEnable && <p className="lead">{hotelData?.propertyType?.name}</p>}
										{editEnable &&
											<Controller
												name="propertyType"
												defaultValue={hotelData?.propertyType?._id}
												control={control}
												rules={{
													required: getLoggedUserType() === UserGroups.super_admin ? false : !skipValidation,
												}}
												render={({ field }) =>
													<Select defaultValue={hotelData?.propertyType?._id} className={`${errors.propertyType ? 'is-invalid' : ''}`} style={{ width: '100%' }} onChange={(value, option) => {
														field.onChange(value);

													}} showSearch>
														{hotelPropertyTypeList}
													</Select>
												}
											/>}
										{editEnable && getLoggedUserType() !== UserGroups.super_admin && <div className="invalid-feedback">Please select a property type!</div>}
									</Col>
								</Row>
								<Row className='formSpace'>
									<Col md={6} style={{ textAlign: 'right' }}>
										<Form.Label>{editEnable && <span className='requiredMark'>*</span>} Name</Form.Label>
									</Col>
									<Col md={6} style={{ textAlign: 'left' }}>
										{!editEnable && <p className="lead">{hotelData?.hotelName}</p>}
										{editEnable && <Form.Control className={`${errors.hotelName ? 'is-invalid' : ''}`} defaultValue={hotelData?.hotelName} {...register("hotelName", { required: getLoggedUserType() === UserGroups.super_admin ? false : true })} />}
										{editEnable && getLoggedUserType() !== UserGroups.super_admin && <div className="invalid-feedback">Please input hotel name!</div>}
									</Col>
								</Row>
								<Row className='formSpace'>
									<Col md={6} style={{ textAlign: 'right' }}>
										<Form.Label>{editEnable && <span className='requiredMark'>*</span>} Year Property was built?</Form.Label>
									</Col>
									<Col md={6} style={{ textAlign: 'left' }}>
										{!editEnable && hotelData?.yearPropertyWasBuilt && <p className="lead">{moment(hotelData?.yearPropertyWasBuilt).format('YYYY')} </p>}
										{editEnable &&
											<Controller
												name="yearPropertyWasBuilt"
												control={control}
												rules={
													{ required: getLoggedUserType() === UserGroups.super_admin ? false : !skipValidation }
												}
												defaultValue={(hotelData?.yearPropertyWasBuilt) ? new Date(hotelData?.yearPropertyWasBuilt) : undefined}
												render={({ field }) =>
													<DatePicker className={`form-control ${errors.yearPropertyWasBuilt ? 'is-invalid' : ''}`} selected={field.value} dateFormat={'yyyy'} showYearPicker={true} onChange={(date) => field.onChange(date)} />}
											/>
										}
										{editEnable && getLoggedUserType() !== UserGroups.super_admin &&
											<ErrorMessage
												errors={errors}
												name="yearPropertyWasBuilt"
												render={({ message }) => <div className="invalid-feedback-custom">Please select year hotel was built!</div>}
											/>
										}
									</Col>
								</Row>
								<Row className='formSpace'>
									<Col md={6} style={{ textAlign: 'right' }}>
										<Form.Label>{editEnable && <span className='requiredMark'>*</span>} When were communal areas last renovated?</Form.Label>
									</Col>
									<Col md={6} style={{ textAlign: 'left' }}>
										{!editEnable && hotelData?.whenWereCommunalAreasLastRenovated && <p className="lead">{moment(hotelData?.whenWereCommunalAreasLastRenovated).format('YYYY')} </p>}
										{editEnable &&
											<Controller
												name="whenWereCommunalAreasLastRenovated"
												control={control}
												rules={
													{ required: getLoggedUserType() === UserGroups.super_admin ? false : !skipValidation }
												}
												defaultValue={(hotelData?.whenWereCommunalAreasLastRenovated) ? new Date(hotelData?.whenWereCommunalAreasLastRenovated) : undefined}
												render={({ field }) =>
													<DatePicker className={`form-control ${errors.whenWereCommunalAreasLastRenovated ? 'is-invalid' : ''}`} selected={field.value} dateFormat={'yyyy'} showYearPicker={true} onChange={(date) => field.onChange(date)} />}
											/>
										}
										{editEnable && getLoggedUserType() !== UserGroups.super_admin &&
											<ErrorMessage
												errors={errors}
												name="whenWereCommunalAreasLastRenovated"
												render={({ message }) => <div className="invalid-feedback-custom">Please select when were communal areas last renovated!</div>}
											/>
										}
									</Col>
								</Row>
								<Row className='formSpace'>
									<Col md={6} style={{ textAlign: 'right' }}>
										<Form.Label>{editEnable && <span className='requiredMark'>*</span>} Description</Form.Label>
									</Col>
									<Col md={6} style={{ textAlign: 'left' }}>
										{!editEnable && <div dangerouslySetInnerHTML={{ __html: hotelData?.moderate?.description?.new_value ? hotelData?.moderate?.description?.new_value : hotelData?.description }}></div>}
										{editEnable && <Controller
											name="description"
											control={control}
											defaultValue={hotelData?.moderate?.description?.new_value ? hotelData?.moderate?.description?.new_value : hotelData?.description}
											rules={{
												required: getLoggedUserType() === UserGroups.super_admin ? false : !skipValidation,

											}}
											render={({ field }) =>
												<CKEditor
													editor={ClassicEditor}
													config={{
														toolbar: {
															items: [
																'heading', '|',
																'undo', 'redo'
															]
														},
														heading: {
															options: [
																{ model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
															]
														}


													}}
													data={hotelData?.moderate?.description?.new_value ? hotelData?.moderate?.description?.new_value : hotelData?.description}
													onChange={(event, editor) => {
														field.onChange(editor.getData())
													}}
												/>
											}
										/>}

										{editEnable && getLoggedUserType() !== UserGroups.super_admin &&
											<ErrorMessage
												errors={errors}
												name="description"
												render={({ message }) => <div className="invalid-feedback-custom">Please input description!</div>}
											/>
										}
									</Col>
								</Row>
								<Row className='formSpace'>
									<Col md={6} style={{ textAlign: 'right' }}>
										<Form.Label>{editEnable && <span className='requiredMark'>*</span>} Telephone</Form.Label>
									</Col>
									<Col md={6} style={{ textAlign: 'left' }}>
										{!editEnable && <p className="lead">{formatPhoneNumber(hotelData?.telephone)}</p>}
										{editEnable &&
											<Controller
												name="telephone"
												control={control}
												defaultValue={hotelData?.telephone}
												rules={{
													required: getLoggedUserType() === UserGroups.super_admin ? false : !skipValidation,
												}}
												render={({ field }) =>
													<PhoneInput
														placeholder=''
														isValid={(inputNumber: string) => {
															return validatePhoneNumber(
																inputNumber,

															);
														}}

														containerClass={`${errors.telephone ? 'is-invalid' : ''}`} value={String(field.value)} country='gb' onChange={(phone) => { field.onChange(phone); setPhone(phone); }} />}
											/>
										}
										{editEnable && getLoggedUserType() !== UserGroups.super_admin && <div className="invalid-feedback">Please input telephone!</div>}
									</Col>
								</Row>

								<Row className='formSpace'>
									<Col md={6} style={{ textAlign: 'right' }}>
										<Form.Label>{editEnable && <span className='requiredMark'>*</span>} No of different room types in the building</Form.Label>
									</Col>
									<Col md={6} style={{ textAlign: 'left' }}>
										{!editEnable && <p className="lead">{hotelData?.moderate?.noOfRoomTypesOffered?.new_value ? hotelData?.moderate?.noOfRoomTypesOffered?.new_value : hotelData?.noOfRoomTypesOffered} </p>}
										{editEnable && <Form.Control type='number' min={0} className={`${errors.noOfRoomTypesOffered ? 'is-invalid' : ''}`} defaultValue={hotelData?.moderate?.noOfRoomTypesOffered?.new_value ? hotelData?.moderate?.noOfRoomTypesOffered?.new_value : hotelData?.noOfRoomTypesOffered} {...register("noOfRoomTypesOffered", { required: getLoggedUserType() === UserGroups.super_admin ? false : !skipValidation })} />}
										{editEnable && getLoggedUserType() !== UserGroups.super_admin && <div className="invalid-feedback">Please input no of different rooms types in the building!</div>}
									</Col>
								</Row>

								<Row className='formSpace'>
									<Col md={6} style={{ textAlign: 'right' }}>
										<Form.Label>{editEnable && <span className='requiredMark'>*</span>} Timezone</Form.Label>
									</Col>
									<Col md={6} style={{ textAlign: 'left' }}>
										{!editEnable && <p className="lead">{hotelData?.timezone}</p>}
										{editEnable &&
											<Controller
												name="timezone"
												defaultValue={hotelData?.timezone}
												control={control}
												rules={{
													required: getLoggedUserType() === UserGroups.super_admin ? false : !skipValidation,
												}}
												render={({ field }) =>
													<Select defaultValue={hotelData?.timezone} className={`${errors.timezone ? 'is-invalid' : ''}`} style={{ width: '100%' }} onChange={(value) => field.onChange(value)} showSearch>
														{renderTimezone()}
													</Select>
												}
											/>}
										{editEnable && getLoggedUserType() !== UserGroups.super_admin && <div className="invalid-feedback">Please select timezone!</div>}
									</Col>
								</Row>
								<Row className='formSpace'>
									<Col md={6} style={{ textAlign: 'right' }}>
										<Form.Label>{editEnable && <span className='requiredMark'>*</span>} Currency</Form.Label>
									</Col>
									<Col md={6} style={{ textAlign: 'left' }}>
										{!editEnable && <p className="lead">{hotelData?.currency}</p>}
										{editEnable &&
											<Controller
												name="currency"
												defaultValue={hotelData?.currency}
												control={control}
												rules={{
													required: getLoggedUserType() === UserGroups.super_admin ? false : !skipValidation,
												}}
												render={({ field }) =>
													<Select defaultValue={hotelData?.currency} className={`${errors.currency ? 'is-invalid' : ''}`} style={{ width: '100%' }} onChange={(value) => field.onChange(value)} showSearch>
														{renderCurrency()}
													</Select>
												}
											/>}
										{editEnable && getLoggedUserType() !== UserGroups.super_admin && <div className="invalid-feedback">Please select currency!</div>}
									</Col>
								</Row>
								<Row className='formSpace'>
									<Col md={6} style={{ textAlign: 'right' }}>
										<Form.Label>{editEnable && <span className='requiredMark'>*</span>} Location</Form.Label>
									</Col>
									<Col md={6} style={{ textAlign: 'left' }}>
										{!editEnable && <p className="lead">{hotelData?.country}</p>}
										{editEnable && <GooglePlaces onPlaceSelect={(location) => setLocationData(location)} />}
										{errors.country && (
											<div className="invalid-feedback" style={{ display: 'block' }}>
												Please select location!
											</div>
										)}
										<div style={{ marginTop: '10px' }}></div>
										<GoogleMap
											googleMapURL={process.env.REACT_APP_MAP_URL}
											loadingElement={<div style={{ height: `100%` }} />}
											containerElement={<div style={{ height: `350px` }} />}
											mapElement={<div style={{ height: `100%` }} />}
											location={location}
											setCoords={setCoords}
										/>
									</Col>
								</Row>
								<Row className='formSpace'>
									<Col md={6} style={{ textAlign: 'right' }}>
										<Form.Label>{editEnable && <span className='requiredMark'>*</span>} Country</Form.Label>
									</Col>
									<Col md={6} style={{ textAlign: 'left' }}>
										{!editEnable && <p className="lead">{hotelData?.country}</p>}
										{editEnable && <Form.Control className={`${errors.country ? 'is-invalid' : ''}`} defaultValue={hotelData?.country}  {...register("country", { required: getLoggedUserType() === UserGroups.super_admin ? false : (location?.country) ? false : (hotelData?.country) ? false : !skipValidation })} readOnly />}
										{editEnable && getLoggedUserType() !== UserGroups.super_admin && <div className="invalid-feedback">Please input country!</div>}
									</Col>
								</Row>
								<Row className='formSpace'>
									<Col md={6} style={{ textAlign: 'right' }}>
										<Form.Label>{editEnable && <span className='requiredMark'>*</span>} Address1</Form.Label>
									</Col>
									<Col md={6} style={{ textAlign: 'left' }}>
										{!editEnable && <p className="lead">{hotelData?.address1}</p>}
										{editEnable && <Form.Control className={`${errors.address1 ? 'is-invalid' : ''}`} defaultValue={hotelData?.address1} {...register("address1", { required: getLoggedUserType() === UserGroups.super_admin ? false : !skipValidation })} />}
										{editEnable && getLoggedUserType() !== UserGroups.super_admin && <div className="invalid-feedback">Please input address1!</div>}
									</Col>
								</Row>
								<Row className='formSpace'>
									<Col md={6} style={{ textAlign: 'right' }}>
										<Form.Label>Address2</Form.Label>
									</Col>
									<Col md={6} style={{ textAlign: 'left' }}>
										{!editEnable && <p className="lead">{hotelData?.address2}</p>}
										{editEnable && <Form.Control defaultValue={hotelData?.address2} {...register("address2")} />}
									</Col>
								</Row>
								<Row className='formSpace'>
									<Col md={6} style={{ textAlign: 'right' }}>
										<Form.Label>{editEnable && <span className='requiredMark'>*</span>} City</Form.Label>
									</Col>
									<Col md={6} style={{ textAlign: 'left' }}>
										{!editEnable && <p className="lead">{hotelData?.city}</p>}
										{editEnable && <Form.Control className={`${errors.city ? 'is-invalid' : ''}`} defaultValue={hotelData?.city} {...register("city", { required: getLoggedUserType() === UserGroups.super_admin ? false : !skipValidation })} />}
										{editEnable && getLoggedUserType() !== UserGroups.super_admin && <div className="invalid-feedback">Please input city!</div>}
									</Col>
								</Row>
								<Row className='formSpace'>
									<Col md={6} style={{ textAlign: 'right' }}>
										<Form.Label>{editEnable && <span className='requiredMark'>*</span>} Postal code</Form.Label>
									</Col>
									<Col md={6} style={{ textAlign: 'left' }}>
										{!editEnable && <p className="lead">{hotelData?.postcode}</p>}
										{editEnable && <Form.Control className={`${errors.postcode ? 'is-invalid' : ''}`} defaultValue={hotelData?.postcode} {...register("postcode", { required: getLoggedUserType() === UserGroups.super_admin ? false : !skipValidation })} />}
										{editEnable && getLoggedUserType() !== UserGroups.super_admin && <div className="invalid-feedback">Please input postal code!</div>}
									</Col>
								</Row>
								<Row className='formSpace'>
									<Col md={6} style={{ textAlign: 'right' }}>
										<Form.Label>County</Form.Label>
									</Col>
									<Col md={6} style={{ textAlign: 'left' }}>
										{!editEnable && <p className="lead">{hotelData?.county}</p>}
										{editEnable && <Form.Control defaultValue={hotelData?.county} {...register("county")} />}
									</Col>
								</Row>

								<Row className='formSpace'>
									<Col md={6} style={{ textAlign: 'right' }}>
										<Form.Label>Province (if applicable)</Form.Label>
									</Col>
									<Col md={6} style={{ textAlign: 'left' }}>
										{!editEnable && <p className="lead">{hotelData?.province}</p>}
										{editEnable && <Form.Control defaultValue={hotelData?.province} {...register("province")} />}
									</Col>
								</Row>
								<Row className='formSpace'>
									<Col md={6} style={{ textAlign: 'right' }}>
										<Form.Label>State</Form.Label>
									</Col>
									<Col md={6} style={{ textAlign: 'left' }}>
										{!editEnable && <p className="lead">{hotelData?.state}</p>}
										{editEnable && (
											<Form.Control
												defaultValue={hotelData?.state}
												className={`${errors.state ? 'is-invalid' : ''}`}
												{...register("state")}
											/>
										)}
										{editEnable && errors.state && getLoggedUserType() !== UserGroups.super_admin && (
											<div className="invalid-feedback">{errors.state.message}</div>
										)}
									</Col>
								</Row>
								<Row className='formSpace'>
									<Col md={6} style={{ textAlign: 'right' }}>
										<Form.Label>{editEnable && <span className='requiredMark'>*</span>} Property location</Form.Label>
									</Col>
									<Col md={6} style={{ textAlign: 'left' }}>
										{!editEnable && <p className="lead">{hotelData?.propertyLocationType?.name}</p>}
										{editEnable &&
											<Controller
												name="propertyLocationType"
												defaultValue={hotelData?.propertyLocationType?._id}
												control={control}
												rules={{
													required: getLoggedUserType() === UserGroups.super_admin ? false : !skipValidation,
												}}
												render={({ field }) =>
													<Select defaultValue={hotelData?.propertyLocationType?._id} className={`${errors.propertyLocationType ? 'is-invalid' : ''}`} style={{ width: '100%' }} onChange={(value, option) => {

														field.onChange(value);

													}} showSearch>
														{propertyLocationTypeList}
													</Select>
												}
											/>}
										{editEnable && getLoggedUserType() !== UserGroups.super_admin && <div className="invalid-feedback">Please select a property location!</div>}
									</Col>
								</Row>

								<Row className='formSpace'>
									<Col md={6} style={{ textAlign: 'right' }}>
										<Form.Label> {editEnable && <span className='requiredMark'>*</span>} Hotel website address</Form.Label>
									</Col>
									<Col md={6} style={{ textAlign: 'left' }}>
										{!editEnable && <p className="lead">{hotelData?.hotelWebsiteAddress}</p>}
										{editEnable && <Form.Control defaultValue={hotelData?.hotelWebsiteAddress} className={`${errors.hotelWebsiteAddress ? 'is-invalid' : ''}`}  {...register("hotelWebsiteAddress", { required: getLoggedUserType() === UserGroups.super_admin ? false : !skipValidation })} />}
										{editEnable && getLoggedUserType() !== UserGroups.super_admin && <div className="invalid-feedback">Please input hotel website Address !</div>}
									</Col>
								</Row>

								<OverlayTrigger
									placement="top"
									delay={overlayTriggerDelay}
									overlay={<Tooltip id='tooltip-table-top' style={toolTipStyle}>Please specify the e-mail address that will receive all system generated messages such as booking requests etc.</Tooltip>}
								>
									<Row className='formSpace'>
										<Col md={6} style={{ textAlign: 'right' }}>
											<Form.Label>{editEnable && <span className='requiredMark'>*</span>} Hotel notification email</Form.Label>
										</Col>
										<Col md={6} style={{ textAlign: 'left' }}>
											{!editEnable && <p className="lead">{hotelData?.hotelNotificationEmail}</p>}
											{editEnable && <Form.Control type='email' className={`${errors.hotelNotificationEmail ? 'is-invalid' : ''}`} defaultValue={hotelData?.hotelNotificationEmail} {...register("hotelNotificationEmail", { required: getLoggedUserType() === UserGroups.super_admin ? false : !skipValidation })} />}
											{editEnable && getLoggedUserType() !== UserGroups.super_admin && <div className="invalid-feedback">Please input hotel notification email!</div>}
										</Col>
									</Row>
								</OverlayTrigger>

								<Row className='formSpace'>
									<Col md={6} style={{ textAlign: 'right' }}>
										<Form.Label>{editEnable && <span className='requiredMark'>*</span>} Market tier</Form.Label>
									</Col>
									<Col md={6} style={{ textAlign: 'left' }}>
										{!editEnable && <p className="lead">{hotelData?.marketTierType}</p>}
										{editEnable &&
											<Controller
												name="marketTierType"
												defaultValue={hotelData?.marketTierType}
												control={control}
												rules={{
													required: getLoggedUserType() === UserGroups.super_admin ? false : !skipValidation,
												}}
												render={({ field }) =>
													<Select defaultValue={hotelData?.marketTierType} className={`${errors.marketTierType ? 'is-invalid' : ''}`} style={{ width: '100%' }} onChange={(value, option) => {

														field.onChange(value);

													}} showSearch>
														{marketTierTypeList}
													</Select>
												}
											/>}
										{editEnable && getLoggedUserType() !== UserGroups.super_admin && <div className="invalid-feedback">Please select a market tier!</div>}
									</Col>
								</Row>
								<Row className='formSpace'>
									<Col md={6} style={{ textAlign: 'right' }}>
										<Form.Label>{editEnable && <span className='requiredMark'>*</span>} Total numbers of rooms</Form.Label>
									</Col>
									<Col md={3} style={{ textAlign: 'left' }}>
										{!editEnable && <p className="lead">{hotelData?.noOfRooms} </p>}
										{editEnable && <Form.Control type='number' min={0} className={`${errors.noOfRooms ? 'is-invalid' : ''}`} defaultValue={hotelData?.noOfRooms} {...register("noOfRooms", { required: getLoggedUserType() === UserGroups.super_admin ? false : !skipValidation })} />}
										{editEnable && getLoggedUserType() !== UserGroups.super_admin && <div className="invalid-feedback">Please select your rooms!</div>}
									</Col>
								</Row>

								<Row className='formSpace'>
									<Col md={6} style={{ textAlign: 'right' }}>
										<Form.Label>{editEnable && <span className='requiredMark'>*</span>} Total numbers of floors</Form.Label>
									</Col>
									<Col md={3} style={{ textAlign: 'left' }}>
										{!editEnable && <p className="lead">{hotelData?.noOfFloors} </p>}
										{editEnable && <Form.Control type='number' min={0} className={`${errors.noOfFloors ? 'is-invalid' : ''}`} defaultValue={hotelData?.noOfFloors} {...register("noOfFloors", { required: getLoggedUserType() === UserGroups.super_admin ? false : !skipValidation })} />}
										{editEnable && getLoggedUserType() !== UserGroups.super_admin && <div className="invalid-feedback">Please select your floors!</div>}
									</Col>
								</Row>

								<Row className='formSpace'>
									<Col md={6} style={{ textAlign: 'right' }}>
										<Form.Label>{editEnable} Please upload your hotel logo</Form.Label>
									</Col>
									<Col md={6} style={{ textAlign: 'left' }}>
										{!editEnable && <HotelImageUpload getImageBlob={getFileBlob} type="file" accept=".pdf, .jpg, .jpeg, .png, .svg, .eps" value={hotelLogo} disabled={true} newUpload={false} />}
										{editEnable &&
											<Controller
												name="hotelLogo"
												defaultValue={String(hotelLogo)}
												control={control}
												render={({ field }) =>
													<HotelImageUpload getImageBlob={getFileBlob} type="file" accept=".pdf, .jpg, .jpeg, .png, .svg ,.eps" value={hotelLogo} disabled={false} newUpload={true} />
												}
											/>}

										{editEnable && getLoggedUserType() !== UserGroups.super_admin && errors.hotelLogo && <div className="invalid-feedback-custom">Please upload hotel logo</div>}
									</Col>
								</Row>
								<Row className='formSpace'>
									<Col md={6} style={{ textAlign: 'right' }}>
									</Col>
									<Col md={6} style={{ textAlign: 'left' }}>

										{!loading && !hotelId && canBrandHotelModuleWrite(selectedBrand.key ? selectedBrand.key : selectedBrand._id, hotelId, UserAccess.hotel_access.details.code) && (
											isFormComplete() ?
												<Button type="submit" id='approve-button' style={formButton}>Create</Button> :
												<Button type="submit" id='approve-button' style={formButton} onClick={() => { setSkipValidation(true); onFinish(watch()); }}>Save Progress</Button>
										)}
										{!loading && editEnable && hotelId && canBrandHotelModuleWrite(selectedBrand.key ? selectedBrand.key : selectedBrand._id, hotelId, UserAccess.hotel_access.details.code) &&
											<Button type="submit" id='approve-button' style={formButton}>Update</Button>
										}
										{
											loading && <div style={{ textAlign: 'center' }}><Spinner animation="border" variant="warning" /></div>
										}
									</Col>
								</Row>
							</Col>
							<Col md={1}></Col>
						</Row>
					</Form>
				</div>
			</Container>
		</>
	)
};

export default HotelDetailsForm;