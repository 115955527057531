/* eslint-disable import/no-anonymous-default-export */
import { action, thunk } from 'easy-peasy';
import { call } from '../common/axios';


export default {
  getRoomTypeListSuccess: null,
  getRoomTypeListError: null,
  reset: false,
  getInventoryAmenitiesSuccess: null,
  getInventoryAmenitiesError: null,
  assignInventoryAmenitySuccess: null,
  assignInventoryAmenityError: null,
  uploadInventoryImageSuccess: null,
  uploadInventoryImageError: null,
  getInventoryImageSuccess: null,
  getInventoryImageError: null,
  updateInventoryImageSuccess: null,
  removeInventoryImageSuccess: null,
  getInventoryImageTagSuccess: null,
  getInventoryImageTagError: null,
  createInventorySuccess: null,
  createInventoryError: null,
  updateInventorySuccess: null,
  updateInventoryError: null,
  getHotelInventoryListSuccess: null,
  getHotelInventoryListError: null,
  getInventoryByIdSuccess: null,
  getInventoryByIdError: null,
  getInventoryByHotelIdSuccess: null,
  getInventoryByHotelIdError: null,
  removeInventorySuccess: null,
  inventoryApprovalSuccess: null,
  inventoryApprovalError: null,
  inventoryReviewSuccess: null,
  inventoryReviewError: null,

  // actions
  resetInventory: action((state, payload) => ({ reset: true })),
  setGetInventoryAmenities: action((state, payload) => ({ getInventoryAmenitiesSuccess: payload })),
  setGetInventoryAmenitiesError: action((state, payload) => ({ getInventoryAmenitiesError: payload })),
  setAssignInventoryAmenity: action((state, payload) => ({ assignInventoryAmenitySuccess: payload })),
  setAssignInventoryAmenityError: action((state, payload) => ({ assignInventoryAmenityError: payload })),
  setUploadInventoryImage: action((state, payload) => ({ uploadInventoryImageSuccess: payload })),
  setUploadInventoryImageError: action((state, payload) => ({ uploadInventoryImageError: payload })),
  setGetInventoryImage: action((state, payload) => ({ getInventoryImageSuccess: payload })),
  setGetInventoryImageError: action((state, payload) => ({ getInventoryImageError: payload })),
  resetInventoryImage: action((state, payload) => ({ reset: true })),
  setUpdateInventoryImage: action((state, payload) => ({ updateInventoryImageSuccess: payload })),
  setRemoveInventoryImage: action((state, payload) => ({ removeInventoryImageSuccess: payload })),
  setGetInventoryImageTag: action((state, payload) => ({ getInventoryImageTagSuccess: payload })),
  setGetInventoryImageTagError: action((state, payload) => ({ getInventoryImageTagError: payload })),
  setGetRoomTypeList: action((state, payload) => ({ getRoomTypeListSuccess: payload })),
  setGetRoomTypeLisError: action((state, payload) => ({ getRoomTypeListError: payload })),
  setCreateInventory: action((state, payload) => ({ createInventorySuccess: payload })),
  setCreateInventoryError: action((state, payload) => ({ createInventoryError: payload })),
  setUpdateInventory: action((state, payload) => ({ updateInventorySuccess: payload })),
  setUpdateInventoryError: action((state, payload) => ({ updateInventoryError: payload })),
  setGetInventoryList: action((state, payload) => ({ getHotelInventoryListSuccess: payload })),
  setGetInventoryListError: action((state, payload) => ({ getHotelInventoryListError: payload })),
  setGetInventoryById: action((state, payload) => ({ getInventoryByIdSuccess: payload })),
  setGetInventoryByIdError: action((state, payload) => ({ getInventoryByIdError: payload })),
  setGetInventoryByHotelId: action((state, payload) => ({ getInventoryByHotelIdSuccess: payload })),
  setGetInventoryByHotelIdError: action((state, payload) => ({ getInventoryByHotelIdError: payload })),
  setRemoveInventory: action((state, payload) => ({ removeInventorySuccess: payload })),
  setInventoryApprovalSuccess: action((state, payload) => ({ inventoryApprovalSuccess: payload })),
  setInventoryApprovalError: action((state, payload) => ({ inventoryApprovalError: payload })),
  setInventoryReviewSuccess: action((state, payload) => ({ inventoryReviewSuccess: payload })),
  setInventoryReviewError: action((state, payload) => ({ inventoryReviewError: payload })),


  // get Inventory amenities
  getInventoryAmenities: thunk(async (actions: any, inventoryId: any) => {
    try {
      const result = await call('get', `/hotel-inventory-sv/inventory/assignAmenity/${inventoryId}`, null, null);
      actions.setGetInventoryAmenities(result.data);
    } catch (error: any) {
      actions.setGetInventoryAmenitiesError(error.response.data);
    }
  }),

  //create and update Inventory amenity
  assignInventoryAmenity: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('post', '/hotel-inventory-sv/inventory/assignAmenity', payload, null);
      actions.setAssignInventoryAmenity(result.data);
    } catch (error: any) {
      actions.setAssignInventoryAmenityError(error.response.data);
    }
  }),

  //upload Inventory image
  uploadInventoryImage: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('post', '/hotel-inventory-sv/inventory/image', payload, null);
      if (payload.isDeleted) {
        actions.setRemoveInventoryImage(result.data);
      }
      else if (payload.imageId !== '' && payload.isDeleted === false) {
        actions.setUpdateInventoryImage(result.data);
      }
      else {
        actions.setUploadInventoryImage(result.data);
      }
    } catch (error: any) {
      actions.setUploadInventoryImageError(error.response.statusText);
    }
  }),

  // get Inventory image list
  getInventoryImage: thunk(async (actions: any, inventoryId: any) => {
    try {
      const result = await call('get', `/hotel-inventory-sv/inventory/image/${inventoryId}`, null, null);
      actions.setGetInventoryImage(result.data);
    } catch (error: any) {
      actions.setGetInventoryImageError(error.response.data);
    }
  }),

  // get Inventory image tag
  getInventoryImageTag: thunk(async (actions: any, brandId: any) => {
    try {
      const result = await call('get', `/hotel-inventory-sv/inventory/image/tag/${brandId}`, null, null);
      actions.setGetInventoryImageTag(result.data);
    } catch (error: any) {
      actions.setGetInventoryImageTagError(error.response.data);
    }
  }),

  // get RoomType list
  getRoomTypeList: thunk(async (actions: any) => {
    try {
      const result = await call('get', `/hotel-inventory-sv/inventory/roomType`, null, null);
      actions.setGetRoomTypeList(result.data);
    } catch (error: any) {
      actions.setGetRoomTypeError(error.response.data);
    }
  }),

  // create Inventory
  createInventory: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('post', '/hotel-inventory-sv/inventory', payload, null);
      actions.setCreateInventory(result.data);
    } catch (error: any) {
      actions.setCreateInventoryError(error.response.data);
    }
  }),
  // update Inventory
  updateInventory: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('patch', '/hotel-inventory-sv/inventory', payload, null);
      if (payload.isDeleted) {
        actions.setRemoveInventory(result.data);
      } else {
        actions.setUpdateInventory(result.data);
      }

    } catch (error: any) {
      actions.setUpdateInventoryError(error.response.data);
    }
  }),

  // get inventory by hotelId
  getInventoryByHotelId: thunk(async (actions: any, hotelId: any) => {
    try {
      const result = await call('get', `/hotel-inventory-sv/inventory/list/${hotelId}`, null, null);
      actions.setGetInventoryByHotelId(result.data);
    } catch (error: any) {
      actions.setGetInventoryByHotelIdError(error.response.data);
    }
  }),

  // get inventory by id
  getInventoryById: thunk(async (actions: any, id: any) => {
    try {
      const result = await call('get', `/hotel-inventory-sv/inventory/${id}`, null, null);
      actions.setGetInventoryById(result.data);
    } catch (error: any) {
      actions.setGetInventoryByIdError(error.response.data);
    }
  }),

  // get inventory list
  getHotelInventoryList: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('get', `/hotel-inventory-sv/inventory/list`, null, payload);
      actions.setGetInventoryList(result.data);
    } catch (error: any) {
      actions.setGetInventoryListError(error.response.data);
    }
  }),

  // approval 
  inventoryApproval: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('post', '/hotel-inventory-sv/inventory/approved', payload, null);
      actions.setInventoryApprovalSuccess(result.data);
    } catch (error: any) {
      actions.setInventoryApprovalError(error.response.data);
    }
  }),

  inventoryReview: thunk(async (actions: any, payload: any) => {
    try {
      const result = await call('post', '/hotel-inventory-sv/inventory/reviewed', payload, null);
      actions.setInventoryReviewSuccess(result.data);
    } catch (error: any) {
      actions.setInventoryReviewError(error.response.data);
    }
  }),
}