import { Form, Col, Row, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FC, useEffect, useState } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { UserGroups } from "../../../../common/constants";
import { getLoggedUserType } from "../../../../common/functions";
import { ReportDataFieldsFormInput } from "../../../../common/interfaces";
import { toast } from 'react-toastify';
import RFPBreadCrumbPage from "../../../common/RFPBredcrumPage";
import Archive from '../../../../images/icons/svg/outside/Icon-Function-Archive.svg';
import { overlayTriggerDelay } from "../../../../common/components-style";
import { Select } from "antd";
const { Option } = Select;

const ReportDataFields: FC<any> = ({ reportId, programmeId, setFormOpen, reload, selectedClient, selectedProgramme, associateProgrammes, reportName }): JSX.Element => {
    const [loading, setLoading] = useState<boolean>(false);
    const { handleSubmit, control } = useForm<ReportDataFieldsFormInput>();
    const [dataTypeList, setDataTypeList] = useState<any>([]);
    const [programmeList, setProgrammeList] = useState<any>([]);
    const [editEnable, setEditEnable] = useState<boolean>(false);
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [contractProgrammeList, setContractProgrammeList] = useState<any>([]);

    const [fields, setFields] = useState([
        { id: 'static-1', fieldName: 'Hotel Contracted', dataType: 'text', associateProgramme: '', contractProgramme: [] },
        { id: 'static-2', fieldName: 'Contract Programme', dataType: 'text', associateProgramme: '', contractProgramme: [] },
        { id: 'static-3', fieldName: '', dataType: '', associateProgramme: '', contractProgramme: [] },
        { id: 'static-4', fieldName: '', dataType: '', associateProgramme: '', contractProgramme: [] },
    ]);
    const filteredFields = fields.filter((field) => {
        if (field.fieldName === 'Contract Programme') {
            return !associateProgrammes?.includes('None');
        }
        return true;
    });

    const {
        createReportDataFields,
        getReportDataFields,
        updateReportDataFields,
        getCorporateProgrammeByClient,
    } = useStoreActions<any>((actions) => ({
        createReportDataFields: actions.hotel.createReportDataFields,
        getReportDataFields: actions.hotel.getReportDataFields,
        updateReportDataFields: actions.hotel.updateReportDataFields,
        getCorporateProgrammeByClient: actions.client.getCorporateProgrammeByClient,
    }))

    const {
        createReportDataFieldsDataSuccess,
        createReportDataFieldsDataError,
        getReportDataFieldsSuccess,
        updateReportDataFieldsDataSuccess,
        updateReportDataFieldsDataError,
        getCorporateProgrammeByClientSuccess,

    } = useStoreState<any>((state) => ({
        createReportDataFieldsDataSuccess: state.hotel.createReportDataFieldsDataSuccess,
        createReportDataFieldsDataError: state.hotel.createReportDataFieldsDataError,
        getReportDataFieldsSuccess: state.hotel.getReportDataFieldsSuccess,
        updateReportDataFieldsDataSuccess: state.hotel.updateReportDataFieldsDataSuccess,
        updateReportDataFieldsDataError: state.hotel.updateReportDataFieldsDataError,
        getCorporateProgrammeByClientSuccess: state.client.getCorporateProgrammeByClientSuccess,
    }));

    useEffect(() => {
        if (createReportDataFieldsDataError) {
            setLoading(false);
            toast.error(createReportDataFieldsDataError?.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }
        if (createReportDataFieldsDataSuccess) {
            setLoading(false);
            toast.success("Report created successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }
        setLoading(false);
    }, [createReportDataFieldsDataError, createReportDataFieldsDataSuccess]);


    useEffect(() => {
        if (updateReportDataFieldsDataError) {
            setLoading(false);
            toast.error(updateReportDataFieldsDataError?.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }
        if (updateReportDataFieldsDataSuccess) {
            setLoading(false);
            toast.success("Report updated successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }
        setLoading(false);
    }, [updateReportDataFieldsDataError, updateReportDataFieldsDataSuccess]);


    useEffect(() => {
        if (reportId) {
            getReportDataFields(reportId)
            setIsEditing(true);
        }
    }, [getReportDataFields, reportId]);

    useEffect(() => {
        if (getReportDataFieldsSuccess?.data) {
            const fetchedFields = getReportDataFieldsSuccess?.data?.fields || [];

            const formattedFields = fetchedFields.map((field, index) => ({
                id: `static-${index}`,
                fieldName: field.fieldName || '',
                dataType: field.dataType || '',
                associateProgramme: field.associateProgramme || '',
                contractProgramme: Array.isArray(field.contractProgramme)
                    ? field.contractProgramme
                    : [],
            }));
            setFields(formattedFields);
            setEditEnable(true);
            setIsEditing(false);
        }
    }, [getReportDataFieldsSuccess]);

    useEffect(() => {
        if (selectedClient) {
            const params: any = {};
            params.clientId = selectedClient._id ? selectedClient._id : selectedClient.key;
            getCorporateProgrammeByClient(params);
        }
    }, [getCorporateProgrammeByClient, selectedClient]);


    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (associateProgrammes || selectedProgramme?.programmeName) {
            const options = [
                ...(associateProgrammes || []).map((val: any, key: number) => (
                    <Option key={key} data={val} value={`${val}`}>
                        {val}
                    </Option>
                )),
                selectedProgramme?.programmeName && (
                    <Option key="selectedProgramme" data={selectedProgramme?.programmeName} value={`${selectedProgramme?.programmeName}`}>
                        {selectedProgramme?.programmeName}
                    </Option>
                ),
            ].filter(Boolean);
            const staticOption = <Option key='None' data='None' value={'None'}>
                None
            </Option>;
            setProgrammeList([staticOption, ...options]);
        }
    }, [associateProgrammes, selectedProgramme]);


    useEffect(() => {
        if (getCorporateProgrammeByClientSuccess) {
            const programNameToAbbreviation = getCorporateProgrammeByClientSuccess?.data.reduce(
                (acc: Record<string, string>, item: any) => {
                    if (item.programmeName && item.abbreviation) {
                        acc[item.programmeName] = item.abbreviation;
                    }
                    return acc;
                },
                {}
            );
            const associatedAbbreviations = associateProgrammes
                .map((programName: string) => programNameToAbbreviation[programName])
                .filter(Boolean);

            const selectedProgrammeAbbreviation =
                programNameToAbbreviation[selectedProgramme?.programmeName] || '';

            const allRelevantAbbreviations = [
                selectedProgrammeAbbreviation,
                ...associatedAbbreviations,
            ];

            const options = getCorporateProgrammeByClientSuccess?.data
                .filter((item: any) => item?.abbreviation)
                .filter((item: any) =>
                    allRelevantAbbreviations.includes(item.abbreviation)
                )
                .map((item: any) => ({
                    value: item?.abbreviation,
                    label: item?.abbreviation,
                }));
            const staticOption = { value: 'None', label: 'None' };
            setContractProgrammeList([staticOption, ...options]);
        }
    }, [associateProgrammes, getCorporateProgrammeByClientSuccess, programmeId, selectedProgramme]);



    const addField = () => {
        setFields((prevFields) => [
            ...prevFields,
            {
                id: `dynamic-${prevFields.length}`,
                fieldName: '',
                dataType: '',
                associateProgramme: '',
                contractProgramme: [],
            },
        ]);
    };

    //////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        const dataTypeListTemp: any = [{
            _id: 'spend',
            name: "Spend",
        },
        {
            _id: 'nights',
            name: "Nights",
        },
        {
            _id: 'text',
            name: "Text",
        },
        ];

        const typeOfDataType = dataTypeListTemp.map((val: any, key: number) => {
            return (
                <Option key={key} data={val} value={`${val._id}`} >{val.name}</Option>
            )
        });

        setDataTypeList(typeOfDataType);
    }, []);

    const handleFieldChange = (index, fieldName, value) => {
        setFields((prevFields) =>
            prevFields.map((field, i) =>
                i === index ? { ...field, [fieldName]: value } : field
            )
        );
    };

    const onSubmit: SubmitHandler<ReportDataFieldsFormInput> = async (values) => {
        const hasErrors = filteredFields.some((field) => {
            if (!editEnable && (field.id === 'static-3' || field.id === 'static-4')) {
                return false;
            }

            const isCommonError =
                !field.fieldName ||
                !field.dataType ||
                (['spend', 'nights'].includes(field.dataType) && !field.associateProgramme);

            const isContractProgrammeError =
                field.fieldName === 'Contract Programme' &&
                (field.dataType !== 'text' || !field.contractProgramme || field.contractProgramme.length === 0);

            return isCommonError || isContractProgrammeError;
        });

        if (hasErrors) {
            alert('Please fill the required fields.');
            return;
        }

        const payload = {
            clientId: selectedClient.key ? selectedClient.key : selectedClient._id,
            programmeId: programmeId,
            reportId: reportId,
            fields: filteredFields.map((field) => {
                return ({
                    fieldName: field.fieldName,
                    dataType: field.dataType,
                    associateProgramme: field.dataType === 'spend' || field.dataType === 'nights'
                        ? field.associateProgramme || null
                        : null,
                    contractProgramme: field.contractProgramme
                })
            }),
        };
        setLoading(true);
        if (editEnable) {
            payload.reportId = reportId;
            updateReportDataFields(payload);
        } else {
            createReportDataFields(payload);
        }
        getReportDataFields(reportId);
        setIsEditing(false);
    };

    const removeField = (index) => {
        if (index < 2) return;
        setFields((prevFields) => prevFields.filter((_, i) => i !== index));
    };
    // 	---------------------------------------------------------------------------------------------------
    return (
        <>

            {reportId &&
                <RFPBreadCrumbPage isPageName="programmeSpendReport" selectedClient={selectedClient} selectedProgramme={selectedProgramme} selectedClientName={selectedClient?.companyName} selectedProgrammeName={selectedProgramme?.programmeName} reportId={reportId} programmeId={programmeId} startDate={reportName}></RFPBreadCrumbPage>
            }
            <div className="create-update-wrapper mysa-create-form" style={{ marginLeft: "40px" }}>
                <Form onSubmit={handleSubmit(onSubmit)} >
                    <h5 style={{ fontWeight: '700', marginBottom: '30px' }}>Mysa super admin will need to type name of fields</h5>
                    <>

                        {filteredFields.map((field, index) => (
                            <Row className="formSpace d-flex align-items-center" key={field.id}>
                                <Col md={3}>
                                    <Form.Label className=" mt-0">{<span className='requiredMark'>*</span>} {`Name of data field ${index + 1}`}</Form.Label>
                                </Col>
                                <Col md={3}>
                                    {(index < 1 || (field.fieldName === 'Contract Programme' && !associateProgrammes?.includes('None'))) ? (
                                        <p className="lead m-0">{field.fieldName}</p>
                                    ) : (
                                        isEditing ? (
                                            <Form.Control
                                                name={`name-${field.id}`}
                                                value={field.fieldName}
                                                onChange={(e) => handleFieldChange(index, 'fieldName', e.target.value)}
                                                className="mysa-form-control"
                                            />
                                        ) : (
                                            <p className="lead m-0">{field.fieldName}</p>
                                        )
                                    )}
                                </Col>

                                <Col md={2}>
                                    {(index < 1 || (field.fieldName === 'Contract Programme' && !associateProgrammes?.includes('None'))) ? (
                                        <p className="lead m-0">{field.dataType}</p>
                                    ) : (
                                        isEditing ? (
                                            <Controller
                                                name={`fields.${index}.dataType`}
                                                defaultValue={field.dataType}
                                                control={control}
                                                render={({ field: controllerField }) => (
                                                    <Select
                                                        value={field.dataType || 'Select data type'}
                                                        className="mysa-select"
                                                        style={{ width: '100%' }}
                                                        onChange={(value) => {
                                                            handleFieldChange(index, 'dataType', value);
                                                            if (!['spend', 'nights'].includes(value)) {
                                                                handleFieldChange(index, 'associateProgramme', '');
                                                            }
                                                        }}
                                                        showSearch
                                                    >
                                                        {dataTypeList}
                                                    </Select>
                                                )}
                                            />
                                        ) : (
                                            <p className="lead m-0">{field.dataType}</p>
                                        )
                                    )}
                                </Col>
                                <Col md={3}>
                                    {

                                        field.fieldName === 'Contract Programme' ? (
                                            isEditing ? (
                                                <Controller
                                                    name={`fields.${index}.contractProgramme`}
                                                    defaultValue={field.contractProgramme || []}
                                                    control={control}
                                                    render={({ field: controllerField }) => {
                                                        const value = controllerField.value || [];

                                                        const filteredProgrammeList = value.includes("None")
                                                            ? contractProgrammeList.filter((programme) => programme.value === "None")
                                                            : contractProgrammeList;

                                                        return (
                                                            <Select
                                                                {...controllerField}
                                                                className="mysa-multiselect"
                                                                style={{ width: '100%' }}
                                                                mode="multiple"
                                                                onChange={(newValue) => {
                                                                    handleFieldChange(index, 'contractProgramme', newValue);

                                                                    if (newValue.includes("None")) {
                                                                        controllerField.onChange(["None"]);
                                                                    } else {
                                                                        controllerField.onChange(newValue.filter((val) => val !== "None"));
                                                                    }
                                                                }}
                                                                showSearch
                                                                value={value}
                                                                placeholder="Select Contract Programme"
                                                            >
                                                                {filteredProgrammeList.map((programme) => (
                                                                    <Select.Option key={programme.value} value={programme.value}>
                                                                        {programme.label}
                                                                    </Select.Option>
                                                                ))}
                                                            </Select>
                                                        );
                                                    }}
                                                />
                                            ) :
                                                (
                                                    <p className="lead m-0">{Array.isArray(field.contractProgramme) && field.contractProgramme.length > 0
                                                        ? field.contractProgramme.join(', ')
                                                        : 'No Contract Programme'}</p>
                                                )
                                        ) : (
                                            ['spend', 'nights'].includes(field.dataType) && (
                                                !isEditing ? (
                                                    <p className="lead m-0">{field.associateProgramme}</p>
                                                ) : (
                                                    <Controller
                                                        name={`fields.${index}.associateProgramme`}
                                                        defaultValue={field.associateProgramme}
                                                        control={control}
                                                        render={({ field: controllerField }) => (
                                                            <Select
                                                                {...controllerField}
                                                                className="mysa-select"
                                                                style={{ width: '100%' }}
                                                                onChange={(value) => {
                                                                    controllerField.onChange(value);
                                                                    handleFieldChange(index, 'associateProgramme', value);
                                                                }}
                                                                value={field.associateProgramme || 'Select associate Programme'}
                                                                showSearch
                                                            >
                                                                {programmeList}
                                                            </Select>
                                                        )}
                                                    />
                                                )
                                            )
                                        )}
                                </Col>


                                {index >= 2 && (
                                    <Col md={1}>
                                        {isEditing &&
                                            <OverlayTrigger
                                                placement="top"
                                                delay={overlayTriggerDelay}
                                                overlay={<Tooltip id='tooltip-table-top'>Remove</Tooltip>}
                                            >
                                                <img className='iconSizes' src={Archive} alt="" onClick={() => removeField(index)} />
                                            </OverlayTrigger>
                                        }
                                    </Col>
                                )}
                            </Row>
                        ))}

                        <Row style={{ paddingTop: '30px' }} className='formSpace'>
                            <Col md={10}>
                                <div className="d-flex justify-content-between">
                                    {getLoggedUserType() === UserGroups.super_admin && !loading && reportId && (
                                        <>
                                            {editEnable ? (
                                                isEditing ? (
                                                    <>
                                                        <Button
                                                            className="h-10 text-white mt-15 ml-12"
                                                            id="create-button"
                                                            type="submit"
                                                        >
                                                            {'Save'}
                                                        </Button>
                                                        <Button
                                                            className="mysa-yellow-button mt-15 ml-12"
                                                            onClick={addField}
                                                        >
                                                            {'Add field'}
                                                        </Button>
                                                    </>
                                                ) : (
                                                    <Button
                                                        className="mysa-yellow-button mt-15 ml-12"
                                                        onClick={() => setIsEditing(true)}
                                                    >
                                                        {'Edit'}
                                                    </Button>
                                                )
                                            ) : (
                                                <>
                                                    <Button
                                                        className="h-10 text-white mt-15 ml-12"
                                                        id="create-button"
                                                        type="submit"
                                                    >
                                                        {'Save'}
                                                    </Button>
                                                    <Button
                                                        className="mysa-yellow-button mt-15 ml-12"
                                                        onClick={addField}
                                                    >
                                                        {'Add field'}
                                                    </Button>
                                                </>
                                            )}
                                        </>
                                    )}
                                </div>

                            </Col>
                        </Row>
                    </>
                </Form>
            </div>
        </>
    )

}

export default ReportDataFields;
