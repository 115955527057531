import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row, Button, Breadcrumb, Tab, Nav, Form, Modal } from 'react-bootstrap';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { EnvironmentOutlined } from "@ant-design/icons";
import ClientSinglePropertyView from './views/ClientSinglePropertyView';
import { formButton, navStyle, tabDivStyle } from '../../../../../common/components-style';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Menu } from 'antd';
import ClientRatesListView from './views/ClientRatesListView';
import ClientAuditView from './views/ClientAuditView';
import ClientPropertyRateDetails from './views/ClientPropertyRateDetails';
import { CommonAnswer, PropertyInventoryStatus, BrandRfpPropertyEvaluationStatus } from '../../../../../common/constants';
import { toast } from 'react-toastify';
import SustainabilityView from './views/SustainabilityView';
import QuestionSubmitted from '../question-submitted/QuestionSubmitted';


const ClientPropertyDetailsToAccess: React.FC<any> = (): JSX.Element => {

    ///////////////////////////////////////// local state manage /////////////////////////////////////////
    const [menuKey, setMenuKey] = useState('property');
    const [property, setProperty] = useState<any>();
    const [amenitiesList, setAmenitiesList] = useState<any>();
    const [enableRatesScreen, setEnableRatesScreen] = useState<boolean>(false);
    const [rates, setRates] = useState<any>('');
    const [inventoryRates, setInventoryRates] = useState<any>([]);
    const [disabledButton, setDisabledButton] = useState<boolean>(false);
    const [showOption, setShowOption] = useState<boolean>(false);
    const [doYouWishDeclineProperty, setDoYouWishDeclineProperty] = useState<any>();
    const [isAcceptancePropertyBasedClarification, setIsAcceptancePropertyBasedClarification] = useState<any>();
    const [isAllInventoryAccepted, setIsAllInventoryAccepted] = useState<boolean>(false);
    const [isCheckNegotiateInventory, setIsCheckNegotiateInventory] = useState<boolean>(false);
    const [doYouWishOpenNegotiationProperty, setDoYouWishOpenNegotiationProperty] = useState<any>();
    // const [showAcceptanceClarification, setShowAcceptanceClarification] = useState<boolean>(false);
    const [declineComment, setDeclineComment] = useState<any>();
    const [showMessage, setShowMessage] = useState<boolean>(false);
    const [showNegotiationMessage, setShowNegotiationMessage] = useState<boolean>(false);
    const [negotiationComment, setNegotiationComment] = useState<any>();
    const [clarificationComment, setClarificationComment] = useState<any>();
    const [rfpProperty, setRfpProperty] = useState<any>();
    const [showAcceptanceMessage, setShowAcceptanceMessage] = useState<boolean>(false);
    const [showDeclineMessage, setShowDeclineMessage] = useState<boolean>(false);
    const [showDecline, setShowDecline] = useState<boolean>(false);
    const [doYouWishNegotiation, setDoYouWishNegotiation] = useState<any>();
    const [showAmendedMessage, setShowAmendedMessage] = useState<boolean>(false);
    const [propertyAcceptInformation, setPropertyAcceptInformation] = useState<any>();

    // ==================== Question Submitted State ====================
    const [surveys, setSurveys] = useState<any>([]);
    const [templateId, setTemplateId] = useState('');

    ////////////////// url manage ///////////
    let { propertyId, rfpId } = useParams<any>();
    let history = useHistory();
    const location = useLocation<any>();

    /////////////////////////////////// global action manage ////////////////////////////////////////////
    const {
        propertyDetailed, getAmenityList, acceptPropertyRateForRFP, declinePropertyRateForRFP, proposedPropertyRateForRFP, negotiatePropertyRateForRFP, getRfpProcessById,
        getPropertyInventoryRatesForRFP, declinePropertyForRFP, acceptCreateUpdatePropertyAcceptDeclineOfRFP, declineCreateUpdatePropertyAcceptDeclineOfRFP, getTemplate,
        reviewCreateUpdatePropertyAcceptDeclineOfRFP, negotiateCreateUpdatePropertyAcceptDeclineOfRFP, getPropertyAcceptDeclineOfRFPByPropertyId, acceptPropertyForRFP, reviewPropertyForRFP, partAcceptedPropertyForRFP, partAcceptedCreateUpdatePropertyAcceptDeclineOfRFP
    } = useStoreActions<any>(
        (actions) => ({
            getRfpProcessById: actions.rfpProcess.getRfpProcessById,
            getTemplate: actions.myoManagementModel.getTemplate,
            propertyDetailed: actions.property.propertyDetailed,
            getAmenityList: actions.property.getAmenityList,
            acceptPropertyRateForRFP: actions.rfpProcess.acceptPropertyRateForRFP,
            declinePropertyRateForRFP: actions.rfpProcess.declinePropertyRateForRFP,
            proposedPropertyRateForRFP: actions.rfpProcess.proposedPropertyRateForRFP,
            negotiatePropertyRateForRFP: actions.rfpProcess.negotiatePropertyRateForRFP,
            // propertyInventoryRates: actions.rfpProcess.propertyInventoryRates,
            getPropertyInventoryRatesForRFP: actions.rfpProcess.getPropertyInventoryRatesForRFP,
            declinePropertyForRFP: actions.rfpProcess.declinePropertyForRFP,
            acceptPropertyForRFP: actions.rfpProcess.acceptPropertyForRFP,
            reviewPropertyForRFP: actions.rfpProcess.reviewPropertyForRFP,
            partAcceptedPropertyForRFP: actions.rfpProcess.partAcceptedPropertyForRFP,
            acceptCreateUpdatePropertyAcceptDeclineOfRFP: actions.rfpProcess.acceptCreateUpdatePropertyAcceptDeclineOfRFP,
            declineCreateUpdatePropertyAcceptDeclineOfRFP: actions.rfpProcess.declineCreateUpdatePropertyAcceptDeclineOfRFP,
            reviewCreateUpdatePropertyAcceptDeclineOfRFP: actions.rfpProcess.reviewCreateUpdatePropertyAcceptDeclineOfRFP,
            negotiateCreateUpdatePropertyAcceptDeclineOfRFP: actions.rfpProcess.negotiateCreateUpdatePropertyAcceptDeclineOfRFP,
            partAcceptedCreateUpdatePropertyAcceptDeclineOfRFP: actions.rfpProcess.partAcceptedCreateUpdatePropertyAcceptDeclineOfRFP,
            getPropertyAcceptDeclineOfRFPByPropertyId: actions.rfpProcess.getPropertyAcceptDeclineOfRFPByPropertyId,

        })
    );

    /////////////////////////////////// global state manage ////////////////////////////////////////////
    const { getPropertyDetailedSuccess, getAmenityListSuccess, acceptPropertyRateForRFPSuccess, acceptPropertyRateForRFPError,
        declinePropertyRateForRFPSuccess, declinePropertyRateForRFPError, getPropertyInventoryRatesForRFPSuccess,
        declinePropertyForRFPSuccess, declinePropertyForRFPError, reviewCreateUpdatePropertyAcceptDeclineOfRFPSuccess,
        reviewCreateUpdatePropertyAcceptDeclineOfRFPError, negotiateCreateUpdatePropertyAcceptDeclineOfRFPSuccess,
        negotiateCreateUpdatePropertyAcceptDeclineOfRFPError, acceptPropertyForRFPSuccess, acceptPropertyForRFPError,
        reviewPropertyForRFPSuccess, reviewPropertyForRFPError,
        acceptCreateUpdatePropertyAcceptDeclineOfRFPSuccess, acceptCreateUpdatePropertyAcceptDeclineOfRFPError,
        declineCreateUpdatePropertyAcceptDeclineOfRFPSuccess, declineCreateUpdatePropertyAcceptDeclineOfRFPError,
        partAcceptedPropertyForRFPSuccess, partAcceptedPropertyForRFPError,
        partAcceptedCreateUpdatePropertyAcceptDeclineOfRFPSuccess, partAcceptedCreateUpdatePropertyAcceptDeclineOfRFPError,
        proposedPropertyRateForRFPSuccess, proposedPropertyRateForRFPError,
        negotiatePropertyRateForRFPSuccess, negotiatePropertyRateForRFPError,
        getPropertyAcceptDeclineOfRFPByPropertyIdSuccess,
        rfpProcessByIdSuccess, getTemplateSuccess,
    } = useStoreState<any>(
        (state) => ({
            rfpProcessByIdSuccess: state.rfpProcess.rfpProcessByIdSuccess,
            getTemplateSuccess: state.myoManagementModel.getTemplateSuccess,

            getPropertyDetailedSuccess: state.property.getPropertyDetailedSuccess,
            getAmenityListSuccess: state.property.getAmenityListSuccess,

            acceptPropertyRateForRFPSuccess: state.rfpProcess.acceptPropertyRateForRFPSuccess,
            acceptPropertyRateForRFPError: state.rfpProcess.acceptPropertyRateForRFPError,

            declinePropertyRateForRFPSuccess: state.rfpProcess.declinePropertyRateForRFPSuccess,
            declinePropertyRateForRFPError: state.rfpProcess.declinePropertyRateForRFPError,

            proposedPropertyRateForRFPSuccess: state.rfpProcess.proposedPropertyRateForRFPSuccess,
            proposedPropertyRateForRFPError: state.rfpProcess.proposedPropertyRateForRFPError,


            negotiatePropertyRateForRFPSuccess: state.rfpProcess.negotiatePropertyRateForRFPSuccess,
            negotiatePropertyRateForRFPError: state.rfpProcess.negotiatePropertyRateForRFPError,

            // getPropertyInventoryRatesSuccess: state.rfpProcess.getPropertyInventoryRatesSuccess,
            getPropertyInventoryRatesForRFPSuccess: state.rfpProcess.getPropertyInventoryRatesForRFPSuccess,

            declinePropertyForRFPSuccess: state.rfpProcess.declinePropertyForRFPSuccess,
            declinePropertyForRFPError: state.rfpProcess.declinePropertyForRFPError,

            reviewCreateUpdatePropertyAcceptDeclineOfRFPSuccess: state.rfpProcess.reviewCreateUpdatePropertyAcceptDeclineOfRFPSuccess,
            reviewCreateUpdatePropertyAcceptDeclineOfRFPError: state.rfpProcess.reviewCreateUpdatePropertyAcceptDeclineOfRFPError,

            negotiateCreateUpdatePropertyAcceptDeclineOfRFPSuccess: state.rfpProcess.negotiateCreateUpdatePropertyAcceptDeclineOfRFPSuccess,
            negotiateCreateUpdatePropertyAcceptDeclineOfRFPError: state.rfpProcess.negotiateCreateUpdatePropertyAcceptDeclineOfRFPError,

            acceptCreateUpdatePropertyAcceptDeclineOfRFPSuccess: state.rfpProcess.acceptCreateUpdatePropertyAcceptDeclineOfRFPSuccess,
            acceptCreateUpdatePropertyAcceptDeclineOfRFPError: state.rfpProcess.acceptCreateUpdatePropertyAcceptDeclineOfRFPError,

            declineCreateUpdatePropertyAcceptDeclineOfRFPSuccess: state.rfpProcess.declineCreateUpdatePropertyAcceptDeclineOfRFPSuccess,
            declineCreateUpdatePropertyAcceptDeclineOfRFPError: state.rfpProcess.declineCreateUpdatePropertyAcceptDeclineOfRFPError,

            acceptPropertyForRFPSuccess: state.rfpProcess.acceptPropertyForRFPSuccess,
            acceptPropertyForRFPError: state.rfpProcess.acceptPropertyForRFPError,

            reviewPropertyForRFPSuccess: state.rfpProcess.reviewPropertyForRFPSuccess,
            reviewPropertyForRFPError: state.rfpProcess.reviewPropertyForRFPError,

            partAcceptedPropertyForRFPSuccess: state.rfpProcess.partAcceptedPropertyForRFPSuccess,
            partAcceptedPropertyForRFPError: state.rfpProcess.partAcceptedPropertyForRFPError,

            partAcceptedCreateUpdatePropertyAcceptDeclineOfRFPSuccess: state.rfpProcess.partAcceptedCreateUpdatePropertyAcceptDeclineOfRFPSuccess,
            partAcceptedCreateUpdatePropertyAcceptDeclineOfRFPError: state.rfpProcess.partAcceptedCreateUpdatePropertyAcceptDeclineOfRFPError,
            getPropertyAcceptDeclineOfRFPByPropertyIdSuccess: state.rfpProcess.getPropertyAcceptDeclineOfRFPByPropertyIdSuccess,

        })
    );


    const redirectProperty = useCallback(() => {
        history.push({
            pathname: `/admin/client-proposal-management`,
            state: {
                cityName: property?.city,
                rfpId: rfpId,
                filterProgramManage: true,
                defaultActiveKey: 'propertyAssessment'
            }
        })
        // history.push('/admin/property-management', { brandId: brandId })
    }, [history, property?.city, rfpId])

    /////////////////////////Get Certificate Template ID//////////////////////////////////////////////////////////////
    useEffect(() => {
        if (rfpId) {
            getRfpProcessById(rfpId);
        }
    }, [rfpId, getRfpProcessById]);

    /////////////////////////////////Get Surveys By Certificate Template ID///////////////////////////////////////
    useEffect(() => {
        if (rfpProcessByIdSuccess?.data.certificateTemplateId) {
            getTemplate({
                security: {
                    apiKey: process.env.REACT_APP_AUDIT_API_KEY,
                    user: "superadmin@mysa.global"
                },
                id: rfpProcessByIdSuccess?.data.certificateTemplateId
            })
            setTemplateId(rfpProcessByIdSuccess?.data.certificateTemplateId);
        }
    }, [getTemplate, rfpProcessByIdSuccess])

    useEffect(() => {
        if (getTemplateSuccess && getTemplateSuccess.template && getTemplateSuccess.template.surveys && getTemplateSuccess.template.surveys.length > 0) {
            let filteredSurveys;
            // Filter surveys based on the condition
            filteredSurveys = getTemplateSuccess.template.surveys.filter(survey => survey.survey_type === "PROPERTY_SURVEY");


            if (filteredSurveys && filteredSurveys.length > 0) {
                setSurveys(filteredSurveys);
            }
        }
    }, [getTemplateSuccess]);

    ///////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (propertyId) {
            const payload = {
                propertyId: propertyId
            }
            propertyDetailed(payload);
            getAmenityList();
        }
    }, [getAmenityList, propertyDetailed, propertyId]);

    ////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        // const payload = {
        //     rfp_id: rfpId,
        //     propertyId: propertyId
        // }
        // propertyInventoryRates(payload);
        const params = {
            brandId: property?.brandId,
            propertyId: propertyId,
            rfpProcessId: rfpId,
            cityName: property?.city
        }
        if (property?.brandId) {
            getPropertyInventoryRatesForRFP(params);
        }


    }, [getPropertyInventoryRatesForRFP, property?.brandId, property?.city, propertyId, rfpId])

    useEffect(() => {
        // if (getPropertyInventoryRatesSuccess) {
        //     setInventoryRates(getPropertyInventoryRatesSuccess.data?.property?.inventory);
        // }
        if (getPropertyInventoryRatesForRFPSuccess?.data) {
            setInventoryRates(getPropertyInventoryRatesForRFPSuccess.data?.inventories);
            setRfpProperty(getPropertyInventoryRatesForRFPSuccess.data);
        }
    }, [getPropertyInventoryRatesForRFPSuccess])

    ///////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (getPropertyDetailedSuccess) {
            if (getPropertyDetailedSuccess?.data) {
                setProperty(getPropertyDetailedSuccess?.data);
            }
        }

        if (getAmenityListSuccess) {
            setAmenitiesList(getAmenityListSuccess.data);
        }

    }, [getAmenityListSuccess, getPropertyDetailedSuccess]);


    ///////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (acceptPropertyRateForRFPSuccess) {
            toast.success("Rate accepted successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });

            const params = {
                brandId: property?.brandId,
                propertyId: propertyId,
                rfpProcessId: rfpId,
                cityName: property?.city
            }
            if (rfpId) {
                getPropertyInventoryRatesForRFP(params);
            }

            if (showAcceptanceMessage && acceptPropertyRateForRFPSuccess && isAcceptancePropertyBasedClarification === CommonAnswer.NO) {
                const payload = {
                    rfpId: rfpId,
                    brandId: property?.brandId,
                    propertyId: propertyId,
                    propertyStatus: BrandRfpPropertyEvaluationStatus.ACCEPTED,
                    cityName: property?.city,
                    isClient: true
                }
                acceptPropertyForRFP(payload);
            }

            if (showAcceptanceMessage && acceptPropertyRateForRFPSuccess && isAcceptancePropertyBasedClarification === CommonAnswer.YES && inventoryRates.length === 1) {
                const payload = {
                    rfpId: rfpId,
                    brandId: property?.brandId,
                    propertyId: propertyId,
                    propertyStatus: BrandRfpPropertyEvaluationStatus.REVIEW,
                    cityName: property?.city,
                    isAllInventoryAccepted: isAllInventoryAccepted,
                    isNegotiation: false,
                    isNegotiateProperty: false,
                    isClient: true
                }
                reviewPropertyForRFP(payload);
            }

            setEnableRatesScreen(false);
            setShowDeclineMessage(false);
        }

        if (declinePropertyRateForRFPSuccess) {
            if (declinePropertyRateForRFPSuccess && doYouWishNegotiation === CommonAnswer.NO) {
                const payload = {
                    rfpId: rfpId,
                    brandId: property?.brandId,
                    propertyId: propertyId,
                    inventoryId: rates?.inventory,
                    doYouWishDeclineProperty: doYouWishDeclineProperty,
                    // declineComment: declineComment,
                    inventoryDeclineComment: declineComment
                }
                declineCreateUpdatePropertyAcceptDeclineOfRFP(payload);
            }



            toast.success("Rate declined successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            const params = {
                brandId: property?.brandId,
                propertyId: propertyId,
                rfpProcessId: rfpId,
                cityName: property?.city
            }
            if (rfpId) {
                getPropertyInventoryRatesForRFP(params);
            }

            if (showDeclineMessage && doYouWishDeclineProperty === CommonAnswer.YES && inventoryRates.length === 1) {
                const payload = {
                    rfpId: rfpId,
                    brandId: property?.brandId,
                    propertyId: propertyId,
                    propertyStatus: BrandRfpPropertyEvaluationStatus.DECLINED,
                    cityName: property?.city,
                    isClient: true

                }
                declinePropertyForRFP(payload);
            } else {
                if (showDeclineMessage && doYouWishDeclineProperty === CommonAnswer.YES) {
                    const payload = {
                        rfpId: rfpId,
                        brandId: property?.brandId,
                        propertyId: propertyId,
                        inventoryId: rates?.inventory,
                        doYouWishDeclineProperty: doYouWishDeclineProperty,
                        declineComment: declineComment,
                    }

                    declineCreateUpdatePropertyAcceptDeclineOfRFP(payload);
                }
            }

            setEnableRatesScreen(false);
            setShowDeclineMessage(false);
        }

        if (proposedPropertyRateForRFPSuccess) {
            toast.success("Rate proposed successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            const params = {
                brandId: property?.brandId,
                propertyId: propertyId,
                rfpProcessId: rfpId,
                cityName: property?.city
            }
            if (rfpId) {
                getPropertyInventoryRatesForRFP(params);
            }
            setEnableRatesScreen(false);
            setShowDeclineMessage(false);
        }

        if (negotiatePropertyRateForRFPSuccess) {
            toast.success("Rate negotiate successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            const params = {
                brandId: property?.brandId,
                propertyId: propertyId,
                rfpProcessId: rfpId,
                cityName: property?.city
            }
            if (rfpId) {
                getPropertyInventoryRatesForRFP(params);
            }

            // if (doYouWishNegotiation === CommonAnswer.YES && rfpProperty?.inventories?.length === 1) { // fixed 630 scenario 7 ticket
            if (doYouWishNegotiation === CommonAnswer.YES && inventoryRates.length === 1) {
                const payload = {
                    rfpId: rfpId,
                    brandId: property?.brandId,
                    propertyId: propertyId,
                    propertyStatus: BrandRfpPropertyEvaluationStatus.REVIEW,
                    cityName: property?.city,
                    isNegotiateProperty: true,
                    isClient: false

                }
                reviewPropertyForRFP(payload);
            } else {
                if (doYouWishNegotiation === CommonAnswer.YES) {
                    const payload = {
                        rfpId: rfpId,
                        brandId: property?.brandId,
                        propertyId: propertyId,
                        inventoryId: rates?.inventory,
                        isNegotiateProperty: true,
                        negotiationComment: negotiationComment,
                    }

                    negotiateCreateUpdatePropertyAcceptDeclineOfRFP(payload);
                }
            }

            setEnableRatesScreen(false);
            setShowDeclineMessage(false);
        }
        if (acceptPropertyRateForRFPError) {

            toast.error(acceptPropertyRateForRFPError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            setEnableRatesScreen(false);
        }

        if (declinePropertyRateForRFPError) {
            toast.error(declinePropertyRateForRFPError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            setEnableRatesScreen(false);
        }

        if (proposedPropertyRateForRFPError) {
            toast.error(proposedPropertyRateForRFPError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            setEnableRatesScreen(false);
        }

        if (negotiatePropertyRateForRFPError) {
            toast.error(negotiatePropertyRateForRFPError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            setEnableRatesScreen(false);
            setShowNegotiationMessage(false);
        }


    }, [acceptPropertyForRFP, acceptPropertyRateForRFPError, acceptPropertyRateForRFPSuccess, declineComment, declineCreateUpdatePropertyAcceptDeclineOfRFP, declinePropertyForRFP, declinePropertyRateForRFPError, declinePropertyRateForRFPSuccess, doYouWishDeclineProperty, doYouWishNegotiation, getPropertyInventoryRatesForRFP, inventoryRates.length, isAcceptancePropertyBasedClarification, isAllInventoryAccepted, negotiateCreateUpdatePropertyAcceptDeclineOfRFP, negotiatePropertyRateForRFPError, negotiatePropertyRateForRFPSuccess, negotiationComment, property?.brandId, property?.city, propertyId, proposedPropertyRateForRFPError, proposedPropertyRateForRFPSuccess, rates?.inventory, reviewPropertyForRFP, rfpId, showAcceptanceMessage, showDeclineMessage]);


    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const callApi = useCallback(() => {
        setShowAmendedMessage(true);
        const payload = {
            rfpId: rfpId,
            propertyId: propertyId
        }
        if (propertyId) {
            getPropertyAcceptDeclineOfRFPByPropertyId(payload);
        }
    }, [getPropertyAcceptDeclineOfRFPByPropertyId, propertyId, rfpId]);

    //////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (getPropertyAcceptDeclineOfRFPByPropertyIdSuccess) {
            setPropertyAcceptInformation(getPropertyAcceptDeclineOfRFPByPropertyIdSuccess?.data);
        }
    }, [getPropertyAcceptDeclineOfRFPByPropertyIdSuccess])


    useEffect(() => {
        if (propertyAcceptInformation) {
            propertyAcceptInformation?.inventories?.forEach((inv) => {
                if (inv.inventoryId === rates?.inventory) {
                    setNegotiationComment(inv?.negotiationComment);
                    setDeclineComment(inv?.inventoryDeclineComment);
                    // setInventoryAmendedRateComment(inv?.inventoryAmendedRateComment);
                    // setInventoryAmendedSecondRateComment(inv?.inventoryAmendedSecondRateComment);
                    // setInventoryMessage(inv?.inventoryMessageThread);
                }

            })

        }
    }, [propertyAcceptInformation, rates?.inventory])

    //// decline property//////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (declinePropertyForRFPSuccess) {
            const payload = {
                rfpId: rfpId,
                brandId: property?.brandId,
                propertyId: propertyId,
                inventoryId: rates?.inventory,
                doYouWishDeclineProperty: doYouWishDeclineProperty,
                declineComment: declineComment,
            }
            declineCreateUpdatePropertyAcceptDeclineOfRFP(payload);

        }
        if (declinePropertyForRFPError) {
            toast.error(declinePropertyForRFPError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }
        if (declineCreateUpdatePropertyAcceptDeclineOfRFPError) {
            toast.error(declineCreateUpdatePropertyAcceptDeclineOfRFPError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }

        if (declineCreateUpdatePropertyAcceptDeclineOfRFPSuccess) {
            if (doYouWishNegotiation === CommonAnswer.YES && declineCreateUpdatePropertyAcceptDeclineOfRFPSuccess) {
                toast.success("Property declined successfully", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'foo-bar'
                });
            }
            if (rfpProperty?.inventories?.length === 1 && declineCreateUpdatePropertyAcceptDeclineOfRFPSuccess) {
                setEnableRatesScreen(false);
                redirectProperty();
            } else {
                const params = {
                    brandId: property?.brandId,
                    propertyId: propertyId,
                    rfpProcessId: rfpId,
                    cityName: property?.city
                }
                if (rfpId) {
                    getPropertyInventoryRatesForRFP(params);
                }
                setEnableRatesScreen(false);
                setShowDeclineMessage(false);

            }

            setShowOption(false);
            setShowMessage(false);
        }


    }, [declineComment, declineCreateUpdatePropertyAcceptDeclineOfRFP, declineCreateUpdatePropertyAcceptDeclineOfRFPError, declineCreateUpdatePropertyAcceptDeclineOfRFPSuccess, declinePropertyForRFPError, declinePropertyForRFPSuccess, doYouWishDeclineProperty, doYouWishNegotiation, getPropertyInventoryRatesForRFP, property?.brandId, property?.city, propertyId, rates?.inventory, redirectProperty, rfpId, rfpProperty?.inventories?.length]);

    /////////////////////////accept property success//////////////////////////////////////////////////////////////
    useEffect(() => {
        if (acceptPropertyForRFPSuccess) {
            const payload = {
                rfpId: rfpId,
                brandId: property?.brandId,
                propertyId: propertyId,
                doYouWishDeclineProperty: doYouWishDeclineProperty,
                isAcceptancePropertyBasedClarification: isAcceptancePropertyBasedClarification,
            }
            acceptCreateUpdatePropertyAcceptDeclineOfRFP(payload);
        }

        if (acceptPropertyForRFPError) {
            toast.error(acceptPropertyForRFPError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }
        if (acceptCreateUpdatePropertyAcceptDeclineOfRFPError) {
            toast.error(acceptCreateUpdatePropertyAcceptDeclineOfRFPError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }

        if (acceptCreateUpdatePropertyAcceptDeclineOfRFPSuccess) {
            toast.success("Property accepted successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            redirectProperty();
        }
    }, [acceptCreateUpdatePropertyAcceptDeclineOfRFP, acceptCreateUpdatePropertyAcceptDeclineOfRFPError, acceptCreateUpdatePropertyAcceptDeclineOfRFPSuccess, acceptPropertyForRFPError, acceptPropertyForRFPSuccess, doYouWishDeclineProperty, isAcceptancePropertyBasedClarification, property?.brandId, propertyId, redirectProperty, rfpId]);



    /////////////////////////review property success//////////////////////////////////////////////////////////////
    useEffect(() => {
        if (reviewPropertyForRFPSuccess && doYouWishNegotiation === CommonAnswer.YES && inventoryRates.length === 1) {
            const payload = {
                rfpId: rfpId,
                brandId: property?.brandId,
                propertyId: propertyId,
                inventoryId: rates?.inventory,
                isNegotiateProperty: true,
                negotiationComment: negotiationComment,
            }

            negotiateCreateUpdatePropertyAcceptDeclineOfRFP(payload);

        }

        if (reviewPropertyForRFPError) {
            toast.error(reviewPropertyForRFPError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }
        if (negotiateCreateUpdatePropertyAcceptDeclineOfRFPError) {
            toast.error(negotiateCreateUpdatePropertyAcceptDeclineOfRFPError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }

        if (negotiateCreateUpdatePropertyAcceptDeclineOfRFPSuccess) {
            toast.success("Property review successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });

            if (rfpProperty?.inventories?.length === 1 && negotiateCreateUpdatePropertyAcceptDeclineOfRFPSuccess) {
                setEnableRatesScreen(false);
                redirectProperty();
            } else {
                const params = {
                    brandId: property?.brandId,
                    propertyId: propertyId,
                    rfpProcessId: rfpId,
                    cityName: property?.city
                }
                if (rfpId) {
                    getPropertyInventoryRatesForRFP(params);
                }
                setEnableRatesScreen(false);
                setShowDeclineMessage(false);
            }

        }


    }, [doYouWishNegotiation, getPropertyInventoryRatesForRFP, inventoryRates.length, negotiateCreateUpdatePropertyAcceptDeclineOfRFP, negotiateCreateUpdatePropertyAcceptDeclineOfRFPError, negotiateCreateUpdatePropertyAcceptDeclineOfRFPSuccess, negotiationComment, property?.brandId, property?.city, propertyId, rates?.inventory, redirectProperty, reviewPropertyForRFPError, reviewPropertyForRFPSuccess, rfpId, rfpProperty?.inventories?.length]);


    /////////////////////////review property success//////////////////////////////////////////////////////////////
    useEffect(() => {
        if (reviewPropertyForRFPSuccess) {
            if (doYouWishDeclineProperty && isAcceptancePropertyBasedClarification && !showAcceptanceMessage) {
                const payload = {
                    rfpId: rfpId,
                    brandId: property?.brandId,
                    propertyId: propertyId,
                    doYouWishDeclineProperty: doYouWishDeclineProperty,
                    isAcceptancePropertyBasedClarification: isAcceptancePropertyBasedClarification,
                    clarificationComment: clarificationComment,
                    // doYouWishOpenNegotiationPropertyRelationToAboveRateSubmission: doYouWishOpenNegotiationProperty,
                    // negotiationComment: negotiationComment,
                }
                reviewCreateUpdatePropertyAcceptDeclineOfRFP(payload);
            }

            if (reviewPropertyForRFPSuccess && showAcceptanceMessage && isAcceptancePropertyBasedClarification === CommonAnswer.YES && inventoryRates?.length === 1) {
                const payload = {
                    rfpId: rfpId,
                    brandId: property?.brandId,
                    propertyId: propertyId,
                    doYouWishDeclineProperty: doYouWishDeclineProperty,
                    isAcceptancePropertyBasedClarification: isAcceptancePropertyBasedClarification,
                    clarificationComment: clarificationComment,
                    // doYouWishOpenNegotiationPropertyRelationToAboveRateSubmission: doYouWishOpenNegotiationProperty,
                    // negotiationComment: negotiationComment,
                }
                reviewCreateUpdatePropertyAcceptDeclineOfRFP(payload);
            }



        }

        if (reviewPropertyForRFPError) {
            toast.error(reviewPropertyForRFPError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }
        if (reviewCreateUpdatePropertyAcceptDeclineOfRFPError) {
            toast.error(reviewCreateUpdatePropertyAcceptDeclineOfRFPError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }

        if (reviewCreateUpdatePropertyAcceptDeclineOfRFPSuccess) {
            toast.success("Property review successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            redirectProperty();
        }


        if (reviewPropertyForRFPSuccess) {
            if (isCheckNegotiateInventory) {
                toast.success("Property review successfully", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'foo-bar'
                });
                redirectProperty();
            }

        }

        if (reviewPropertyForRFPError) {
            toast.error(reviewPropertyForRFPError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }

    }, [clarificationComment, doYouWishDeclineProperty, inventoryRates?.length, isAcceptancePropertyBasedClarification, isCheckNegotiateInventory, property?.brandId, propertyId, redirectProperty, reviewCreateUpdatePropertyAcceptDeclineOfRFP, reviewCreateUpdatePropertyAcceptDeclineOfRFPError, reviewCreateUpdatePropertyAcceptDeclineOfRFPSuccess, reviewPropertyForRFPError, reviewPropertyForRFPSuccess, rfpId, showAcceptanceMessage]);


    /////////////////////////part accepted property success//////////////////////////////////////////////////////////////
    useEffect(() => {
        if (partAcceptedPropertyForRFPSuccess) {
            const payload = {
                rfpId: rfpId,
                brandId: property?.brandId,
                propertyId: propertyId,
                doYouWishDeclineProperty: doYouWishDeclineProperty,
                // doYouWishOpenNegotiationPropertyRelationToAboveRateSubmission: doYouWishOpenNegotiationProperty,
                // negotiationComment: negotiationComment,
                isAcceptancePropertyBasedClarification: isAcceptancePropertyBasedClarification,
            }

            partAcceptedCreateUpdatePropertyAcceptDeclineOfRFP(payload);

        }

        if (partAcceptedPropertyForRFPError) {
            toast.error(partAcceptedPropertyForRFPError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }
        if (partAcceptedCreateUpdatePropertyAcceptDeclineOfRFPError) {
            toast.error(partAcceptedCreateUpdatePropertyAcceptDeclineOfRFPError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }

        if (partAcceptedCreateUpdatePropertyAcceptDeclineOfRFPSuccess) {
            if (isAcceptancePropertyBasedClarification === CommonAnswer.NO) {
                toast.success("Property part accepted successfully", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'foo-bar'
                });
            } else {
                toast.success("Property review successfully", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'foo-bar'
                });
            }

            redirectProperty();
        }


    }, [doYouWishDeclineProperty, isAcceptancePropertyBasedClarification, partAcceptedCreateUpdatePropertyAcceptDeclineOfRFP, partAcceptedCreateUpdatePropertyAcceptDeclineOfRFPError, partAcceptedCreateUpdatePropertyAcceptDeclineOfRFPSuccess, partAcceptedPropertyForRFPError, partAcceptedPropertyForRFPSuccess, property?.brandId, propertyId, redirectProperty, rfpId]);

    /// commented for new scenario implementation
    // /////////////////////////negotiate rate //////////////////////////////////////////////////////////////
    // useEffect(() => {
    //     if (negotiateCreateUpdatePropertyAcceptDeclineOfRFPSuccess) {
    //         if (doYouWishOpenNegotiationProperty === CommonAnswer.NO) {
    //             const payload = {
    //                 rfpId: rfpId,
    //                 brandId: property?.brandId,
    //                 propertyId: propertyId,
    //                 propertyInventoryId: rates?.inventory,
    //                 propertyInventoryStatus: PropertyInventoryStatus.DECLINED,
    //                 cityName: property?.city,

    //             }
    //             declinePropertyRateForRFP(payload);
    //         } else {
    //             const payload = {
    //                 rfpId: rfpId,
    //                 brandId: property?.brandId,
    //                 propertyId: propertyId,
    //                 propertyInventoryId: rates?.inventory,
    //                 propertyInventoryStatus: PropertyInventoryStatus.NEGOTIATE,
    //                 cityName: property?.city,

    //             }
    //             negotiatePropertyRateForRFP(payload);
    //         }
    //         // redirectProperty();
    //     }

    //     if (negotiateCreateUpdatePropertyAcceptDeclineOfRFPError) {
    //         toast.error(negotiateCreateUpdatePropertyAcceptDeclineOfRFPError.message, {
    //             position: toast.POSITION.BOTTOM_RIGHT,
    //             className: 'foo-bar'
    //         });
    //     }

    // }, [declinePropertyRateForRFP, doYouWishOpenNegotiationProperty, negotiateCreateUpdatePropertyAcceptDeclineOfRFPError, negotiateCreateUpdatePropertyAcceptDeclineOfRFPSuccess, property?.brandId, property?.city, propertyId, rates?.inventory, negotiatePropertyRateForRFP, rfpId]);



    ///////////////////////// menu change ///////////////////////////////////
    const menuChange = useCallback((value) => {
        setMenuKey(value.key);
    }, []);

    /////////////////////////accept rate ///////////////////////////////////
    const onAcceptRate = useCallback(() => {
        if (inventoryRates.length === 1) {
            setShowAcceptanceMessage(true);

        } else {
            setShowAcceptanceMessage(false);
            const payload = {
                rfpId: rfpId,
                brandId: property?.brandId,
                propertyId: propertyId,
                propertyInventoryId: rates?.inventory,
                propertyInventoryStatus: PropertyInventoryStatus.ACCEPTED,
                cityName: property?.city,

            }
            acceptPropertyRateForRFP(payload);
        }
    }, [acceptPropertyRateForRFP, inventoryRates.length, property?.brandId, property?.city, propertyId, rates?.inventory, rfpId]);


    const acceptOneInventoryProperty = useCallback(() => {
        const payload = {
            rfpId: rfpId,
            brandId: property?.brandId,
            propertyId: propertyId,
            propertyInventoryId: rates?.inventory,
            propertyInventoryStatus: PropertyInventoryStatus.ACCEPTED,
            cityName: property?.city,

        }

        acceptPropertyRateForRFP(payload);
    }, [acceptPropertyRateForRFP, property?.brandId, property?.city, propertyId, rates?.inventory, rfpId])


    const declineOneInventoryOfProperty = useCallback(() => {
        const payload = {
            rfpId: rfpId,
            brandId: property?.brandId,
            propertyId: propertyId,
            propertyInventoryId: rates?.inventory,
            propertyInventoryStatus: PropertyInventoryStatus.DECLINED,
            cityName: property?.city,

        }
        declinePropertyRateForRFP(payload);
    }, [declinePropertyRateForRFP, property?.brandId, property?.city, propertyId, rates?.inventory, rfpId]);


    const negotiateOneInventoryOfProperty = useCallback(() => {

        const payload = {
            rfpId: rfpId,
            brandId: property?.brandId,
            propertyId: propertyId,
            propertyInventoryId: rates?.inventory,
            propertyInventoryStatus: PropertyInventoryStatus.REVIEW,
            cityName: property?.city,

        }
        negotiatePropertyRateForRFP(payload);
    }, [negotiatePropertyRateForRFP, property?.brandId, property?.city, propertyId, rates?.inventory, rfpId]);

    ///////////////////////// decline rate ///////////////////////////////////
    const onDeclineRate = useCallback(() => {
        // if (inventoryRates?.length === 1) {
        //     setShowDeclineMessage(true);

        // } else {
        //     setShowDeclineMessage(false);
        //     const payload = {
        //         rfpId: rfpId,
        //         brandId: property?.brandId,
        //         propertyId: propertyId,
        //         propertyInventoryId: rates?.inventory,
        //         propertyInventoryStatus: PropertyInventoryStatus.DECLINED,
        //         cityName: property?.city,

        //     }
        //     declinePropertyRateForRFP(payload);
        // }
        setShowDeclineMessage(true);
    }, []);


    ///////////////////////// proposed rate ///////////////////////////////////
    const onProposedRate = useCallback(() => {
        const payload = {
            rfpId: rfpId,
            brandId: property?.brandId,
            propertyId: propertyId,
            propertyInventoryId: rates?.inventory,
            propertyInventoryStatus: PropertyInventoryStatus.PROPOSED,
            cityName: property?.city,

        }
        proposedPropertyRateForRFP(payload);
    }, [property?.brandId, property?.city, propertyId, proposedPropertyRateForRFP, rates?.inventory, rfpId]);

    //////////// accept or decline //////////
    const onChangePropertyOption = useCallback((value) => {
        if (value === CommonAnswer.NO) {
            setShowDeclineMessage(false);
            setShowDecline(false);

            setEnableRatesScreen(false);
        }
        if (value) {
            setDoYouWishDeclineProperty(value);
        }

    }, []);

    //////////// accept or decline //////////
    const onChangeNegotiateOption = useCallback((value) => {
        if (value === CommonAnswer.NO && inventoryRates?.length === 1) {
            setShowDecline(true);

            //   setEnableRatesScreen(false);
        }
        //     setShowDecline(false);
        setDoYouWishNegotiation(value);
    }, [inventoryRates?.length]);

    ////////////  //////////
    const onSubmitClarification = useCallback((value) => {
        if (value) {
            setIsAcceptancePropertyBasedClarification(value);
        }

    }, []);

    //////////// accept or decline //////////
    const onOpenNegotiation = useCallback((value) => {
        setDoYouWishOpenNegotiationProperty(value);
    }, []);


    //////////// declineProperty //////////
    const declineProperty = useCallback(() => {
        const payload = {
            rfpId: rfpId,
            brandId: property?.brandId,
            propertyId: propertyId,
            propertyStatus: BrandRfpPropertyEvaluationStatus.DECLINED,
            cityName: property?.city,
            isAllInventoryAccepted: isAllInventoryAccepted,
            isClient: true
        }
        declinePropertyForRFP(payload);
    }, [declinePropertyForRFP, isAllInventoryAccepted, property?.brandId, property?.city, propertyId, rfpId]);

    //////////// accept Property //////////
    const acceptProperty = useCallback(() => {
        const payload = {
            rfpId: rfpId,
            brandId: property?.brandId,
            propertyId: propertyId,
            propertyStatus: BrandRfpPropertyEvaluationStatus.ACCEPTED,
            cityName: property?.city,
            isClient: true
        }
        acceptPropertyForRFP(payload);
    }, [acceptPropertyForRFP, property?.brandId, property?.city, propertyId, rfpId]);

    //////////// reviewProperty //////////
    const onReviewProperty = useCallback(() => {
        if (isAcceptancePropertyBasedClarification === CommonAnswer.YES) {
            const payload = {
                rfpId: rfpId,
                brandId: property?.brandId,
                propertyId: propertyId,
                propertyStatus: BrandRfpPropertyEvaluationStatus.REVIEW,
                cityName: property?.city,
                isAllInventoryAccepted: isAllInventoryAccepted,
                isNegotiation: false,
                isNegotiateProperty: false,
                isClient: true

            }
            reviewPropertyForRFP(payload);
        } else {
            const payload = {
                rfpId: rfpId,
                brandId: property?.brandId,
                propertyId: propertyId,
                propertyStatus: BrandRfpPropertyEvaluationStatus.REVIEW,
                cityName: property?.city,
                isAllInventoryAccepted: isAllInventoryAccepted,
                isNegotiation: true,
                isClient: true
            }
            reviewPropertyForRFP(payload);
        }

    }, [isAcceptancePropertyBasedClarification, isAllInventoryAccepted, property?.brandId, property?.city, propertyId, reviewPropertyForRFP, rfpId]);

    // //////////// part accepted //////////
    // const onSubmitNegotiation = useCallback(() => {
    //     setShowAcceptanceClarification(true);
    // }, []);


    //////////// submit negotiate comment //////////
    const onSubmitComment = useCallback(() => {
        const payload = {
            rfpId: rfpId,
            brandId: property?.brandId,
            propertyId: propertyId,
            inventoryId: rates?.inventory,
            doYouWishOpenNegotiationPropertyRelationToAboveRateSubmission: doYouWishOpenNegotiationProperty,
            negotiationComment: negotiationComment,
        }

        negotiateCreateUpdatePropertyAcceptDeclineOfRFP(payload);
    }, [doYouWishOpenNegotiationProperty, negotiateCreateUpdatePropertyAcceptDeclineOfRFP, negotiationComment, property?.brandId, propertyId, rates?.inventory, rfpId]);


    //////////// part accept Property //////////
    const partAcceptProperty = useCallback(() => {
        if (isAcceptancePropertyBasedClarification === CommonAnswer.NO) {
            const payload = {
                rfpId: rfpId,
                brandId: property?.brandId,
                propertyId: propertyId,
                propertyStatus: BrandRfpPropertyEvaluationStatus.PART_ACCEPTED,
                cityName: property?.city,
                isAllInventoryAccepted: isAllInventoryAccepted,
                isNegotiation: false

            }
            partAcceptedPropertyForRFP(payload);
        } else {
            const payload = {
                rfpId: rfpId,
                brandId: property?.brandId,
                propertyId: propertyId,
                propertyStatus: BrandRfpPropertyEvaluationStatus.REVIEW,
                cityName: property?.city,
                isAllInventoryAccepted: isAllInventoryAccepted,
                isNegotiation: false

            }
            partAcceptedPropertyForRFP(payload);
        }



    }, [isAcceptancePropertyBasedClarification, isAllInventoryAccepted, partAcceptedPropertyForRFP, property?.brandId, property?.city, propertyId, rfpId]);



    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        const filteredRfpProcessList = inventoryRates?.filter(process => process.rfpPropertyRateStatus === PropertyInventoryStatus.PROPOSED);
        if (filteredRfpProcessList?.length > 0) {
            setDisabledButton(true);
        } else {
            setDisabledButton(false);
        }

        const filteredAcceptInventoryList = inventoryRates?.filter(process => process.rfpPropertyRateStatus === PropertyInventoryStatus.DECLINED);
        //   console.log(filteredAcceptInventoryList,'filteredAcceptInventoryList');
        if (filteredAcceptInventoryList?.length > 0) {
            setIsAllInventoryAccepted(false);
        } else {
            setIsAllInventoryAccepted(true);
        }


        const filteredNegotiateInventoryList = inventoryRates?.filter(process => ((process.rfpPropertyRateStatus === PropertyInventoryStatus.REVIEW)));
        if (filteredNegotiateInventoryList?.length >= 1) {
            setIsCheckNegotiateInventory(true);
        } else {
            setIsCheckNegotiateInventory(false);
        }


    }, [inventoryRates]);

    const onNegotiateProperty = useCallback(() => {
        const payload = {
            rfpId: rfpId,
            brandId: property?.brandId,
            propertyId: propertyId,
            propertyStatus: BrandRfpPropertyEvaluationStatus.REVIEW,
            cityName: property?.city,
            isAllInventoryAccepted: isAllInventoryAccepted,
            isNegotiation: false,
            isNegotiateProperty: true,
            isClient: true
        }
        reviewPropertyForRFP(payload);
    }, [isAllInventoryAccepted, property?.brandId, property?.city, propertyId, reviewPropertyForRFP, rfpId])


    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <>
            <div style={tabDivStyle}>
                <Tab.Container defaultActiveKey="property" >
                    <Row >
                        <Col sm={2}>
                            <Nav variant="pills" className="flex-column secondry-menu" style={navStyle}>
                                <div style={{ marginTop: '0%' }}>
                                    <Nav.Item><Nav.Link eventKey="property">Property</Nav.Link></Nav.Item>
                                    {
                                        surveys.length > 0 &&
                                        <Nav.Item><Nav.Link eventKey="RFPSurvey">Property RFP Survey</Nav.Link></Nav.Item>
                                    }
                                    {/* <Nav.Item><Nav.Link eventKey="RFPSurvey">Property Survey  </Nav.Link></Nav.Item> */}
                                    <Nav.Item><Nav.Link eventKey="operator" onClick={() => {
                                        history.push({
                                            pathname: `/admin/client-proposal-management/operator/${propertyId}/${rfpId}`
                                        })
                                    }}>Operator</Nav.Link></Nav.Item>
                                </div>
                            </Nav>
                        </Col>
                        <Col sm={10}>
                            <Tab.Content >
                                <Tab.Pane eventKey="property" >
                                    <Row>
                                        <Col md={7}>
                                            <Breadcrumb className='bc_unset'>
                                                <Breadcrumb.Item style={{ fontSize: '24px' }} onClick={() => {
                                                    history.push({
                                                        pathname: `/admin/client-proposal-management`,
                                                        state: {
                                                            cityName: property ? property?.city : location?.state?.query,
                                                            rfpId: rfpId,
                                                            filterProgramManage: true,
                                                            defaultActiveKey: 'propertyAssessment'
                                                        }
                                                    })
                                                }}>{location?.state?.query}</Breadcrumb.Item>
                                                <Breadcrumb.Item style={{ fontSize: '24px', cursor: 'pointer' }} active onClick={() => {
                                                    setEnableRatesScreen(false);
                                                }}>{`${property?.propertyName} by ${property?.brand?.name}`} </Breadcrumb.Item>
                                                {enableRatesScreen && <Breadcrumb.Item style={{ fontSize: '24px', cursor: 'pointer' }} active >{`${rates?.inventoryDetails[0]?.accommodationDetail}`} </Breadcrumb.Item>}
                                            </Breadcrumb>

                                        </Col>

                                        <Col md={5}>
                                            {!enableRatesScreen && ((rfpProperty?.evaluationStatus === BrandRfpPropertyEvaluationStatus.COMPLETE && !isCheckNegotiateInventory) || (rfpProperty?.evaluationStatus === BrandRfpPropertyEvaluationStatus.REVIEWED && !isCheckNegotiateInventory) || (rfpProperty?.evaluationStatus === BrandRfpPropertyEvaluationStatus.INCOMPLETE && !isCheckNegotiateInventory)) &&
                                                <div style={{ textAlign: 'right' }}>
                                                    <Button className='btn-approval-property btn_design mr-0' hidden={disabledButton} onClick={() => { setShowOption(true); setShowMessage(true); }}><b>Approve/Decline Property</b></Button>
                                                    {/* <Button style={{ ...buttonStyle, background: '#4ABEB4', color: '#ffffff' }}><b>Decline <CloseOutlined /></b></Button> */}
                                                </div>
                                            }

                                            {!enableRatesScreen && (rfpProperty?.evaluationStatus === BrandRfpPropertyEvaluationStatus.COMPLETE && isCheckNegotiateInventory) &&
                                                <div style={{ textAlign: 'right' }}>
                                                    <Button className='btn-negotiate btn_design mr-0' hidden={disabledButton} onClick={() => { onNegotiateProperty() }}><b>Negotiate with Property</b></Button>
                                                    {/* <Button style={{ ...buttonStyle, background: '#4ABEB4', color: '#ffffff' }}><b>Decline <CloseOutlined /></b></Button> */}
                                                </div>
                                            }


                                            {(rfpProperty?.evaluationStatus === BrandRfpPropertyEvaluationStatus.ACCEPTED) && !enableRatesScreen &&
                                                <div style={{ textAlign: 'right' }}>
                                                    <Button className='btn-approval btn_design' ><b>Accepted Property </b> </Button>
                                                </div>
                                            }

                                            {(rfpProperty?.evaluationStatus === BrandRfpPropertyEvaluationStatus.DECLINED) && !enableRatesScreen &&
                                                <div style={{ textAlign: 'right' }}>
                                                    <Button className='btn-decline btn_design'  ><b>Declined Property </b></Button>
                                                </div>
                                            }
                                            {(rfpProperty?.evaluationStatus === BrandRfpPropertyEvaluationStatus.REVIEW) && !enableRatesScreen &&
                                                <div style={{ textAlign: 'right' }}>
                                                    <Button className='btn-review btn_design'  ><b>Review Property </b></Button>
                                                </div>
                                            }
                                            {/* 
                                            {(enableRatesScreen && rates?.rfpPropertyRateStatus === PropertyInventoryStatus.PROPOSED) && <Button className='btn-approval' onClick={() => { onAcceptRate() }}><b>Accept Rates <CheckOutlined /></b> </Button>}


                                            {(enableRatesScreen && rates?.rfpPropertyRateStatus === PropertyInventoryStatus.PROPOSED) && <Button className='btn-decline btn_design' onClick={() => { onDeclineRate() }} ><b>Decline Rates <CloseOutlined /></b></Button>} */}

                                            <div >
                                                {(enableRatesScreen && rates?.rfpPropertyRateStatus === PropertyInventoryStatus.PROPOSED) && <Button className='btn-approval mr-8' onClick={() => { onAcceptRate() }}><b>Accept Rates </b> </Button>}
                                                {/* {(enableRatesScreen && rates?.rfpPropertyRateStatus === PropertyInventoryStatus.PROPOSED) && <Button className='btn-negotiate btn_design mr-8' onClick={() => { setShowNegotiationMessage(true) }} ><b>Negotiate Rates </b></Button>} */}
                                                {(enableRatesScreen && rates?.rfpPropertyRateStatus === PropertyInventoryStatus.PROPOSED) && <Button className='btn-decline btn_design' onClick={() => { onDeclineRate(); }} ><b>Decline Rates </b></Button>}

                                            </div>
                                            <div className='d-flex flex-row' >
                                                {enableRatesScreen && ((rates?.rfpPropertyRateStatus !== PropertyInventoryStatus.PROPOSED)) && <Button className='btn-comment mr-2' onClick={() => { callApi(); }} ><b>View Comments </b></Button>}
                                                {(enableRatesScreen && rates?.rfpPropertyRateStatus === PropertyInventoryStatus.ACCEPTED) &&
                                                    <Button className='btn-approval btn_design mr-8' ><b>Accepted </b> </Button>
                                                }
                                                {(enableRatesScreen && rates.rfpPropertyRateStatus === PropertyInventoryStatus.DECLINED) &&
                                                    // <div style={{ textAlign: 'right' }}>
                                                    <Button className='btn-decline btn_design mr-8'  ><b>Declined </b></Button>

                                                }

                                            </div>



                                        </Col>
                                    </Row>
                                    <Row >
                                        <Col md={12}>
                                            <div className='icn_txt' style={{ marginTop: '-5px' }}><EnvironmentOutlined /> {`${property?.address1} ${property?.address2}, ${property?.city} - ${property?.postcode}`}</div>
                                        </Col>
                                    </Row>


                                    <Modal
                                        show={showNegotiationMessage}
                                        onHide={() => { setShowNegotiationMessage(false); }}
                                        size="xl"
                                        aria-labelledby="contained-modal-title-vcenter"
                                        centered={false}
                                    >
                                        <Modal.Header closeButton>
                                        </Modal.Header>
                                        <Modal.Body>

                                            {
                                                <Row className='mt-3'>
                                                    <div style={{ fontSize: '16px' }}>Do you wish to open up negotiation with the property in relation to the rate submission?
                                                        <span style={{ marginLeft: '2%' }}>
                                                            <Form.Check
                                                                inline
                                                                value={'Yes'}
                                                                label={'Yes'}
                                                                type="radio"
                                                                name='doYouWishOpenNegotiationPropertyRelationToAboveRateSubmission'
                                                                onChange={(value) => {
                                                                    onOpenNegotiation(value.target.value);
                                                                }}
                                                            />
                                                            <Form.Check
                                                                inline
                                                                value={'No'}
                                                                label={'No'}
                                                                type="radio"

                                                                name='doYouWishOpenNegotiationPropertyRelationToAboveRateSubmission'
                                                                onChange={(value) => {
                                                                    onOpenNegotiation(value.target.value);
                                                                }}
                                                            />
                                                        </span>
                                                    </div>
                                                </Row>}

                                            {
                                                (doYouWishOpenNegotiationProperty === CommonAnswer.NO || doYouWishOpenNegotiationProperty === CommonAnswer.YES) &&
                                                <div>
                                                    <Row className='mt-3'>
                                                        <Col style={{ textAlign: 'left' }}>
                                                            <Form.Control as="textarea" rows={5}
                                                                placeholder="please provide comments"

                                                                name='negotiationComment'
                                                                // value={''}
                                                                onChange={(e) => {
                                                                    setNegotiationComment(e.target.value);
                                                                }}
                                                            />


                                                        </Col>
                                                    </Row>
                                                    {(doYouWishOpenNegotiationProperty === CommonAnswer.NO || doYouWishOpenNegotiationProperty === CommonAnswer.YES) && <Row className='formSpace'>
                                                        <Col md={6} style={{ textAlign: 'left' }}>
                                                            <Button id='approve-button' style={formButton} onClick={() => { onSubmitComment(); }} >Submit</Button>
                                                        </Col>
                                                    </Row>}
                                                </div>
                                            }

                                        </Modal.Body>
                                    </Modal>

                                    {/* decline or accept property */}
                                    <Modal
                                        show={showMessage}
                                        onHide={() => { setShowMessage(false); setShowOption(false); }}
                                        size="xl"
                                        aria-labelledby="contained-modal-title-vcenter"
                                        centered={false}
                                    >
                                        <Modal.Header closeButton>
                                        </Modal.Header>
                                        <Modal.Body>

                                            {showOption &&
                                                <Row className=''>
                                                    <div style={{ fontSize: '16px' }}>Do you wish to decline this property?
                                                        <span style={{ marginLeft: '2%' }}>
                                                            <Form.Check
                                                                inline
                                                                value={'Yes'}
                                                                label={'Yes'}
                                                                type="radio"
                                                                name='doYouWishDeclineProperty'
                                                                onChange={(value) => {
                                                                    onChangePropertyOption(value.target.value);
                                                                }}
                                                            />
                                                            <Form.Check
                                                                inline
                                                                value={'No'}
                                                                label={'No'}
                                                                type="radio"

                                                                name='doYouWishDeclineProperty'
                                                                onChange={(value) => {
                                                                    onChangePropertyOption(value.target.value);
                                                                }}
                                                            />
                                                        </span>
                                                    </div>
                                                </Row>}

                                            {doYouWishDeclineProperty === CommonAnswer.YES &&
                                                <div>
                                                    <Row className='mt-3'>
                                                        <Col style={{ textAlign: 'left' }}>
                                                            <Form.Control as="textarea" rows={5}
                                                                placeholder="please provide comments"
                                                                name='declineComment'
                                                                // value={''}
                                                                onChange={(e) => {
                                                                    setDeclineComment(e.target.value);
                                                                }}
                                                            />


                                                        </Col>
                                                    </Row>

                                                    <Row className='formSpace'>
                                                        <Col md={6} style={{ textAlign: 'left' }}>
                                                            <Button className='btn-decline' onClick={() => { declineProperty() }} ><b>Decline Property </b></Button>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            }
                                            {/* {(doYouWishDeclineProperty === CommonAnswer.NO && !isAllInventoryAccepted) &&
                                                <Row className='mt-3'>
                                                    <div style={{ fontSize: '16px' }}>Do you wish to open up negotiation with the property in relation to the rate submission?
                                                        <span style={{ marginLeft: '2%' }}>
                                                            <Form.Check
                                                                inline
                                                                value={'Yes'}
                                                                label={'Yes'}
                                                                type="radio"
                                                                name='doYouWishOpenNegotiationPropertyRelationToAboveRateSubmission'
                                                                onChange={(value) => {
                                                                    onOpenNegotiation(value.target.value);
                                                                }}
                                                            />
                                                            <Form.Check
                                                                inline
                                                                value={'No'}
                                                                label={'No'}
                                                                type="radio"

                                                                name='doYouWishOpenNegotiationPropertyRelationToAboveRateSubmission'
                                                                onChange={(value) => {
                                                                    onOpenNegotiation(value.target.value);
                                                                }}
                                                            />
                                                        </span>
                                                    </div>
                                                </Row>}

                                            {
                                                !isAllInventoryAccepted && (doYouWishOpenNegotiationProperty === CommonAnswer.NO || doYouWishOpenNegotiationProperty === CommonAnswer.YES) &&
                                                <div>
                                                    <Row className='mt-3'>
                                                        <Col style={{ textAlign: 'left' }}>
                                                            <Form.Control as="textarea" rows={5}
                                                                placeholder="please provide comments"

                                                                name='negotiationComment'
                                                                // value={''}
                                                                onChange={(e) => {
                                                                    setNegotiationComment(e.target.value);
                                                                }}
                                                            />


                                                        </Col>
                                                    </Row>
                                                    {!showAcceptanceClarification && <Row className='formSpace'>
                                                        <Col md={6} style={{ textAlign: 'left' }}>
                                                            <Button id='approve-button' style={formButton} onClick={() => { onSubmitNegotiation(); }} >Submit</Button>
                                                        </Col>
                                                    </Row>}
                                                </div>
                                            } */}

                                            {((doYouWishDeclineProperty === CommonAnswer.NO && isAllInventoryAccepted)) &&
                                                <Row className='mt-3'>
                                                    <div style={{ fontSize: '16px' }}>Is acceptance of the property based on any factors or further clarification?
                                                        <span style={{ marginLeft: '2%' }}>
                                                            <Form.Check
                                                                inline
                                                                value={'Yes'}
                                                                label={'Yes'}
                                                                type="radio"
                                                                name='isAcceptancePropertyBasedClarification'
                                                                onChange={(value) => {
                                                                    onSubmitClarification(value.target.value);
                                                                }}
                                                            />
                                                            <Form.Check
                                                                inline
                                                                value={'No'}
                                                                label={'No'}
                                                                type="radio"

                                                                name='isAcceptancePropertyBasedClarification'
                                                                onChange={(value) => {
                                                                    onSubmitClarification(value.target.value);
                                                                }}
                                                            />
                                                        </span>
                                                    </div>
                                                </Row>}

                                            {((doYouWishDeclineProperty === CommonAnswer.NO && !isAllInventoryAccepted)) &&
                                                <Row className='mt-3'>
                                                    <div style={{ fontSize: '16px' }}>Is acceptance of the property based on any factors or further clarification?
                                                        <span style={{ marginLeft: '2%' }}>
                                                            <Form.Check
                                                                inline
                                                                value={'Yes'}
                                                                label={'Yes'}
                                                                type="radio"
                                                                name='isAcceptancePropertyBasedClarification'
                                                                onChange={(value) => {
                                                                    onSubmitClarification(value.target.value);
                                                                }}
                                                            />
                                                            <Form.Check
                                                                inline
                                                                value={'No'}
                                                                label={'No'}
                                                                type="radio"

                                                                name='isAcceptancePropertyBasedClarification'
                                                                onChange={(value) => {
                                                                    onSubmitClarification(value.target.value);
                                                                }}
                                                            />
                                                        </span>
                                                    </div>
                                                </Row>}

                                            {
                                                isAcceptancePropertyBasedClarification === CommonAnswer.YES &&
                                                <div>
                                                    <Row className='mt-3'>
                                                        <Col style={{ textAlign: 'left' }}>


                                                            <Form.Control as="textarea" rows={5}
                                                                placeholder="please provide comments"
                                                                name='clarificationComment'
                                                                // value={''}
                                                                onChange={(e) => {
                                                                    setClarificationComment(e.target.value);
                                                                }}
                                                            />


                                                        </Col>
                                                    </Row>

                                                    <Row className='formSpace'>
                                                        <Col md={6} style={{ textAlign: 'left' }}>
                                                            <Button id='approve-button' style={formButton} onClick={() => { onReviewProperty() }} >Submit</Button>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            }

                                            {
                                                (isAcceptancePropertyBasedClarification === CommonAnswer.NO && doYouWishDeclineProperty === CommonAnswer.NO && !isAllInventoryAccepted) &&
                                                <div>
                                                    <Row className='formSpace'>
                                                        <p>Click to accept the property for the inventories accepted above</p>
                                                    </Row>
                                                    <Row className='formSpace'>
                                                        <Col md={6} style={{ textAlign: 'left' }}>
                                                            <Button className='btn-approval' onClick={() => { partAcceptProperty() }} ><b>Accept Property </b></Button>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            }
                                            {/* 
                                            {
                                                (isAcceptancePropertyBasedClarification === CommonAnswer.NO && doYouWishDeclineProperty === CommonAnswer.NO && !isAllInventoryAccepted) && 
                                                <div>

                                                    <Row className='formSpace'>
                                                        <Col md={6} style={{ textAlign: 'left' }}>
                                                            <Button id='approve-button' style={formButton} onClick={() => { partAcceptProperty() }} >Submit</Button>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            } */}


                                            {
                                                isAcceptancePropertyBasedClarification === CommonAnswer.NO && doYouWishDeclineProperty === CommonAnswer.NO && isAllInventoryAccepted &&
                                                <div>
                                                    <Row className='formSpace'>
                                                        <Col md={6} style={{ textAlign: 'left' }}>
                                                            <Button className='btn-approval' onClick={() => { acceptProperty() }} ><b>Accept Property </b></Button>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            }
                                        </Modal.Body>
                                    </Modal>


                                    <Modal
                                        show={showAcceptanceMessage}
                                        onHide={() => { setShowAcceptanceMessage(false); }}
                                        size="xl"
                                        aria-labelledby="contained-modal-title-vcenter"
                                        centered={false}
                                    >
                                        <Modal.Header closeButton>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <Row className='mt-3'>
                                                <div style={{ fontSize: '16px' }}>Is acceptance of the property based on any factors or further clarification?
                                                    <span style={{ marginLeft: '2%' }}>
                                                        <Form.Check
                                                            inline
                                                            value={'Yes'}
                                                            label={'Yes'}
                                                            type="radio"
                                                            name='isAcceptancePropertyBasedClarification'
                                                            onChange={(value) => {
                                                                onSubmitClarification(value.target.value);
                                                            }}
                                                        />
                                                        <Form.Check
                                                            inline
                                                            value={'No'}
                                                            label={'No'}
                                                            type="radio"

                                                            name='isAcceptancePropertyBasedClarification'
                                                            onChange={(value) => {
                                                                onSubmitClarification(value.target.value);
                                                            }}
                                                        />
                                                    </span>
                                                </div>
                                            </Row>

                                            {
                                                isAcceptancePropertyBasedClarification === CommonAnswer.YES &&
                                                <div>
                                                    <Row className='mt-3'>
                                                        <Col style={{ textAlign: 'left' }}>


                                                            <Form.Control as="textarea" rows={5}
                                                                placeholder="please provide comments"
                                                                name='clarificationComment'
                                                                // value={''}
                                                                onChange={(e) => {
                                                                    setClarificationComment(e.target.value);
                                                                }}
                                                            />


                                                        </Col>
                                                    </Row>

                                                    <Row className='formSpace'>
                                                        <Col md={6} style={{ textAlign: 'left' }}>
                                                            <Button id='approve-button' style={formButton} onClick={() => { acceptOneInventoryProperty() }} >Submit</Button>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            }

                                            {/* {
                                                (isAcceptancePropertyBasedClarification === CommonAnswer.NO && doYouWishDeclineProperty === CommonAnswer.NO && !isAllInventoryAccepted) &&
                                                <div>
                                                    <Row className='formSpace'>
                                                        <p>Click to accept the property for the inventories accepted above</p>
                                                    </Row>
                                                    <Row className='formSpace'>
                                                        <Col md={6} style={{ textAlign: 'left' }}>
                                                            <Button className='btn-approval' onClick={() => { partAcceptProperty() }} ><b>Accept Property </b></Button>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            } */}



                                            {
                                                isAcceptancePropertyBasedClarification === CommonAnswer.NO &&
                                                <div>
                                                    <Row className='formSpace'>
                                                        <Col md={6} style={{ textAlign: 'left' }}>
                                                            <Button className='btn-approval' onClick={() => { acceptOneInventoryProperty() }} ><b>Accept Property </b></Button>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            }
                                        </Modal.Body>
                                    </Modal>


                                    <Modal
                                        show={showDeclineMessage}
                                        onHide={() => { setShowDeclineMessage(false); }}
                                        size="xl"
                                        aria-labelledby="contained-modal-title-vcenter"
                                        centered={false}
                                    >
                                        <Modal.Header closeButton>
                                        </Modal.Header>
                                        <Modal.Body>

                                            <Row className=''>
                                                <div style={{ fontSize: '16px' }}>Do you wish to negotiate the rate?
                                                    <span style={{ marginLeft: '2%' }}>
                                                        <Form.Check
                                                            inline
                                                            value={'Yes'}
                                                            label={'Yes'}
                                                            type="radio"
                                                            name='doYouWishNegotiateRate'
                                                            onChange={(value) => {
                                                                onChangeNegotiateOption(value.target.value);
                                                            }}
                                                        />
                                                        <Form.Check
                                                            inline
                                                            value={'No'}
                                                            label={'No'}
                                                            type="radio"

                                                            name='doYouWishNegotiateRate'
                                                            onChange={(value) => {
                                                                onChangeNegotiateOption(value.target.value);
                                                            }}
                                                        />
                                                    </span>
                                                </div>
                                            </Row>

                                            {(showDecline && rfpProperty?.inventories?.length === 1 && doYouWishNegotiation === CommonAnswer.NO) && <Row className='mt-3'>
                                                <div style={{ fontSize: '16px' }}>Do you wish to decline this property?
                                                    <span style={{ marginLeft: '2%' }}>
                                                        <Form.Check
                                                            inline
                                                            value={'Yes'}
                                                            label={'Yes'}
                                                            type="radio"
                                                            name='doYouWishDeclineProperty'
                                                            onChange={(value) => {
                                                                onChangePropertyOption(value.target.value);
                                                            }}
                                                        />
                                                        <Form.Check
                                                            inline
                                                            value={'No'}
                                                            label={'No'}
                                                            type="radio"

                                                            name='doYouWishDeclineProperty'
                                                            onChange={(value) => {
                                                                onChangePropertyOption(value.target.value);
                                                            }}
                                                        />
                                                    </span>
                                                </div>
                                            </Row>}

                                            {showDecline && doYouWishNegotiation === CommonAnswer.NO && doYouWishDeclineProperty === CommonAnswer.YES &&
                                                <div>
                                                    <Row className='mt-3'>
                                                        <Col style={{ textAlign: 'left' }}>
                                                            <Form.Control as="textarea" rows={5}
                                                                placeholder="please provide comments"
                                                                name='declineComment'
                                                                // value={''}
                                                                onChange={(e) => {
                                                                    setDeclineComment(e.target.value);
                                                                }}
                                                            />


                                                        </Col>
                                                    </Row>

                                                    <Row className='formSpace'>
                                                        <Col md={6} style={{ textAlign: 'left' }}>
                                                            <Button id='approve-button' style={formButton} onClick={() => { declineOneInventoryOfProperty() }} >Submit</Button>
                                                        </Col>
                                                        <Col md={6} style={{ textAlign: 'right' }}>
                                                            <Button style={formButton} onClick={() => { setShowDeclineMessage(false); }} id='edit-button' >Close</Button>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            }

                                            {doYouWishNegotiation === CommonAnswer.YES &&
                                                <div>
                                                    <Row className='mt-3'>
                                                        <Col style={{ textAlign: 'left' }}>
                                                            <Form.Control as="textarea" rows={5}
                                                                placeholder="please provide negotiate comments"
                                                                name='negotiationComment'
                                                                // value={''}
                                                                onChange={(e) => {
                                                                    setNegotiationComment(e.target.value);
                                                                }}
                                                            />
                                                        </Col>
                                                    </Row>

                                                    <Row className='formSpace'>
                                                        <Col md={6} style={{ textAlign: 'left' }}>
                                                            <Button id='approve-button' style={formButton} onClick={() => { negotiateOneInventoryOfProperty() }} >Submit</Button>
                                                        </Col>
                                                        <Col md={6} style={{ textAlign: 'right' }}>
                                                            <Button style={formButton} onClick={() => { setShowDeclineMessage(false); }} id='edit-button' >Close</Button>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            }


                                            {(doYouWishNegotiation === CommonAnswer.NO && rfpProperty?.inventories?.length > 1) &&
                                                <div>
                                                    <Row className='mt-3'>
                                                        <Col style={{ textAlign: 'left' }}>
                                                            <Form.Control as="textarea" rows={5}
                                                                placeholder="please provide decline comments"
                                                                name='declineComment'
                                                                // value={''}
                                                                onChange={(e) => {
                                                                    setDeclineComment(e.target.value);
                                                                }}
                                                            />


                                                        </Col>
                                                    </Row>

                                                    <Row className='formSpace'>
                                                        <Col md={6} style={{ textAlign: 'left' }}>
                                                            <Button id='approve-button' style={formButton} onClick={() => { declineOneInventoryOfProperty() }} >Submit</Button>
                                                        </Col>
                                                        <Col md={6} style={{ textAlign: 'right' }}>
                                                            <Button style={formButton} onClick={() => { setShowDeclineMessage(false); }} id='edit-button' >Close</Button>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            }


                                        </Modal.Body>
                                    </Modal>

                                    {
                                        <Modal
                                            show={showAmendedMessage}
                                            onHide={() => { setShowAmendedMessage(false); }}
                                            size="lg"
                                            aria-labelledby="contained-modal-title-vcenter"
                                            centered={false}
                                        >
                                            <Modal.Header closeButton>
                                            </Modal.Header>
                                            <Modal.Body>
                                                {
                                                    (negotiationComment && (rates?.rfpPropertyRateStatus === PropertyInventoryStatus.REVIEW)) &&
                                                    <Row>
                                                        <Col md={10}><div style={{ textAlign: 'left' }}>

                                                            <Form.Control as="textarea" rows={5} disabled
                                                                // placeholder="please provide comments"
                                                                name='negotiationComment'
                                                                defaultValue={negotiationComment}
                                                            />
                                                        </div></Col>
                                                        <Col md={1}></Col>
                                                    </Row>}
                                                {
                                                    (declineComment && rates?.rfpPropertyRateStatus === PropertyInventoryStatus.DECLINED) &&
                                                    <Row>
                                                        <Col md={10}><div style={{ textAlign: 'left' }}>

                                                            <Form.Control as="textarea" rows={5} disabled
                                                                // placeholder="please provide comments"
                                                                name='declinedComment'
                                                                defaultValue={declineComment}
                                                            />
                                                        </div></Col>
                                                        <Col md={1}></Col>
                                                    </Row>}

                                                <Row className='formSpace'>
                                                    <Col md={6} style={{ textAlign: 'left' }}>
                                                        {/* <Button id='approve-button' style={formButton} onClick={() => { onSubmitResponseOfProperty() }} >Submit</Button> */}
                                                    </Col>
                                                    <Col md={6} style={{ textAlign: 'right' }}>
                                                        <Button style={formButton} onClick={() => { setShowAmendedMessage(false); }} id='edit-button' >Close</Button>
                                                    </Col>
                                                </Row>

                                            </Modal.Body>
                                        </Modal>}

                                    {/* {showOption &&
                                            <Row className='mt-5'>
                                                <div style={{ fontSize: '16px' }}>Do you wish to decline this property?
                                                    <span style={{ marginLeft: '2%' }}>
                                                        <Form.Check
                                                            inline
                                                            value={'Yes'}
                                                            label={'Yes'}
                                                            type="radio"
                                                            name='doYouWishDeclineProperty'
                                                            onChange={(value) => {
                                                                onChangePropertyOption(value.target.value);
                                                            }}
                                                        />
                                                        <Form.Check
                                                            inline
                                                            value={'No'}
                                                            label={'No'}
                                                            type="radio"

                                                            name='doYouWishDeclineProperty'
                                                            onChange={(value) => {
                                                                onChangePropertyOption(value.target.value);
                                                            }}
                                                        />
                                                    </span>
                                                </div>
                                            </Row>} */}



                                    {
                                        !enableRatesScreen && <div>


                                            <div className='mt-4'>
                                                <Menu onClick={menuChange} mode="horizontal" selectedKeys={[menuKey]} className="w-full new_tab ">
                                                    <Menu.Item key="property" >
                                                        <span>Property</span>
                                                    </Menu.Item>
                                                    <Menu.Item key="rates" >
                                                        <span>Rates</span>
                                                    </Menu.Item>
                                                    <Menu.Item key="audit" >
                                                        <span>Audit</span>
                                                    </Menu.Item>
                                                    <Menu.Item key="sustainability" >
                                                        <span>Sustainability</span>
                                                    </Menu.Item>
                                                </Menu>
                                            </div>

                                            <div className="new_tab_ctnt new_tab_ctnt2" >
                                                <div className='w-full' >
                                                    {(menuKey === 'property') && <ClientSinglePropertyView property={property} amenitiesList={amenitiesList} selectCityName={location?.state?.query} />}
                                                    {(menuKey === 'rates') && <ClientRatesListView setRates={setRates} setEnableRatesScreen={setEnableRatesScreen} inventoryRates={inventoryRates} property={property} />}
                                                    {(menuKey === 'audit') && <ClientAuditView property={property} />}
                                                    {(menuKey === 'sustainability') && <SustainabilityView propertyId={propertyId} />}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        enableRatesScreen && <div>
                                            <Row>
                                                <Col md={12}>
                                                    <div className='mt-5  w-full'>
                                                        <ClientPropertyRateDetails rates={rates} property={property} />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    }
                                </Tab.Pane>
                                <Tab.Pane eventKey="RFPSurvey">
                                    <Row>
                                        <Col md={7}>
                                            <Breadcrumb className='bc_unset'>
                                                <Breadcrumb.Item style={{ fontSize: '24px' }} onClick={() => {
                                                    history.push({
                                                        pathname: `/admin/client-proposal-management`,
                                                        state: {
                                                            cityName: property?.city,
                                                            rfpId: rfpId,
                                                            filterProgramManage: true,
                                                            defaultActiveKey: 'propertyAssessment'
                                                        }
                                                    })
                                                }}>{property?.city}</Breadcrumb.Item>
                                                <Breadcrumb.Item
                                                    style={{ fontSize: '24px', cursor: 'pointer' }} active
                                                    onClick={() => {
                                                        setEnableRatesScreen(false);
                                                    }}
                                                >
                                                    {`${property?.propertyName} by ${property?.brand?.name}`}
                                                </Breadcrumb.Item>
                                                {enableRatesScreen && <Breadcrumb.Item style={{ fontSize: '24px', cursor: 'pointer' }} active >{`${rates?.inventoryDetails[0]?.accommodationDetail}`} </Breadcrumb.Item>}
                                            </Breadcrumb>
                                        </Col>
                                    </Row>
                                    <Row className="mb-6">
                                        <Col md={12}>
                                            <div className='icn_txt' style={{ marginTop: '-5px' }}><EnvironmentOutlined /> {`${property?.address1} ${property?.address2}, ${property?.city} - ${property?.postcode}`}</div>
                                        </Col>
                                    </Row>
                                    <QuestionSubmitted
                                        surveys={surveys}
                                        templateId={templateId}
                                    />
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </div>


        </>
    );
}

export default ClientPropertyDetailsToAccess;