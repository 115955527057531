import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Table, message } from 'antd';
import { useStoreActions, useStoreState } from 'easy-peasy';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import RoomTypeForm from './RoomTypeForm';

const RoomTypeList: React.FC<any> = (): JSX.Element => {
  const [formOpen, setFormOpen] = useState<boolean>(false);
  const [roomTypeList, setRoomTypeList] = useState<any>([]);
  const [roomTypeDetails, setRoomTypeDetails] = useState<any>(null);
  const [roomTypeId, setRoomTypeId] = useState<number | null>(null);

  ////////////////////////////////////////////////////////////////////////////////////////////
  const loadForm = useCallback((data) => {
    setFormOpen(true);
    setRoomTypeDetails(data);
    if (data !== null) {
      setRoomTypeId(data.key);
    } else {
      setRoomTypeId(null);
    }
  }, []);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const { getRoomTypeList, updateRoomType } = useStoreActions<any>((actions) => ({
    getRoomTypeList: actions.inventory.getRoomTypeList,
    updateRoomType: actions.inventory.updateRoomType,
  }));

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const { getRoomTypeListSuccess, getRoomTypeListError, updateRoomTypeError, removeRoomTypeSuccess } = useStoreState<any>((state) => ({
    getRoomTypeListSuccess: state.inventory.getRoomTypeListSuccess,
    getRoomTypeListError: state.inventory.getRoomTypeListError,
    updateRoomTypeError: state.inventory.updateRoomTypeError,
    removeRoomTypeSuccess: state.inventory.removeRoomTypeSuccess,
  }));

  ////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    const params: any = {};
    params.isFilter = false;
    getRoomTypeList(params);
  }, [getRoomTypeList]);

  //////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (getRoomTypeListSuccess) {
      setRoomTypeList(getRoomTypeListSuccess.data);
    }
    if (getRoomTypeListError) {
      message.error(getRoomTypeListError.message)
    }

    if (removeRoomTypeSuccess) {
      message.success('RoomType removed successfully');
      const params: any = {};
      params.isFilter = false;
      getRoomTypeList(params);
    }

    if (updateRoomTypeError) {
      message.error(updateRoomTypeError.message)
    }

  }, [getRoomTypeListError, getRoomTypeListSuccess, removeRoomTypeSuccess, updateRoomTypeError, getRoomTypeList]);

  //////////////////////////////////////////////////////////////////////////////////////////////////////////
  const removeData = useCallback((values) => {
    const data = {
      id: values.key,
      isDeleted: true
    }
    updateRoomType(data);

  }, [updateRoomType]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const columns = [
    { title: 'Name', dataIndex: 'name', key: 'name', outerWidth: '75px' },
    { title: 'No of bedrooms', dataIndex: 'occupancy', key: 'occupancy', outerWidth: '75px' },
    { title: 'Property Type', dataIndex: 'propertyType', key: 'propertyType' },
    {
      title: 'Status',
      dataIndex: '',
      key: 'status  ',
      render: (data: any) => {

        return (<div className={`${data.status === 'active' ? 'text-green-500' : 'text-red-500'}`}>
          {data.status}
        </div>)
      },
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'status',
      render: (data: any) => {
        return (<div>
          <Button onClick={() => loadForm(data)} className="border-none" ><EditOutlined /></Button>
          <Button onClick={() => removeData(data)} className="border-none" ><DeleteOutlined /></Button>
        </div>)
      },
    },
  ];

  /////////////////////////////////////////////////////////////////////////////////////////////////////
  const data = useMemo(() => {
    return _.map(roomTypeList, roomType => {
      return {
        key: roomType._id,
        name: roomType.name,
        occupancy: roomType.occupancy,
        propertyType: roomType.propertyType,
        status: roomType.status
      }
    })
  }, [roomTypeList]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <div>
      <div className="flex justify-between">
        <p className="text-2xl mb-10">Room Type Details</p>
        <Button hidden={formOpen} onClick={() => { loadForm(null) }} className="btn_design m-1 text-white">Create New</Button>
        <Button hidden={!formOpen} onClick={() => setFormOpen(false)} className="btn_design m-1 text-white">Show List</Button>
      </div>
      {formOpen && <RoomTypeForm setFormOpen={setFormOpen} roomTypeDetails={roomTypeDetails} roomTypeId={roomTypeId} />}
      <div className="mb-1" hidden={formOpen}>
        <Table columns={columns} dataSource={data} />
      </div>
    </div>
  )
};

export default RoomTypeList;