import { Breadcrumb, Button } from 'react-bootstrap';
import { useStoreActions, useStoreState } from 'easy-peasy';
import React, { useCallback, useEffect, useState } from 'react';
import HotelInventoryForm from './form/HotelInventoryForm';
import HotelInventoryList from './HotelInventoryList';
import { Link } from 'react-router-dom';
import { routingButton } from '../../../common/components-style';
import { getStorage } from '../../../common/storage';
import { toast } from 'react-toastify';


const HotelInventoryHome: React.FC<any> = ({ location }): JSX.Element => {
	const [formOpen, setFormOpen] = useState<boolean>(false);
	const [inventoryId, setInventoryId] = useState<number | null>(null);
	const [hotelInventoryList, setHotelInventoryList] = useState<any>([]);
	const [hotelId, setHotelId] = useState<number | null>(null);
	const [selectedHotel, setSelectedHotel] = useState<any>(null);
	const [hideButton, setHideButton] = useState<boolean>(false);
	const [offeredRoom, setOfferedRoom] = useState<number>(1);
	const [allInOneHotelBreadCrumb, setAllInOneHotelBreadCrumb] = useState<boolean>(false);
	const [inventoryData, setInventoryData] = useState<any>();

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const { updateInventory, resetInventory, getHotelInventoryList, getInventoryByHotelId, getHotel, getInventoryById } = useStoreActions<any>((actions) => ({
		updateInventory: actions.hotelInventory.updateInventory,
		resetInventory: actions.hotelInventory.resetInventory,
		getHotelInventoryList: actions.hotelInventory.getHotelInventoryList,
		getInventoryByHotelId: actions.hotelInventory.getInventoryByHotelId,
		getHotel: actions.hotel.getHotel,
		getInventoryById: actions.hotelInventory.getInventoryById,
	}));

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const { removeInventorySuccess, updateInventoryError, getHotelInventoryListSuccess, getHotelInventoryListError, getInventoryByHotelIdSuccess, getHotelSuccess, getInventoryByIdSuccess } = useStoreState<any>((state) => ({
		getHotelInventoryListSuccess: state.hotelInventory.getHotelInventoryListSuccess,
		getGetHotelInventoryListError: state.hotelInventory.getHotelInventoryListError,
		removeInventorySuccess: state.hotelInventory.removeInventorySuccess,
		updateInventoryError: state.hotelInventory.updateInventoryError,
		getInventoryByHotelIdSuccess: state.hotelInventory.getInventoryByHotelIdSuccess,
		getHotelSuccess: state.hotel.getHotelSuccess,
		getInventoryByIdSuccess: state.hotelInventory.getInventoryByIdSuccess,
	}))

	/**
	 * special navigate 
	 */
	useEffect(() => {
		if (location.state?.allInOneHotelBreadCrumb) {
			setAllInOneHotelBreadCrumb(location.state?.allInOneHotelBreadCrumb);
		}
	}, [location.state?.allInOneHotelBreadCrumb]);

	useEffect(() => {
		const hotel = getStorage('myo_selected_hotel');
		setSelectedHotel(hotel);
	}, [])

	////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (location.state?.hotelId) {
			const params: any = {};
			params.hotelId = location.state?.hotelId;
			getHotelInventoryList(params);
		}

	}, [getHotelInventoryList, location]);

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const loadForm = useCallback((data) => {
		setFormOpen(true);
		setInventoryId(data.key ? data.key : data._id);
		setHotelId(data.hotelId ? data.hotelId : data?.hotelId?._id);
	}, []);


	const loadData = useCallback((data) => {
		setInventoryId(data._id);
		setHotelId(data.hotelId);
	}, []);

	////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (selectedHotel) {
			getHotel(selectedHotel._id)
		}
	}, [getHotel, selectedHotel]);

	//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (getHotelSuccess) {
			const data = getHotelSuccess.data;

			if (data?.noOfRoomTypesOffered === undefined) {
				if (data?.moderate?.noOfRoomTypesOffered?.new_value) {
					setOfferedRoom(data?.moderate?.noOfRoomTypesOffered?.new_value);
				} else {
					setOfferedRoom(1);
				}
			} else {
				if (data?.moderate?.noOfRoomTypesOffered?.new_value) {
					setOfferedRoom(data?.moderate?.noOfRoomTypesOffered?.new_value);
				} else {
					setOfferedRoom(data?.noOfRoomTypesOffered);
				}
			}
		}

	}, [getHotelSuccess]);


	////////////////////////////////////////////////////////////////////////////////////////////////

	useEffect(() => {
		if (selectedHotel) {
			getInventoryByHotelId(selectedHotel._id);
		}

	}, [getInventoryByHotelId, selectedHotel]);

	/////////////////////////////////////////////////////////////////////////////////////////////////////////////

	useEffect(() => {
		if (getInventoryByHotelIdSuccess) {
			if (getInventoryByHotelIdSuccess.data.length === offeredRoom) {
				setHideButton(true);
			} else {
				setHideButton(false);
			}
		}
	}, [getInventoryByHotelIdSuccess, offeredRoom]);

	///////////////////////////////////////////////////////////////////////////////////////////////////////////////

	useEffect(() => {
		if (location.state?.selectedInventory) {
			loadForm(location.state?.selectedInventory);
		}
	}, [loadForm, location])


	////////////////////////////////////////////////////////////////////////////////////////////////////

	useEffect(() => {
		if (getHotelInventoryListSuccess) {
			setHotelInventoryList(getHotelInventoryListSuccess.data.inventory);
		}
		if (getHotelInventoryListError) {
			toast.error(getHotelInventoryListError.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});

		}
	}, [getHotelInventoryListError, getHotelInventoryListSuccess]);


	/////////////////////////////////////////////////////////////////////////////////////////////////////////
	const refreshInventories = useCallback(() => {
		const params: any = {};
		if (location.state?.hotelId) {
			params.hotelId = location.state?.hotelId;
		}
		if (location.state?.selectedInventory) {
			params.hotelId = location.state?.selectedInventory.hotelId;
		}
		getHotelInventoryList(params);
	}, [getHotelInventoryList, location]);



	////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (removeInventorySuccess) {
			toast.success("Inventory removed successfully", {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
			resetInventory();
			refreshInventories();
		}
		if (updateInventoryError) {
			toast.error(updateInventoryError.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
		}
	}, [removeInventorySuccess, updateInventoryError, resetInventory, refreshInventories]);

	///////////////////////////////////////////////////////////////////////////////////////////////////////
	const removeData = useCallback((values) => {
		const data = {
			id: values.key,
			isDeleted: true
		}
		updateInventory(data);

	}, [updateInventory]);

	useEffect(() => {
		if (inventoryId) {
			getInventoryById(inventoryId);
		}

	}, [getInventoryById, inventoryId]);


	/////////////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (getInventoryByIdSuccess) {

			setInventoryData(getInventoryByIdSuccess.data);
		}

	}, [getInventoryByIdSuccess]);

	/////////////////////////////////////////////////////////////////////////////////////////////////////
	return (

		<div className="inventory-home">
			<div className="flex justify-between">
				{!selectedHotel && <p className="text-2xl mb-10"></p>}
				{/* brand to hotel with inventory */}
				{!formOpen && selectedHotel && !allInOneHotelBreadCrumb && <Breadcrumb className="mb-10">
					<Breadcrumb.Item>
						<Link to={{ pathname: "/admin/brand-management", state: { selectedBrand: location.state?.selectedBrand } }}>{location.state?.selectedBrand?.name}</Link>
					</Breadcrumb.Item>
					<Breadcrumb.Item>
						<Link to={{ pathname: "/admin/hotel-management", state: { selectedHotel: selectedHotel, isBack: true, allInOneHotelBreadCrumb: allInOneHotelBreadCrumb } }}>Hotel Management</Link>
					</Breadcrumb.Item>
					<Breadcrumb.Item>
						<Link to={{ pathname: "/admin/hotel-management", state: { selectedHotel: selectedHotel, allInOneHotelBreadCrumb: allInOneHotelBreadCrumb } }}>{selectedHotel.city ? `${selectedHotel.city} - ${selectedHotel.hotelName}` : selectedHotel.hotelName}</Link>
					</Breadcrumb.Item>
					<Breadcrumb.Item active={(formOpen) ? false : true}>
						<Link to="" onClick={(e) => { e.preventDefault(); setFormOpen(false); refreshInventories(); }}>Inventory Management</Link>
					</Breadcrumb.Item>
				</Breadcrumb>}

				{/* direct hotel management */}
				{!formOpen && selectedHotel && allInOneHotelBreadCrumb && <Breadcrumb className="mb-10">
					<Breadcrumb.Item>
						<Link to={{ pathname: "/admin/brand-hotel-management" }}>Hotel Management</Link>
					</Breadcrumb.Item>
					<Breadcrumb.Item>
						<Link to={{ pathname: "/admin/hotel-management", state: { allInOneHotelBreadCrumb: allInOneHotelBreadCrumb, selectedHotel: selectedHotel } }}>{selectedHotel.city ? `${selectedHotel.city} - ${selectedHotel.hotelName}` : selectedHotel.hotelName}</Link>
					</Breadcrumb.Item>
					<Breadcrumb.Item active={(formOpen) ? false : true}>
						<Link to="" onClick={(e) => { e.preventDefault(); setFormOpen(false); refreshInventories(); }}>Inventory Management</Link>
					</Breadcrumb.Item>
				</Breadcrumb>}

				{
					!hideButton ? <Button hidden={formOpen} onClick={() => { setFormOpen(true); setInventoryId(null); setHotelId(null); }} id='routing-button' style={routingButton} className="btn btn-primary"><b>Create New Inventory</b></Button>
						: !formOpen && <div style={{ fontSize: '20px', marginTop: '0px', fontWeight: 'bold', color: '#fAC90E' }} >You have reached your maximum inventory </div>
				}
			</div>
			{!formOpen && <HotelInventoryList loadForm={loadForm} removeData={removeData} hotelInventoryList={hotelInventoryList} onInventorySelect={(data) => { }} />}
			{formOpen && <HotelInventoryForm allInOneHotelBreadCrumb={allInOneHotelBreadCrumb} inventoryId={inventoryId} hotelId={hotelId} setFormOpen={setFormOpen} selectedHotel={selectedHotel} selectedBrand={location.state?.selectedBrand} loadData={loadData} setHideButton={setHideButton} selectedInventory={inventoryData} />}
		</div>
	)
};

export default HotelInventoryHome;