import React, { useState } from 'react';
import { Col, Nav, Row, Tab } from 'react-bootstrap';
import { navContentStyle, navStyle, tabDivStyle } from '../../../../common/components-style';
import HotelInventoryDetailsForm from './HotelInventoryDetailsForm';
import HotelInventoryImages from './HotelInventoryImages';
import HotelInventoryAmenities from './HotelInventoryAmenities';
import { InventoryStatus } from '../../.././../common/constants';



const HotelInventoryForm: React.FC<any> = ({ allInOneHotelBreadCrumb, inventoryId, hotelId, setFormOpen, selectedHotel, selectedBrand, loadData ,selectedInventory}): JSX.Element => {
  const [tabKey, setTabKey] = useState<any>('details');

  return (
    <div style={tabDivStyle}>
      <Tab.Container defaultActiveKey="details" key={tabKey} activeKey={tabKey} onSelect={(tab) => setTabKey(tab)}>
        <Row >
          <Col sm={2}>
            <Nav variant="pills" className="flex-column  secondry-menu" style={navStyle}>
              <div style={{ marginTop: '0%' }}>
                <Nav.Item>
                  <Nav.Link eventKey="details">Inventory Details</Nav.Link>
                </Nav.Item>
                {selectedInventory?.status !== InventoryStatus.INPROGRESS && (
                  <>
                    {hotelId && inventoryId && (
                      <>
                        <Nav.Item><Nav.Link eventKey="inventoryImages">Inventory Images</Nav.Link></Nav.Item>
                        <Nav.Item><Nav.Link eventKey="inventoryAmenities">Inventory Amenities</Nav.Link></Nav.Item>
                      </>
                    )}
                  </>
                )}
              </div>
            </Nav>
          </Col>
          <Col sm={10}>
            <Tab.Content>
              <Tab.Pane eventKey="details" >
                <div style={navContentStyle}>
                  <HotelInventoryDetailsForm allInOneHotelBreadCrumb={allInOneHotelBreadCrumb} inventoryId={inventoryId} selectedHotel={selectedHotel} selectedBrand={selectedBrand} setFormOpen={setFormOpen} loadData={loadData} />
                </div>
              </Tab.Pane>
              {hotelId && inventoryId && selectedInventory?.status !== InventoryStatus.INPROGRESS && <Tab.Pane eventKey="inventoryImages">
                <div style={navContentStyle}>
                  <HotelInventoryImages allInOneHotelBreadCrumb={allInOneHotelBreadCrumb} inventoryId={inventoryId} setFormOpen={setFormOpen} selectedHotel={selectedHotel} selectedBrand={selectedBrand} />
                </div>
              </Tab.Pane>}
              {hotelId && inventoryId && selectedInventory?.status !== InventoryStatus.INPROGRESS && <Tab.Pane eventKey="inventoryAmenities">
                <div style={navContentStyle}>
                  <HotelInventoryAmenities allInOneHotelBreadCrumb={allInOneHotelBreadCrumb} inventoryId={inventoryId} setFormOpen={setFormOpen} selectedHotel={selectedHotel} selectedBrand={selectedBrand} />
                </div>
              </Tab.Pane>}
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </div>
  )
};

export default HotelInventoryForm;