import { Menu } from 'antd';
import { useStoreActions, useStoreState } from 'easy-peasy';
import React, { useCallback, useEffect, useState } from 'react';
import { Breadcrumb, Col, Nav, Row, Tab } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { navStyle, tabDivStyle } from '../../../../../common/components-style';
import ClientAboutDetails from './views/ClientAboutDetails';
import ClientPropertyListView from './views/ClientPropertyListView';
// import ClientBrandQuestionListView from './views/ClientBrandQuestionListView';
import QuestionSubmitted from '../../common-views/question-submitted/QuestionSubmitted';
import BrandSustainabilityView from '../../common-views/property-sub-component/views/BrandSustainabilityView';

const ClientOperatorHome: React.FC<any> = (): JSX.Element => {

    ////////////////// url manage ///////////
    let { propertyId, rfpId } = useParams<any>();
    const history = useHistory();
    ///////////////////////////////////////// local state manage /////////////////////////////////////////
    const [menuKey, setMenuKey] = useState('about');
    const [defaultActiveKey] = useState('operator');
    const [property, setProperty] = useState<any>();
    const [brand, setBrand] = useState<any>();

    // ==================== Question Submitted State ====================
    const [surveys, setSurveys] = useState<any>([]);
    const [templateId, setTemplateId] = useState('');

    /////////////////////////////////// global action manage ////////////////////////////////////////////
    const { propertyDetailed, getBrandDetails, getRfpProcessById, getTemplate } = useStoreActions<any>(
        (actions) => ({
            propertyDetailed:
                actions.property.propertyDetailed,
            getBrandDetails: actions.brand.getBrandDetails,
            getRfpProcessById: actions.rfpProcess.getRfpProcessById,
            getTemplate: actions.myoManagementModel.getTemplate,
        })
    );

    /////////////////////////////////// global state manage ////////////////////////////////////////////
    const { getPropertyDetailedSuccess, getBrandDetailsSuccess, rfpProcessByIdSuccess, getTemplateSuccess, } = useStoreState<any>(
        (state) => ({
            getPropertyDetailedSuccess: state.property.getPropertyDetailedSuccess,
            getBrandDetailsSuccess: state.brand.getBrandDetailsSuccess,

            rfpProcessByIdSuccess: state.rfpProcess.rfpProcessByIdSuccess,
            getTemplateSuccess: state.myoManagementModel.getTemplateSuccess,
        })
    );
    /////////////////////////Get Certificate Template ID//////////////////////////////////////////////////////////////
    useEffect(() => {
        if (rfpId) {
            getRfpProcessById(rfpId);
        }
    }, [rfpId, getRfpProcessById]);

    /////////////////////////////////Get Surveys By Certificate Template ID///////////////////////////////////////
    useEffect(() => {
        if (rfpProcessByIdSuccess?.data.certificateTemplateId) {
            getTemplate({
                security: {
                    apiKey: process.env.REACT_APP_AUDIT_API_KEY,
                    user: "superadmin@mysa.global"
                },
                id: rfpProcessByIdSuccess?.data.certificateTemplateId
            })
            setTemplateId(rfpProcessByIdSuccess?.data.certificateTemplateId);
        }
    }, [getTemplate, rfpProcessByIdSuccess])

    useEffect(() => {
        if (getTemplateSuccess && getTemplateSuccess.template && getTemplateSuccess.template.surveys && getTemplateSuccess.template.surveys.length > 0) {
            let filteredSurveys;
            filteredSurveys = getTemplateSuccess.template.surveys.filter(survey => survey.survey_type === "BRAND_SURVEY");

            if (filteredSurveys && filteredSurveys.length > 0) {
                setSurveys(filteredSurveys);
            }
        }
    }, [getTemplateSuccess]);

    ///////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (getPropertyDetailedSuccess) {
            setProperty(getPropertyDetailedSuccess.data);
            const payload = {
                brand_id: getPropertyDetailedSuccess.data?.brandId
            }
            getBrandDetails(payload);
        }

    }, [getBrandDetails, getPropertyDetailedSuccess]);

    ///////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (getBrandDetailsSuccess) {
            setBrand(getBrandDetailsSuccess.data);
        }

    }, [getBrandDetailsSuccess]);

    ///////////////////////// menu change ///////////////////////////////////
    const menuChange = useCallback((value) => {
        setMenuKey(value.key);
    }, []);

    ///////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (propertyId) {
            const payload = {
                propertyId: propertyId
            }
            propertyDetailed(payload);
        }
    }, [propertyDetailed, propertyId]);


    return (<>
        <div style={tabDivStyle}>
            <Tab.Container defaultActiveKey={defaultActiveKey} >
                <Row >
                    <Col sm={2}>
                        <Nav variant="pills" className="flex-column secondry-menu" style={navStyle}>
                            <div style={{ marginTop: '0%' }}>
                                <Nav.Item><Nav.Link eventKey="operator">Operator</Nav.Link></Nav.Item>
                                {
                                    surveys.length > 0 &&
                                    <Nav.Item><Nav.Link eventKey="RFPSurvey">Brand RFP Survey</Nav.Link></Nav.Item>
                                }
                            </div>
                        </Nav>
                    </Col>
                    <Col sm={10}>
                        <Tab.Content >
                            <Tab.Pane eventKey="operator" >
                                {/* <div > */}
                                <Row className='mt-4'>
                                    <Col md={12}>
                                        <Breadcrumb >
                                            <Breadcrumb.Item style={{ fontSize: '24px' }} onClick={() => {
                                                history.push({
                                                    pathname: `/admin/client-proposal-management`,
                                                    state: {
                                                        cityName: property?.city,
                                                        rfpId: rfpId,
                                                        filterProgramManage: true
                                                    }
                                                })
                                            }}>{property?.city}</Breadcrumb.Item>
                                            <Breadcrumb.Item style={{ fontSize: '24px' }} onClick={() => {
                                                history.push({
                                                    pathname: `/admin/client-proposal-management/property/${propertyId}/${rfpId}`
                                                })
                                            }}>{`${property?.propertyName}`}</Breadcrumb.Item>
                                            <Breadcrumb.Item style={{ fontSize: '24px' }} active>{`${property?.brand?.name}`}</Breadcrumb.Item>
                                        </Breadcrumb>
                                    </Col>
                                </Row>
                                <Row className='new_tab_wpr operator_menu magneta_tab'>
                                    <Col md={12}>
                                        <div style={{ textAlign: 'right' }}>
                                            <Menu onClick={menuChange} mode="horizontal" className="w-full new_tab" selectedKeys={[menuKey]}>
                                                <Menu.Item key="about" >
                                                    <span>About</span>
                                                </Menu.Item>
                                                <Menu.Item key="sustainability">
                                                    <span>Sustainability</span>
                                                </Menu.Item>
                                                <Menu.Item key="properties" >
                                                    <span>Properties</span>
                                                </Menu.Item>
                                            </Menu>
                                        </div>
                                    </Col>
                                </Row>
                                <div className='new_tab_ctnt' style={{ marginLeft: '7px' }}>
                                    <div className='new_tab_ctnt_inr'>
                                        {(menuKey === 'about') && <ClientAboutDetails brand={brand} />}
                                        {/* {(menuKey === 'sustainability') && <ClientBrandQuestionListView brand={brand} />} */}
                                        {(menuKey === 'sustainability') && <BrandSustainabilityView brand={brand} />}
                                        {(menuKey === 'properties') && <ClientPropertyListView brand={brand} />}
                                    </div>
                                </div>

                                {/* </div> */}
                            </Tab.Pane>
                            <Tab.Pane eventKey="RFPSurvey">
                                <Row className='mb-20'>
                                    <Col md={12}>
                                        <Breadcrumb >
                                            <Breadcrumb.Item style={{ fontSize: '24px' }} onClick={() => {
                                                history.push({
                                                    pathname: `/admin/client-proposal-management`,
                                                    state: {
                                                        cityName: property?.city,
                                                        rfpId: rfpId,
                                                        filterProgramManage: true
                                                    }
                                                })
                                            }}>{property?.city}</Breadcrumb.Item>
                                            <Breadcrumb.Item style={{ fontSize: '24px' }} onClick={() => {
                                                history.push({
                                                    pathname: `/admin/client-proposal-management/property/${propertyId}/${rfpId}`
                                                })
                                            }}>{`${property?.propertyName}`}</Breadcrumb.Item>
                                            <Breadcrumb.Item style={{ fontSize: '24px' }} active>{`${property?.brand?.name}`}</Breadcrumb.Item>
                                        </Breadcrumb>
                                    </Col>
                                </Row>
                                <QuestionSubmitted
                                    surveys={surveys}
                                    templateId={templateId}
                                />
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </div>
    </>)

}

export default ClientOperatorHome;