import { FC, useState, useEffect } from "react";
import { useStoreActions, useStoreState } from 'easy-peasy';
import DataTable from "react-data-table-component";
import { tableStyles, selectStylesLittleRect } from "../../../../common/components-style";
import RFPBreadCrumbPage from "../../../common/RFPBredcrumPage";
import { Button, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import { useHistory } from "react-router-dom";

interface ProgrammeOption {
    value: string;
    label: string;
}

const ReportData: FC<any> = ({ reportId, programmeId, setFormOpen, reload, selectedClient, selectedProgramme, accommodationType, reportName, currency, associateProgrammes }): JSX.Element => {
    const [data, setData] = useState<Array<any>>([]);
    const [firstLoading, setFirstLoading] = useState<boolean>(true);
    const [programmeList, setProgrammeList] = useState<any>([]);
    const [selectedCountry, setSelectedCountry] = useState<string | null>(null);
    const [selectedCity, setSelectedCity] = useState<string | null>(null);
    const [filteredData, setFilteredData] = useState<any[]>([]);
    const [currencyName, setCurrencyName] = useState<any>(null);
    const [selectedAssociateProgramme, setSelectedAssociateProgramme] = useState<ProgrammeOption | null>(null);

    let history = useHistory();

    const { getLocationOverviewReport, getReportData, getReportDataFilterByProgram, getReportById } = useStoreActions<any>((actions) => ({
        getLocationOverviewReport: actions.hotel.getLocationOverviewReport,
        getReportData: actions.hotel.getReportData,
        getReportById: actions.booking.getReportById,
        getReportDataFilterByProgram: actions.hotel.getReportDataFilterByProgram
    }));

    /**
     * global state
     */
    const { getReportDataSuccess, getReportByIdError,
        getReportByIdSuccess, getReportDataFilterByProgramSuccess } = useStoreState<any>((state) => ({
            getReportDataSuccess: state.hotel.getReportDataSuccess,
            getReportByIdError: state.booking.getReportByIdError,
            getReportByIdSuccess: state.booking.getReportByIdSuccess,
            getReportDataFilterByProgramSuccess: state.hotel.getReportDataFilterByProgramSuccess,


        }));

    useEffect(() => {
        if (getReportByIdSuccess?.data) {
            setCurrencyName(getReportByIdSuccess?.data?.currency);
        }
    }, [getReportByIdError, getReportByIdSuccess?.data]);


    useEffect(() => {
        if (reportId) {
            getLocationOverviewReport(reportId);
            const reportPayload = { _id: reportId };
            getReportById(reportPayload);
            const payload = {
                reportId: reportId,
                programmeId: selectedAssociateProgramme ? selectedAssociateProgramme?.value : '',
            };
            if (selectedAssociateProgramme) {
                getReportDataFilterByProgram(payload)
            }
            else {
                getReportData(payload)
            }
        }
    }, [getLocationOverviewReport, getReportById, getReportData, reportId, selectedAssociateProgramme, getReportDataFilterByProgram]);


    useEffect(() => {
        if (getReportDataSuccess?.data) {
            setFirstLoading(false);
            setData(getReportDataSuccess?.data);
            setFilteredData(getReportDataSuccess?.data);
        }
    }, [getReportDataSuccess]);

    useEffect(() => {
        if (getReportDataFilterByProgramSuccess?.data) {
            setFirstLoading(false);
            setData(getReportDataFilterByProgramSuccess?.data);
            setFilteredData(getReportDataFilterByProgramSuccess?.data);
        }
    }, [getReportDataFilterByProgramSuccess?.data]);


    type Column = {
        name: React.ReactNode;
        selector: (row: any) => any;
    };
    const formatDataValue = (value: number | undefined): string => {
        if (value !== undefined) {
            const roundedValue = Math.round(value * 100) / 100;

            return roundedValue.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
        }
        return '0';
    };

    const extractAllUsedFields = (row: any, currencyName: string): Column[] => {
        const dynamicColumns: Column[] = [];
        for (const key in row) {
            if (key.endsWith("_UsedFields") && typeof row[key] === "object") {
                const fieldData = row[key];
                Object.keys(fieldData).forEach((nestedKey) => {
                    dynamicColumns.push({
                        name: (
                            <span title={`${nestedKey} (${currencyName})`}>
                                {nestedKey} ({currencyName})
                            </span>
                        ),
                        selector: (row) => {
                            const fieldValue = row[key]?.[nestedKey];
                            return typeof fieldValue === "number" ? formatDataValue(fieldValue) : fieldValue;
                        },
                    });
                });
            }
        }
        return dynamicColumns;
    };

    const extractTopLevelFields = (row: any, currencyName: string): Column[] => {
        const topLevelColumns: Column[] = [];

        for (const key in row) {
            if (!key.endsWith("_UsedFields") && key !== "City" && key !== "Country") {
                const fieldTitle = selectedAssociateProgramme && selectedAssociateProgramme.value !== ''
                    ? `${key}`
                    : `${key} (${currencyName})`;

                topLevelColumns.push({
                    name: (
                        <span title={fieldTitle}>
                            {fieldTitle}
                        </span>
                    ),
                    selector: (row) => {
                        const fieldValue = row[key];
                        return typeof fieldValue === "number" ? formatDataValue(fieldValue) : fieldValue;
                    },
                });
            }
        }
        return topLevelColumns;
    };

    const createDynamicColumns = (data: any[], currencyName: string): Column[] => {
        const baseColumns: Column[] = [
            { name: <span title="Country">Country</span>, selector: (row) => row.Country },
            { name: <span title="City">City</span>, selector: (row) => row.City },
        ];

        if (data.length === 0) {
            return baseColumns;
        }

        const topLevelColumns = extractTopLevelFields(data[0], currencyName);
        const usedFieldsColumns = extractAllUsedFields(data[0], currencyName);

        return [...baseColumns, ...topLevelColumns, ...usedFieldsColumns];
    };
    const dynamicColumns = createDynamicColumns(data, currencyName);

    useEffect(() => {
        if ((associateProgrammes && associateProgrammes.length > 0) || selectedProgramme?.programmeName) {
            const options = [
                ...(associateProgrammes || [])
                    .filter((item: string) => item !== 'None')
                    .map((item: string) => ({
                        value: item,
                        label: item,
                    })),
                selectedProgramme?.programmeName && {
                    value: selectedProgramme.programmeName,
                    label: selectedProgramme.programmeName,
                },
            ].filter(Boolean);

            setProgrammeList(options);
        } else {
            setProgrammeList([]);
        }
    }, [associateProgrammes, selectedProgramme]);



    const uniqueCountries = Array.from(
        new Set(data.map((item) => item.Country))
    ).filter(Boolean);

    const countryOptions = uniqueCountries.map(country => ({
        label: country,
        value: country
    }));

    const uniqueCity = Array.from(
        new Set(data.map((item) => item.City))
    ).filter(Boolean);

    const cityOptions = uniqueCity.map(city => ({
        label: city,
        value: city
    }));

    const handleProgrammeChange = (selectedOption) => {
        setSelectedAssociateProgramme(selectedOption);
    };

    useEffect(() => {
        let filtered = data;

        if (selectedCountry) {
            filtered = filtered.filter((hotel) => hotel.Country === selectedCountry);
        }

        if (selectedCity) {
            filtered = filtered.filter((hotel) => hotel.City === selectedCity);
        }

        setFilteredData(filtered);
    }, [selectedCountry, selectedCity, data]);


    return (
        <>
            <div className="col-md-12" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div>
                    {reportId && (
                        <RFPBreadCrumbPage
                            isPageName="locationOverviewReport"
                            selectedClient={selectedClient}
                            selectedProgramme={selectedProgramme}
                            selectedClientName={selectedClient?.companyName}
                            selectedProgrammeName={selectedProgramme?.programmeName}
                            reportId={reportId}
                            programmeId={programmeId}
                            startDate={reportName}
                        />
                    )}
                </div>

                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <Button
                        className="customDeleteBtn" onClick={() => {
                            history.push({
                                pathname: '/admin/programme-spend-data',
                                state: {
                                    programmeId: programmeId,
                                    reportId: reportId,
                                    formOpen: true,
                                    selectedClient: selectedClient,
                                    selectedProgramme: selectedProgramme,
                                    tabKey: 'locationTableColumns',
                                    reportName: reportName
                                }
                            });
                        }}
                    >
                        Data
                    </Button>
                </div>
            </div>

            <Row className='btn_wrapper' style={{ marginTop: '25px' }}>
                <Col xs={2}>
                    <Select
                        className='search_bar_reports select_btn'
                        isClearable
                        placeholder={<div>Search Country</div>}
                        options={countryOptions}
                        styles={selectStylesLittleRect}
                        isSearchable={true}
                        onChange={(option) => setSelectedCountry(option ? option.value : null)}
                    />
                </Col>

                <Col xs={2}>
                    <Select
                        className='search_bar_reports select_btn'
                        isClearable
                        placeholder={<div>Search City</div>}
                        options={cityOptions}
                        styles={selectStylesLittleRect}
                        isSearchable={true}
                        onChange={(option) => setSelectedCity(option ? option.value : null)}
                    />
                </Col>
                <Col xs={3}>
                    {programmeList.length > 0 ? (
                        <Select
                            className="search_bar_reports select_btn"
                            isClearable
                            placeholder="Search Programme"
                            styles={selectStylesLittleRect}
                            options={programmeList}
                            value={selectedAssociateProgramme}
                            onChange={handleProgrammeChange}
                        />
                    ) : null}
                </Col>

            </Row>
            {
                !firstLoading && <div className='brnd_tbl' style={{ marginTop: '50px' }}>
                    <DataTable className="reports_table"
                        columns={dynamicColumns}
                        data={filteredData}
                        pagination
                        striped={true}
                        customStyles={tableStyles}
                        theme="mysaTable"
                        fixedHeader={true}
                        noDataComponent="Updating records"
                    />
                </div>
            }
        </>
    )
}

export default ReportData;