import React, { useCallback, useEffect, useState, } from 'react';
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import ViewEdit from '../../../../images/icons/svg/outside/Icon-Function-View-Edit.svg';
import DataTable from 'react-data-table-component';
import { useStoreActions, useStoreState } from 'easy-peasy';
import moment from 'moment';
import { overlayTriggerDelay, selectStyles, tableStyles } from '../../../../common/components-style';
import { RfpProcessStatus, UserGroups } from '../../../../common/constants';
import { getLoggedUserType } from '../../../../common/functions';
import Select from 'react-select';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';



const ActiveRFPsListPage: React.FC<any> = (): JSX.Element => {
    const [rfpStatus, setRfpStatus] = useState<String>("");
    const [memoizedRfpProcessData, setMemoizedRfpProcessData] = useState<any>([]);
    const [temData, setTemData] = useState<any>([]);
    const history = useHistory();
    /////////////////////////////////////////////////////////////////////////////////////////////////////
    const {

        getAllCreatedRfpProcessList
    } = useStoreActions<any>((actions) => ({
        getAllCreatedRfpProcessList: actions.rfpProcess.getAllCreatedRfpProcessList

    }));

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const {
        getAllCreatedRfpProcessListSuccess
    } = useStoreState<any>((state) => ({
        getClientListSuccess: state.client.getClientListSuccess,
        getAllCreatedRfpProcessListSuccess: state.rfpProcess.getAllCreatedRfpProcessListSuccess
    }));


    ////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        getAllCreatedRfpProcessList();
    }, [getAllCreatedRfpProcessList]);

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (getAllCreatedRfpProcessListSuccess?.data) {
            // console.log(getAllCreatedRfpProcessListSuccess?.data);
            setTemData(getAllCreatedRfpProcessListSuccess?.data);
        }

    }, [getAllCreatedRfpProcessListSuccess?.data]);

    /**
     * data manage
     */
    useEffect(() => {
        let mappedData: any;
        if (rfpStatus === '') {
            if (temData) {
                mappedData = temData?.filter(process => (process?.rfpadminreview?.releaseStatus === false))
                    .map((rfpData) => {
                        const cities = rfpData?.locations.map((item: any) => item.cities)
                        const citiesArray = [].concat.apply([], cities);
                        const cityNames = citiesArray.map((item: any) => item.cityName);
                        const brandCount = rfpData?.brandrfp?.length

                        /**
                         * get property length from submitted rfp
                         */
                        let submittedPropertiesCount: Number = 0;
                        //loop brand rfp list
                        rfpData?.brandrfp?.forEach((brRfp) => {
                            //filter rfp ids related brand
                            const filterRfps = brRfp?.rfpIds?.filter((rfpid) => rfpid.rfpId === rfpData?._id);

                            for (const rfp of filterRfps) {
                                for (const location of rfp.locations) {
                                    for (const city of location.cities) {
                                        submittedPropertiesCount += city?.properties?.length;
                                    }
                                }
                            }
                        });

                        return {
                            key: rfpData?._id,
                            endDate: rfpData?.endDate,
                            locations: cityNames?.length,
                            companyName: rfpData?.client?.companyName,
                            programmeName: rfpData?.programme?.programmeName,
                            brandCount,
                            propertyCount: submittedPropertiesCount,
                            rfpStatus: rfpData?.rfpStatus,
                            startDate: rfpData?.startDate,
                            questionSubmitDate: rfpData?.questionSubmitDate,
                            selectedClient: { key: rfpData?.clientId, companyName: rfpData?.client?.companyName },
                            programmeId: rfpData?.programmeId,
                            programme: rfpData?.programme,

                        }
                    });

                setMemoizedRfpProcessData(mappedData);
            }
        } else {
            mappedData = temData?.filter(process => (process?.rfpadminreview?.releaseStatus === false))
                .map((rfpData) => {
                    const cities = rfpData?.locations.map((item: any) => item.cities)
                    const citiesArray = [].concat.apply([], cities);
                    const cityNames = citiesArray.map((item: any) => item.cityName);
                    const brandCount = rfpData?.brandrfp?.length

                    /**
                     * get property length from submitted rfp
                     */
                    let submittedPropertiesCount: Number = 0;
                    //loop brand rfp list
                    rfpData?.brandrfp?.forEach((brRfp) => {
                        //filter rfp ids related brand
                        const filterRfps = brRfp?.rfpIds?.filter((rfpid) => rfpid.rfpId === rfpData?._id);

                        for (const rfp of filterRfps) {
                            for (const location of rfp.locations) {
                                for (const city of location.cities) {
                                    submittedPropertiesCount += city.properties.length;
                                }
                            }
                        }
                    });

                    return {
                        key: rfpData?._id,
                        endDate: rfpData?.endDate,
                        locations: cityNames?.length,
                        companyName: rfpData?.client?.companyName,
                        programmeName: rfpData?.programme?.programmeName,
                        brandCount,
                        propertyCount: submittedPropertiesCount,
                        rfpStatus: rfpData?.rfpStatus,
                        startDate: rfpData?.startDate,
                        questionSubmitDate: rfpData?.questionSubmitDate,
                        programme: rfpData?.programme,
                        selectedClient: { key: rfpData?.clientId, companyName: rfpData?.client?.companyName },
                        programmeId: rfpData?.programmeId,
                    }
                });

            let filteredData = _.filter(mappedData, (process) => (process?.rfpStatus === rfpStatus));

            setMemoizedRfpProcessData(filteredData);
        }
    }, [rfpStatus, temData])

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const columns = [
        {
            name: 'Company',
            selector: row => row.companyName,
        },
        {
            name: 'Programme',
            selector: row => {
                return (
                    <OverlayTrigger

                        placement="top"
                        delay={overlayTriggerDelay}
                        overlay={<Tooltip id='tooltip-table-top'>{row.programmeName}</Tooltip>}
                    >
                        <p style={{ marginBottom: '0px' }} >{String(row.programmeName).slice(0, 10) + "..."}</p>
                    </OverlayTrigger>
                )
            }
        },
        {
            name: 'Start',
            selector: row => moment(row.startDate).format("D MMM YYYY"),
        },
        {
            name: 'Questions',
            selector: row => moment(row.questionSubmitDate).format("D MMM YYYY"),
        },
        {
            name: 'End',
            selector: row => moment(row.endDate).format("D MMM YYYY"),
        },
        {
            name: 'Locations',
            selector: row => row.locations,
        },
        {
            name: 'Brands',
            selector: row => row.brandCount,
        },
        {
            name: 'Properties',
            selector: row => row.propertyCount,
        },
        {
            name: "Status",
            selector: (row) => {
                let color = "text-red-500 uppercase font-bold";
                if (row.rfpStatus === RfpProcessStatus.INPROGRESS) color = 'font-bold uppercase text-yellow-500';
                if (row.rfpStatus === RfpProcessStatus.COMPLETED) color = 'font-bold uppercase text-green-500';
                return (
                    <span className={color}>{row.rfpStatus}</span>
                )
            }
        },
        {
            name: 'Action',
            wrap: true,

            cell: (data: any) => {
                return (<>
                    <OverlayTrigger
                        placement="top"
                        delay={overlayTriggerDelay}
                        overlay={<Tooltip id='tooltip-table-top'>View/Edit</Tooltip>}
                    >
                        <img className='iconSizes mr-2' src={ViewEdit} alt="" onClick={() => {
                            // handleOpen(data)
                            // setStorage("myo_selected_my_rfp", data);

                            history.push('/admin/rfp-management', { programmeId: data?.programmeId, selectedClient: data?.selectedClient, selectedProgramme: data?.programme })
                        }} />
                    </OverlayTrigger>
                </>)
            }
        },

    ];


    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const onChangeStatus = useCallback((data: any) => {
        if (data?.value) {
            setRfpStatus(data.value);
        } else {
            setRfpStatus('');
        }

    }, []);

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <>
            {getLoggedUserType() === UserGroups.super_admin && <>
                <Row className='btn_wrapper' style={{ marginTop: '-24px' }}>

                    <Col xs={2}>
                        <Select
                            isClearable
                            className='search_bar select_btn'
                            placeholder={<div className="text-left">Select Status</div>}
                            options={[
                                {
                                    value: RfpProcessStatus.NOTSTARTED,
                                    label: 'not_started',
                                },
                                {
                                    value: RfpProcessStatus.INPROGRESS,
                                    label: 'in_progress',
                                },
                                {
                                    value: RfpProcessStatus.COMPLETED,
                                    label: 'Completed',
                                },

                            ]}
                            styles={selectStyles}
                            isSearchable={false}
                            onChange={onChangeStatus}
                        />
                    </Col>
                </Row>

            </>}

            <div className="property-list ">
                <div className='mt-3'>
                    <DataTable
                        columns={columns}
                        data={memoizedRfpProcessData}
                        pagination
                        striped={true}
                        customStyles={tableStyles}
                        theme='mysaTable'
                        fixedHeader={true}

                    />
                </div>
            </div>
        </>
    )
};

export default ActiveRFPsListPage;