import { Select, Checkbox, Input } from 'antd';
import { useStoreActions, useStoreState } from 'easy-peasy';
import React, { useCallback, useEffect, useState } from 'react';
import { AmenityType, Sizes } from '../../../../common/constants';
import _ from 'lodash';
import { Col, Form, Row, Image, Spinner, Button } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { HotelInventoryAssignAmenityFormInput } from '../../../../common/interfaces';
import { formButton } from '../../../../common/components-style';
import { toast } from 'react-toastify';
import { ErrorMessage } from '@hookform/error-message';
import BreadCrumbPage from '../../../common/BreadCrumbPage';


const { Option } = Select;

const HotelInventoryAmenities: React.FC<any> = ({ allInOneHotelBreadCrumb, setFormOpen, inventoryId, selectedBrand, selectedHotel }): JSX.Element => {
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<HotelInventoryAssignAmenityFormInput>();
  const [loading, setLoading] = useState<boolean>(false);
  const [editEnable, setEditEnable] = useState<boolean>(false);
  const [inventoryAmenity, setInventoryAmenity] = useState<any>();
  const [amenityId, setAmenityId] = useState<any>();
  const [amenitiesList, setAmenitiesList] = useState<any>();
  const [sizeOfTv, setSizeOfTv] = useState<string>(Sizes.CM);
  const [inventoryData, setInventoryData] = useState<any>();
  const [hotelPropertyType, setHotelPropertyType] = useState<any>();

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const { getAmenityList, getInventoryAmenities, assignInventoryAmenity, resetInventory, getInventoryById, getHotel } = useStoreActions<any>((actions) => ({
    getAmenityList: actions.hotel.getAmenityList,
    getInventoryAmenities: actions.hotelInventory.getInventoryAmenities,
    assignInventoryAmenity: actions.hotelInventory.assignInventoryAmenity,
    resetInventory: actions.hotelInventory.resetInventory,
    getInventoryById: actions.hotelInventory.getInventoryById,
    getHotel: actions.hotel.getHotel
  }));

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const { getInventoryByIdSuccess, getAmenityListSuccess, getAmenityListError, getInventoryAmenitiesSuccess, getInventoryAmenitiesError, assignInventoryAmenitySuccess, assignInventoryAmenityError, getHotelSuccess, getHotelError } = useStoreState<any>((state) => ({
    getAmenityListSuccess: state.hotel.getAmenityListSuccess,
    getAmenityListError: state.hotel.getAmenityListError,
    getInventoryAmenitiesSuccess: state.hotelInventory.getInventoryAmenitiesSuccess,
    getInventoryAmenitiesError: state.hotelInventory.getInventoryAmenitiesError,
    assignInventoryAmenitySuccess: state.hotelInventory.assignInventoryAmenitySuccess,
    assignInventoryAmenityError: state.hotelInventory.assignInventoryAmenityError,
    getInventoryByIdSuccess: state.hotelInventory.getInventoryByIdSuccess,
    getHotelSuccess: state.hotel.getHotelSuccess,
    getHotelError: state.hotel.getHotelError,
  }));

  /////////////////////////////// manage size of tvs select option //////////////////////////////
  const onChangeSizeOfTv = useCallback((value) => {
    setSizeOfTv(value);
  }, [])

  //////////////////////////////// provideInformationTvs options ////////////////////////////////////
  const sizeOfTvsOptions = [
    { label: 'Flat screen', value: 'Flat screen' },
    { label: 'Less than 3 years old', value: 'Less than 3 years old' },
    { label: 'HD', value: 'HD' },
    { label: 'Smart TV', value: 'Smart TV' },
  ];

  //////////////////////////////sizeOfTv cm/inch //////////////////////////////////
  const selectAfter = (
    <Select defaultValue={inventoryAmenity?.sizeOfTv?.size ? inventoryAmenity?.sizeOfTv?.size : Sizes.CM} style={{ width: 80 }} onChange={onChangeSizeOfTv}>
      <Option value={Sizes.CM}>{Sizes.CM}</Option>
      <Option value={Sizes.INCH}>{Sizes.INCH}</Option>
    </Select>
  );

  //////////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    getAmenityList();
  }, [getAmenityList]);


  ////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (inventoryId) {
      getInventoryById(inventoryId);
    }

  }, [getInventoryById, inventoryId]);


  /////////////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (getInventoryByIdSuccess) {

      setInventoryData(getInventoryByIdSuccess.data);
    }

  }, [getInventoryByIdSuccess]);


  ///////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (inventoryId) {
      getInventoryAmenities(inventoryId);
    }
  }, [getInventoryAmenities, inventoryId]);

  //////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (assignInventoryAmenitySuccess) {
      setLoading(false);
      resetInventory();

      toast.success("Inventory amenity assigned successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
      getInventoryAmenities(inventoryId);
      setEditEnable(false);
    }
    if (assignInventoryAmenityError) {
      setLoading(false);
      toast.error(assignInventoryAmenityError.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
    }
  }, [resetInventory, assignInventoryAmenitySuccess, assignInventoryAmenityError, getInventoryAmenities, inventoryId]);

  //////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (getAmenityListSuccess) {
      if (getAmenityListSuccess.data === null) {
        toast.warning("No Data found", {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: 'foo-bar'
        });
      }
      else {
        const amenity = getAmenityListSuccess.data;
        setAmenitiesList(amenity);
      }
    }
    if (getAmenityListError) {
      toast.error(getAmenityListError.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
    }

  }, [getAmenityListError, getAmenityListSuccess]);

  //////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (getInventoryAmenitiesSuccess) {
      if (getInventoryAmenitiesSuccess.data === null) {
        // message.warning('No assigned amenity data found');
      } else {
        setInventoryAmenity(getInventoryAmenitiesSuccess.data);
        const inventoryAmenity: Array<Object> = [];
        const amenity = getInventoryAmenitiesSuccess.data.amenity;
        _.map(amenity, element =>
          inventoryAmenity.push(element._id)
        );
        setAmenityId(inventoryAmenity);
        setSizeOfTv(getInventoryAmenitiesSuccess.data.sizeOfTv?.size);
      }
    }
    if (getInventoryAmenitiesError) {
      toast.error(getInventoryAmenitiesError.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
    }
  }, [getInventoryAmenitiesSuccess, getInventoryAmenitiesError]);

  useEffect(() => {
    if (selectedHotel) {
      getHotel(selectedHotel._id)
    }
  }, [getHotel, selectedHotel]);

  useEffect(() => {
    if (getHotelSuccess) {
      const data = getHotelSuccess.data;
      setHotelPropertyType(data?.propertyType?.name)
    }
    if (getHotelError) {
      toast.error(getHotelError.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
    }
  }, [getHotelSuccess, getHotelError]);



  /////////////////////////////////////////////////////////////////////////////////////////////////////
  const renderAmenities = useCallback(() => {
    if (amenitiesList) {
      const data = _.filter(amenitiesList, user => (user.type === AmenityType.all) || (user.type === AmenityType.accommodation));
      return data.map((at: any, key: number) => {
        return (
          <Row className="mt-3" key={key}>
            <Col md={2}>
              <div style={{ textAlign: "right" }}>
                <Image src={at.iconLocation} alt="" width={40} />
              </div>
            </Col>
            <Col md={10} >
              <div style={{ marginTop: "2%", marginLeft: '-9%' }}>
                <Checkbox value={`${at._id}`}></Checkbox><span style={{ marginLeft: '3%' }}>{at.name}</span>
              </div>
            </Col>
          </Row>
        );
      });
    }
  }, [amenitiesList]);

  const changeFormEditable = useCallback(() => {
    if (editEnable) {
      setEditEnable(false);
    } else {
      setEditEnable(true);
    }
  }, [editEnable]);

  //////////////////////////////////////////////////////////////////////////////////////////////////////
  const onFinish = (values) => {
    setLoading(true);
    values.inventoryId = inventoryId;
    values.sizeOfTv = {
      text: values.sizeOfTv,
      size: sizeOfTv ? sizeOfTv : Sizes.CM
    };
    assignInventoryAmenity(values)
  };

  /////////////////////////////////////////////////////////////////////////////////////////////////////
  const renderSizeOfTvsOptions = () => {
    return sizeOfTvsOptions.map((at: any, key: number) => {
      return (
        <>
          <Checkbox value={`${at.value}`}>{at.label}</Checkbox>
        </>
      );
    });
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      <div className="content-title fixed_title">
        <BreadCrumbPage allInOneHotelBreadCrumb={allInOneHotelBreadCrumb} isPageName="HotelInventory" selectedHotel={selectedHotel} selectedBrand={selectedBrand} inventoryData={inventoryData} setFormOpen={setFormOpen} inventoryId={inventoryId}></BreadCrumbPage>
        <div className='edit_btn_wpr'>
          <div className='d-flex align-items-center justify-content-end gx-2'>
            {/* <HelpModal /> */}
            {
              !loading && !editEnable && inventoryId && <Button id='edit-button' className='mb-0' style={formButton} onClick={changeFormEditable} >Edit</Button>
            }
          </div>
        </div>
      </div>
      <div>
        <div className="jumbotron">
          <Form onSubmit={handleSubmit(onFinish)}>
            <Row>
              <Col md={12}>
                <Row className="formSpace">
                  <Col md={6} style={{ textAlign: "right" }}>
                    <Form.Label>
                      {editEnable && <span className="requiredMark">*</span>} Does the room have storage facilities
                    </Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: "left" }}>
                    {!editEnable && (
                      <p className="lead">
                        {inventoryAmenity?.storageFacilities}
                      </p>
                    )}
                    {editEnable && (
                      <Controller
                        name="storageFacilities"
                        defaultValue={inventoryAmenity?.storageFacilities}
                        control={control}
                        rules={{
                          required: true,
                        }}
                        render={({ field }) =>
                          <>
                            {
                              inventoryAmenity?.storageFacilities === 'Yes'
                                ?
                                <Form.Check
                                  inline
                                  value={'Yes'}
                                  name="storageFacilities"
                                  label={'Yes'}
                                  type="radio"
                                  defaultChecked
                                  onChange={(value) => { field.onChange(value.currentTarget.value); }}
                                />
                                :
                                <Form.Check
                                  inline
                                  value={'Yes'}
                                  name="storageFacilities"
                                  label={'Yes'}
                                  type="radio"
                                  onChange={(value) => { field.onChange(value.currentTarget.value) }}
                                />
                            }

                            {
                              inventoryAmenity?.storageFacilities === 'No'
                                ?
                                <Form.Check
                                  inline
                                  value={'No'}
                                  name="storageFacilities"
                                  label={'No'}
                                  type="radio"
                                  defaultChecked
                                  onChange={(value) => { field.onChange(value.currentTarget.value); }}
                                />
                                :
                                <Form.Check
                                  inline
                                  value={'No'}
                                  name="storageFacilities"
                                  label={'No'}
                                  type="radio"
                                  onChange={(value) => { field.onChange(value.currentTarget.value); }}
                                />
                            }

                          </>
                        }
                      />)}
                    {editEnable &&
                      <ErrorMessage
                        errors={errors}
                        name="storageFacilities"
                        render={({ message }) => <div className="invalid-feedback-custom">Please select your answer!</div>} />}
                  </Col>
                </Row>

                <Row className="formSpace">
                  <Col md={6} style={{ textAlign: "right" }}>
                    <Form.Label>
                      {editEnable && <span className="requiredMark">*</span>} Please provide information on the TVs in this room type
                    </Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: "left" }}>

                    {!editEnable &&
                      <Row  >
                        <Col md={12}>
                          <div >
                            <Checkbox.Group value={inventoryAmenity?.provideInformationTvs} style={{ width: '100%' }}>
                              {renderSizeOfTvsOptions()}
                            </Checkbox.Group>
                          </div>
                        </Col>
                      </Row>
                    }
                    {editEnable && (
                      <Controller
                        name="provideInformationTvs"
                        defaultValue={inventoryAmenity?.provideInformationTvs}
                        control={control}
                        rules={{
                          required: true,
                        }}
                        render={({ field }) => (
                          <>
                            <Row >
                              <Col md={12}>
                                <div >
                                  <Checkbox.Group defaultValue={inventoryAmenity?.provideInformationTvs} style={{ width: '100%' }} onChange={(value) => { field.onChange(value); }}>
                                    {renderSizeOfTvsOptions()}
                                  </Checkbox.Group>
                                </div>
                              </Col>
                            </Row>

                          </>
                        )}
                      />
                    )}
                    {editEnable && errors.amenity && (
                      <div className="invalid-feedback-custom">
                        Please select a TVs in your apartments
                      </div>
                    )}
                  </Col>
                </Row>
                <Row className='formSpace'>
                  <Col md={6} style={{ textAlign: 'right' }}>
                    <Form.Label>{editEnable && <span className='requiredMark'>*</span>} What is the size of your TVs in the room?</Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: 'left' }}>
                    {!editEnable && <p className="lead">{inventoryAmenity?.sizeOfTv?.text} {inventoryAmenity?.sizeOfTv?.size}</p>}
                    {editEnable && <Controller
                      name="sizeOfTv"
                      defaultValue={inventoryAmenity?.sizeOfTv?.text}
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field }) => (
                        <>
                          <Input addonAfter={selectAfter} type={'number'} className={`${errors.sizeOfTv ? 'is-invalid' : ''}`} defaultValue={inventoryAmenity?.sizeOfTv?.text} onChange={(value) => { field.onChange(value) }} />
                        </>
                      )}
                    />
                    }
                    {editEnable && errors.sizeOfTv && <div className="invalid-feedback-custom">Please input your answer!</div>}
                  </Col>
                </Row>
                <Row className="formSpace">
                  <Col md={6} style={{ textAlign: "right" }}>
                    <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Please indicate what amenities you offer within the apartment</Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: "left" }}>
                    {!editEnable &&
                      <Checkbox.Group value={amenityId} style={{ width: '100%' }} >
                        {renderAmenities()}
                      </Checkbox.Group>
                    }
                    {editEnable && (
                      <Controller
                        name="amenity"
                        defaultValue={amenityId}
                        control={control}
                        rules={{
                          required: true,
                        }}
                        render={({ field }) => (
                          <>
                            <Checkbox.Group defaultValue={amenityId} style={{ width: '100%' }} onChange={(value) => { field.onChange(value); }}>
                              {renderAmenities()}
                            </Checkbox.Group>
                          </>
                        )}
                      />
                    )}
                    {editEnable && errors.amenity && (
                      <div className="invalid-feedback-custom">
                        Please select a amenities!
                      </div>
                    )}
                  </Col>
                </Row>

                <Row className='formSpace'>
                  <Col md={6} style={{ textAlign: 'right' }}>
                  </Col>
                  <Col md={6} style={{ textAlign: 'left' }}>
                    {!loading && editEnable && inventoryId &&
                      <Button type="submit" id='approve-button' style={formButton}>Update</Button>
                    }

                    {
                      loading && <div style={{ textAlign: 'center' }}><Spinner animation="border" variant="warning" /></div>
                    }
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </>
  )
};

export default HotelInventoryAmenities
