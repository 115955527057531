import { useStoreActions, useStoreState } from "easy-peasy";
import React, { useEffect, useState, useCallback } from "react";
import { UserGroups } from "../../../../../common/constants";
import { formatPhoneNumber, getLoggedUserType, isFirstUserSelectedBrand, isBrandAdmin, isOnlyBrandAdmin, isOnlyChiefBrandAdmin, getUserRole, isFirstUserSelectedBrandAsChiefBrandAdmin } from "../../../../../common/functions";
import { useForm } from "react-hook-form";
import { Row, Col, Form, Button, Spinner, Container, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { BrandUserFormInput } from "../../../../../common/interfaces";
import { toast } from 'react-toastify';
import { formButton, overlayTriggerDelay, toolTipStyle } from "../../../../../common/components-style";
import SweetAlert from 'react-bootstrap-sweetalert';

const BrandUserView: React.FC<any> = ({ selectedUser, close, reload, brandId }): JSX.Element => {

  /////////////////////////////////////////Form Declaration/////////////////////////////////////////////////
  // const [form] = Form.useForm();
  const { register, watch, handleSubmit } = useForm<BrandUserFormInput>();


  ///////////////////////////////////Local State Manage///////////////////////////////////////////////////
  const [loading, setLoading] = useState<boolean>(false);
  // const [isBrand, setIsBrand] = useState("");
  const [archivePopop, setArchivePopop] = useState<boolean>(false);

  ////////////////////////////Global State Manage//////////////////////////////////////////////////////////
  const updateAccess = useStoreActions<any>((actions) => actions.user.updateAccess);

  const updateAccessSuccess = useStoreState<any>((state) => state.user.updateAccessSuccess);

  /////////////////////////////////////Update Access Response Handle/////////////////////////////////////////////
  useEffect(() => {
    //user update success
    if (updateAccessSuccess) {
      setLoading(false);
      reload();
      // message.success("Permission updated successfully");
      toast.success("Permission updated successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
      close();
    }
    // const userRole = getStorage("isBrand");
    // setIsBrand(userRole);
  }, [reload, updateAccessSuccess, close]);

  //////////////////////////////////////Form Submit////////////////////////////////////////////////////////////
  const onSubmit = useCallback((values) => {
    setLoading(true);
    // const isUserDisable = watch("isUserDisable") === true ? true : false
    // updateAccess(setAutoBrandReadAccess(selectedUser.arrId, selectedUser.userId, isUserDisable));
    updateAccess({
      id: selectedUser.arrId,
      userId: selectedUser.userId,
      role:
        watch("isAdmin") === true
          ? UserGroups.brand_admin
          : UserGroups.user,
      accessType: "brandRole",
      isUserDisable: watch("isUserDisable") === true ? true : false,
      userData: getUserRole(brandId),
      tab: "Brand Users",
      brandId: brandId,
      existingRole: selectedUser?.role
    });
  },
    [updateAccess, watch, selectedUser, brandId]
  );

  return (
    <>
      <Container fluid className="p-0">
        <div className="jumbotron" style={{ width: '100%' }}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md={1}></Col>
              <Col md={10}>
                <Row className='formSpace'>
                  <Col md={4} style={{ textAlign: 'right' }}>
                    <Form.Label> Email</Form.Label>
                  </Col>
                  <Col md={8} style={{ textAlign: 'left' }}>
                    <p className="lead">{selectedUser?.email}</p>
                  </Col>
                </Row>
              </Col>
              <Col md={1}></Col>
            </Row>
            <Row>
              <Col md={1}></Col>
              <Col md={10}>
                <Row className='formSpace'>
                  <Col md={4} style={{ textAlign: 'right' }}>
                    <Form.Label> First name</Form.Label>
                  </Col>
                  <Col md={8} style={{ textAlign: 'left' }}>
                    <p className="lead">{selectedUser?.firstName}</p>
                  </Col>
                </Row>
              </Col>
              <Col md={1}></Col>
            </Row>
            <Row>
              <Col md={1}></Col>
              <Col md={10}>
                <Row className='formSpace'>
                  <Col md={4} style={{ textAlign: 'right' }}>
                    <Form.Label> Last name</Form.Label>
                  </Col>
                  <Col md={8} style={{ textAlign: 'left' }}>
                    <p className="lead">{selectedUser?.lastName}</p>
                  </Col>
                </Row>
              </Col>
              <Col md={1}></Col>
            </Row>
            <Row>
              <Col md={1}></Col>
              <Col md={10}>
                <Row className='formSpace'>
                  <Col md={4} style={{ textAlign: 'right' }}>
                    <Form.Label> Job title</Form.Label>
                  </Col>
                  <Col md={8} style={{ textAlign: 'left' }}>
                    <p className="lead">{selectedUser?.position}</p>
                  </Col>
                </Row>
              </Col>
              <Col md={1}></Col>
            </Row>

            <Row>
              <Col md={1}></Col>
              <Col md={10}>
                <Row className='formSpace'>
                  <Col md={4} style={{ textAlign: 'right' }}>
                    <Form.Label> Phone</Form.Label>
                  </Col>
                  <Col md={8} style={{ textAlign: 'left' }}>
                    <p className="lead">{formatPhoneNumber(selectedUser?.phone)}</p>
                  </Col>
                </Row>
              </Col>
              <Col md={1}></Col>
            </Row>
            <Row>
              <Col md={1}></Col>
              <Col md={10}>
                <Row className='formSpace'>
                  <Col md={4} style={{ textAlign: 'right' }}>
                    <Form.Label>Mobile</Form.Label>
                  </Col>
                  <Col md={8} style={{ textAlign: 'left' }}>
                    <p className="lead">{formatPhoneNumber(selectedUser?.mobile)}</p>
                  </Col>
                </Row>
              </Col>
              <Col md={1}></Col>
            </Row>
            {(isFirstUserSelectedBrand() || getLoggedUserType() === UserGroups.super_admin || isBrandAdmin(brandId)) &&
              <>
                <OverlayTrigger
                  placement="top"
                  delay={overlayTriggerDelay}
                  overlay={
                    <Tooltip id='tooltip-table-top' style={toolTipStyle}>
                      Are you sure you wish to provide Brand User permissions? This will give this user all permissions across the brand and all properties.
                      If not, please create the user and manage permissions in the User Management table.
                    </Tooltip>
                  }
                >
                  <Row>
                    <Col md={1}></Col>
                    <Col md={10}>
                      <Row className='formSpace'>
                        <Col md={4} style={{ textAlign: 'right' }}></Col>
                        <Col md={8} style={{ textAlign: 'left' }}>
                          <Form.Check
                            type="checkbox"
                            label="Set as admin"
                            {...register("isAdmin")}
                            defaultChecked={selectedUser.role === UserGroups.brand_admin ? true : false}
                            disabled={
                              (!isOnlyChiefBrandAdmin(brandId) && !isFirstUserSelectedBrandAsChiefBrandAdmin()) &&
                              (
                                isOnlyBrandAdmin(brandId) &&
                                selectedUser.role !== UserGroups.user
                              )
                            }
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col md={1}></Col>
                  </Row>
                </OverlayTrigger>
                <Row>
                  <Col md={1}></Col>
                  <Col md={10}>
                    <Row className='formSpace'>
                      <Col md={4} style={{ textAlign: 'right' }}></Col>
                      <Col md={8} style={{ textAlign: 'left' }}>
                        <Form.Check
                          type="checkbox"
                          label="Disable user"
                          {...register("isUserDisable")}
                          defaultChecked={selectedUser.status === 'inactive'}
                          disabled={
                            (!isOnlyChiefBrandAdmin(brandId) && !isFirstUserSelectedBrandAsChiefBrandAdmin()) &&
                            (
                              isOnlyBrandAdmin(brandId) &&
                              selectedUser.role !== UserGroups.user
                            )
                          }
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col md={1}></Col>
                </Row>
              </>
            }


            {/* {(!isFirstUserSelectedBrand() && !(getLoggedUserType() === UserGroups.super_admin )) && <OverlayTrigger
              placement="top"
              delay={overlayTriggerDelay}
              overlay={<Tooltip id='tooltip-table-top' style={toolTipStyle}>Are you sure you wish to provide Brand User permissions? This will give this user all permissions across the brand and all properties.' If not, please create the user and manage permissions in the User Management table</Tooltip>}
            >
              <Row>
                <Col md={1}></Col>
                <Col md={10}>
                  <Row className='formSpace'>
                    <Col md={4} style={{ textAlign: 'right' }}>

                    </Col>
                    <Col md={8} style={{ textAlign: 'left' }}>
                      <Form.Check type="checkbox" label="Set as admin" {...register("isAdmin")} checked={selectedUser.role === UserGroups.brand_admin ? true : false} />
                    </Col>
                  </Row>
                </Col>
                <Col md={1}></Col>
              </Row>
            </OverlayTrigger>
            } */}

            <Row>
              <Col md={1}></Col>
              <Col md={10}>
                <Row className='formSpace'>
                  <Col md={4} style={{ textAlign: 'right' }}>
                  </Col>
                  <Col md={8} className="btn_align_left">
                    {(isFirstUserSelectedBrand() ||
                      getLoggedUserType() === UserGroups.super_admin || isBrandAdmin(brandId)) && !loading &&
                      <Button id='approve-button' style={formButton} onClick={() => {
                        if (watch('isUserDisable')) {
                          setArchivePopop(true);
                        } else {
                          handleSubmit(onSubmit)();
                        }
                      }}>Update</Button>
                    }
                    {(!isFirstUserSelectedBrand() ||
                      !(getLoggedUserType() === UserGroups.super_admin)) && !loading &&
                      <Button id='edit-button' style={formButton} onClick={() => { close(); }}>Close</Button>
                    }
                    {
                      loading && <div style={{ textAlign: 'center' }}><Spinner animation="border" variant="warning" /></div>
                    }
                  </Col>

                </Row>
              </Col>
              <Col md={1}></Col>
            </Row>
          </Form>
        </div>
      </Container>

      <SweetAlert
        show={archivePopop}
        warning
        showCancel={true}
        focusCancelBtn={true}
        confirmBtnText="Yes disable it!"
        confirmBtnBsStyle="danger"
        title="Are you sure you want to disable this user?"
        onConfirm={() => {
          handleSubmit(onSubmit)();
          setArchivePopop(false);
        }}
        onCancel={() => {
          setArchivePopop(false);
        }}
      >
      </SweetAlert>
    </>

  );
};
export default BrandUserView;
