import { FC, useState, useEffect, useCallback } from "react";
import { useStoreActions, useStoreState } from 'easy-peasy';
import DataTable from "react-data-table-component";
import { tableStyles, overlayTriggerDelay } from "../../../../common/components-style";
import RFPBreadCrumbPage from "../../../common/RFPBredcrumPage";
import { Button } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import { toast } from 'react-toastify';
import { OverlayTrigger, Stack, Tooltip, Modal } from "react-bootstrap";
import ViewEdit from '../../../../images/icons/svg/eyeIcon.svg';
import Archive from '../../../../images/icons/svg/outside/Icon-Function-Archive.svg';
import SweetAlert from "react-bootstrap-sweetalert";

const UnmatchedPropertyData: FC<any> = ({ reportId, programmeId, setFormOpen, reload, selectedClient, selectedProgramme, accommodationType, reportName }): JSX.Element => {
    const [data, setData] = useState<Array<any>>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [tableLoading, setTableLoading] = useState<boolean>(false);
    const [firstLoading, setFirstLoading] = useState<boolean>(true);
    const [page, setPage] = useState<number>(1);
    const [perPage, setPerPage] = useState<number>(10);
    const [csvAllData, setCsvData] = useState<Array<any>>([]);
    const [clearDataEnabled, setClearDataEnabled] = useState<boolean>(false);
    const [archivePopup, setArchivePopup] = useState<boolean>(false);
    const [hotelArchive, setHotelArchive] = useState<any>("");
    const [dataModelShow, setDataModelShow] = useState<boolean>(false);
    const [mismatchData, setMismatchData] = useState<any>(null);


    const { getAllHotelTempData, deleteHotelsTempData, getAllHotelPaginationTempData, deleteHotelsTempDataById } = useStoreActions<any>((actions) => ({
        getAllHotelTempData: actions.hotel.getAllHotelTempData,
        deleteHotelsTempData: actions.hotel.deleteHotelsTempData,
        deleteHotelsTempDataById: actions.hotel.deleteHotelsTempDataById,
        getAllHotelPaginationTempData: actions.hotel.getAllHotelPaginationTempData
    }));

    /**
     * global state
     */
    const { getHotelsTempDataSuccess, getHotelsPaginationTempDataSuccess } = useStoreState<any>((state) => ({
        getHotelsTempDataSuccess: state.hotel.getHotelsTempDataSuccess,
        getHotelsPaginationTempDataSuccess: state.hotel.getHotelsPaginationTempDataSuccess,

    }));

    const getLoadAllTempHotels = useCallback(() => {
        if (selectedClient && programmeId && reportId) {
            setLoading(true);
            const payload = {
                page: page,
                perPage: perPage,
                clientId: selectedClient?.key ? selectedClient?.key : selectedClient?._id,
                programmeId: programmeId,
                reportId: reportId
            };
            getAllHotelTempData(payload);
        }
    }, [selectedClient, getAllHotelTempData, page, perPage, programmeId, reportId]);

    useEffect(() => {
        if (selectedClient && programmeId && reportId) {
            getLoadAllTempHotels();
        }
    }, [getLoadAllTempHotels, selectedClient, programmeId, reportId]);



    const handleDownloadStart = useCallback(async () => {
        let isMounted = true;
        setClearDataEnabled(false);
        const payload = {
            page: 0,
            perPage: 0,
            clientId: selectedClient?.key ? selectedClient?.key : selectedClient?._id,
            programmeId: programmeId,
            reportId: reportId,
        };
        try {
            const res = await getAllHotelPaginationTempData(payload);
            if (res?.status && res?.data?.data.length > 0 && isMounted) {
                const csvTempData = res?.data?.data.map((row) => ({
                    brandName: row.brandName || '',
                    hotelName: row.hotelName || '',
                    address: row.address || '',
                    city: row.city || '',
                    state: row.state || '',
                    country: row.country || '',
                }));
                if (isMounted) setCsvData(csvTempData);
                if (isMounted) startDownloading(res?.data?.data);
            }
        } catch (error) {
            if (isMounted) {
                toast.error("Download Failed!", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'foo-bar',
                });
                setLoading(false);
            }
        }
        return () => {
            isMounted = false;
        };
    }, [getAllHotelPaginationTempData, programmeId, selectedClient?._id, selectedClient?.key, reportId]);


    useEffect(() => {
        if (selectedClient) {
            if (programmeId && reportId) {
                let isMounted = true;

                const start = async () => {
                    await handleDownloadStart();
                };

                start();
                return () => {
                    isMounted = false;
                };
            }
        }
    }, [handleDownloadStart, programmeId, reportId, selectedClient]);



    useEffect(() => {
        if (getHotelsTempDataSuccess?.data) {
            setData(getHotelsTempDataSuccess?.data?.data || []);
            setFirstLoading(false);
            setTableLoading(false);
            setLoading(false);
        }

    }, [getHotelsTempDataSuccess]);

    const getLoadPaginationTempHotels = useCallback(() => {
        if (selectedClient && programmeId && reportId) {
            const payload = {
                page: page,
                perPage: perPage,
                clientId: selectedClient?.key ? selectedClient?.key : selectedClient?._id,
                programmeId: programmeId,
                reportId: reportId,
            };
            getAllHotelPaginationTempData(payload);
        }
    }, [selectedClient, getAllHotelPaginationTempData, page, perPage, programmeId, reportId])

    useEffect(() => {
        if (selectedClient && programmeId && reportId) {
            getLoadPaginationTempHotels();
        }
    }, [getLoadPaginationTempHotels, programmeId, reportId, selectedClient]);


    useEffect(() => {
        if (getHotelsPaginationTempDataSuccess?.data) {
            setCsvData(getHotelsPaginationTempDataSuccess?.data?.data || []);
        }

    }, [getHotelsPaginationTempDataSuccess]);

    const csvHeaders = [
        { label: "Brand", key: "brandName" },
        { label: "Hotel", key: "hotelName" },
        { label: "Address", key: "address" },
        { label: "State", key: "state" },
        { label: "City", key: "city" },
        { label: "Country", key: "country" },
    ];

    const startDownloading = (record: any[]) => {

        if (record?.length > 0) {
            record.map((row) => ({
                brandName: row.brandName || '',
                hotelName: row.hotelName || '',
                address: row.address || '',
                city: row.city || '',
                state: row.state || '',
                country: row.country || '',
            }));
        }
    }

    const initialDownload = () => {
        setTimeout(() => {
            setClearDataEnabled(true);
            toast.success("File downloaded successfully!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            setLoading(false);

        }, 2000);
    }

    const handleClearData = async () => {
        setLoading(true);
        setClearDataEnabled(false);

        const payload = {
            clientId: selectedClient?.key ? selectedClient?.key : selectedClient?._id,
            programmeId: programmeId,
        };

        try {
            await deleteHotelsTempData(payload);

            toast.success("Data cleared successfully!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });

            setData([]);

            setLoading(false);
            setClearDataEnabled(true);
        } catch (error) {
            toast.error("Failed to clear data!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            setLoading(false);
        }
    };

    const handleDeleteHotel = useCallback(
        (hotelId) => {
            setLoading(true);
            deleteHotelsTempDataById(hotelId);
            getLoadAllTempHotels();
        },
        [deleteHotelsTempDataById, getLoadAllTempHotels]
    );

    const handleOpenModal = (data) => {
        setMismatchData(data);
        setDataModelShow(true);
    };

    const columns = [
        {
            name: 'Brand',
            selector: row => row.brandName,
        },
        {
            name: 'Hotel',
            selector: row => row.hotelName,
        },
        {
            name: 'Address',
            minWidth: '200px',
            selector: row => row.address,
        },
        {
            name: 'State',
            selector: row => row.state,
        },
        {
            name: 'City',
            selector: row => row.city,
        },
        {
            name: 'Country',
            selector: row => row.country,
        },
        {
            name: 'Outcome',
            selector: row => row.matchOutcome,
            button: true,
            key: 'action',
            wrap: true,
            minWidth: '300px',
            cell: (data: any) => {
                return (
                    <div style={{ display: "flex" }}>
                        <div style={{ marginTop: "10px", marginInlineEnd: "10px" }}>
                            {data.matchOutcome ? (
                                <span>{data.matchOutcome}</span>
                            ) : (
                                <span>No Match Outcome</span>
                            )}
                        </div>
                        {data.matchOutcome !== 'No Match' &&
                            <Stack direction="horizontal" style={{ marginTop: '10px' }}>
                                <OverlayTrigger
                                    placement="top"
                                    delay={overlayTriggerDelay}
                                    overlay={<Tooltip id='tooltip-table-top'>View / Edit</Tooltip>}
                                >
                                    <img
                                        className='iconSizes mr-2'
                                        src={ViewEdit}
                                        alt="View/Edit"
                                        onClick={() => handleOpenModal(data)}
                                    />
                                </OverlayTrigger>

                                <OverlayTrigger
                                    placement="top"
                                    delay={overlayTriggerDelay}
                                    overlay={<Tooltip id="tooltip-table-top">Archive</Tooltip>}
                                >
                                    <img
                                        className="iconSizes"
                                        src={Archive}
                                        alt="Archive"
                                        onClick={() => {
                                            const hotelData = data?._id;
                                            setHotelArchive(hotelData);
                                            setArchivePopup(true);
                                        }}
                                    />
                                </OverlayTrigger>
                            </Stack>
                        }
                    </div>
                );
            }
        }

    ];

    interface UnmatchedFieldRow {
        field: string;
        hotelTempValue: string;
        bestMatchValue: string;
    }

    const DEFAULT_HOTEL_VALUE = "N/A";

    const generateUnmatchedData = (mismatchData: any): UnmatchedFieldRow[] => {
        if (!mismatchData || !mismatchData.unmatchedFields) {
            return [];
        }

        const fields = mismatchData.unmatchedFields.split(', ');

        return fields.map((field) => {
            const hotelTempValue = mismatchData[field] || DEFAULT_HOTEL_VALUE;
            const bestMatchValue =
                mismatchData.bestMatch?.[field] !== undefined
                    ? mismatchData.bestMatch[field]
                    : DEFAULT_HOTEL_VALUE;

            return { field, hotelTempValue, bestMatchValue };
        });
    };

    return (
        <>
            <div className="col-md-12" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <div>
                    {reportId && (
                        <RFPBreadCrumbPage
                            isPageName="programmeSpendReport"
                            selectedClient={selectedClient}
                            selectedProgramme={selectedProgramme}
                            selectedClientName={selectedClient?.companyName}
                            selectedProgrammeName={selectedProgramme?.programmeName}
                            reportId={reportId}
                            programmeId={programmeId}
                            startDate={reportName}
                        />
                    )}
                </div>

                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <Button
                        onClick={handleClearData}
                        className="btn_design customClearDataBtn"
                        disabled={!clearDataEnabled}
                    >
                        Clear Data
                    </Button>
                    <CSVLink
                        data={csvAllData}
                        headers={csvHeaders}
                        filename={`${reportName || "report"}.csv`}
                        className="h-10 ml-4 customDeleteBtn"
                        target="_blank"
                        onClick={initialDownload}
                        uFEFF={false}
                    >
                        {loading ? "Downloading..." : "Download"}
                    </CSVLink>
                </div>
            </div>

            {

                !firstLoading && <div className='brnd_tbl' style={{ marginTop: '50px' }}>
                    <DataTable
                        columns={columns}
                        data={data}
                        progressPending={tableLoading}
                        pagination
                        striped={true}
                        customStyles={tableStyles}
                        theme='mysaTable'
                        fixedHeader={true}
                        noDataComponent={'Updating records'}
                    />
                </div>
            }
            <Modal
                show={dataModelShow}
                onHide={() => setDataModelShow(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton className="d-flex justify-content-between">
                    <Modal.Title id="contained-modal-title-vcenter">
                        {mismatchData
                            ? `${mismatchData.brandName || 'Unknown Brand'} - ${mismatchData.hotelName || 'Unknown Hotel'}`
                            : 'No Data Available'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DataTable<UnmatchedFieldRow>
                        columns={[
                            { name: 'Field', selector: (row: UnmatchedFieldRow) => row.field },
                            { name: 'Upload Property Value', selector: (row: UnmatchedFieldRow) => row.hotelTempValue },
                            { name: 'Myo Hotel Value', selector: (row: UnmatchedFieldRow) => row.bestMatchValue },
                        ]}
                        data={generateUnmatchedData(mismatchData)}
                        pagination
                        striped={true}
                        customStyles={tableStyles}
                        theme="mysaTable"
                        fixedHeader={true}
                        noDataComponent={'Updating records'}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <div className="w-100">
                        <Button className="yellow_shadow_btn" style={{ width: '150px' }} onClick={() => setDataModelShow(false)}>Close</Button>
                    </div>
                </Modal.Footer>
            </Modal>

            <SweetAlert
                show={archivePopup}
                warning
                showCancel={true}
                focusCancelBtn={true}
                confirmBtnText="Yes archive it!"
                confirmBtnBsStyle="danger"
                title="Are you sure you wish to archive this hotel?"
                onConfirm={() => {
                    handleDeleteHotel(hotelArchive);
                    setArchivePopup(false);
                }}
                onCancel={() => {
                    setArchivePopup(false);
                }}
            ></SweetAlert>
        </>
    )
}

export default UnmatchedPropertyData;