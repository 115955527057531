import React, { } from "react";
import { NavLink } from 'react-router-dom';
import { isProgramAdmin, isBooker, isTraveller, getLoggedUserType, hasBrand, isSuperAdmin, hasBrandAccommodationType } from "../../../../common/functions";
import _ from 'lodash';
/**
 * icons 
 */
import bookingIcon from '../../../../images/icons/svg/navigate/Icon-Navigation-Booking-Management.svg';
import bookingIconSelected from '../../../../images/icons/svg/navigate/Icon-Navigation-Booking-Management-selected.svg';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { iconDivSelectedStyle, iconDivStyle, iconImageSelectedStyle, overlayTriggerDelay, toolTipStyle } from "../../../../common/components-style";
import { UserGroups } from "../../../../common/constants";

/**
 * type to this component
 */

type TBookerReportsNav = {
    setIsExpand: React.MouseEventHandler,
    pathname: string,
    showProgramme: any,
    showTraveler: any
    showBooker: any
}

const BookerReportsNav: React.FunctionComponent<TBookerReportsNav> = ({ setIsExpand, pathname, showProgramme, showTraveler, showBooker }) => {

    // /**
    //  * get param 
    //  */
    // const { id }: any = useParams();

    /**
     * booking report manage
     */
    const corporateAdmin = isProgramAdmin(); // if has programme / corporate admin true || false return
    const booker = isBooker(); // if has booker true || false return
    const traveler = isTraveller(); // if has booker true || false return
    const super_admin = isSuperAdmin(); // if has super_admin true || false return
    const brandAndProperty = !hasBrand() && _.includes([
        UserGroups.corporate_admin,
        UserGroups.corporate_sub_admin,
        UserGroups.chief_property_admin,
        UserGroups.booker,
        UserGroups.traveler,
        UserGroups.property_admin,
        UserGroups.department_admin,
        UserGroups.user,
        UserGroups.programme_admin,
        UserGroups.super_admin,
    ], getLoggedUserType());


    return (<>

        <NavLink
            onClick={setIsExpand}
            hidden={(!corporateAdmin || !showProgramme)}
            exact
            activeClassName=""
            to={{
                pathname: "/admin/corporate-booking-reports",
                state: {
                    filterProgramme: false
                }
            }}>
            {
                (pathname === '/admin/corporate-booking-reports' || pathname === '/admin/traveler-property-page') ?
                    <OverlayTrigger
                        placement="right"
                        delay={overlayTriggerDelay}
                        overlay={<Tooltip id='tooltip-right' style={toolTipStyle}>Booking Management</Tooltip>}
                    >
                        <div style={iconDivSelectedStyle}><img id='bookingIconSelectedCorporate' style={iconImageSelectedStyle} src={bookingIconSelected} alt="" /></div>
                    </OverlayTrigger>
                    :
                    <OverlayTrigger
                        placement="right"
                        delay={overlayTriggerDelay}
                        overlay={<Tooltip id='tooltip-right' style={toolTipStyle}>Booking Management</Tooltip>}
                    >
                        <div style={iconDivStyle}><img id='bookingIconCorporate' src={bookingIcon} alt="" /></div>
                    </OverlayTrigger>

            }
        </NavLink>
        <NavLink
            onClick={setIsExpand}
            hidden={(!booker || !showBooker)}
            exact
            activeClassName=""
            to="/admin/booker-booking-reports">
            {
                (pathname === '/admin/booker-booking-reports' || pathname === '/admin/traveler-property-page') ?
                    <OverlayTrigger
                        placement="right"
                        delay={overlayTriggerDelay}
                        overlay={<Tooltip id='tooltip-right' style={toolTipStyle}>Booking Management</Tooltip>}
                    >
                        <div style={iconDivSelectedStyle}><img id='bookingIconSelectedBooker' style={iconImageSelectedStyle} src={bookingIconSelected} alt="" /></div>
                    </OverlayTrigger>
                    :
                    <OverlayTrigger
                        placement="right"
                        delay={overlayTriggerDelay}
                        overlay={<Tooltip id='tooltip-right' style={toolTipStyle}>Booking Management</Tooltip>}
                    >
                        <div style={iconDivStyle}><img id='bookingIconBooker' src={bookingIcon} alt="" /></div>
                    </OverlayTrigger>

            }
        </NavLink>
        <NavLink
            onClick={setIsExpand}
            hidden={(!traveler || !showTraveler)}
            exact
            activeClassName=""
            to="/admin/traveler-booking-reports">
            {
                (pathname === '/admin/traveler-booking-reports' || pathname === '/admin/traveler-property-page') ?
                    <OverlayTrigger
                        placement="right"
                        delay={overlayTriggerDelay}
                        overlay={<Tooltip id='tooltip-right' style={toolTipStyle}>Booking Management</Tooltip>}
                    >
                        <div style={iconDivSelectedStyle}><img id='bookingIconSelectedTraveler' style={iconImageSelectedStyle} src={bookingIconSelected} alt="" /></div>
                    </OverlayTrigger>
                    :
                    <OverlayTrigger
                        placement="right"
                        delay={overlayTriggerDelay}
                        overlay={<Tooltip id='tooltip-right' style={toolTipStyle}>Booking Management</Tooltip>}
                    >
                        <div style={iconDivStyle}><img id='bookingIconTraveler' src={bookingIcon} alt="" /></div>
                    </OverlayTrigger>

            }

        </NavLink>
        {!hasBrandAccommodationType() && (
            <NavLink
                onClick={setIsExpand}
                hidden={(brandAndProperty)}
                exact
                activeClassName=""
                to="/admin/brand-property-booking-reports">
                {

                    (pathname === '/admin/brand-property-booking-reports') ?
                        <OverlayTrigger
                            placement="right"
                            delay={overlayTriggerDelay}
                            overlay={<Tooltip id='tooltip-right' style={toolTipStyle}>Booking Management</Tooltip>}
                        >
                            <div style={iconDivSelectedStyle}><img id='bookingIconSelectedBrand' style={iconImageSelectedStyle} src={bookingIconSelected} alt="" /></div>
                        </OverlayTrigger>
                        :
                        <OverlayTrigger
                            placement="right"
                            delay={overlayTriggerDelay}
                            overlay={<Tooltip id='tooltip-right' style={toolTipStyle}>Booking Management</Tooltip>}
                        >
                            <div style={iconDivStyle}><img id='bookingIconBrand' src={bookingIcon} alt="" /></div>
                        </OverlayTrigger>

                }
            </NavLink>
        )}
        <NavLink
            onClick={setIsExpand}
            hidden={(!super_admin)}
            exact
            activeClassName=""
            to="/admin/super-admin-booking-reports">
            {
                (pathname === '/admin/super-admin-booking-reports') ?
                    <OverlayTrigger
                        placement="right"
                        delay={overlayTriggerDelay}
                        overlay={<Tooltip id='tooltip-right' style={toolTipStyle}>Booking Management</Tooltip>}
                    >
                        <div style={iconDivSelectedStyle}><img id='bookingIconSelectedSuperAdmin' style={iconImageSelectedStyle} src={bookingIconSelected} alt="" /></div>
                    </OverlayTrigger>
                    :
                    <OverlayTrigger
                        placement="right"
                        delay={overlayTriggerDelay}
                        overlay={<Tooltip id='tooltip-right' style={toolTipStyle}>Booking Management</Tooltip>}
                    >
                        <div style={iconDivStyle}><img id='bookingIconSuperAdmin' src={bookingIcon} alt="" /></div>
                    </OverlayTrigger>

            }
        </NavLink>
    </>)
};

export default BookerReportsNav;