// import { message } from 'antd';
import { useStoreActions, useStoreState } from 'easy-peasy';
import React, { useEffect, useState, useCallback } from 'react';
import { UserGroups, UserAccess, BrandStatus } from '../../../../../common/constants';
import DebounceSearch from '../../../../common/DebounceSearch'
import { canBrandModuleWrite, getLoggedUserEmail, getLoggedUserType, getMailDomain, setAutoBrandReadAccess, getUserRole } from '../../../../../common/functions';
import _ from 'lodash';
import { BrandUserFormInput } from '../../../../../common/interfaces';
import { Row, Col, Container, Form, Button, Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useForm, Controller } from "react-hook-form";
import { formButton, overlayTriggerDelay, toolTipStyle } from '../../../../../common/components-style';
import { toast } from 'react-toastify';
import { getStorage } from '../../../../../common/storage';
import Permission from '../../../../../images/icons/svg/outside/Icon-Function-Permission.svg';
import { isOnlyBrandAdmin } from "../../../../../common/functions";


const BrandUserForm: React.FC<any> = ({ brandId, selectedUser, reload, close, selectedBrand, data }): JSX.Element => {
	const { register, watch, handleSubmit, setValue, reset, formState: { errors }, control } = useForm<BrandUserFormInput>();
	const [email, setEmail] = useState('');
	const [loading, setLoading] = useState<boolean>(false);
	const [brandData, setBrandData] = useState<any>();
	// const [brandFinanceData, setBrandFinanceData] = useState<any>();
	const [group, setGroup] = useState("");
	const [brandDomains, setBrandDomains] = useState<Array<string>>([]);

	const updateUser = useStoreActions<any>((actions) => actions.user.updateUser);
	const createUser = useStoreActions<any>((actions) => actions.user.createUser);
	const updateAccess = useStoreActions<any>((actions) => actions.user.updateAccess);
	const resetUser = useStoreActions<any>((actions) => actions.user.resetUser);
	const createUserMapping = useStoreActions<any>((actions) => actions.user.createUserMapping);
	const updateUserMapping = useStoreActions<any>((actions) => actions.user.updateUserMapping);
	const onloadBrand = useStoreActions<any>((actions) => actions.brand.onloadBrand);
	// const onUpdateBrand = useStoreActions<any>((actions) => actions.brand.onUpdateBrand);
	const resetBrand = useStoreActions<any>((actions) => actions.brand.resetBrand);
	const getBrandFinanceDetailByBrandId = useStoreActions<any>((actions) => actions.brand.getBrandFinanceDetailByBrandId);
	const getHeaderUserMapping = useStoreActions<any>((actions) => actions.user.getUserMapping);
	const onUpdateBrand = useStoreActions<any>((actions) => actions.brand.onUpdateBrand);

	const userUpdate = useStoreState<any>((state) => state.user.userUpdate);
	const userCreate = useStoreState<any>((state) => state.user.userCreate);
	const userCreateError = useStoreState<any>((state) => state.user.userCreateError);
	const userUpdateError = useStoreState<any>((state) => state.user.userUpdateError);
	const updateAccessSuccess = useStoreState<any>((state) => state.user.updateAccessSuccess);
	const createUserMappingSuccess = useStoreState<any>((state) => state.user.createUserMappingSuccess);
	const updateUserMappingSuccess = useStoreState<any>((state) => state.user.updateUserMappingSuccess);
	const loadBrandSuccess = useStoreState<any>((state) => state.brand.loadBrandSuccess);
	const updateBrandSuccess = useStoreState<any>((state) => state.brand.updateBrandSuccess);
	const getBrandFinanceDetailSuccess = useStoreState<any>((state) => state.brand.getBrandFinanceDetailSuccess);
	const getHeaderUserMappingSuccess = useStoreState<any>((state) => state.user.getUserMappingSuccess);

	////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (brandId) {
			onloadBrand(brandId);
		}
	}, [brandId, onloadBrand]);

	////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (brandId) {
			getBrandFinanceDetailByBrandId(brandId);
		}
	}, [brandId, getBrandFinanceDetailByBrandId]);


	/////////////////////////////Check is brand user///////////////////////////////////////////////////////////////////
	useEffect(() => {
		const { userData } = getStorage("myo_logged_user");
		if (userData?._id !== '61b75ea5110a6c0013246b5d') {
			const userId = {
				userId: userData?._id,
			};
			getHeaderUserMapping(userId);

		}

		// getHeaderUserMapping({ brandId });
	}, [brandId, getHeaderUserMapping]);

	useEffect(() => {
		if (getHeaderUserMappingSuccess) {
			if (getHeaderUserMappingSuccess.data.result.length > 0) {
				getHeaderUserMappingSuccess.data.result[0].brands.forEach((brand) => {
					if (getLoggedUserEmail() === getHeaderUserMappingSuccess.data.result[0].email && brand.role === UserGroups.brand_admin) {
						setGroup(brand.role);
					}
				});
			}
		}
	}, [getHeaderUserMappingSuccess]);
	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		//user update success
		if (userUpdate) {
			updateAccess({

				id: selectedUser.arrId,
				userId: selectedUser.userId,
				// role: form.getFieldValue("isAdmin") === true ? UserGroups.brand_admin : UserGroups.user,
				accessType: "brandRole",
				userData: getUserRole(brandId),
				tab: "Brand Users",
				brandId: brandId,
				existingRole: selectedUser?.role

			});
			// form.resetFields();
			reset();
			reload();
			setLoading(false)
			// message.success("User updated successfully");
			toast.success("User updated successfully", {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
			resetUser();
		}
		// user create success
		if (userCreate) {
			if (userCreate.data.data) {

				// create mapping
				createUserMapping({
					userId: userCreate.data.user._id,
					email: userCreate.data.user.email,
					brandId,
					role: watch('isChiefAdmin') === true ? UserGroups.chief_brand_admin : watch('isAdmin') === true ? UserGroups.brand_admin : UserGroups.user,
					isFirstUser: watch('isChiefAdmin') === true ? true : false
				})
			} else {
				//update mapping
				updateUserMapping({
					userId: userCreate.data.user._id,
					brand: {
						brandId,
						role: watch('isChiefAdmin') === true ? UserGroups.chief_brand_admin : watch('isAdmin') === true ? UserGroups.brand_admin : UserGroups.user,
					}
				})
			}
			// form.resetFields();
			if (watch('isChiefAdmin') === true) {
				const payload = {
					id: brandId,
					brandStatus: 'inviteSent'
				}
				onUpdateBrand(payload);
			}

			reset();
			resetUser();
			setLoading(false);
			// reload();
		}

		// update user mapping success 
		if (createUserMappingSuccess || updateUserMappingSuccess) {

			const arrId = _.filter(updateUserMappingSuccess?.data?.brands, (a) => a.brandId === brandId).shift()?._id || ''
			const id = createUserMappingSuccess?.data?.brands[0]?._id || arrId;
			const userId = createUserMappingSuccess?.data.userId || updateUserMappingSuccess?.data?.userId;
			// const isUserDisable = false;
			updateAccess(setAutoBrandReadAccess(id, userId));
			// message.success("User created successfully");
			toast.success("User created successfully", {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			})
			resetUser();
			reload();
		}

		// user create error
		if (userCreateError) {
			// message.error(userCreateError.message);
			toast.error(userCreateError.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
			resetUser();
			setLoading(false);
		}

		// access update success
		if (updateAccessSuccess) {
			resetUser();
			setLoading(false);
			reload();
			// message.success('Permission updated successfully');
			toast.success("Permission updated successfully", {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			})
		}

		// user update error
		if (userUpdateError) {
			// message.error(userUpdateError.message);
			toast.error(userUpdateError.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
			setLoading(false);
			resetUser();
		}

		if (updateBrandSuccess) {
			// message.success('Brand updated successfully');
			toast.success("Brand updated successfully", {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			})
			resetBrand();
		}

		if (loadBrandSuccess) {
			// setBrandData(loadBrandSuccess.data);
			setBrandDomains(loadBrandSuccess.data?.allowedDomains);
		}
		if (getBrandFinanceDetailSuccess) {
			// const brandFinance = getBrandFinanceDetailSuccess.data;
			// if (brandFinance !== null) {
			// 	setBrandFinanceData(brandFinance);
			// }
		}
	}, [reload, userUpdate, setLoading, userUpdateError, updateAccessSuccess, selectedUser, updateAccess, resetUser, userCreate, userCreateError, updateUserMapping, brandId, updateUserMappingSuccess, createUserMappingSuccess, createUserMapping, updateBrandSuccess, loadBrandSuccess, resetBrand, getBrandFinanceDetailSuccess, watch, reset, onUpdateBrand]);

	const onSubmit = useCallback((values) => {
		// check domain
		if (brandDomains.length > 0) {
			const getDomainValidation = getMailDomain(email, brandDomains);
			if (getDomainValidation) {

				setLoading(true);
				values.email = email;
				if (selectedUser) {
					delete values.isAdmin;
					delete values.isChiefAdmin;
					// if (selectedUser.isFirstUser) {
					// let userData: any;
					// let brandStatus: any;
					// userData = {
					// 	email: values.email,
					// 	firstName: values.firstName,
					// 	lastName: values.lastName,
					// 	phone: values.phone,
					// 	position: values.position
					// }
					// if (brandFinanceData) {
					// 	if (isBrandComplete(brandData, userData, brandFinanceData)) {
					// 		brandStatus = BrandStatus.pending;
					// 	} else {

					// 		brandStatus = BrandStatus.incomplete;
					// 	}
					// }

					// onUpdateBrand({ id: brandId, brandStatus: brandStatus });
					// }
					updateUser(values);
				} else {
					if (brandData?.data?.length !== 0) {
						toast.warning('This user has already been created', {
							position: toast.POSITION.BOTTOM_RIGHT,
							className: 'foo-bar'
						});
						setLoading(false);
					} else {
						delete values.isAdmin;
						delete values.isChiefAdmin;
						values.userGroup = UserGroups.user
						values.telephone = values.phone;
						delete values.phone;
						values.userData = getUserRole(brandId);
						values.tab = "Brand Users";
						values.brandId = brandId;
						values.toUserRole = watch('isChiefAdmin') === true ? UserGroups.chief_brand_admin : watch('isAdmin') === true ? UserGroups.brand_admin : UserGroups.user;
						createUser(values);
					}

				}
			} else {
				toast.error('Invalid brand domain. please check your e-mail address domain with brand domains', {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'foo-bar'
				});
			}
		} else {

			setLoading(true);
			values.email = email;
			if (selectedUser) {
				delete values.isAdmin;
				delete values.isChiefAdmin;
				updateUser(values);
			} else {
				if (brandData?.data?.length !== 0) {
					toast.warning('This user has already been created', {
						position: toast.POSITION.BOTTOM_RIGHT,
						className: 'foo-bar'
					});
					setLoading(false);
				} else {
					delete values.isAdmin;
					delete values.isChiefAdmin;
					values.userGroup = UserGroups.user
					values.telephone = values.phone;
					delete values.phone;
					values.userData = getUserRole(brandId);
					values.tab = "Brand Users";
					values.brandId = brandId;
					values.toUserRole = watch('isChiefAdmin') === true ? UserGroups.chief_brand_admin : watch('isAdmin') === true ? UserGroups.brand_admin : UserGroups.user;
					createUser(values);
				}

			}
		}


	}, [email, brandDomains, selectedUser, updateUser, brandData?.data?.length, createUser, brandId, watch]);

	async function fetchUserList(email) {
		const encodedString = encodeURIComponent(email);
		const param = email === '' ? null : encodedString;
		return fetch(`${process.env.REACT_APP_API_URL}user-sv/users/search?search=${param}`)
			.then((response: any) => response.json())
			.then((body) =>
				// {
				// setBrandData(body?.data);
				body.data.map((user) => ({
					label: user.email,
					value: user.email,
					user
				}))
				// }
			);
	}

	////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		const encodedString = encodeURIComponent(email);
		const param = email === '' ? null : encodedString;
		const data = fetch(`${process.env.REACT_APP_API_URL}user-sv/users/search?search=${param}`)
			.then((response: any) => response.json())
			.then((body) => {
				setBrandData(body);
				body.data.map((user) => ({
					label: user.email,
					value: user.email,
					user
				}))
			}


			)


	}, [email]);

	return (
		<>
			{/* <Row>
				<Col xs={12}><div className="content-title">{title}</div></Col>
			</Row> */}
			<Container>
				<div className="jumbotron" >
					<Form onSubmit={handleSubmit(onSubmit)}>
						<Row>
							{isOnlyBrandAdmin(brandId) && (
								<div style={{ width: '89%', margin: '0 auto' }}>
									<p style={{ marginBottom: '1rem' }}>
										If you require this user to have all brand permissions (including all properties),
										please tick the <b>'Set as admin'</b> option when creating the user.
									</p>
									<p style={{ flexWrap: 'wrap', display: 'flex', marginBottom: '1rem' }}>
										All brand users will automatically be restricted to brand 'read' permissions. You will be able to provide them
										with 'write' permissions via the
										<OverlayTrigger
											placement="top"
											delay={overlayTriggerDelay}
											overlay={<Tooltip id="tooltip-table-top">Permission</Tooltip>}
										>
											<img className="iconSizes" src={Permission} alt="Permission icon" />
										</OverlayTrigger> icon in the user list once you have created them.
									</p>
									<p style={{ marginBottom: '1rem' }}>
										<b>
											Please note: For users you wish to create with only specific property permissions,
											please create them in the Property Management section.
										</b>
									</p>
								</div>
							)}

							<Col md={10} style={{ marginLeft: '-200px' }}>
								<Row className='formSpace'>
									<Col md={6} style={{ textAlign: 'right' }}>
										<Form.Label><span className='requiredMark'>*</span> Email</Form.Label>
									</Col>
									<Col md={6} style={{ textAlign: 'left' }}>
										<Controller
											name="email"
											control={control}
											defaultValue={selectedUser?.email}
											rules={{
												required: email ? false : true
											}}
											render={({ field }) =>

												<DebounceSearch
													value={email}
													placeholder="search users"
													fetchOptions={fetchUserList}
													onChange={(newValue) => {
														setEmail(newValue);
														setValue("firstName", "");
														setValue("lastName", "");
													}}
													disabled={selectedUser ? true : false}
													onSelect={(newValue, options) => {
														setEmail(newValue);
														setValue("firstName", options.user.firstName);
														setValue("lastName", options.user.lastName);
													}}
													style={{
														width: '100%',
													}}

													containerClass={`${errors.email ? 'is-invalid' : ''}`}
												/>
											}
										/>
										{errors.email && <div className="invalid-feedback-custom">Please input email!</div>}
									</Col>
								</Row>
								<Row className='formSpace'>
									<Col md={6} style={{ textAlign: 'right' }}>
										<Form.Label><span className='requiredMark'>*</span> First name</Form.Label>
									</Col>
									<Col md={6} style={{ textAlign: 'left' }}>
										<Form.Control className={`${errors.firstName ? 'is-invalid' : ''}`} defaultValue={selectedUser?.firstName} {...register("firstName", { required: true })} />
										<div className="invalid-feedback">Please input first name!</div>
									</Col>
								</Row>
								<Row className='formSpace'>
									<Col md={6} style={{ textAlign: 'right' }}>
										<Form.Label> <span className='requiredMark'>*</span> Last name</Form.Label>
									</Col>
									<Col md={6} style={{ textAlign: 'left' }}>
										<Form.Control className={`${errors.lastName ? 'is-invalid' : ''}`} defaultValue={selectedUser?.lastName} {...register("lastName", { required: true })} />
										<div className="invalid-feedback">Please input last name!</div>
									</Col>
								</Row>
								{((group === UserGroups.brand_admin || getLoggedUserType() === UserGroups.super_admin) && data.length >= 1) && <OverlayTrigger
									placement="top"
									delay={overlayTriggerDelay}
									overlay={<Tooltip id='tooltip-table-top' style={toolTipStyle}>Are you sure you wish to provide Brand User permissions? This will give this user all permissions across the brand and all properties.' If not, please create the user and manage permissions in the User Management table</Tooltip>}
								>
									<Row className='formSpace'>
										<Col md={6} style={{ textAlign: 'right' }}>
										</Col>
										<Col md={6} style={{ textAlign: 'left' }}>
											<Form.Check disabled={brandId && selectedUser?.isFirstUser && getLoggedUserType() !== UserGroups.super_admin} type="checkbox" label="Set as admin" {...register("isAdmin", { required: false })} />
										</Col>
									</Row>
								</OverlayTrigger>}

								{(getLoggedUserType() === UserGroups.super_admin && selectedBrand.brandStatus === BrandStatus.not_started && data.length === 0) &&
									//  <OverlayTrigger
									// 	placement="top"
									// 	delay={overlayTriggerDelay}
									// 	overlay={<Tooltip id='tooltip-table-top' style={toolTipStyle}>Are you sure you wish to provide Brand User permissions? This will give this user all permissions across the brand and all properties.' If not, please create the user and manage permissions in the User Management table</Tooltip>}
									// >
									<Row className='formSpace'>
										<Col md={6} style={{ textAlign: 'right' }}>
										</Col>
										<Col md={6} style={{ textAlign: 'left' }}>
											<Form.Check type="checkbox" label="Set as Chief Brand Admin" {...register("isChiefAdmin", { required: false })} />
										</Col>
									</Row>
									// </OverlayTrigger>
								}

								<Row className='formSpace'>
									<Col md={6} style={{ textAlign: 'right' }}>
									</Col>
									<Col md={6} style={{ textAlign: 'left' }}>
										{!loading && canBrandModuleWrite(brandId, UserAccess.brand_access.users.code) &&
											<Button type="submit" className='mr-3' id='approve-button' style={formButton} >Create</Button>

										}
										{!loading &&
											<Button id='edit-button' style={formButton} onClick={() => { close(); }}>Close</Button>
										}
										{
											loading && <div style={{ textAlign: 'center' }}><Spinner animation="border" variant="warning" /></div>
										}
									</Col>
								</Row>
							</Col>
							<Col md={1}></Col>
						</Row>
					</Form>
				</div>
			</Container>

		</>

	)
}

export default BrandUserForm;