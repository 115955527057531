import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Row, Col, Button, Modal, Form } from 'react-bootstrap';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { formButton } from '../../../common/components-style';

import { BrandRegisterFormInput } from '../../../common/interfaces';
import { useForm, Controller } from "react-hook-form";
import { Select } from "antd";
import domain from '../../../common/genericDomain.json';
const { Option } = Select;



const NewBrand: React.FC<any> = ({ showModal, list }): JSX.Element => {

    const [brandId, setBrandId] = useState<String>("");
    const [brandData, setBrandData] = useState<any>();
    const [companyList, setCompanyList] = useState<any>([]);
    const [brandModelShow, setBrandModelShow] = useState<boolean>(showModal);



    const [accommodationTypeList, setAccommodationTypeList] = useState<any>([]);
    const {
        register,

        handleSubmit,
        formState: { errors },
        control,

    } = useForm<BrandRegisterFormInput>();

    const { getBrandListNewVersion, onCreateBrand, onGetPartnerList } = useStoreActions<any>((actions) => ({
        getBrandListNewVersion: actions.brand.getBrandListNewVersion,
        onGetPartnerList: actions.partner.onGetPartnerList,
        onCreateBrand: actions.brand.onCreateBrand,

    }));

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const { partnerListSuccess, partnerListError, createBrandSuccess, createBrandError } = useStoreState<any>((state) => ({
        partnerListSuccess: state.partner.partnerListSuccess,
        partnerListError: state.partner.partnerListError,
        createBrandSuccess: state.brand.createBrandSuccess,
        createBrandError: state.brand.createBrandError
    }));

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const callBrandList = useCallback(() => {
        const payload = {
            brand_id: '',
            brand_status: '',
            property_status: '',
        }
        getBrandListNewVersion(payload)
    }, [getBrandListNewVersion])


    //////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (list) {
            // const locationListTemp: any = [{
            //     _id: 'hotel',
            //     name: "Hotel",
            // },
            // {
            //     _id: 'serviced_apartments',
            //     name: "Extended Stay/Apart hotel",
            // },
            // {
            //     _id: 'both',
            //     name: "Both",
            // },
            // ];

            const typeOfPartner = list.map((val: any, key: number) => {
                return (
                    <Option key={key} data={val} value={`${val._id}`} >{val.name}</Option>
                )
            });

            setAccommodationTypeList(typeOfPartner);
        }

    }, [list]);

    useEffect(() => {
        onGetPartnerList();
    }, [onGetPartnerList])

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const onSubmit = (values) => {
        const domainList = domain;
        const isAllowed = values?.allowedDomains.some(domain => domainList.includes(domain));

        if (isAllowed) {
            toast.error("Generic domains like Gmail, Hotmail, Yahoo, etc., are not allowed.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });

        } else {
            const data = {
                brandStatus: 'not_started',
                accommodationType: values.accommodationType,
                name: values.name,
                parentAccommodationCompany: values.parentAccommodationCompany,
                allowedDomains: values.allowedDomains
                    ? values.allowedDomains.toString()
                    : ""
            }
            onCreateBrand(data);
        }

    };

    ////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (createBrandSuccess) {
            setBrandModelShow(false);
            toast.success("Brand created successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });
            setBrandId(createBrandSuccess?.data?._id)
            setBrandData(createBrandSuccess?.data)
            callBrandList();
        }

        if (createBrandError) {
            toast.error(createBrandError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });
        }

    }, [callBrandList, createBrandError, createBrandSuccess]);

    //////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (partnerListSuccess) {
            let companyListTemp: any = [{ _id: '0', name: 'None' }]
            const data = partnerListSuccess.data.filter((user) => user.partnerType.name === 'Parent Accommodation Company');
            data.map(ele => {
                companyListTemp.push(ele);
            })

            const typeOfPartner = companyListTemp.map((val: any, key: number) => {
                return (
                    <Option key={key} data={val} value={`${val.name}`} >{val.name}</Option>
                )
            });

            setCompanyList(typeOfPartner);
        }

    }, [partnerListSuccess]);
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <>

            <Modal
                show={brandModelShow}
                onHide={() => setBrandModelShow(false)}
                size="lg"
                // height='100%'
                aria-labelledby="contained-modal-title-vcenter"
                centered
                style={{ height: '600px' }}
            >
                {/* <Modal.Dialog style={{ height: '70vh' }}> */}
                <Modal.Header closeButton>

                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col md={1}></Col>
                            <Col md={10}>
                                <Row className="formSpace">
                                    <Col md={6} style={{ textAlign: "right" }}>
                                        <Form.Label>
                                            {<span className="requiredMark">*</span>}{" "}
                                            Brand name
                                        </Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: "left" }}>

                                        {
                                            <Form.Control
                                                className={`${errors.name ? "is-invalid" : ""}`}

                                                {...register("name", { required: true })}

                                            />
                                        }
                                        {
                                            <div className="invalid-feedback">
                                                Please input brand name!
                                            </div>
                                        }
                                    </Col>
                                </Row>
                                <Row className='formSpace'  >
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{<span className='requiredMark'>*</span>} Accommodation types operated</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>

                                        {
                                            <Controller
                                                name="accommodationType"
                                                defaultValue={brandData?.accommodationType}
                                                control={control}
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field }) =>
                                                    <Select
                                                        id='accommodationType'
                                                        // getPopupContainer={() => document.body}
                                                        dropdownStyle={{ zIndex: 1060 }}
                                                        defaultValue={brandData?.accommodationType} className={`${errors.accommodationType ? 'is-invalid' : ''}`} style={{ width: '100%' }}
                                                        onChange={(value, option) => {
                                                            field.onChange(value)
                                                        }}
                                                        // onSelect={onTypeSelect}
                                                        showSearch
                                                    >
                                                        {accommodationTypeList}

                                                    </Select>
                                                }
                                            />}
                                        {<div className="invalid-feedback">Please select a accommodation type!</div>}
                                    </Col>
                                </Row>

                                <Row className='formSpace' >
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{<span className='requiredMark'>*</span>} Parent Accommodation Company</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {/* {!editEnable && <p className="lead">{brandData?.parentAccommodationCompany}</p>} */}
                                        {
                                            <Controller
                                                name="parentAccommodationCompany"
                                                defaultValue={brandData?.parentAccommodationCompany}
                                                control={control}
                                                rules={{
                                                    required: true,
                                                }}

                                                render={({ field }) =>
                                                    <Select
                                                        id='parentAccommodationCompany'
                                                        dropdownStyle={{ zIndex: 1060 }}
                                                        defaultValue={brandData?.parentAccommodationCompany} className={`${errors.parentAccommodationCompany ? 'is-invalid' : ''}`} style={{ width: '100%' }}
                                                        onChange={(value, option) => {
                                                            field.onChange(value)
                                                        }}
                                                        // onSelect={onTypeSelect}
                                                        showSearch>
                                                        {companyList}

                                                    </Select>
                                                }
                                            />}
                                        {<div className="invalid-feedback">Please select a parent Accommodation Company!</div>}
                                    </Col>
                                </Row>

                                <Row className="formSpace" style={{ marginBottom: '130px' }}>
                                    <Col md={6} style={{ textAlign: "right" }}>
                                        <Form.Label>
                                            {<span className="requiredMark">*</span>}{" "}
                                            Brand domains
                                        </Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: "left" }}>

                                        {(
                                            <Controller
                                                name="allowedDomains"
                                                defaultValue={brandData?.allowedDomains}
                                                control={control}
                                                rules={{
                                                    required: true,
                                                    pattern: {
                                                        value:
                                                            /((ftp|http|https):\/\/)?([a-z0-9]+\.)?([a-z0-9][a-z0-9-]*)?((\.[a-z]{2,6})|(\.[a-z]{2,6})(\.[a-z]{2,6}))$/,
                                                        message: "Please enter valid domain name",
                                                    },
                                                }}
                                                render={({ field }) => (
                                                    <Select
                                                        id='allowedDomains'
                                                        dropdownStyle={{ zIndex: 1060 }}
                                                        defaultValue={brandData?.allowedDomains}
                                                        onChange={(value) => field.onChange(value)}
                                                        mode="tags"
                                                        className={`${errors.allowedDomains ? "is-invalid" : ""
                                                            }`}
                                                        options={[]}
                                                        style={{ width: "100%" }}

                                                    ></Select>
                                                )}
                                            />
                                        )}
                                        {
                                            <div className="invalid-feedback">
                                                Please enter valid domain name!
                                            </div>
                                        }
                                    </Col>
                                </Row>
                                <Row className="formSpace " >
                                    <Col md={6} style={{ textAlign: "right" }}></Col>
                                    <Col md={6} style={{ textAlign: "left" }}>
                                        {

                                            <Button
                                                id="approve-button"
                                                style={formButton}
                                                type="submit"
                                            >
                                                Save
                                            </Button>
                                        }
                                        {
                                            <Button

                                                id="edit-button"
                                                style={formButton}
                                                onClick={() => setBrandModelShow(false)}
                                            >
                                                Close
                                            </Button>
                                        }

                                    </Col>
                                </Row>
                            </Col>
                            <Col md={1}></Col>
                        </Row>
                    </Form>
                    {/* <UserView selectedUser={selectedUser} /> */}
                </Modal.Body>
                {/* </Modal.Dialog> */}
            </Modal>







        </>
    )
};

export default NewBrand;