/* eslint-disable import/no-anonymous-default-export */
import { action, thunk } from 'easy-peasy';
import { call } from '../common/axios';
import { TBookingCorporateForm } from '../components/admin/booking-report/bookings/corporate/BookingCorporate';
import { TBookingBookerForm } from '../components/admin/booking-report/bookings/booker/BookingBooker';
import { TBookingTravelerForm } from '../components/admin/booking-report/bookings/traveler/BookingTraveler';
import { TBrandAndPropertyForm } from '../components/admin/booking-report/bookings/brand-and-property/brandAndProperty';
import { TBookingSuperAdminForm } from '../components/admin/booking-report/bookings/super-admin/BookingSuperAdmin';

export default {
	getAvailabilityAndRateByLocationSuccess: null,
	getAvailabilityAndRateByLocationError: null,
	getAvailabilityAndRateByPropertyIdAndInventoryIdSuccess: null,
	getAvailabilityAndRateByPropertyIdAndInventoryIdError: null,
	getFreshAvailabilityAndRateByPropertyIdAndInventoryIdSuccess: null,
	getFreshAvailabilityAndRateByPropertyIdAndInventoryIdError: null,
	sendContactMailSuccess: null,
	sendContactMailError: null,
	getAvailabilityAndRateByOtherLocationSuccess: null,
	getAvailabilityAndRateByOtherLocationError: null,
	getBookingRequestResponseSuccess: null,
	getBookingRequestResponseError: null,
	getRequestBookingListByBookerSuccess: null,
	getRequestBookingListByBookerError: null,
	getMessageThreadByIdSuccess: null,
	getMessageThreadByIdError: null,
	getUpdateMessageThreadSuccess: null,
	getUpdateMessageThreadError: null,
	getRequestBookingListByBrandSuccess: null,
	getRequestBookingListByBrandError: null,
	getUpdateRequestStatusSuccess: null,
	getUpdateRequestStatusError: null,
	getLocationListByBrandSuccess: null,
	getLocationListByBrandError: null,
	getPropertyListByBrandSuccess: null,
	getPropertyListByBrandError: null,
	getRateSuccess: null,
	getManagementReport: null,
	getReset: false,
	getSecureAvailabilityAndRateSuccess: null,
	getSecureAvailabilityAndRateError: null,
	getAddTravelerToSecureRateKeySuccess: null,
	getAddTravelerToSecureRateKeyError: null,
	getCreateLiveBookingSuccess: null,
	getCreateLiveBookingError: null,
	getBookingByIdSuccess: null,
	getBookingByIdError: null,
	getSuperAdminRequestBookingSuccess: null,
	getSuperAdminRequestBookingError: null,

	/**
	 * booing report
	 */

	getCorporateAdminReportSuccess: null,
	getCorporateAdminReportError: null,
	getBookerAdminReportSuccess: null,
	getBookerAdminReportError: null,
	getTravelerAdminReportSuccess: null,
	getTravelerAdminReportError: null,
	getBrandAndPropertyAdminReportSuccess: null,
	getBrandAndPropertyAdminReportError: null,
	getSuperAdminReportSuccess: null,
	getSuperAdminReportError: null,
	getBookingStatusSuccess: null,
	getBookingStatusError: null,
	getBookingCancelWithChargeSuccess: null,
	getBookingCancelWithChargeError: null,

	getAmendBookingRequestSuccess: null,
	getAmendBookingRequestError: null,

	getAcceptAmendBookingRequestSuccess: null,
	getAcceptAmendBookingRequestError: null,

	sendOperatorBookingConfirmationEmailSuccess: null,
	sendOperatorBookingConfirmationEmailError: null,

	sendBookerBookingConfirmationEmailSuccess: null,
	sendBookerBookingConfirmationEmailError: null,

	sendTravelerBookingConfirmationEmailSuccess: null,
	sendTravelerBookingConfirmationEmailError: null,

	sendNoAcceptAmendBookingEmailSuccess: null,
	sendNoAcceptAmendBookingEmailError: null,

	sendAcceptAmendBookingEmailSuccess: null,
	sendAcceptAmendBookingEmailError: null,

	sendCancelBookingRequestEmailSuccess: null,
	sendCancelBookingRequestEmailError: null,

	sendAmendBookingRequestEmailSuccess: null,
	sendAmendBookingRequestEmailError: null,

	sendCancelledBookingNoChargeEmailSuccess: null,
	sendCancelledBookingNoChargeEmailError: null,

	sendCancelledBookingWithChargeEmailSuccess: null,
	sendCancelledBookingWithChargeEmailError: null,
	amendBookingCancelWithChargeSuccess: null,
	amendBookingCancelWithChargeError: null,
	reinstateBookingRequestSuccess: null,
	reinstateBookingRequestError: null,

	createTravelerOptionSuccess: null,
	createTravelerOptionError: null,

	getTravelerOptionByTravelerIdSuccess: null,
	getTravelerOptionByTravelerIdError: null,

	getTravelerOptionByBookerIdSuccess: null,
	getTravelerOptionByBookerIdError: null,

	updateTravelerOptionMessageThreadSuccess: null,
	updateTravelerOptionMessageThreadError: null,

	deleteTravelerOptionBySelectedOptionsIdSuccess: null,

	deleteTravelerOptionBySelectedOptionsIdError: null,
	keepOnlySelectedOptionSuccess: null,
	keepOnlySelectedOptionError: null,

	createReportSuccess: null,
	createReportError: null,
	getReportByIdSuccess: null,
	getReportByIdError: null,

	reportByProgrammeIdSuccess: null,
	reportByProgrammeIdLoading: false,
	reportByProgrammeIdError: null,
	getReportListSuccess: null,
	getReportListLoading: false,
	getReportListError: null,


	// update report
	updateReportSuccess: null,
	updateReportLoading: null,
	updateReportError: null,

	// actions
	setRateSuccess: action((state, payload) => ({ getRateSuccess: payload })),
	setManagementReport: action((state, payload) => ({ getManagementReport: payload })),
	setSecureAvailabilityAndRateSuccess: action((state, payload) => ({ getSecureAvailabilityAndRateSuccess: payload })),
	setSecureAvailabilityAndRateError: action((state, payload) => ({ getSecureAvailabilityAndRateError: payload })),
	setAddTravelerToSecureRateKeySuccess: action((state, payload) => ({ getAddTravelerToSecureRateKeySuccess: payload })),
	setAddTravelerToSecureRateKeyError: action((state, payload) => ({ getAddTravelerToSecureRateKeyError: payload })),
	setCreateLiveBookingSuccess: action((state, payload) => ({ getCreateLiveBookingSuccess: payload })),
	setCreateLiveBookingError: action((state, payload) => ({ getCreateLiveBookingError: payload })),
	setBookingByIdSuccess: action((state, payload) => ({ getBookingByIdSuccess: payload })),
	setBookingByIdError: action((state, payload) => ({ getBookingByIdError: payload })),
	setGetAvailabilityAndRateByLocation: action((state, payload) => ({ getAvailabilityAndRateByLocationSuccess: payload })),
	setGetAvailabilityAndRateByLocationError: action((state, payload) => ({ getAvailabilityAndRateByLocationError: payload })),
	setGetAvailabilityAndRateByPropertyIdAndInventoryId: action((state, payload) => ({ getAvailabilityAndRateByPropertyIdAndInventoryIdSuccess: payload })),
	setGetAvailabilityAndRateByPropertyIdAndInventoryIdError: action((state, payload) => ({ getAvailabilityAndRateByPropertyIdAndInventoryIdError: payload })),
	setGetFreshAvailabilityAndRateByPropertyIdAndInventoryId: action((state, payload) => ({ getFreshAvailabilityAndRateByPropertyIdAndInventoryIdSuccess: payload })),
	setGetFreshAvailabilityAndRateByPropertyIdAndInventoryIdError: action((state, payload) => ({ getFreshAvailabilityAndRateByPropertyIdAndInventoryIdError: payload })),


	setSendContactMail: action((state, payload) => ({ sendContactMailSuccess: payload })),
	setSendContactMailError: action((state, payload) => ({ sendContactMailError: payload })),
	setGetAvailabilityAndRateOtherLocation: action((state, payload) => ({ getAvailabilityAndRateByOtherLocationSuccess: payload })),
	setGetAvailabilityAndRateByOtherLocationError: action((state, payload) => ({ getAvailabilityAndRateByOtherLocationError: payload })),
	setBookingRequestResponseSuccess: action((state, payload) => ({ getBookingRequestResponseSuccess: payload })),
	setBookingRequestResponseError: action((state, payload) => ({ getBookingRequestResponseError: payload })),
	setRequestBookingListByBookerSuccess: action((state, payload) => ({ getRequestBookingListByBookerSuccess: payload })),
	setRequestBookingListByBookerError: action((state, payload) => ({ getRequestBookingListByBookerError: payload })),
	setMessageThreadByIdSuccess: action((state, payload) => ({ getMessageThreadByIdSuccess: payload })),
	setMessageThreadByIdError: action((state, payload) => ({ getMessageThreadByIdError: payload })),
	setUpdateMessageThreadSuccess: action((state, payload) => ({ getUpdateMessageThreadSuccess: payload })),
	setUpdateMessageThreadError: action((state, payload) => ({ getUpdateMessageThreadError: payload })),
	setRequestBookingListByBrandSuccess: action((state, payload) => ({ getRequestBookingListByBrandSuccess: payload })),
	setRequestBookingListByBrandError: action((state, payload) => ({ getRequestBookingListByBrandError: payload })),
	setUpdateRequestStatusSuccess: action((state, payload) => ({ getUpdateRequestStatusSuccess: payload })),
	setUpdateRequestStatusError: action((state, payload) => ({ getUpdateRequestStatusError: payload })),
	setGetLocationListByBrandSuccess: action((state, payload) => ({ getLocationListByBrandSuccess: payload })),
	setGetLocationListByBrandError: action((state, payload) => ({ getLocationListByBrandError: payload })),
	setGetPropertyListByBrandSuccess: action((state, payload) => ({ getPropertyListByBrandSuccess: payload })),
	setGetPropertyListByBrandError: action((state, payload) => ({ getPropertyListByBrandError: payload })),
	setReset: action((state, payload) => ({ getReset: payload })),
	setSuperAdminReportSuccess: action((state, payload) => ({ getSuperAdminReportSuccess: payload })),
	setSuperAdminReportError: action((state, payload) => ({ getSuperAdminReportError: payload })),

	setCreateTravelerOptionSuccess: action((state, payload) => ({ createTravelerOptionSuccess: payload })),
	setCreateTravelerOptionError: action((state, payload) => ({ createTravelerOptionError: payload })),

	setGetTravelerOptionByTravelerIdSuccess: action((state, payload) => ({ getTravelerOptionByTravelerIdSuccess: payload })),
	setGetTravelerOptionByTravelerIdError: action((state, payload) => ({ getTravelerOptionByTravelerIdError: payload })),

	setDeleteTravelerOptionBySelectedOptionsIdSuccess: action((state, payload) => ({ deleteTravelerOptionBySelectedOptionsIdSuccess: payload })),
	setDeleteTravelerOptionBySelectedOptionsIdError: action((state, payload) => ({ deleteTravelerOptionBySelectedOptionsIdError: payload })),

	setGetTravelerOptionByBookerIdSuccess: action((state, payload) => ({ getTravelerOptionByBookerIdSuccess: payload })),
	setGetTravelerOptionByBookerIdError: action((state, payload) => ({ getTravelerOptionByBookerIdError: payload })),
	setUpdateTravelerOptionMessageThreadSuccess: action((state, payload) => ({ updateTravelerOptionMessageThreadSuccess: payload })),
	setUpdateTravelerOptionMessageThreadError: action((state, payload) => ({ updateTravelerOptionMessageThreadError: payload })),
	setKeepOnlySelectedOptionSuccess: action((state, payload) => ({ keepOnlySelectedOptionSuccess: payload })),
	setKeepOnlySelectedOptionError: action((state, payload) => ({ keepOnlySelectedOptionError: payload })),

	/**
	 * booking report
	 */
	setCorporateAdminReportSuccess: action((state, payload) => ({ getCorporateAdminReportSuccess: payload })),
	setCorporateAdminReportError: action((state, payload) => ({ getCorporateAdminReportError: payload })),
	setBookerReportSuccess: action((state, payload) => ({ getBookerAdminReportSuccess: payload })),
	setBookerReportError: action((state, payload) => ({ getBookerAdminReportError: payload })),
	setTravelerReportSuccess: action((state, payload) => ({ getTravelerAdminReportSuccess: payload })),
	setTravelerReportError: action((state, payload) => ({ getTravelerAdminReportError: payload })),
	setBrandAndPropertyAdminReportSuccess: action((state, payload) => ({ getBrandAndPropertyAdminReportSuccess: payload })),
	setBrandAndPropertyAdminReportError: action((state, payload) => ({ getBrandAndPropertyAdminReportError: payload })),
	setSuperAdminRequestBookingSuccess: action((state, payload) => ({ getSuperAdminRequestBookingSuccess: payload })),
	setSuperAdminRequestBookingError: action((state, payload) => ({ getSuperAdminRequestBookingError: payload })),
	setBookingStatusSuccess: action((state, payload) => ({ getBookingStatusSuccess: payload })),
	setBookingStatusError: action((state, payload) => ({ getBookingStatusError: payload })),
	setBookingCancelWithChargeSuccess: action((state, payload) => ({ getBookingCancelWithChargeSuccess: payload })),
	setBookingCancelWithChargeError: action((state, payload) => ({ getBookingCancelWithChargeError: payload })),


	setAmendBookingRequestSuccess: action((state, payload) => ({ getAmendBookingRequestSuccess: payload })),
	setAmendBookingRequestError: action((state, payload) => ({ getAmendBookingRequestError: payload })),

	setAcceptAmendBookingRequestSuccess: action((state, payload) => ({ getAcceptAmendBookingRequestSuccess: payload })),
	setAcceptAmendBookingRequestError: action((state, payload) => ({ getAcceptAmendBookingRequestError: payload })),

	setSendNoAcceptAmendBookingEmailSuccess: action((state, payload) => ({ sendNoAcceptAmendBookingEmailSuccess: payload })),
	setSendNoAcceptAmendBookingEmailError: action((state, payload) => ({ sendNoAcceptAmendBookingEmailError: payload })),

	setSendAcceptAmendBookingEmailSuccess: action((state, payload) => ({ sendAcceptAmendBookingEmailSuccess: payload })),
	setSendAcceptAmendBookingEmailError: action((state, payload) => ({ sendAcceptAmendBookingEmailError: payload })),

	setSendCancelBookingRequestEmailSuccess: action((state, payload) => ({ sendCancelBookingRequestEmailSuccess: payload })),
	setSendCancelBookingRequestEmailError: action((state, payload) => ({ sendCancelBookingRequestEmailError: payload })),


	setSendOperatorBookingConfirmationEmailSuccess: action((state, payload) => ({ sendOperatorBookingConfirmationEmailSuccess: payload })),
	setSendOperatorBookingConfirmationEmailError: action((state, payload) => ({ sendOperatorBookingConfirmationEmailError: payload })),

	setSendBookerBookingConfirmationEmailSuccess: action((state, payload) => ({ sendBookerBookingConfirmationEmailSuccess: payload })),
	setSendBookerBookingConfirmationEmailError: action((state, payload) => ({ sendBookerBookingConfirmationEmailError: payload })),

	setSendTravelerBookingConfirmationEmailSuccess: action((state, payload) => ({ sendTravelerBookingConfirmationEmailSuccess: payload })),
	setSendTravelerBookingConfirmationEmailError: action((state, payload) => ({ sendTravelerBookingConfirmationEmailError: payload })),

	setSendAmendBookingRequestEmailSuccess: action((state, payload) => ({ sendAmendBookingRequestEmailSuccess: payload })),
	setSendAmendBookingRequestEmailError: action((state, payload) => ({ sendAmendBookingRequestEmailError: payload })),

	setSendCancelledBookingNoChargeEmailSuccess: action((state, payload) => ({ sendCancelledBookingNoChargeEmailSuccess: payload })),
	setSendCancelledBookingNoChargeEmailError: action((state, payload) => ({ sendCancelledBookingNoChargeEmailError: payload })),

	setAmendBookingCancelWithChargeSuccess: action((state, payload) => ({ amendBookingCancelWithChargeSuccess: payload })),
	setAmendBookingCancelWithChargeError: action((state, payload) => ({ amendBookingCancelWithChargeError: payload })),


	setReinstateBookingRequestSuccess: action((state, payload) => ({ reinstateBookingRequestSuccess: payload })),
	setReinstateBookingRequestError: action((state, payload) => ({ reinstateBookingRequestError: payload })),

	// Report
	setCreateReport: action((state, payload) => ({ createReportSuccess: payload })),
	setCreateReportError: action((state, payload) => ({ createReportError: payload })),
	setReportByIdSuccess: action((state, payload) => ({ getReportByIdSuccess: payload })),
	setReportByIdError: action((state, payload) => ({ getReportByIdError: payload })),


	setReportByProgrammeIdSuccess: action((state, payload) => ({ reportByProgrammeIdSuccess: payload })),
	setReportByProgrammeIdLoading: action((state, payload) => ({ reportByProgrammeIdLoading: payload })),
	setReportByProgrammeIdError: action((state, payload) => ({ reportByProgrammeIdError: payload })),
	setReportList: action((state, payload) => ({ getReportListSuccess: payload })),
	setReportListLording: action((state, payload) => ({ getReportListLoading: payload })),
	setReportListError: action((state, payload) => ({ getReportListError: payload })),

	// update report
	setUpdateReportSuccess: action((state, payload) => ({ updateReportSuccess: payload })),
	setUpdateReportLoading: action((state, payload) => ({ updateReportLoading: payload })),
	setUpdateReportError: action((state, payload) => ({ updateReportError: payload })),

	bookingReset: thunk(async (actions: any, payload: any) => {
		actions.setReset(true);
	}),

	// send booking confirmation email
	sendAcceptAmendBookingEmail: thunk(async (actions: any, payload: any) => {
		try {
			const result = await call('post', `/booking-sv/booking/sendAcceptAmendBookingEmail`, payload, null);
			actions.setSendAcceptAmendBookingEmailSuccess(result.data);
		} catch (error: any) {
			actions.setSendAcceptAmendBookingEmailError(error.response.data);
		}
	}),


	// send booking confirmation email
	sendCancelBookingRequestEmail: thunk(async (actions: any, payload: any) => {
		try {
			const result = await call('post', `/booking-sv/booking/sendCancelBookingRequestEmail`, payload, null);
			actions.setSendCancelBookingRequestEmailSuccess(result.data);
		} catch (error: any) {
			actions.setSendCancelBookingRequestEmailError(error.response.data);
		}
	}),


	// send booking confirmation email
	sendNoAcceptAmendBookingEmail: thunk(async (actions: any, payload: any) => {
		try {
			const result = await call('post', `/booking-sv/booking/sendNoAcceptAmendBookingEmail`, payload, null);
			actions.setSendNoAcceptAmendBookingEmailSuccess(result.data);
		} catch (error: any) {
			actions.setSendNoAcceptAmendBookingEmailError(error.response.data);
		}
	}),


	sendCancelledBookingNoChargeEmail: thunk(async (actions: any, payload: any) => {
		try {
			const result = await call('post', `/booking-sv/booking/sendCancelledBookingNoChargeEmail`, payload, null);
			actions.setSendCancelledBookingNoChargeEmailSuccess(result.data);
		} catch (error: any) {
			actions.setSendCancelledBookingNoChargeEmailError(error.response.data);
		}
	}),

	sendCancelledBookingWithChargeEmail: thunk(async (actions: any, payload: any) => {
		try {
			const result = await call('post', `/booking-sv/booking/sendCancelledBookingWithChargeEmail`, payload, null);
			actions.setSendCancelledBookingWithChargeEmailSuccess(result.data);
		} catch (error: any) {
			actions.setSendCancelledBookingWithChargeEmailError(error.response.data);
		}
	}),



	amendBookingCancelWithCharge: thunk(async (actions: any, payload: any) => {
		try {
			const result = await call('post', `/booking-sv/booking/amendBookingCancelWithCharge`, payload, null);
			actions.setAmendBookingCancelWithChargeSuccess(result.data);
		} catch (error: any) {
			actions.setAmendBookingCancelWithChargeError(error.response.data);
		}
	}),


	reinstateBookingRequest: thunk(async (actions: any, payload: any) => {
		try {
			const result = await call('post', `/booking-sv/booking/reinstateBookingRequest`, payload, null);
			actions.setReinstateBookingRequestSuccess(result.data);
		} catch (error: any) {
			actions.setReinstateBookingRequestError(error.response.data);
		}
	}),

	// create traveler option
	createTravelerOption: thunk(async (actions: any, payload: any) => {
		try {
			const result = await call('post', `/booking-sv/booking/createTravelerOption`, payload, null);
			actions.setCreateTravelerOptionSuccess(result.data);
		} catch (error: any) {
			actions.setCreateTravelerOptionError(error.response.data);
		}
	}),


	updateTravelerOptionMessageThread: thunk(async (actions: any, payload: any) => {
		try {
			const result = await call('patch', `/booking-sv/booking/updateTravelerOptionMessageThread`, payload, null);
			actions.setUpdateTravelerOptionMessageThreadSuccess(result.data);
		} catch (error: any) {
			actions.setUpdateTravelerOptionMessageThreadError(error.response.data);
		}
	}),



	deleteTravelerOption: thunk(async (actions: any, payload: any) => {
		try {
			const result = await call('delete', `/booking-sv/booking/deleteTravelerOption/${payload.id}`, payload, null);
			actions.setKeepOnlySelectedOptionSuccess(result.data);
		} catch (error: any) {
			actions.setKeepOnlySelectedOptionError(error.response.data);
		}
	}),

	// get traveler option
	getTravelerOptionByTravelerId: thunk(async (actions: any, payload: any) => {

		try {
			const result = await call('get', `/booking-sv/booking/getTravelerOptionByTravelerId/${payload.travelerId}`, payload, null);
			actions.setGetTravelerOptionByTravelerIdSuccess(result.data);
		} catch (error: any) {
			actions.setGetTravelerOptionByTravelerIdError(error.response.data);
		}
	}),

	// get booker option
	getTravelerOptionByBookerId: thunk(async (actions: any, payload: any) => {

		try {
			const result = await call('get', `/booking-sv/booking/getTravelerOptionByBookerId/${payload.bookerId}`, payload, null);
			actions.setGetTravelerOptionByBookerIdSuccess(result.data);
		} catch (error: any) {
			actions.setGetTravelerOptionByBookerIdError(error.response.data);
		}
	}),


	deleteTravelerOptionBySelectedOptionsId: thunk(async (actions: any, payload: any) => {
		try {
			const result = await call('delete', `/booking-sv/booking/deleteTravelerOptionBySelectedOptionsId`, payload, null);

			actions.setDeleteTravelerOptionBySelectedOptionsIdSuccess(result.data);
		} catch (error: any) {

			actions.setDeleteTravelerOptionBySelectedOptionsIdError(error.response.data);
		}
	}),

	// send booking confirmation email
	sendOperatorBookingConfirmationEmail: thunk(async (actions: any, payload: any) => {
		try {
			const result = await call('post', `/booking-sv/booking/sendOperatorBookingConfirmationEmail`, payload, null);
			actions.setSendOperatorBookingConfirmationEmailSuccess(result.data);
		} catch (error: any) {
			actions.setSendOperatorBookingConfirmationEmailError(error.response.data);
		}
	}),


	// send booking confirmation email
	sendBookerBookingConfirmationEmail: thunk(async (actions: any, payload: any) => {
		try {
			const result = await call('post', `/booking-sv/booking/sendBookerBookingConfirmationEmail`, payload, null);
			actions.setSendBookerBookingConfirmationEmailSuccess(result.data);
		} catch (error: any) {
			actions.setSendBookerBookingConfirmationEmailError(error.response.data);
		}
	}),


	// send booking confirmation email
	sendTravelerBookingConfirmationEmail: thunk(async (actions: any, payload: any) => {
		try {
			const result = await call('post', `/booking-sv/booking/sendTravelerBookingConfirmationEmail`, payload, null);
			actions.setSendTravelerBookingConfirmationEmailSuccess(result.data);
		} catch (error: any) {
			actions.setSendTravelerBookingConfirmationEmailError(error.response.data);
		}
	}),

	// send booking confirmation email
	sendAmendBookingRequestEmail: thunk(async (actions: any, payload: any) => {
		try {
			const result = await call('post', `/booking-sv/booking/sendAmendBookingRequestEmail`, payload, null);
			actions.setSendAmendBookingRequestEmailSuccess(result.data);
		} catch (error: any) {
			actions.setSendAmendBookingRequestEmailError(error.response.data);
		}
	}),


	getLocationListByBrand: thunk(async (actions: any, payload: any) => {
		try {
			const result = await call('get', '/booking-sv/booking/getLocationListByBrand', null, payload);
			actions.setGetLocationListByBrandSuccess(result.data);
		} catch (error: any) {
			actions.setGetLocationListByBrandError(error.response.data);
		}
	}),

	getPropertyListByBrand: thunk(async (actions: any, payload: any) => {
		try {
			const result = await call('get', '/booking-sv/booking/getPropertyListByBrand', null, payload);
			actions.setGetPropertyListByBrandSuccess(result.data);
		} catch (error: any) {
			actions.setGetPropertyListByBrandError(error.response.data);
		}
	}),

	// thunks
	getAvailabilityAndRateByLocation: thunk(async (actions: any, payload: any) => {
		try {
			const result = await call('get', '/booking-sv/booking/getAvailabilityAndRateByLocation', null, payload);
			actions.setGetAvailabilityAndRateByLocation(result.data);
		} catch (error: any) {
			actions.setGetAvailabilityAndRateByLocationError(error.response.data);
		}
	}),

	// thunks
	getAvailabilityAndRateByOtherLocation: thunk(async (actions: any, payload: any) => {
		try {
			const result = await call('get', '/booking-sv/booking/getAvailabilityAndRateByOtherLocation', null, payload);
			actions.setGetAvailabilityAndRateOtherLocation(result.data);
		} catch (error: any) {
			actions.setGetAvailabilityAndRateByOtherLocationError(error.response.data);
		}
	}),

	// thunks
	getAvailabilityAndRateByPropertyIdAndInventoryId: thunk(async (actions: any, payload: any) => {
		try {
			const result = await call('get', '/booking-sv/booking/getAvailabilityAndRateByPropertyIdAndInventoryId', null, payload);
			actions.setGetAvailabilityAndRateByPropertyIdAndInventoryId(result.data);
		} catch (error: any) {
			actions.setGetAvailabilityAndRateByPropertyIdAndInventoryIdError(error.response.data);
		}
	}),


	getFreshAvailabilityAndRateByPropertyIdAndInventoryId: thunk(async (actions: any, payload: any) => {
		try {
			const result = await call('get', '/booking-sv/booking/getFreshAvailabilityAndRateByPropertyIdAndInventoryId', null, payload);
			actions.setGetFreshAvailabilityAndRateByPropertyIdAndInventoryId(result.data);
		} catch (error: any) {
			actions.setGetFreshAvailabilityAndRateByPropertyIdAndInventoryIdError(error.response.data);
		}
	}),
	// send contact email
	onSendContactMail: thunk(async (actions: any, payload: any) => {
		try {
			const result = await call('post', `/booking-sv/booking/sendContact`, payload, null);
			actions.setSendContactMail(result.data);
		} catch (error: any) {
			actions.setSendContactMailError(error.response.data);
		}
	}),

	/////////// create booking request 
	createBookingRequest: thunk(async (actions: any, payload: any) => {
		try {
			const result = await call('post', `/booking-sv/booking/createRequest`, payload, null);
			actions.setBookingRequestResponseSuccess(result.data);
		} catch (error: any) {
			actions.setBookingRequestResponseError(error.response.data);
		}
	}),

	getRequestBookingListByBooker: thunk(async (actions: any, payload: any) => {
		try {
			const result = await call('get', `/booking-sv/booking/getRequestBookingListByBooker?clientId=${payload.clientId}&programmeId=${payload.programmeId}&requestUserId=${payload.requestUserId}`, null, null);
			actions.setRequestBookingListByBookerSuccess(result?.data);
		} catch (error: any) {
			actions.setRequestBookingListByBookerError(error?.response?.data);
		}
	}),

	getMessageThreadById: thunk(async (actions: any, payload: any) => {
		try {
			const result = await call('get', `/booking-sv/booking/getMessageThreadById?_id=${payload._id}&_reqGroupId=${payload._reqGroupId}&_reqBrandId=${payload._reqBrandId}&_reqPropertyId=${payload._reqPropertyId}&_reqInventoryId=${payload._reqInventoryId}`, null, null);
			actions.setMessageThreadByIdSuccess(result?.data);
		} catch (error: any) {
			actions.setMessageThreadByIdError(error?.response?.data);
		}
	}),

	updateMessageThread: thunk(async (actions: any, payload: any) => {
		try {
			const result = await call('post', `/booking-sv/booking/updateMessageThread`, payload, null);
			actions.setUpdateMessageThreadSuccess(result.data);
		} catch (error: any) {
			actions.setUpdateMessageThreadError(error.response.data);
		}
	}),

	getRequestBookingListByBrand: thunk(async (actions: any, payload: any) => {
		try {
			const result = await call('get', `/booking-sv/booking/getRequestBookingListByBrand`, null, payload);
			actions.setRequestBookingListByBrandSuccess(result?.data);
		} catch (error: any) {
			actions.setRequestBookingListByBrandError(error?.response?.data);
		}
	}),

	updateRequestStatus: thunk(async (actions: any, payload: any) => {
		try {
			const result = await call('post', `/booking-sv/booking/updateRequestStatus`, payload, null);
			actions.setUpdateRequestStatusSuccess(result?.data);
		} catch (error: any) {
			actions.setUpdateRequestStatusError(error?.response?.data);
		}
	}),

	getRate: thunk(async (actions: any, payload: any) => {
		try {
			const result = await call('post', `/booking-sv/booking/get-rate`, payload, null);
			actions.setRateSuccess(result?.data);
		} catch (error: any) {
		}
	}),

	managementReport: thunk(async (actions: any, payload: any) => {
		try {
			const result = await call('get', `/booking-sv/booking/managementReport`, null, payload);
			actions.setManagementReport(result?.data);
		} catch (error: any) {
		}
	}),

	secureAvailabilityAndRate: thunk(async (actions: any, payload: any) => {
		try {
			const result = await call('post', `/booking-sv/booking/secureAvailabilityAndRate`, payload, null);
			actions.setSecureAvailabilityAndRateSuccess(result?.data);
		} catch (error: any) {
			actions.setSecureAvailabilityAndRateError(error?.response?.data);
		}
	}),

	addTravelerToSecureRateKey: thunk(async (actions: any, payload: any) => {
		try {
			const result = await call('post', `/booking-sv/booking/addTravelerToSecureRateKey`, payload, null);
			actions.setAddTravelerToSecureRateKeySuccess(result?.data);
		} catch (error: any) {
			actions.setAddTravelerToSecureRateKeyError(error?.response?.data);
		}
	}),

	createLiveBooking: thunk(async (actions: any, payload: any) => {
		try {
			const result = await call('post', `/booking-sv/booking/createLiveBooking`, payload, null);
			actions.setCreateLiveBookingSuccess(result?.data);
		} catch (error: any) {
			actions.setCreateLiveBookingError(error?.response?.data);
		}
	}),

	getBookingById: thunk(async (actions: any, payload: any) => {
		try {
			const result = await call('get', `/booking-sv/booking/getBookingById/${payload.bookingID}`, null, null);
			actions.setBookingByIdSuccess(result?.data);
		} catch (error: any) {
			actions.setBookingByIdError(error?.response?.data);
		}
	}),

	/**
	 * super admin request booking
	 */
	superAdminRequestBooking: thunk(async (actions: any, payload: any) => {
		try {
			const result = await call('get', `/booking-sv/booking/getRequestBookingListBySuperAdmin?requestUserId=${payload.requestUserId}`, null, null);
			actions.setSuperAdminRequestBookingSuccess(result?.data);
		} catch (error: any) {
			actions.setSuperAdminRequestBookingError(error?.response?.data);
		}
	}),

	/**
	 * booking reports
	 */

	corporateAdminReport: thunk(async (actions: any, payload: TBookingCorporateForm) => {
		try {
			const result = await call('get', `/booking-sv/booking/corporate-admin-report?clientId=${payload.clientId}&programmeId=${payload.programmeId}&country=${payload.country}&city=${payload.city}&search=${payload.search}&brandId=${payload.brand}&propertyId=${payload.property}&status=${payload.status}`, null, null);
			actions.setCorporateAdminReportSuccess(result?.data);
		} catch (error: any) {
			actions.setCorporateAdminReportError(error?.response?.data);
		}
	}),

	bookerAdminReport: thunk(async (actions: any, payload: TBookingBookerForm) => {
		try {
			const result = await call('get', `/booking-sv/booking/booker-report?clientId=${payload.clientId}&programmeId=${payload.programmeId}&bookerId=${payload.bookerId}&country=${payload.country}&city=${payload.city}&search=${payload.search}&brandId=${payload.brand}&propertyId=${payload.property}&status=${payload.status}&permission=${payload.permission}`, null, null);
			actions.setBookerReportSuccess(result?.data);
		} catch (error: any) {
			actions.setBookerReportError(error?.response?.data);
		}
	}),

	travelerAdminReport: thunk(async (actions: any, payload: TBookingTravelerForm) => {
		try {
			const result = await call('get', `/booking-sv/booking/traveler-report?clientId=${payload.clientId}&programmeId=${payload.programmeId}&travelerId=${payload.travelerId}&city=${payload.city}&status=${payload.status}`, null, null);
			actions.setTravelerReportSuccess(result?.data);
		} catch (error: any) {
			actions.setTravelerReportError(error?.response?.data);
		}
	}),

	brandAndPropertyAdminReport: thunk(async (actions: any, payload: TBrandAndPropertyForm) => {
		try {
			const result = await call('get', `/booking-sv/booking/brand-admin-report?brandId=${payload.brandId}&propertyId=${payload.property}&search=${payload.search}&status=${payload.status}`, null, null);
			actions.setBrandAndPropertyAdminReportSuccess(result?.data);
		} catch (error: any) {
			actions.setBrandAndPropertyAdminReportError(error?.response?.data);
		}
	}),

	superAdminReport: thunk(async (actions: any, payload: TBookingSuperAdminForm) => {
		try {
			const result = await call('get', `/booking-sv/booking/super-admin-report?status=${payload.status}&search=${payload.search}&brandId=${payload.brand}&propertyId=${payload.property}&country=${payload.country}&city=${payload.city}&clientId=${payload.client}&programmeId=${payload.programme}`, null, null);
			actions.setSuperAdminReportSuccess(result?.data);
		} catch (error: any) {
			actions.setSuperAdminReportReportError(error?.response?.data);
		}
	}),

	bookingStatus: thunk(async (actions: any, payload: any) => {
		try {
			const result = await call('post', `/booking-sv/booking/booking-status`, payload, null);
			actions.setBookingStatusSuccess(result?.data);
		} catch (error: any) {
			actions.setBookingStatusError(error?.response?.data);
		}
	}),

	bookingCancelWithCharge: thunk(async (actions: any, payload: any) => {
		try {
			const result = await call('post', `/booking-sv/booking/booking-cancel-with-charge`, payload, null);
			actions.setBookingCancelWithChargeSuccess(result?.data);
		} catch (error: any) {
			actions.setBookingCancelWithChargeError(error?.response?.data);
		}
	}),


	amendBookingRequest: thunk(async (actions: any, payload: any) => {
		try {
			const result = await call('post', `/booking-sv/booking/amendBookingRequest`, payload, null);
			actions.setAmendBookingRequestSuccess(result?.data);
		} catch (error: any) {
			actions.setAmendBookingRequestError(error?.response?.data);
		}
	}),

	acceptAmendBookingRequest: thunk(async (actions: any, payload: any) => {
		try {
			const result = await call('post', `/booking-sv/booking/acceptAmendBookingWithoutCharge`, payload, null);
			actions.setAcceptAmendBookingRequestSuccess(result?.data);
		} catch (error: any) {
			actions.setAcceptAmendBookingRequestError(error?.response?.data);
		}
	}),

	createReport: thunk(async (actions: any, payload: any) => {
		try {
			const result = await call('post', `/booking-sv/booking/createReport`, payload, null);
			actions.setCreateReport(result.data);
		} catch (error: any) {
			actions.setCreateReportError(error.response.data);
		}
	}),

	getReportById: thunk(async (actions: any, payload: any) => {
		try {
			const result = await call('get', `/booking-sv/booking/getReportById/${payload._id}`, null, null);
			actions.setReportByIdSuccess(result?.data);
		} catch (error: any) {
			actions.setReportByIdError(error?.response?.data);
		}
	}),

	// get report by programme id
	getReportByProgrammeId: thunk(async (actions: any, payload: any) => {
		actions.setReportByProgrammeIdLoading(true);
		try {
			const result = await call('get', `/booking-sv/booking/programme/list?clientId=${payload?.clientId}&programmeId=${payload?.programmeId}`, null, null);
			actions.setReportByProgrammeIdLoading(false);
			actions.setReportByProgrammeIdSuccess(result.data);
		} catch (error: any) {
			actions.setReportByProgrammeIdLoading(false);
			actions.setReportByProgrammeIdError(error.response.data);
		}
	}
	),

	//updating rfp process
	updateReport: thunk(async (actions: any, payload: any) => {
		try {
			actions.setUpdateReportLoading(true);
			const result = await call('patch', `/booking-sv/booking/updateReport`, payload, null);
			actions.setUpdateReportSuccess(result.data);
			actions.setUpdateReportLoading(false);
		}
		catch (error: any) {
			actions.setUpdateReportLoading(false);
			actions.setUpdateReportError(error.response.data);
		}
	}),
}