import { Select } from 'antd';
import { useStoreActions, useStoreState } from 'easy-peasy';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { getLoggedUserEmail, getLoggedUserType } from '../../../../common/functions';
import { UserGroups, CommonAnswer, InventoryStatus } from '../../../../common/constants';
import { Controller, useForm } from 'react-hook-form';
import { Row, Col, Form, Button, Spinner, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { HotelInventoryFormInput } from '../../../../common/interfaces';
import { formButton, formButton2, overlayTriggerDelay } from '../../../../common/components-style';
import { ErrorMessage } from '@hookform/error-message';
import BreadCrumbPage from '../../../common/BreadCrumbPage';
import moment from 'moment'
import DatePicker from "react-datepicker";
import { setStorage } from '../../../../common/storage';
const { Option } = Select;


const HotelInventoryDetailsForm: React.FC<any> = ({ allInOneHotelBreadCrumb, inventoryId, selectedHotel, selectedBrand, setFormOpen, loadData }): JSX.Element => {
  const { register, setValue, handleSubmit, watch, formState: { errors }, control, reset } = useForm<HotelInventoryFormInput>();
  const [editEnable, setEditEnable] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [roomTypeList, setRoomTypeList] = useState<any>();
  const [roomTypeId, setRoomTypeId] = useState<any>(0);
  const [bedTypeList, setBedTypeList] = useState<any>();
  const [hotels, setHotels] = useState<any>(null);
  const [offeredRoom, setOfferedRoom] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [dataList, setDataList] = useState<any>([]);
  const [dummyList, setDummyList] = useState<any>([]);
  const [messages, setMessage] = useState<any>(null);
  const [bedTypeId, setBedTypeId] = useState<Array<any>>([]);
  const [showCreateButton, setShowCreateButton] = useState<boolean>(false);
  const [areAllApartmentsNonSmoking, setAreAllApartmentsNonSmoking] = useState<boolean>(false);
  const [doYouHaveMaximumLengthOfStay, setDoYouHaveMaximumLengthOfStay] = useState<boolean>(false);
  const [inventoryData, setInventoryData] = useState<any>();
  const [hotelPropertyType, setHotelPropertyType] = useState<any>();
  const [skipValidation, setSkipValidation] = useState<boolean>(false);
  const watchedFields = watch();
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const { inventoryApproval, inventoryReview, getRoomTypeList, getBedTypeList, getInventoryById, createInventory, updateInventory, resetInventory, getAllHotelNameList, getHotel, getInventoryByHotelId } = useStoreActions<any>((actions) => ({
    getRoomTypeList: actions.hotelInventory.getRoomTypeList,
    getBedTypeList: actions.inventory.getBedTypeList,
    getInventoryById: actions.hotelInventory.getInventoryById,
    createInventory: actions.hotelInventory.createInventory,
    updateInventory: actions.hotelInventory.updateInventory,
    resetInventory: actions.hotelInventory.resetInventory,
    getHotel: actions.hotel.getHotel,
    getAllHotelNameList: actions.hotel.getAllHotelNameList,
    getInventoryByHotelId: actions.hotelInventory.getInventoryByHotelId,
    inventoryApproval: actions.hotelInventory.inventoryApproval,
    inventoryReview: actions.hotelInventory.inventoryReview,
  }));

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const { inventoryReviewError, inventoryReviewSuccess, inventoryApprovalSuccess, inventoryApprovalError, getRoomTypeListSuccess, getRoomTypeListError, getBedTypeListSuccess, getBedTypeListError
    , getInventoryByIdSuccess, getInventoryByIdError, createInventorySuccess, createInventoryError, updateInventorySuccess, updateInventoryError,
    getAllHotelNameListSuccess, getInventoryByHotelIdSuccess, getInventoryByHotelIdError,
    getAllHotelNameListError, getHotelSuccess, getHotelError } = useStoreState<any>((state) => ({
      getRoomTypeListSuccess: state.hotelInventory.getRoomTypeListSuccess,
      getRoomTypeListError: state.hotelInventory.getRoomTypeListError,
      getBedTypeListSuccess: state.inventory.getBedTypeListSuccess,
      getBedTypeListError: state.inventory.getBedTypeListError,
      getInventoryByIdSuccess: state.hotelInventory.getInventoryByIdSuccess,
      getInventoryByIdError: state.hotelInventory.getInventoryByIdError,
      createInventorySuccess: state.hotelInventory.createInventorySuccess,
      createInventoryError: state.hotelInventory.createInventoryError,
      updateInventorySuccess: state.hotelInventory.updateInventorySuccess,
      updateInventoryError: state.hotelInventory.updateInventoryError,
      getAllHotelNameListSuccess: state.hotel.getAllHotelNameListSuccess,
      getAllHotelNameListError: state.hotel.getAllHotelNameListError,
      getHotelSuccess: state.hotel.getHotelSuccess,
      getHotelError: state.hotel.getHotelError,
      getInventoryByHotelIdSuccess: state.hotelInventory.getInventoryByHotelIdSuccess,
      getInventoryByHotelIdError: state.hotelInventory.getInventoryByHotelIdError,
      inventoryApprovalSuccess: state.hotelInventory.inventoryApprovalSuccess,
      inventoryApprovalError: state.hotelInventory.inventoryApprovalError,
      inventoryReviewSuccess: state.hotelInventory.inventoryReviewSuccess,
      inventoryReviewError: state.hotelInventory.inventoryReviewError,
    }));

  ////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    reset();
    if (selectedHotel) {
      getHotel(selectedHotel._id)
    }
  }, [getHotel, reset, selectedHotel]);

  ////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (selectedHotel) {
      getInventoryByHotelId(selectedHotel._id);
    }

  }, [getInventoryByHotelId, selectedHotel]);

  ////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    getAllHotelNameList();
  }, [getAllHotelNameList]);

  ////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    getRoomTypeList();
  }, [getRoomTypeList]);

  ////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    getBedTypeList();
  }, [getBedTypeList]);

  ////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (inventoryId) {
      getInventoryById(inventoryId);
    } else {
      setEditEnable(true);
    }

  }, [getInventoryById, inventoryId]);


  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (getAllHotelNameListError) {
      toast.error(getAllHotelNameListError.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
    }

    if (getAllHotelNameListSuccess) {
      const hotel = getAllHotelNameListSuccess.data;
      setHotels(hotel);
    }

  }, [getAllHotelNameListError, getAllHotelNameListSuccess, selectedHotel]);

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (getHotelSuccess) {
      const data = getHotelSuccess.data;
      setHotelPropertyType(data?.propertyType?.name)
      if (data?.moderate?.noOfRoomTypesOffered?.new_value) {
        setOfferedRoom(data?.moderate?.noOfRoomTypesOffered?.new_value);
      } else {
        setOfferedRoom(data?.noOfRoomTypesOffered);
      }
    }

    if (getHotelError) {
      toast.error(getHotelError.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
    }
  }, [getHotelSuccess, getHotelError]);

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (getInventoryByHotelIdSuccess) {
      setTotalCount(getInventoryByHotelIdSuccess.data.length)
    }
    if (getInventoryByHotelIdError) {
      toast.error(getInventoryByHotelIdError.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
    }
    setMessage(totalCount + ' of ' + offeredRoom + ' room types defined');
    if (totalCount === offeredRoom) {
      setShowCreateButton(true);
    } else {
      setShowCreateButton(false);
    }
  }, [getInventoryByHotelIdError, getInventoryByHotelIdSuccess, totalCount, offeredRoom]);

  //////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (getInventoryByIdSuccess) {
      const inventory = getInventoryByIdSuccess.data;
      setStorage('myo_selected_hotel_inventory', inventory)
      setInventoryData(inventory);

      let newData: any = [];
      let newBedIds: any = [];
      _.map(inventory.bedType, element => {
        newBedIds.push(element._id)
        return (
          newData.push({ id: element._id, name: element.name })
        )
      }
      );

      setDataList(newData);
      setValue('bedType', newBedIds);
      setBedTypeId(newBedIds);

      setRoomTypeId(inventory?.accommodationConfiguration?._id)
      if (inventory.areAllApartmentsNonSmoking === CommonAnswer.NO) {
        setAreAllApartmentsNonSmoking(true);
      } else {
        setAreAllApartmentsNonSmoking(false);
      }
    }
    if (getInventoryByIdError) {
      toast.error(getInventoryByIdError.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
    }
  }, [getInventoryByIdError, getInventoryByIdSuccess, setValue]);

  //////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (getRoomTypeListSuccess) {
      const roomType = getRoomTypeListSuccess.data;
      setRoomTypeList(roomType);
    }
    if (getRoomTypeListError) {
      toast.error(getRoomTypeListError.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
    }
  }, [getRoomTypeListError, getRoomTypeListSuccess]);


  useEffect(() => {

    if (inventoryData?.bedType?.length === 0) {

      if (roomTypeList) {
        const occupancy = _.first(_.filter(roomTypeList, user => (user._id === inventoryData?.accommodationConfiguration?._id))).occupancy;

        let list = new Array(occupancy);

        let data: any = [];
        if (list) {
          _.each(list, (yy) => {
            data.push([]);
            setDummyList(data);

          })
        }
      }
    }

  }, [inventoryData, inventoryData?.accommodationConfiguration?._id, inventoryData?.bedType?.length, roomTypeList])

  //////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (getBedTypeListSuccess) {
      const bedType = getBedTypeListSuccess.data;
      setBedTypeList(bedType);
    }
    if (getBedTypeListError) {
      toast.error(getBedTypeListError.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
    }

  }, [getBedTypeListError, getBedTypeListSuccess]);

  //////////////////////////////////////////////////////////////////////////////////////////////
  const renderHotel = useCallback(() => {
    let includeList: Array<string> = [];
    let filterData;
    if ([UserGroups.chief_hotel_admin, UserGroups.hotel_admin].includes(getLoggedUserType())) {
      includeList = [UserGroups.chief_hotel_admin, UserGroups.hotel_admin];
      filterData = _.filter(hotels, propVal => _.find(propVal.hotelUsers, value => value.email === getLoggedUserEmail() && _.includes(includeList, value.role)));
    } else {
      filterData = hotels;
    }
    if (hotels) {
      return filterData.map((acc: any, key: number) => {
        return (
          <Option key={key} value={`${acc._id}`} >{acc.hotelName}</Option>
        )
      });
    }
  }, [hotels]);

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////
  const renderRoomType = useCallback(() => {
    if (roomTypeList) {
      return roomTypeList.map((acc: any, key: number) => {
        return (
          <Option key={key} value={`${acc._id}`} >{acc.name}</Option>
        )
      });
    }
  }, [roomTypeList]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const onRoomTypeChange = useCallback((value) => {
    if (roomTypeList) {
      const occupancy = _.first(_.filter(roomTypeList, user => (user._id === value))).occupancy;
      setRoomTypeId(value);
      // setValue('noOfRoomType', occupancy);

      let list = new Array(occupancy);

      let newData: any = [];
      if (list) {
        _.each(list, (yy) => {
          newData.push([]);
        })
        setDataList(newData);
      }
      setBedTypeId([]);
      setValue('bedType', []);
    }

  }, [roomTypeList, setValue])

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const renderBedType = useCallback(() => {
    if (bedTypeList) {
      return bedTypeList.map((acc: any, key: number) => {
        return (

          <Option key={key} value={`${acc._id}`} >{acc.name}</Option>
        )
      });
    }
  }, [bedTypeList])

  /////////////////////////////////////////////////////////////////////////////////////////////////////
  const onBedTypeChange = useCallback((index, value) => {

    let updatedBedTypeId = [...bedTypeId];
    updatedBedTypeId[index] = value;

    setBedTypeId(updatedBedTypeId);

  }, [bedTypeId]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (createInventorySuccess) {
      loadData(createInventorySuccess.data);
      resetInventory();
      toast.success("Inventory created successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
      getInventoryById(createInventorySuccess?.data?._id);
      getInventoryByHotelId(selectedHotel?._id);
      setEditEnable(false);
      scrollToTop();
    }

    if (createInventoryError) {
      toast.error(createInventoryError.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
    }

    if (updateInventorySuccess) {
      toast.success("Inventory updated successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
      resetInventory();
      getInventoryByHotelId(selectedHotel?._id);
      getInventoryById(updateInventorySuccess?.data?._id);
      setEditEnable(false);
      scrollToTop();
    }
    if (updateInventoryError) {
      toast.error(updateInventoryError.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
    }

    if (inventoryApprovalSuccess) {
      getInventoryById(inventoryId);
      toast.success("Inventory approved successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
    }

    if (inventoryApprovalError) {
      toast.error(inventoryApprovalError?.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
    }


    if (inventoryReviewSuccess) {
      getInventoryById(inventoryId);
      toast.success("Inventory reviewed successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
    }

    if (inventoryReviewError) {
      toast.error(inventoryReviewError?.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
    }
    setLoading(false);
  }, [resetInventory, createInventorySuccess, createInventoryError, updateInventorySuccess, updateInventoryError, getInventoryByHotelId, selectedHotel, loadData, getInventoryById, inventoryApprovalSuccess, inventoryApprovalError, inventoryId, inventoryReviewSuccess, inventoryReviewError]);



  const onChangeAreAllApartmentsNonSmoking = useCallback((value) => {
    if (value === CommonAnswer.NO) {
      setAreAllApartmentsNonSmoking(true);
    } else {
      setAreAllApartmentsNonSmoking(false);
    }
  }, []);


  const onChangeDoYouHaveMaximumLengthOfStay = useCallback((value) => {
    if (value === CommonAnswer.YES) {
      setDoYouHaveMaximumLengthOfStay(true);
    } else {
      setDoYouHaveMaximumLengthOfStay(false);
    }
  }, []);


  const validateBedType = () => {
    if (!bedTypeId || bedTypeId.length === 0) {
      toast.error('Please select bed types for all bedrooms!', {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar',
      });
      return false;
    }

    const hasInvalidBedType = bedTypeId.some((type) => !type || type === '');
    if (hasInvalidBedType) {
      toast.error('All bedrooms must have a valid bed type selected!', {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar',
      });
      return false;
    }

    return true;
  };
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const onSubmit = (values) => {

    if (!values.accommodationConfiguration) {
      toast.error('Please enter a configuration!', {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
      return;
    }

    if (!values.accommodationDetail) {
      toast.error('Please enter a detail!', {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: 'foo-bar'
      });
      return;
    }

    if (!validateBedType()) {
      return;
    }

    setLoading(true);
    if (inventoryId) {
      values.id = inventoryId;
      const data: any = {
        id: inventoryId,
        accommodationConfiguration: values.accommodationConfiguration ? values.accommodationConfiguration : inventoryData?.accommodationConfiguration?._id,
        whereWereIndividualApartments: values.whereWereIndividualApartments ? values.whereWereIndividualApartments : inventoryData?.whereWereIndividualApartments,
        areAllApartmentsNonSmoking: values.areAllApartmentsNonSmoking ? values.areAllApartmentsNonSmoking : inventoryData?.areAllApartmentsNonSmoking,
        doesRoomTypeAirConditioning: values.doesRoomTypeAirConditioning ? values.doesRoomTypeAirConditioning : inventoryData?.doesRoomTypeAirConditioning,
        havePrivateBathrooms: values.havePrivateBathrooms ? values.havePrivateBathrooms : inventoryData?.havePrivateBathrooms,
        pleaseAdviseWhatPercentageAreNonSmoking: values.pleaseAdviseWhatPercentageAreNonSmoking ? values.pleaseAdviseWhatPercentageAreNonSmoking ? inventoryData?.areAllApartmentsNonSmoking : inventoryData?.areAllApartmentsNonSmoking : '',
        howManyApartmentsAreSuitable: values.howManyApartmentsAreSuitable ? values.howManyApartmentsAreSuitable : inventoryData?.howManyApartmentsAreSuitable,
        noOfRoomType: values.noOfRoomType ? values.noOfRoomType : inventoryData?.noOfRoomType,
        bedType: bedTypeId?.filter((val) => val !== undefined),
        doYouHaveMaximumLengthOfStay: values.doYouHaveMaximumLengthOfStay ? values.doYouHaveMaximumLengthOfStay : inventoryData?.doYouHaveMaximumLengthOfStay,
        maximumStayed: values.maximumStayed ? values.maximumStayed : inventoryData?.maximumStayed === null ? 0 : inventoryData?.maximumStayed,
        moderate: {
          details: {
            new_value: values.accommodationDetail ? values.accommodationDetail : inventoryData?.accommodationDetail
          }
        },
      }
      if (inventoryData?.status === InventoryStatus.INPROGRESS) {
        data.status = InventoryStatus.INCOMPLETE;
      }
      updateInventory(data);
    } else {
      const data = {
        hotelId: values.hotelId,
        accommodationDetail: values.accommodationDetail,
        accommodationConfiguration: roomTypeId,
        whereWereIndividualApartments: values.whereWereIndividualApartments,
        areAllApartmentsNonSmoking: values.areAllApartmentsNonSmoking,
        doesRoomTypeAirConditioning: values.doesRoomTypeAirConditioning,
        havePrivateBathrooms: values.havePrivateBathrooms,
        pleaseAdviseWhatPercentageAreNonSmoking: values.pleaseAdviseWhatPercentageAreNonSmoking ? values.pleaseAdviseWhatPercentageAreNonSmoking : '',
        howManyApartmentsAreSuitable: values.howManyApartmentsAreSuitable,
        noOfRoomType: values.noOfRoomType,
        bedType: bedTypeId?.filter((val) => val !== undefined),
        doYouHaveMaximumLengthOfStay: values.doYouHaveMaximumLengthOfStay,
        maximumStayed: values.maximumStayed,
        moderate: {
          details: {
            new_value: values.accommodationDetail
          }
        },
        status: isFormComplete() ? InventoryStatus.INCOMPLETE : InventoryStatus.INPROGRESS
      }
      createInventory(data);
    }
  };

  const changeFormEditable = useCallback(() => {
    scrollToTop();
    if (editEnable) {
      setEditEnable(false);
    } else {
      setEditEnable(true);
    }
  }, [editEnable]);

  //////////////// approval part ////////
  const onApprovedInventory = () => {
    setLoading(true);
    const payload = {
      inventoryId: inventoryId
    }
    inventoryApproval(payload);
  };

  //////////////// review part ////////
  const onReviewedInventory = () => {
    setLoading(true);
    const payload = {
      inventoryId: inventoryId
    }
    inventoryReview(payload);
  };

  const isFormComplete = () => {
    const requiredFields = [
      'hotelId',
      'accommodationConfiguration',
      'accommodationDetail',
      'doesRoomTypeAirConditioning',
      'bedType',
      'whereWereIndividualApartments',
      'areAllApartmentsNonSmoking',
      'howManyApartmentsAreSuitable',
      'noOfRoomType',
      'havePrivateBathrooms'
    ];
    if (hotelPropertyType && hotelPropertyType.toLowerCase() === 'hotel with extended stay') {
      requiredFields.push('doYouHaveMaximumLengthOfStay');
    }
    if (areAllApartmentsNonSmoking) {
      requiredFields.push('pleaseAdviseWhatPercentageAreNonSmoking');
    }
    if (doYouHaveMaximumLengthOfStay) {
      requiredFields.push('maximumStayed');
    }

    const missingRequiredFields = requiredFields.filter(field => {
      const fieldValue = watchedFields[field];


      return fieldValue == null || (typeof fieldValue === 'string' && fieldValue.trim() === '');
    });
    return missingRequiredFields.length === 0;
  };

  const hasChanges = () => {
    const initialValues = {
      accommodationDetail: '',
      accommodationConfiguration: '',
      whereWereIndividualApartments: '',
      doesRoomTypeAirConditioning: '',
      areAllApartmentsNonSmoking: '',
      howManyApartmentsAreSuitable: '',
      havePrivateBathrooms: '',
      noOfRoomType: 0,
      hotelId: '',
      noOfRoomTypesOffered: 0,
      bedType: Array<object>,
      pleaseAdviseWhatPercentageAreNonSmoking: '',
      doYouHaveMaximumLengthOfStay: '',
      maximumStayed: 0,
    };

    const ignoredFields = ['hotelId', 'noOfRoomTypesOffered'];
    const currentValues = watch();

    const normalizeValue = (value) => {
      if (value === 'null') {
        return null;
      }
      return value;
    };

    const isFieldModified = (field, currentValue, initialValue) => {
      const normalizedCurrentValue = normalizeValue(currentValue);
      const normalizedInitialValue = normalizeValue(initialValue);

      if (ignoredFields.includes(field)) return false;

      return (normalizedCurrentValue || '') !== (normalizedInitialValue || '');
    };

    const modifiedFields = Object.keys(currentValues).filter(field => {
      const currentValue = currentValues[field];
      const initialValue = initialValues[field];
      const hasChanged = isFieldModified(field, currentValue, initialValue);

      return hasChanged;
    });
    return modifiedFields.length > 0;
  };

  const handleBreadcrumbClick = (e, breadcrumbType) => {

    const isComplete = isFormComplete();
    const hasFieldChanges = hasChanges();

    if (!inventoryId && !isComplete && hasFieldChanges) {
      e.preventDefault();
      const currentValues = watch();
      onSubmit(currentValues);
    }
    else {
      if (breadcrumbType === 'hotelInventory') {
        setFormOpen(false);
      }
    }
  };

  //////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      <div className="content-title fixed_title">
        <BreadCrumbPage allInOneHotelBreadCrumb={allInOneHotelBreadCrumb} isPageName="HotelInventory" selectedHotel={selectedHotel} selectedBrand={selectedBrand} inventoryData={inventoryData} setFormOpen={setFormOpen} inventoryId={inventoryId} onClick={handleBreadcrumbClick}></BreadCrumbPage>
        <div className='edit_btn_wpr'>
          <div className='d-flex align-items-center justify-content-end gx-2'>
            {/* <HelpModal /> */}
            {
              !loading && !editEnable && inventoryId && <Button id='edit-button' className='mb-0' style={formButton} onClick={changeFormEditable} >Edit</Button>
            }
          </div>
        </div>
      </div>
      <div>
        <div className="jumbotron">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md={1}></Col>
              <Col md={10}>
                <Row className='formSpace'>
                  <Col md={6} style={{ textAlign: 'right' }}>
                    <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Hotel name</Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: 'left' }}>
                    {!editEnable && <p className="lead">{selectedHotel?.hotelName}</p>}
                    {editEnable &&
                      <Controller
                        name="hotelId"
                        defaultValue={selectedHotel._id}
                        control={control}
                        rules={{
                          required: true,
                        }}
                        render={({ field }) =>
                          <Select defaultValue={selectedHotel._id} className={`${errors.hotelId ? 'is-invalid' : ''}`} style={{ width: '100%' }} onChange={(value) => field.onChange(value)} disabled={selectedHotel}>
                            {renderHotel()}
                          </Select>
                        }
                      />}
                    {editEnable && <div className="invalid-feedback">Please select hotel name!</div>}
                  </Col>
                </Row>
                <Row className='formSpace'>
                  <Col md={6} style={{ textAlign: 'right' }}>
                    <Form.Label>Number of inventory types</Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: 'left' }}>
                    {!editEnable && <p className="lead">{offeredRoom || inventoryData?.hotelId?.noOfRoomTypesOffered}</p>}
                    {editEnable && <Form.Control type="number" disabled defaultValue={offeredRoom || inventoryData?.hotelId?.noOfRoomTypesOffered} {...register("noOfRoomTypesOffered")} />}
                  </Col>
                </Row>

                <Row className='formSpace'>
                  <Col md={6} style={{ textAlign: 'right' }}>
                  </Col>
                  <Col md={6} style={{ textAlign: 'left' }}>
                    <p className="lead message" style={{ textAlign: 'right', fontWeight: 'bold' }} >{messages}</p>
                  </Col>
                </Row>
                <Row className='formSpace'>
                  <Col md={6} style={{ textAlign: 'right' }}>
                    <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Configuration</Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: 'left' }}>
                    {!editEnable && <p className="lead">{inventoryData?.accommodationConfiguration?.name}</p>}
                    {editEnable &&
                      <Controller
                        name="accommodationConfiguration"
                        defaultValue={inventoryData?.accommodationConfiguration?._id}
                        control={control}
                        rules={{
                          required: true,
                        }}
                        render={({ field }) =>
                          <Select defaultValue={inventoryData?.accommodationConfiguration?._id} className={`${errors.accommodationConfiguration ? 'is-invalid' : ''}`} style={{ width: '100%' }} onChange={(value) => {
                            field.onChange(value);
                            onRoomTypeChange(value);
                          }} >
                            {renderRoomType()}
                          </Select>
                        }
                      />}
                    {editEnable && <div className="invalid-feedback">Please select accommodation configuration!</div>}
                  </Col>
                </Row>
                <Row className='formSpace'>
                  <Col md={6} style={{ textAlign: 'right' }}>
                    <OverlayTrigger
                      placement="top"
                      delay={overlayTriggerDelay}
                      overlay={<Tooltip id='tooltip-table-top'>Please provide a short, factual sub-heading of the inventory hotel - this will appear on the Hotel and Booking Confirmation pages eg. ‘Standard Open Plan Apartment’ or 'Superior One Bedroom Apartment’. Max. 4 words.</Tooltip>}
                    >
                      <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Detail</Form.Label>
                    </OverlayTrigger>

                  </Col>
                  <Col md={6} style={{ textAlign: 'left' }}>
                    {!editEnable && <p className="lead">{inventoryData?.moderate?.details?.new_value ? inventoryData?.moderate?.details?.new_value : inventoryData?.accommodationDetail}</p>}
                    {editEnable &&
                      <OverlayTrigger
                        placement="top"
                        delay={overlayTriggerDelay}
                        overlay={<Tooltip id='tooltip-table-top'>Please provide a short, factual sub-heading of the inventory hotel - this will appear on the Hotel and Booking Confirmation pages eg. ‘Standard Open Plan Apartment’ or 'Superior One Bedroom Apartment’. Max. 4 words.</Tooltip>}
                      >
                        <Form.Control className={`${errors.accommodationDetail ? 'is-invalid' : ''}`} as="textarea" rows={5} maxLength={32} defaultValue={inventoryData?.moderate?.details?.new_value ? inventoryData?.moderate?.details?.new_value : inventoryData?.accommodationDetail} {...register("accommodationDetail", { required: true })}
                        />
                      </OverlayTrigger>
                    }
                    {editEnable && <div className="invalid-feedback">Please input accommodation detail!</div>}
                  </Col>
                </Row>
                <Row className='formSpace'>
                  <Col md={6} style={{ textAlign: 'right' }}>
                    <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Does this room type have air conditioning</Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: 'left' }}>
                    {!editEnable && <p className="lead">{inventoryData?.doesRoomTypeAirConditioning}</p>}
                    {editEnable &&
                      <Controller
                        name="doesRoomTypeAirConditioning"
                        defaultValue={inventoryData?.doesRoomTypeAirConditioning}
                        control={control}
                        rules={{
                          required: !skipValidation,
                        }}
                        render={({ field }) =>
                          <>
                            {
                              inventoryData?.doesRoomTypeAirConditioning === 'Yes'
                                ?
                                <Form.Check
                                  inline
                                  value={'Yes'}
                                  name="doesRoomTypeAirConditioning"
                                  label={'Yes'}
                                  type="radio"
                                  defaultChecked
                                  onChange={(value) => { field.onChange(value.currentTarget.value); }}
                                />
                                :
                                <Form.Check
                                  inline
                                  value={'Yes'}
                                  name="doesRoomTypeAirConditioning"
                                  label={'Yes'}
                                  type="radio"
                                  onChange={(value) => { field.onChange(value.currentTarget.value); }}
                                />
                            }

                            {
                              inventoryData?.doesRoomTypeAirConditioning === 'No'
                                ?
                                <Form.Check
                                  inline
                                  value={'No'}
                                  name="doesRoomTypeAirConditioning"
                                  label={'No'}
                                  type="radio"
                                  defaultChecked
                                  onChange={(value) => { field.onChange(value.currentTarget.value); }}
                                />
                                :
                                <Form.Check
                                  inline
                                  value={'No'}
                                  name="doesRoomTypeAirConditioning"
                                  label={'No'}
                                  type="radio"
                                  onChange={(value) => { field.onChange(value.currentTarget.value); }}
                                />
                            }

                          </>
                        }
                      />}
                    {editEnable &&
                      <ErrorMessage
                        errors={errors}
                        name="doesRoomTypeAirConditioning"
                        render={({ message }) => <div className="invalid-feedback-custom">Please select your answer!!</div>} />}
                  </Col>
                </Row>

                {(!editEnable && inventoryData?.bedType?.length === 0) &&
                  (
                    dummyList.map((da: any, key) =>
                      <Row className='formSpace' key={key}>
                        <Col md={6} style={{ textAlign: 'right' }}>
                          <Form.Label>{!editEnable && <span className='requiredMark'>*</span>} Type of bed/configuration</Form.Label>
                        </Col>
                        <Col md={6} style={{ textAlign: 'left' }}>
                          {!editEnable &&
                            <Controller
                              name={`bedType.${key}`}
                              defaultValue={bedTypeId[key] || ''}
                              control={control}
                              rules={{
                                required: true,
                              }}
                              render={({ field }) =>
                              (<Select value={da?.id || field.value} style={{ width: '100%' }} onChange={(value) => {
                                field.onChange(value);
                                onBedTypeChange(key, value);
                              }} >
                                {renderBedType()}
                              </Select>)
                              }
                            />}
                          {!editEnable &&
                            <ErrorMessage
                              errors={errors}
                              name={`bedType.${key}`}
                              render={({ message }) => <div className="invalid-feedback-custom">Please select bed type!!!</div>} />}
                        </Col>
                      </Row>
                    )
                  )
                }
                {!!roomTypeId &&

                  (dataList.map((da: any, key) =>
                    <Row className='formSpace' key={key}>
                      <Col md={6} style={{ textAlign: 'right' }}>
                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Type of bed/configuration</Form.Label>
                      </Col>
                      <Col md={6} style={{ textAlign: 'left' }}>
                        {!editEnable && <p className="lead">{da.name}</p>}
                        {editEnable &&
                          <Controller
                            name={`bedType.${key}`}
                            defaultValue={bedTypeId[key] || ''}
                            control={control}
                            rules={{
                              required: true,
                            }}
                            render={({ field }) => {
                              return (<Select value={bedTypeId[key] || da?.id} style={{ width: '100%' }} onChange={(value) => {
                                field.onChange(value);
                                onBedTypeChange(key, value);
                              }} >
                                {renderBedType()}
                              </Select>)
                            }
                            }
                          />}
                        {editEnable &&
                          <ErrorMessage
                            errors={errors}
                            name={`bedType.${key}`}
                            render={({ message }) => <div className="invalid-feedback-custom">Please select bed type!!!</div>}
                          />
                        }
                      </Col>
                    </Row>
                  ))}



                <Row className='formSpace'>
                  <Col md={6} style={{ textAlign: 'right' }}>
                    <Form.Label>{editEnable && <span className='requiredMark'>*</span>}  When were individual apartments last renovated?</Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: 'left' }}>
                    {!editEnable && inventoryData?.whereWereIndividualApartments && <p className="lead">{moment(inventoryData?.whereWereIndividualApartments).format('YYYY')} </p>}
                    {editEnable &&
                      <Controller
                        name="whereWereIndividualApartments"
                        control={control}
                        rules={
                          { required: !skipValidation }
                        }
                        defaultValue={(inventoryData?.whereWereIndividualApartments) ? new Date(inventoryData?.whereWereIndividualApartments) : undefined}
                        render={({ field }) =>
                          <DatePicker className={`form-control ${errors.whereWereIndividualApartments ? 'is-invalid' : ''}`} selected={field.value} dateFormat={'yyyy'} showYearPicker={true} onChange={(date) => field.onChange(date)} />}
                      />
                    }
                    {editEnable &&
                      <ErrorMessage
                        errors={errors}
                        name="whereWereIndividualApartments"
                        render={({ message }) => <div className="invalid-feedback-custom">Please select when were individual apartments last renovated!</div>}
                      />
                    }
                  </Col>
                </Row>

                <Row className='formSpace'>
                  <Col md={6} style={{ textAlign: 'right' }}>
                    <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Are all apartments non-smoking?</Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: 'left' }}>
                    {!editEnable && <p className="lead">{inventoryData?.areAllApartmentsNonSmoking}</p>}
                    {editEnable &&
                      <Controller
                        name="areAllApartmentsNonSmoking"
                        defaultValue={inventoryData?.areAllApartmentsNonSmoking}
                        control={control}
                        rules={{
                          required: !skipValidation,
                        }}
                        render={({ field }) =>
                          <>
                            {
                              inventoryData?.areAllApartmentsNonSmoking === 'Yes'
                                ?
                                <Form.Check
                                  inline
                                  value={'Yes'}
                                  name="areAllApartmentsNonSmoking"
                                  label={'Yes'}
                                  type="radio"
                                  defaultChecked
                                  onChange={(value) => { field.onChange(value.currentTarget.value); onChangeAreAllApartmentsNonSmoking(value.currentTarget.value) }}
                                />
                                :
                                <Form.Check
                                  inline
                                  value={'Yes'}
                                  name="areAllApartmentsNonSmoking"
                                  label={'Yes'}
                                  type="radio"
                                  onChange={(value) => { field.onChange(value.currentTarget.value); onChangeAreAllApartmentsNonSmoking(value.currentTarget.value) }}
                                />
                            }

                            {
                              inventoryData?.areAllApartmentsNonSmoking === 'No'
                                ?
                                <Form.Check
                                  inline
                                  value={'No'}
                                  name="areAllApartmentsNonSmoking"
                                  label={'No'}
                                  type="radio"
                                  defaultChecked
                                  onChange={(value) => { field.onChange(value.currentTarget.value); onChangeAreAllApartmentsNonSmoking(value.currentTarget.value) }}
                                />
                                :
                                <Form.Check
                                  inline
                                  value={'No'}
                                  name="areAllApartmentsNonSmoking"
                                  label={'No'}
                                  type="radio"
                                  onChange={(value) => { field.onChange(value.currentTarget.value); onChangeAreAllApartmentsNonSmoking(value.currentTarget.value) }}
                                />
                            }

                          </>
                        }
                      />}
                    {editEnable &&
                      <ErrorMessage
                        errors={errors}
                        name="areAllApartmentsNonSmoking"
                        render={({ message }) => <div className="invalid-feedback-custom">Please select your answer!!</div>} />}
                  </Col>
                </Row>

                {areAllApartmentsNonSmoking && <Row className='formSpace'>
                  <Col md={6} style={{ textAlign: 'right' }}>
                    <Form.Label>{editEnable && <span className='requiredMark'>*</span>}  Please advise what percentage are non-smoking?</Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: 'left' }}>
                    {!editEnable && <p className="lead">{inventoryData?.pleaseAdviseWhatPercentageAreNonSmoking}</p>}
                    {editEnable && <Form.Control className={`${errors.pleaseAdviseWhatPercentageAreNonSmoking ? 'is-invalid' : ''}`} defaultValue={inventoryData?.pleaseAdviseWhatPercentageAreNonSmoking} {...register("pleaseAdviseWhatPercentageAreNonSmoking", { required: !skipValidation })} />}
                    {editEnable && <div className="invalid-feedback">Please input your answer!</div>}
                  </Col>
                </Row>}
                <Row className='formSpace'>
                  <Col md={6} style={{ textAlign: 'right' }}>
                    <Form.Label>{editEnable && <span className='requiredMark'>*</span>}How many rooms are suitable for people with physical disabilities?</Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: 'left' }}>
                    {!editEnable && <p className="lead">{inventoryData?.howManyApartmentsAreSuitable}</p>}
                    {editEnable && <Form.Control type="number" min={0} className={`${errors.howManyApartmentsAreSuitable ? 'is-invalid' : ''}`} defaultValue={inventoryData?.howManyApartmentsAreSuitable} {...register("howManyApartmentsAreSuitable", { required: !skipValidation })} />}
                    {editEnable && <div className="invalid-feedback">Please input your answer!</div>}
                  </Col>
                </Row>
                <Row className='formSpace'>
                  <Col md={6} style={{ textAlign: 'right' }}>
                    <Form.Label>No of this room type</Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: 'left' }}>
                    {!editEnable && <p className="lead">{inventoryData?.noOfRoomType}</p>}
                    {editEnable && <Form.Control min={0} type="number" className={`${errors.noOfRoomType ? 'is-invalid' : ''}`} defaultValue={inventoryData?.noOfRoomType} {...register("noOfRoomType", { required: !skipValidation })} />}
                    {editEnable && <div className="invalid-feedback">Please input your answer!</div>}
                  </Col>
                </Row>
                <Row className='formSpace'>
                  <Col md={6} style={{ textAlign: 'right' }}>
                    <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Do all these room types have a private bathrooms</Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: 'left' }}>
                    {!editEnable && <p className="lead">{inventoryData?.havePrivateBathrooms}</p>}
                    {editEnable &&
                      <Controller
                        name="havePrivateBathrooms"
                        defaultValue={inventoryData?.havePrivateBathrooms}
                        control={control}
                        rules={{
                          required: !skipValidation,
                        }}
                        render={({ field }) =>
                          <>
                            {
                              inventoryData?.havePrivateBathrooms === 'Yes'
                                ?
                                <Form.Check
                                  inline
                                  value={'Yes'}
                                  name="havePrivateBathrooms"
                                  label={'Yes'}
                                  type="radio"
                                  defaultChecked
                                  onChange={(value) => { field.onChange(value.currentTarget.value); }}
                                />
                                :
                                <Form.Check
                                  inline
                                  value={'Yes'}
                                  name="havePrivateBathrooms"
                                  label={'Yes'}
                                  type="radio"
                                  onChange={(value) => { field.onChange(value.currentTarget.value); }}
                                />
                            }

                            {
                              inventoryData?.havePrivateBathrooms === 'No'
                                ?
                                <Form.Check
                                  inline
                                  value={'No'}
                                  name="havePrivateBathrooms"
                                  label={'No'}
                                  type="radio"
                                  defaultChecked
                                  onChange={(value) => { field.onChange(value.currentTarget.value); }}
                                />
                                :
                                <Form.Check
                                  inline
                                  value={'No'}
                                  name="havePrivateBathrooms"
                                  label={'No'}
                                  type="radio"
                                  onChange={(value) => { field.onChange(value.currentTarget.value); }}
                                />
                            }

                          </>
                        }
                      />}
                    {editEnable &&
                      <ErrorMessage
                        errors={errors}
                        name="havePrivateBathrooms"
                        render={({ message }) => <div className="invalid-feedback-custom">Please select your answer!!</div>} />}
                  </Col>
                </Row>
                {hotelPropertyType && hotelPropertyType.toLowerCase() === 'hotel with extended stay' &&
                  <Row className='formSpace'>
                    <Col md={6} style={{ textAlign: 'right' }}>
                      <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Do you have a maximum length of stay?</Form.Label>
                    </Col>
                    <Col md={6} style={{ textAlign: 'left' }}>
                      {!editEnable && <p className="lead">{inventoryData?.doYouHaveMaximumLengthOfStay}</p>}
                      {editEnable &&
                        <Controller
                          name="doYouHaveMaximumLengthOfStay"
                          defaultValue={inventoryData?.doYouHaveMaximumLengthOfStay}
                          control={control}
                          rules={{
                            required: !skipValidation,
                          }}
                          render={({ field }) =>
                            <>
                              {
                                inventoryData?.doYouHaveMaximumLengthOfStay === 'Yes'
                                  ?
                                  <Form.Check
                                    inline
                                    value={'Yes'}
                                    name="doYouHaveMaximumLengthOfStay"
                                    label={'Yes'}
                                    type="radio"
                                    defaultChecked
                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangeDoYouHaveMaximumLengthOfStay(value.currentTarget.value) }}
                                  />
                                  :
                                  <Form.Check
                                    inline
                                    value={'Yes'}
                                    name="doYouHaveMaximumLengthOfStay"
                                    label={'Yes'}
                                    type="radio"
                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangeDoYouHaveMaximumLengthOfStay(value.currentTarget.value) }}
                                  />
                              }

                              {
                                inventoryData?.doYouHaveMaximumLengthOfStay === 'No'
                                  ?
                                  <Form.Check
                                    inline
                                    value={'No'}
                                    name="doYouHaveMaximumLengthOfStay"
                                    label={'No'}
                                    type="radio"
                                    defaultChecked
                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangeDoYouHaveMaximumLengthOfStay(value.currentTarget.value) }}
                                  />
                                  :
                                  <Form.Check
                                    inline
                                    value={'No'}
                                    name="doYouHaveMaximumLengthOfStay"
                                    label={'No'}
                                    type="radio"
                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangeDoYouHaveMaximumLengthOfStay(value.currentTarget.value) }}
                                  />
                              }

                            </>
                          }
                        />}
                      {editEnable &&
                        <ErrorMessage
                          errors={errors}
                          name="doYouHaveMaximumLengthOfStay"
                          render={({ message }) => <div className="invalid-feedback-custom">Please select your answer!!</div>} />}
                    </Col>
                  </Row>
                }


                {doYouHaveMaximumLengthOfStay && <Row className='formSpace'>
                  <Col md={6} style={{ textAlign: 'right' }}>
                    <Form.Label>{editEnable && <span className='requiredMark'>*</span>}  Maximum stay</Form.Label>
                  </Col>
                  <Col md={6} style={{ textAlign: 'left' }}>
                    {!editEnable && <p className="lead">{inventoryData?.maximumStayed}</p>}
                    {editEnable && <Form.Control min={0} type="number" className={`${errors.maximumStayed ? 'is-invalid' : ''}`} defaultValue={inventoryData?.maximumStayed} {...register("maximumStayed", { required: !skipValidation })} />}
                    {editEnable && <div className="invalid-feedback">Please input your answer!</div>}
                  </Col>
                </Row>}
                <Row className='formSpace'>
                  <Col md={6} style={{ textAlign: 'right' }}>
                  </Col>
                  <Col md={6} style={{ textAlign: 'left' }}>
                    {!loading && !editEnable && inventoryId && getLoggedUserType() === UserGroups.super_admin && (selectedBrand?.brandStatus === 'accepted') && (inventoryData?.status === InventoryStatus.PENDING || inventoryData?.status === InventoryStatus.REVIEW) &&
                      <Button id='approve-button' style={formButton} onClick={() => { onApprovedInventory() }}>Approve</Button>
                    }
                    {!loading && !editEnable && inventoryId && getLoggedUserType() === UserGroups.super_admin && inventoryData?.status === InventoryStatus.PENDING && (selectedBrand?.brandStatus === 'accepted') &&
                      <Button id='approve-button' style={formButton2} onClick={() => { onReviewedInventory() }}>Review</Button>
                    }
                    {!loading && !inventoryId && (
                      isFormComplete() ?
                        <Button type="submit" id='approve-button' hidden={showCreateButton} style={formButton}>Create</Button> :
                        <Button type="submit" id='approve-button' style={formButton} onClick={() => { setSkipValidation(true); onSubmit(watch()); }}>Save Progress</Button>
                    )}

                    {!loading && editEnable && inventoryId &&
                      <Button type="submit" id='approve-button' style={formButton}>Update</Button>
                    }
                    {!loading && !editEnable && inventoryId && inventoryData?.bedType?.length === 0 &&
                      <Button type="submit" id='approve-button' style={formButton}>Update</Button>
                    }

                    {
                      loading && <div style={{ textAlign: 'center' }}><Spinner animation="border" variant="warning" /></div>
                    }
                  </Col>
                </Row>
              </Col>
              <Col md={1}></Col>
            </Row>
          </Form>
        </div>
      </div>
    </>
  )
};

export default HotelInventoryDetailsForm;