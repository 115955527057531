import { useStoreActions } from 'easy-peasy';
import React, { useCallback } from 'react'
import { Row, Col, Breadcrumb, } from 'react-bootstrap';
import { Link } from 'react-router-dom';
const BreadCrumbPage: React.FC<any> = ({ allInOnePropertyBreadCrumb, allInOneHotelBreadCrumb, isPageName, propertyData, hotelData, selectedBrand, setFormOpen, inventoryData, selectedProperty, inventoryId, tab, selectedHotel, onClick }): JSX.Element => {

   const { getProperties, getInventoryList, getHotels, getHotelInventoryList } = useStoreActions<any>((actions) => ({
      getProperties: actions.property.getProperties,
      getHotels: actions.hotel.getHotels,
      getInventoryList: actions.inventory.getInventoryList,
      getHotelInventoryList: actions.hotelInventory.getHotelInventoryList
   }));

   //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   const refreshProperties = useCallback(() => {
      const params: any = {};
      if (selectedBrand) {
         params.brandId = selectedBrand.key ? selectedBrand.key : selectedBrand?._id;
      }

      getProperties(params)
   }, [getProperties, selectedBrand]);

   const refreshHotels = useCallback(() => {
      const params: any = {};
      if (selectedBrand) {
         params.brandId = selectedBrand.key ? selectedBrand.key : selectedBrand?._id;
      }

      getHotels(params)
   }, [getHotels, selectedBrand]);

   /////////////////////////////////////////////////////////////////////////////////////////////////////////
   const refreshInventories = useCallback(() => {
      const params: any = {};
      if (selectedProperty) {
         params.propertyId = selectedProperty._id;
      }
      getInventoryList(params);
   }, [getInventoryList, selectedProperty]);

   const refreshHotelInventories = useCallback(() => {
      const params: any = {};
      if (selectedHotel) {
         params.hotelId = selectedHotel._id;
      }
      getHotelInventoryList(params);
   }, [getHotelInventoryList, selectedHotel]);

   return (
      <div>
         {/* brand to property  */}
         {isPageName === 'Property' && !allInOnePropertyBreadCrumb &&
            <Row>
               <Col xs={12}><div className="">
                  <Breadcrumb className="mb-10">
                     <Breadcrumb.Item>
                        <Link to={{ pathname: "/admin/brand-management", state: { selectedBrand: selectedBrand } }} onClick={(e) => onClick(e)}>{selectedBrand?.name}</Link>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item >
                        <Link to="" onClick={(e) => { e.preventDefault(); refreshProperties(); onClick(e, 'property') }}>Property Management</Link>
                     </Breadcrumb.Item>
                     {!propertyData && <Breadcrumb.Item active>New Property</Breadcrumb.Item>}
                     {propertyData && <Breadcrumb.Item active >{propertyData.city ? `${propertyData.city} - ${propertyData.propertyName}` : propertyData.propertyName}</Breadcrumb.Item>}
                  </Breadcrumb>
               </div></Col>
            </Row>
         }

         {/* direct property management */}
         {isPageName === 'Property' && allInOnePropertyBreadCrumb &&
            <Row>
               <Col xs={12}><div className="">
                  <Breadcrumb className="mb-10">
                     <Breadcrumb.Item>
                        <Link to={{ pathname: "/admin/brand-management", state: { selectedBrand: selectedBrand, allInOnePropertyBreadCrumb: allInOnePropertyBreadCrumb, tab: tab } }} onClick={(e) => onClick(e)}>Property Management</Link>
                     </Breadcrumb.Item>
                     {!propertyData && <Breadcrumb.Item active>New Property</Breadcrumb.Item>}
                     {propertyData && <Breadcrumb.Item active >{propertyData.city ? `${propertyData.city} - ${propertyData.propertyName}` : propertyData.propertyName}</Breadcrumb.Item>}
                  </Breadcrumb>
               </div></Col>
            </Row>
         }
         {/* brand to property with inventory*/}
         {isPageName === 'Inventory' && !allInOnePropertyBreadCrumb &&
            <Row>
               <Col xs={12}><div className="">
                  <Breadcrumb className="mb-10">
                     <Breadcrumb.Item>
                        <Link to={{ pathname: "/admin/brand-management", state: { selectedBrand: selectedBrand } }} onClick={(e) => onClick(e)}>{selectedBrand?.name}</Link>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item>
                        <Link to={{ pathname: "/admin/property-management", state: { selectedProperty: selectedProperty, allInOnePropertyBreadCrumb: allInOnePropertyBreadCrumb, isBack: true } }} onClick={(e) => onClick(e)}>Property Management</Link>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item>
                        <Link to={{ pathname: "/admin/property-management", state: { selectedProperty: selectedProperty, allInOnePropertyBreadCrumb: allInOnePropertyBreadCrumb } }} onClick={(e) => onClick(e)}>{selectedProperty.city ? `${selectedProperty.city} - ${selectedProperty.propertyName}` : selectedProperty.propertyName}</Link>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item >
                        <Link to="" onClick={(e) => { e.preventDefault(); refreshInventories(); onClick(e, 'inventory') }}>Inventory Management</Link>
                     </Breadcrumb.Item>
                     {(!inventoryId || !inventoryData) && <Breadcrumb.Item active>New inventory</Breadcrumb.Item>}
                     {(inventoryData && inventoryId) && <Breadcrumb.Item active> {inventoryData?.moderate?.details?.new_value ? inventoryData?.moderate?.details?.new_value : inventoryData?.accommodationDetail}</Breadcrumb.Item>}
                  </Breadcrumb>
               </div></Col>
            </Row>
         }

         {/* direct property management */}
         {isPageName === 'Inventory' && allInOnePropertyBreadCrumb &&
            <Row>
               <Col xs={12}><div className="">
                  <Breadcrumb className="mb-10">
                     <Breadcrumb.Item>
                        <Link to={{ pathname: "/admin/brand-property-management" }} onClick={(e) => onClick(e)}>Property Management</Link>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item>
                        <Link to={{ pathname: "/admin/property-management", state: { selectedProperty: selectedProperty, allInOnePropertyBreadCrumb: allInOnePropertyBreadCrumb } }} onClick={(e) => onClick(e)}>{selectedProperty.city ? `${selectedProperty.city} - ${selectedProperty.propertyName}` : selectedProperty.propertyName}</Link>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item >
                        <Link to="" onClick={(e) => { e.preventDefault(); refreshInventories(); onClick(e, 'inventory') }}>Inventory Management</Link>
                     </Breadcrumb.Item>
                     {(!inventoryId || !inventoryData) && <Breadcrumb.Item active>New inventory</Breadcrumb.Item>}
                     {(inventoryData && inventoryId) && <Breadcrumb.Item active> {inventoryData?.moderate?.details?.new_value ? inventoryData?.moderate?.details?.new_value : inventoryData?.accommodationDetail}</Breadcrumb.Item>}
                  </Breadcrumb>
               </div></Col>
            </Row>
         }
         {/* brand to hotel  */}
         {isPageName === 'Hotel' && !allInOneHotelBreadCrumb &&
            <Row>
               <Col xs={12}><div className="">
                  <Breadcrumb className="mb-10">
                     <Breadcrumb.Item>
                        <Link to={{ pathname: "/admin/brand-management", state: { selectedBrand: selectedBrand } }} onClick={(e) => onClick(e)}>{selectedBrand?.name}</Link>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item >
                        <Link to="" onClick={(e) => { e.preventDefault(); refreshHotels(); onClick(e, 'hotel') }}>Hotel Management</Link>
                     </Breadcrumb.Item>
                     {!hotelData && <Breadcrumb.Item active>New Hotel</Breadcrumb.Item>}
                     {hotelData && <Breadcrumb.Item active >{hotelData.city ? `${hotelData.city} - ${hotelData.hotelName}` : hotelData.hotelName}</Breadcrumb.Item>}
                  </Breadcrumb>
               </div></Col>
            </Row>
         }
         {/* direct hotel management */}
         {isPageName === 'Hotel' && allInOneHotelBreadCrumb &&
            <Row>
               <Col xs={12}><div className="">
                  <Breadcrumb className="mb-10">
                     <Breadcrumb.Item>
                        <Link to={{ pathname: "/admin/brand-management", state: { selectedBrand: selectedBrand, allInOneHotelBreadCrumb: allInOneHotelBreadCrumb, tab: tab } }} onClick={(e) => onClick(e)}>Hotel Management</Link>
                     </Breadcrumb.Item>
                     {!hotelData && <Breadcrumb.Item active>New Hotel</Breadcrumb.Item>}
                     {hotelData && <Breadcrumb.Item active >{hotelData.city ? `${hotelData.city} - ${hotelData.hotelName}` : hotelData.hotelName}</Breadcrumb.Item>}
                  </Breadcrumb>
               </div></Col>
            </Row>
         }

         {/* brand to hotel with inventory*/}
         {isPageName === 'HotelInventory' && !allInOneHotelBreadCrumb &&
            <Row>
               <Col xs={12}><div className="">
                  <Breadcrumb className="mb-10">
                     <Breadcrumb.Item>
                        <Link to={{ pathname: "/admin/brand-management", state: { selectedBrand: selectedBrand } }} onClick={(e) => onClick(e)}>{selectedBrand?.name}</Link>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item>
                        <Link to={{ pathname: "/admin/hotel-management", state: { selectedHotel: selectedHotel, allInOneHotelBreadCrumb: allInOneHotelBreadCrumb, isBack: true } }} onClick={(e) => onClick(e)}>Hotel Management</Link>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item>
                        <Link to={{ pathname: "/admin/hotel-management", state: { selectedHotel: selectedHotel, allInOneHotelBreadCrumb: allInOneHotelBreadCrumb } }} onClick={(e) => onClick(e)}>{selectedHotel.city ? `${selectedHotel.city} - ${selectedHotel.hotelName}` : selectedHotel.hotelName}</Link>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item >
                        <Link to="" onClick={(e) => { e.preventDefault(); refreshHotelInventories(); onClick(e, 'hotelInventory') }}>Inventory Management</Link>
                     </Breadcrumb.Item>
                     {(!inventoryId || !inventoryData) && <Breadcrumb.Item active>New inventory</Breadcrumb.Item>}
                     {(inventoryData && inventoryId) && <Breadcrumb.Item active> {inventoryData?.moderate?.details?.new_value ? inventoryData?.moderate?.details?.new_value : inventoryData?.accommodationDetail}</Breadcrumb.Item>}
                  </Breadcrumb>
               </div></Col>
            </Row>
         }

         {/* direct hotel management */}
         {isPageName === 'HotelInventory' && allInOneHotelBreadCrumb &&
            <Row>
               <Col xs={12}><div className="">
                  <Breadcrumb className="mb-10">
                     <Breadcrumb.Item>
                        <Link to={{ pathname: "/admin/brand-hotel-management" }} onClick={(e) => onClick(e)}>Hotel Management</Link>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item>
                        <Link to={{ pathname: "/admin/hotel-management", state: { selectedHotel: selectedHotel, allInOneHotelBreadCrumb: allInOneHotelBreadCrumb } }} onClick={(e) => onClick(e)}>{selectedHotel.city ? `${selectedHotel.city} - ${selectedHotel.hotelName}` : selectedHotel.hotelName}</Link>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item >
                        <Link to="" onClick={(e) => { e.preventDefault(); refreshHotelInventories(); onClick(e, 'hotelInventory') }}>Inventory Management</Link>
                     </Breadcrumb.Item>
                     {(!inventoryId || !inventoryData) && <Breadcrumb.Item active>New inventory</Breadcrumb.Item>}
                     {(inventoryData && inventoryId) && <Breadcrumb.Item active> {inventoryData?.moderate?.details?.new_value ? inventoryData?.moderate?.details?.new_value : inventoryData?.accommodationDetail}</Breadcrumb.Item>}
                  </Breadcrumb>
               </div></Col>
            </Row>
         }
      </div>
   )
}

export default BreadCrumbPage;
