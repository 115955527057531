import { message } from 'antd';
import { Form, Col, Row, Button } from "react-bootstrap";
import { FC, useCallback, useEffect, useState } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import moment from "moment";
import { UserGroups } from "../../../../common/constants";
import { getLoggedUserType } from "../../../../common/functions";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { ProgrammeSendDataFormInput } from "../../../../common/interfaces";
import DatePicker from "react-datepicker";
import { toast } from 'react-toastify';
import { formButton } from "../../../../common/components-style";
import RFPBreadCrumbPage from "../../../common/RFPBredcrumPage";
import currency from '../../../../common/currency.json';
import { Select } from "antd";
const { Option } = Select;

const ProgrammeSpendDetails: FC<any> = ({ reportId, programmeId, setFormOpen, reload, selectedClient, selectedProgramme }): JSX.Element => {
	const [loading, setLoading] = useState<boolean>(false);
	const [formSelectedEndDate, setFormSelectedEndDate] = useState<any>(null);
	const [formSelectedStartDate, setFormSelectedStartDate] = useState<any>(null);
	const [selectedClientName, setSelectedClientName] = useState<string>("");
	const [selectedProgrammeName, setSelectedProgrammeName] = useState<string>("");
	const [selectedProgrammeId, setSelectedProgrammeId] = useState<string>("");
	const [clientId, setClientId] = useState<string>("");
	const [reportDetails, setReportDetails] = useState<any>();
	const [editEnable, setEditEnable] = useState<boolean>(false);
	const { register, handleSubmit, formState: { errors }, control } = useForm<ProgrammeSendDataFormInput>();
	const [accommodationTypeList, setAccommodationTypeList] = useState<any>([]);
	const [programmeList, setProgrammeList] = useState<any>([]);

	const {

		createReport,
		getRfpProcessByProgrammeId,
		getCorporateProgrammeById,
		getCorporateProgrammeByClient,
		getReportById,
		updateReport,
		getReportByProgrammeId
	} = useStoreActions<any>((actions) => ({

		createReport: actions.booking.createReport,
		getRfpProcessByProgrammeId: actions.rfpProcess.getRfpProcessByProgrammeId,
		getClientLocationByProgrammeId: actions.client.getClientLocationByProgrammeId,
		getCorporateProgrammeById: actions.client.getCorporateProgrammeById,
		getCorporateProgrammeByClient: actions.client.getCorporateProgrammeByClient,
		getReportById: actions.booking.getReportById,
		updateReport: actions.booking.updateReport,
		getReportByProgrammeId: actions.booking.getReportByProgrammeId,
	}))

	const {

		getCorporateProgrammeByIdSuccess,
		getCorporateProgrammeByIdError,
		getCorporateProgrammeByClientSuccess,
		getCorporateProgrammeByClientError,
		getReportByIdError,
		getReportByIdSuccess,
		reportCreationSuccess,
		reportCreationError,
		updateReportSuccess,
		updateReportError,

	} = useStoreState<any>((state) => ({

		getCorporateProgrammeByIdSuccess: state.client.getCorporateProgrammeByIdSuccess,
		getCorporateProgrammeByIdError: state.client.getCorporateProgrammeByIdError,
		getClientLocationByProgrammeIdSuccess: state.client.getClientLocationByProgrammeIdSuccess,
		getClientLocationByProgrammeIdError: state.client.getClientLocationByProgrammeIdError,
		getCorporateProgrammeByClientSuccess: state.client.getCorporateProgrammeByClientSuccess,
		getCorporateProgrammeByClientError: state.client.getProgrammeCorporateByClientError,
		getReportByIdError: state.booking.getReportByIdError,
		getReportByIdSuccess: state.booking.getReportByIdSuccess,
		reportCreationSuccess: state.booking.createReportSuccess,
		reportCreationError: state.booking.createReportError,
		reportLoading: state.booking.createReportLoading,
		updateReportSuccess: state.booking.updateReportSuccess,
		updateReportError: state.booking.updateReportError,

	}));
	// set the programme id if exists ////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		setSelectedProgrammeId(programmeId);
	}, [programmeId]);


	// setting the programme data to the local state ///////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (getCorporateProgrammeByIdSuccess?.data) {
			const { clientId, programmeName, _id } = getCorporateProgrammeByIdSuccess?.data;
			setClientId(clientId._id);
			setSelectedClientName(clientId.companyName);
			setSelectedProgrammeId(_id);
			setSelectedProgrammeName(programmeName);
		}
		if (getCorporateProgrammeByIdError) {
			toast.error(getCorporateProgrammeByIdError?.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});

		}
	}, [getCorporateProgrammeByIdError, getCorporateProgrammeByIdSuccess?.data, reportId]);

	////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (selectedProgrammeId) {
			getCorporateProgrammeById(selectedProgrammeId);
		}
	}, [getCorporateProgrammeById, selectedProgrammeId]);

	// setting the programme id data ////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (selectedProgrammeId) {
			getRfpProcessByProgrammeId(selectedProgrammeId);
		}
	}, [getRfpProcessByProgrammeId, selectedProgrammeId])


	useEffect(() => {
		if (reportId) {
			const payload = { _id: reportId };
			getReportById(payload);
			setEditEnable(false);
		} else {
			setEditEnable(true);
		}
	}, [getReportById, reportId]);

	useEffect(() => {
		if (getReportByIdSuccess?.data) {
			setReportDetails(getReportByIdSuccess?.data);
			setFormSelectedEndDate(new Date(getReportByIdSuccess?.data.endDate));
			setFormSelectedStartDate(new Date(getReportByIdSuccess?.data.startDate));
		}
	}, [getReportByIdError, getReportByIdSuccess?.data]);


	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (clientId) {
			const params: any = {};
			params.clientId = clientId;
			getCorporateProgrammeByClient(params);
		}
	}, [getCorporateProgrammeByClient, clientId]);


	//////////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (getCorporateProgrammeByClientSuccess) {
			const options = getCorporateProgrammeByClientSuccess.data
				.filter((item: any) => item._id !== selectedProgrammeId)
				.map((item: any) => ({
					value: item.programmeName,
					label: item.programmeName,
				}));
			const staticOption = { value: 'None', label: 'None' };
			setProgrammeList([staticOption, ...options]);
		}

		if (getCorporateProgrammeByClientError) {
			message.success(getCorporateProgrammeByClientError.message);
		}

	}, [getCorporateProgrammeByClientSuccess, getCorporateProgrammeByClientError, selectedProgrammeId])

	useEffect(() => {
		if (reportCreationError) {
			setLoading(false);
			toast.error(reportCreationError?.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
		}
		if (reportCreationSuccess) {
			setLoading(false);
			toast.success("Report created successfully", {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
			if (programmeId) {
				const payload = {
					programmeId: programmeId,
					clientId: selectedClient?.key ? selectedClient?.key : selectedClient?._id,
				};
				getReportByProgrammeId(payload);
			}

			setFormOpen(false);
		}
		setLoading(false);
	}, [getReportByProgrammeId, programmeId, reportCreationError, reportCreationSuccess, selectedClient?._id, selectedClient?.key, setFormOpen]);

	useEffect(() => {
		if (updateReportSuccess) {
			setLoading(false);
			toast.success("Report updated successfully", {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
			if (updateReportSuccess?.data?._id) {
				const payload = { _id: updateReportSuccess?.data?._id };
				getReportById(payload);
			}
			setEditEnable(false);
		}
		if (updateReportError) {
			setLoading(false);
			toast.error(updateReportError?.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
			setEditEnable(false);
		}
		setLoading(false);
	}, [getReportById, updateReportError, updateReportSuccess]);


	////////////////////////////////////////////////////////////////////////////////////////////////////
	const onSubmit: SubmitHandler<ProgrammeSendDataFormInput> = (values) => {
		setLoading(true);
		const programmeId = selectedProgrammeId;
		const formattedData = {
			id: reportDetails?._id,
			clientId,
			programmeId,
			endDate: formSelectedEndDate ? moment(formSelectedEndDate).format("YYYY-MM-DD") : moment(values.endDate).format("YYYY-MM-DD"),
			startDate: moment(values.startDate).format("YYYY-MM-DD"),
			accommodationType: values.accommodationType,
			associatedProgramme: values.associatedProgramme,
			reportName: values.reportName,
			currency: values.currency

		}
		if (reportDetails?._id) {
			updateReport(formattedData);
		}
		else {
			createReport(formattedData);
		}
	};

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const changeFormEditable = useCallback(() => {
		if (editEnable) {
			setEditEnable(false);
		} else {
			setEditEnable(true);
		}
	}, [editEnable]);


	//////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		const locationListTemp: any = [{
			_id: 'hotel',
			name: "Hotel",
		},
		{
			_id: 'serviced_apartments',
			name: "Serviced Accommodation",
		},
		{
			_id: 'both',
			name: "Both",
		},
		];

		const typeOfPartner = locationListTemp.map((val: any, key: number) => {
			return (
				<Option key={key} data={val} value={`${val._id}`} >{val.name}</Option>
			)
		});
		setAccommodationTypeList(typeOfPartner);

	}, []);

	const renderCurrency = useCallback(() => {
		if (currency) {
			return currency.map((cur: any, key: number) => {
				return (
					<Option key={cur.cc} value={`${cur.cc}`} >{cur.name} <span className="text-blue-400 font-bold ml-2">({cur.symbol}) - {cur.cc}</span></Option>
				)
			});
		}

	}, []);

	// 	---------------------------------------------------------------------------------------------------
	return (
		<>

			{reportId &&
				<RFPBreadCrumbPage isPageName="programmeSpendReport" selectedClient={selectedClient} selectedProgramme={selectedProgramme} selectedClientName={selectedClientName} selectedProgrammeName={selectedProgrammeName} reportId={reportId} programmeId={programmeId} startDate={reportDetails?.reportName}></RFPBreadCrumbPage>
			}
			<div className="create-update-wrapper" style={{ marginLeft: "40px" }}>
				<Form onSubmit={handleSubmit(onSubmit)} >
					<Row className='formSpace'>
						<Col md={3} style={{ textAlign: 'right', }}>
							<Form.Label>{<span className='requiredMark'>*</span>} Company name</Form.Label>
						</Col>

						<Col md={9} style={{ textAlign: 'left' }}>
							{!editEnable && <p className="lead">{selectedClientName}</p>}
							{editEnable && <Form.Control className={`${errors.companyName ? 'is-invalid' : ''}`} defaultValue={selectedClientName} {...register("companyName", { required: true })} readOnly={true} />}
							{editEnable && <div className="invalid-feedback">Please input company name!</div>}
						</Col>
					</Row>
					<Row className='formSpace'>
						<Col md={3} style={{ textAlign: 'right' }}>
							<Form.Label>{<span className='requiredMark'>*</span>} Name of programme</Form.Label>
						</Col>

						<Col md={9} style={{ textAlign: 'left' }}>
							{!editEnable && <p className="lead">{selectedProgrammeName}</p>}
							{editEnable && <Form.Control className={`${errors.selectedProgrammeName ? 'is-invalid' : ''}`} defaultValue={selectedProgrammeName} {...register("selectedProgrammeName", { required: true })} readOnly={true} />}
							{editEnable && <div className="invalid-feedback">Please input programme name!</div>}
						</Col>
					</Row>
					<Row className='formSpace'>
						<Col md={3} style={{ textAlign: 'right' }}>
							<Form.Label>{<span className='requiredMark'>*</span>} Report Name</Form.Label>
						</Col>

						<Col md={9} style={{ textAlign: 'left' }}>
							{!editEnable && <p className="lead">{reportDetails?.reportName}</p>}
							{editEnable && <Form.Control className={`${errors.reportName ? 'is-invalid' : ''}`} defaultValue={reportDetails?.reportName} {...register("reportName", { required: true })} />}
							{editEnable && <div className="invalid-feedback">Please input report name!</div>}
						</Col>
					</Row>
					<Row className='formSpace'>
						<Col md={3} style={{ textAlign: 'right' }}>
							<Form.Label>{editEnable && <span className='requiredMark'>*</span>} Accommodation type</Form.Label>
						</Col>
						<Col md={9} style={{ textAlign: 'left' }}>
							{!editEnable && <p className="lead">{reportDetails?.accommodationType}</p>}
							{editEnable &&
								<Controller
									name="accommodationType"
									defaultValue={reportDetails?.accommodationType}
									control={control}
									rules={{
										required: true,
									}}
									render={({ field }) =>
										<Select defaultValue={reportDetails?.accommodationType} className={`${errors.accommodationType ? 'is-invalid' : ''}`} style={{ width: '100%' }}
											onChange={(value, option) => {
												field.onChange(value)
											}}
											showSearch>
											{accommodationTypeList}
										</Select>
									}
								/>}
							{editEnable && <div className="invalid-feedback">Please select a accommodation type!</div>}
						</Col>
					</Row>
					<Row className='formSpace'>
						<Col md={3} style={{ textAlign: 'right' }}>
							<Form.Label>{editEnable && <span className='requiredMark'>*</span>} Associated Programme(s)</Form.Label>
						</Col>
						<Col md={9} style={{ textAlign: 'left' }}>
							{!editEnable && (
								<p className="lead">
									{reportDetails?.associatedProgramme
										.filter(Boolean)
										.join(", ")}
								</p>
							)}
							{editEnable && (
								<Controller
									name="associatedProgramme"
									defaultValue={reportDetails?.associatedProgramme}
									control={control}
									rules={{
										required: true,
									}}
									render={({ field }) => {
										const filteredProgrammeList =
											field?.value?.includes("None") ?
												programmeList.filter((programme) => programme.value === "None") :
												programmeList;

										return (
											<Select
												mode="multiple"
												value={field.value}
												onChange={(values) => {
													if (values.includes("None")) {
														field.onChange(["None"]);
													} else {
														field.onChange(values.filter((val) => val !== "None"));
													}
												}}
												className={`${errors.associatedProgramme ? "is-invalid" : ""}`}
												style={{ width: "100%" }}
												showSearch
												placeholder="Select Programmes"
											>
												{filteredProgrammeList.map((programme) => (
													<Select.Option key={programme.value} value={programme.value}>
														{programme.label}
													</Select.Option>
												))}
											</Select>
										);
									}}
								/>
							)}
							{editEnable && (
								<div className="invalid-feedback">Please select at least one associated programme!</div>
							)}
						</Col>
					</Row>
					<Row className='formSpace'>
						<Col md={3} style={{ textAlign: 'right' }}>
							<Form.Label>{editEnable && <span className='requiredMark'>*</span>} Currency</Form.Label>
						</Col>
						<Col md={9} style={{ textAlign: 'left' }}>
							{!editEnable && <p className="lead">{reportDetails?.currency}</p>}
							{editEnable &&
								<Controller
									name="currency"
									defaultValue={reportDetails?.currency}
									control={control}
									rules={{
										required: true,
									}}
									render={({ field }) =>
										<Select defaultValue={reportDetails?.currency} className={`${errors.currency ? 'is-invalid' : ''}`} style={{ width: '100%' }}
											onChange={(value, option) => {
												field.onChange(value)
											}}
											showSearch>
											{renderCurrency()}
										</Select>
									}
								/>}
							{editEnable && <div className="invalid-feedback">Please select a currency!</div>}
						</Col>
					</Row>
					<Row className='formSpace'>
						<Col md={3} style={{ textAlign: 'right' }}>
							<Form.Label>{<span className='requiredMark'>*</span>}Data start date</Form.Label>
						</Col>

						<Col md={9} style={{ textAlign: 'left' }}>
							{!editEnable && <p className="lead">{moment(reportDetails?.startDate).format('D MMM YYYY')} </p>}
							{
								editEnable &&
								<Controller
									name='startDate'
									control={control}
									rules={{ required: true }}
									defaultValue={(reportDetails?.startDate) ? new Date(reportDetails?.startDate) : undefined}
									render={
										({ field }) => {
											return (
												<DatePicker
													dateFormat="d MMM yyyy"
													placeholderText="Select start date"
													className="form-control"
													selected={field?.value}
													onChange={(date) => {
														field.onChange(date);
														setFormSelectedStartDate(date);
													}}
												/>
											)
										}
									}
								/>
							}
							{errors.startDate && <p><span className="form-error" >This field is required</span></p>}
						</Col>
					</Row>
					<Row className='formSpace'>
						<Col md={3} style={{ textAlign: 'right' }}>
							<Form.Label>{<span className='requiredMark'>*</span>} Data end Date</Form.Label>
						</Col>
						<Col md={9} style={{ textAlign: 'left' }}>
							{!editEnable && <p className="lead">{moment(reportDetails?.endDate).format('D MMM YYYY')} </p>}
							{editEnable && <Controller
								name='endDate'
								control={control}
								rules={{ required: true }}
								defaultValue={(reportDetails?.endDate) ? new Date(reportDetails?.endDate) : undefined}
								render={
									({ field }) => {
										return (
											<DatePicker
												dateFormat="d MMM yyyy"
												placeholderText="Select end date"
												className="form-control"
												selected={field?.value}
												minDate={formSelectedStartDate ? new Date(formSelectedStartDate) : new Date()}
												onChange={(date) => {
													field.onChange(date);
													setFormSelectedEndDate(date);
												}}
											/>
										)
									}
								}
							/>
							}
							{errors.endDate && <p><span className="form-error" >This field is required</span></p>}
						</Col>
					</Row>

					<Row style={{ paddingTop: '30px' }} className='formSpace'>
						<Col md={3} style={{ textAlign: 'right' }}>

						</Col>
						<Col md={3} >
							{getLoggedUserType() === UserGroups.super_admin && !loading && editEnable && reportId && <Button id='create-button' type="submit">{'Update'}</Button>}
							{getLoggedUserType() === UserGroups.super_admin && !loading && !reportId && <Button id='create-button' className="btn_design" type="submit"
							>{'Create'}
							</Button>}
							{
								!loading && !editEnable && reportId && <Button id='edit-button' style={formButton} onClick={changeFormEditable} >Edit</Button>
							}
						</Col>
					</Row>

				</Form>
			</div>
		</>
	)

}

export default ProgrammeSpendDetails;
