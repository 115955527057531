import React, { useEffect, useMemo, useCallback, useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import _ from 'lodash';
import { capitalizeRole, canBrandModuleWrite } from '../../../../../common/functions';
import BrandUserForm from './BrandUserForm';
import { UserGroups, UserAccess, UserProfile } from '../../../../../common/constants';
import PermissionModel from './PermissionModel';
import BrandUserView from './BrandUserView';
import { Button, OverlayTrigger, Tooltip, Row, Col } from 'react-bootstrap';
import { $mysaMegenta, $mysaTeal, $mysaYellow, mysaTableTheme, overlayTriggerDelay, resendButton, routingButton, tableUserStyles } from '../../../../../common/components-style';
import ViewEdit from '../../../../../images/icons/svg/outside/Icon-Function-View-Edit.svg';
import Permission from '../../../../../images/icons/svg/outside/Icon-Function-Permission.svg';
import DataTable, { createTheme } from 'react-data-table-component';
import { toast } from 'react-toastify';

import SweetAlert from 'react-bootstrap-sweetalert';
// import { HelpModal } from '../../../../common/HelpModal';

////////////////////// table theme setting ////////////////////////////////////
createTheme('mysaTable', mysaTableTheme, 'light');


const BrandUsersList: React.FC<any> = ({ brandId, selectedBrand }): JSX.Element => {
	const [title, setTitle] = useState('');
	const [selectedUser, setSelectedUser] = useState(null);
	const [loadModel, setLoadModel] = useState(false)
	const [brandProperties, setBrandProperties] = useState([]);
	const [userMappingData, setUserMappingData] = useState([])
	const [showForm, setShowForm] = useState(false);
	const [showView, setShowView] = useState(false);

	const [showList, setShowList] = useState<boolean>(false);

	const { onResendPassword, getUserMapping, resetAuth, getBrandProperties, onloadBrand } = useStoreActions<any>((actions) => ({
		resetAuth: actions.auth.resetAuth,
		onResendPassword: actions.auth.onResendPassword,
		getUserMapping: actions.user.getUserMapping,
		getBrandProperties: actions.property.getBrandProperties,
		onloadBrand: actions.brand.onloadBrand,
	}));

	const { resendPassword, resendPasswordError, getUserMappingSuccess, getBrandPropertiesSuccess, loadBrandSuccess } = useStoreState<any>((state) => ({
		resendPassword: state.auth.resendPassword,
		resendPasswordError: state.auth.resendPasswordError,
		getUserMappingSuccess: state.user.getUserMappingSuccess,
		getBrandPropertiesSuccess: state.property.getBrandPropertiesSuccess,
		loadBrandSuccess: state.brand.loadBrandSuccess,
	}));

	useEffect(() => {

		getUserMapping({ brandId });
		onloadBrand(brandId);

		getBrandProperties(brandId);
	}, [brandId, getBrandProperties, getUserMapping, onloadBrand, selectedBrand]);

	// useEffect(() => {
	// 	if (selectedBrand) {
	// 		const { accommodationType, parentAccommodationCompany } = selectedBrand;
	// 		const isAccommodationTypeEmpty = !accommodationType || accommodationType.trim() === "";
	// 		const isParentCompanyEmpty = !parentAccommodationCompany || parentAccommodationCompany.trim() === "";

	// 		setShowList(isAccommodationTypeEmpty && isParentCompanyEmpty);
	// 		console.log((isAccommodationTypeEmpty && isParentCompanyEmpty), 'fdfgfd')
	// 	}
	// }, [selectedBrand]);

	// console.log(showList, 'showList')

	useEffect(() => {
		if (resendPassword) {
			// message.success('password resend successful, Please check the email');
			toast.success("password resend successful, Please check the email", {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			})
			resetAuth()
		}

		if (resendPasswordError) {
			// message.error(resendPasswordError.message);
			toast.error(resendPasswordError.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
			resetAuth();
		}

		if (getBrandPropertiesSuccess) {
			setBrandProperties(getBrandPropertiesSuccess?.data)
		}

		if (loadBrandSuccess) {
			setTitle(loadBrandSuccess.data.name);
		}

	}, [resendPassword, resetAuth, resendPasswordError, getBrandPropertiesSuccess, loadBrandSuccess]);

	const reload = useCallback(() => {
		setTimeout(() => getUserMapping({ brandId }), 1000);
		setShowForm(false);
	}, [brandId, getUserMapping])

	const data: any = useMemo(() => {
		if (getUserMappingSuccess) {
			const { result, poolUsers } = getUserMappingSuccess.data;
			setUserMappingData(result);
			const mappedData = _.map(result, (val) => {
				const role = _.filter(val.brands, (a) => a.brandId === brandId).shift()?.role || ''
				const arrId = _.filter(val.brands, (a) => a.brandId === brandId).shift()?._id || ''
				const access = _.filter(val.brands, (a) => a.brandId === brandId).shift()?.access || ''
				const userStatus = poolUsers[val.user?.email]?.UserStatus
				const properties = _.filter(val.brands, (a) => a.brandId === brandId).shift()?.properties || []
				const isFirstUser = _.filter(val.brands, (a) => a.brandId === brandId).shift()?.isFirstUser

				return {
					userId: val?.user?._id,
					firstName: val?.user?.firstName,
					lastName: val?.user?.lastName,
					email: val?.user?.email,
					phone: val?.user?.phone,
					mobile: val?.user?.mobile,
					position: val?.user?.position,
					userProfile: val?.user?.userProfile,
					userStatus: userStatus,
					userType: (role === UserGroups.brand_admin && isFirstUser) ? capitalizeRole(UserGroups.chief_brand_admin) : capitalizeRole(role),
					status: val.status,
					data: val,
					role,
					arrId,
					access,
					properties,
					isFirstUser
				}
			});
			let filterData: any = [];
			filterData = _.filter(mappedData, (a) => _.some(a.access, (x) => [
				UserAccess.brand_access.details.code,
				// UserAccess.brand_access.audit.code,
				UserAccess.brand_access.bookings.code,
				UserAccess.brand_access.reporting.code,
				UserAccess.brand_access.tenders.code,
				UserAccess.brand_access.users.code
			].includes(x.module)) || a.role === UserGroups.brand_admin || (!a.properties.length || !a.access.length));

			return filterData;

		}
		return []
	}, [brandId, getUserMappingSuccess])

	const columns = [


		{ name: 'First name', selector: row => row.firstName, maxWidth: '180px' },
		{ name: 'Last name', selector: row => row.lastName, maxWidth: '180px' },
		{ name: 'Email', selector: row => row.email, minWidth: '220px' },
		{ name: 'User type', selector: row => row.userType, minWidth: '110px', maxWidth: '160px' },
		{
			name: 'Status',
			// minWidth: '180px',
			// maxWidth: '250px' ,
			cell: (row) => {
				let color = "";
				if (row.userStatus !== 'CONFIRMED') color = $mysaYellow;
				if (row.userStatus === 'CONFIRMED') color = $mysaTeal;
				if (row.userStatus === 'FORCE_CHANGE_PASSWORD') color = $mysaMegenta;
				if (row.status === 'inactive') color = $mysaMegenta;

				return (

					<span style={{ color: color, fontWeight: 'bold', textTransform: 'capitalize' }} className='text-capitalize'>
						{
							(row.status === 'inactive') && 'DISABLED'
						}
						{
							(row.status !== 'inactive') && `${row.userStatus}`
						}
						{row.status !== 'inactive' && row.userStatus === 'FORCE_CHANGE_PASSWORD' && <Button style={resendButton} id='resend-button' onClick={() => onResendPassword({ email: row.email })} className="ml-2 mt-1" >Resend</Button>}
					</span>
					// <span style={{ color: color, fontWeight: 'bold', textTransform: 'capitalize' }} >{row.userStatus} {row.userStatus === 'FORCE_CHANGE_PASSWORD' && <Button onClick={() => onResendPassword({ email: row.email })} style={resendButton} id='resend-button' >Resend</Button>}</span>
					// <>
					// 	<span style={{ color: color, fontWeight: 'bold', textTransform: 'capitalize' }} className='text-capitalize'>{row.userStatus}</span>
					// 	{row.userStatus === 'FORCE_CHANGE_PASSWORD' && <Button style={resendButton} id='resend-button' onClick={() => onResendPassword({ email: row.email })} className="ml-5" >Resend</Button>}
					// </>
				)
			}
		},
		{
			name: 'User profile',
			// minWidth: '120px',
			// button: true,
			cell: (row) => {
				let color = "";
				if (row.userProfile !== UserProfile.COMPLETED) color = $mysaMegenta;
				if (row.userProfile === UserProfile.COMPLETED) color = $mysaTeal;

				return (
					<span style={{ color: color, fontWeight: 'bold' }} className='text-capitalize'>{row.userProfile}</span>
				);

			}
		},
		{
			name: 'Action',
			button: true,
			cell: (row) => (
			
				<>
					{/* {(row.role === UserGroups.brand_admin || row.role === UserGroups.chief_brand_admin) && <OverlayTrigger
						placement="top"
						delay={overlayTriggerDelay}
						overlay={<Tooltip id='tooltip-table-top'>View / Edit</Tooltip>}
					>
						<img
							style={!(row.role === UserGroups.brand_admin || row.role === UserGroups.chief_brand_admin || !canBrandModuleWrite(brandId, UserAccess.brand_access.users.code)) ?
								{ marginLeft: '-2px' } : { marginLeft: '-32px' }}
							className='iconSizes' src={ViewEdit} alt="" onClick={() => { setSelectedUser(row); setShowView(true) }} />
					</OverlayTrigger>} */}
					{(row.role !== UserGroups.brand_admin || row.role !== UserGroups.chief_brand_admin) && <OverlayTrigger
						placement="top"
						delay={overlayTriggerDelay}
						overlay={<Tooltip id='tooltip-table-top'>View / Edit</Tooltip>}
					>
						<img
							style={!(row.role === UserGroups.brand_admin || row.role === UserGroups.chief_brand_admin || !canBrandModuleWrite(brandId, UserAccess.brand_access.users.code)) ?
								{ marginLeft: '-2px' } : { marginLeft: '-32px' }}
							className='iconSizes'
							src={ViewEdit}
							alt=""
							onClick={() => { setSelectedUser(row); setShowView(true) }} />
					</OverlayTrigger>}
					{!((row.role === UserGroups.brand_admin || row.role === UserGroups.chief_brand_admin) || !canBrandModuleWrite(brandId, UserAccess.brand_access.users.code)) && <OverlayTrigger
						placement="top"
						delay={overlayTriggerDelay}
						overlay={<Tooltip id='tooltip-table-top'>Permission</Tooltip>}

					>
						<img className='iconSizes' src={Permission} alt="" onClick={() => { setLoadModel(true); setSelectedUser(row) }} />
					</OverlayTrigger>}
				</>
			)

		},
	];

	return (
		<div style={{ marginTop: "-2%", paddingTop: "4px" }}>
			<Row>
				{
					// title && <Row>
					// 	<Col xs={12}><div className="content-title">{title} - {propertyData?.city}</div></Col>
					// </Row>


				}
				<Col xs={9}>{title && <div className="content-title">{title}</div>}</Col>
				<Col xs={3} className="text-right">
					<div className='d-flex align-items-center justify-content-end gx-2'>
						{/* <HelpModal /> */}
						{canBrandModuleWrite(brandId, UserAccess.brand_access.users.code) && <Button style={routingButton} id='routing-button' hidden={showForm || showView || showList} className='float-right' onClick={() => { setSelectedUser(null); setShowForm(true) }}><b>New User</b></Button>}
					</div>
					{/* <Button style={routingButton} id='routing-button' hidden={showForm || showView} className='float-right' onClick={() => { setSelectedUser(null); setShowForm(true) }}><b>New User</b></Button> */}
				</Col>
			</Row>


			{/* <Button type="primary" hidden={!showForm} className='float-right' onClick={() => setShowForm(false)}>User List</Button> */}
			{showForm && <BrandUserForm data={data} selectedBrand={selectedBrand} selectedUser={selectedUser} brandId={brandId} close={() => setShowForm(false)} reload={reload} />}
			{showView && <BrandUserView selectedUser={selectedUser} close={() => setShowView(false)} reload={reload} brandId={brandId} />}
			{showForm || showView || showList ||
				<div className='mt-4 brandUser_table'>
					<DataTable
						columns={columns}
						data={data}
						pagination
						striped={true}
						customStyles={tableUserStyles}
						theme='mysaTable'
						fixedHeader={true}
						noDataComponent={'Updating records'}
					/>
				</div>
			}
			{showList &&
				<SweetAlert
					show={showList}
					warning
					showCancel={true}
					focusCancelBtn={true}
					// confirmBtnText="Yes archive it!"
					// confirmBtnBsStyle="danger"
					title="Please complete Parent Company and Property Type before inviting Chief Brand Admin"
					onConfirm={() => {
						// onApproveArchiveBrand(brandArchive);
						setShowList(false);
						// setArchivePopop(false);
					}}
				// onCancel={() => {
				// 	setShowList(false);
				// }}
				>
				</SweetAlert>


			}

			{loadModel && <PermissionModel brandId={brandId} userMappingData={userMappingData} brandProperties={brandProperties} selectedUser={selectedUser} onClose={() => { setLoadModel(false); getUserMapping({ brandId }) }} />}
		</div>
	)
}

export default BrandUsersList
