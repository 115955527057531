import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { setStorage } from "../../../common/storage";
import {
  $mysaMegenta,
  $mysaRed,
  $mysaTeal,
  $mysaYellow,
  mysaTableTheme,
  overlayTriggerDelay,
  routingButton,
  tableStyles,
} from "../../../common/components-style";
import { Button, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import ViewEdit from "../../../images/icons/svg/outside/Icon-Function-View-Edit.svg";
import DataTable, { createTheme } from "react-data-table-component";
import {
  HotelStatus,
  UserAccess,
  UserGroups,
} from "../../../common/constants";
import {
  canBrandHotelModuleWrite,
  getLoggedUserType,
} from "../../../common/functions";
import Archive from "../../../images/icons/svg/outside/Icon-Function-Archive.svg";
import SweetAlert from "react-bootstrap-sweetalert";
import { useStoreActions, useStoreState } from "easy-peasy";
import { toast } from "react-toastify";

////////////////////// table theme setting ////////////////////////////////////
createTheme("mysaTable", mysaTableTheme, "light");

const HotelList: React.FC<any> = ({
  hotels,
  loadForm,
  refreshHotels,
  hotelId,
  selectedBrand,
  formOpen,
  setFormOpen,
  setHotelId,
}): JSX.Element => {
  /**
   * global action
   */
  const { updateHotel } = useStoreActions<any>((actions) => ({
    updateHotel: actions.hotel.updateHotel,
  }));

  /**
   * global state
   */
  const { updateHotelError, updateHotelSuccess } = useStoreState<any>(
    (state) => ({
      updateHotelError: state.hotel.updateHotelError,
      updateHotelSuccess: state.hotel.updateHotelSuccess,
    })
  );

  /**
   * local state
   */
  const [archivePopop, setArchivePopop] = useState<boolean>(false);
  const [hotelStatus, setHotelStatus] = useState<string>("");
  const [hotelArchive, setHotelArchive] = useState<any>("");
  const [data, setData] = useState<any>([]);

  /**
   * update state
   */
  const onHotelArchive = useCallback(
    (hotel) => {
      updateHotel(hotel);
    },
    [updateHotel]
  );

  /**
   * manage state
   */
  useEffect(() => {
    if (updateHotelSuccess) {
      toast.success("Hotel archived successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
      refreshHotels();
    }

    if (updateHotelError) {
      toast.error(updateHotelError.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "foo-bar",
      });
    }
  }, [refreshHotels, updateHotelError, updateHotelSuccess]);

  useEffect(() => {
    let temData: any = [];

    if (hotelStatus.length > 1) {
      temData = _.filter(hotels.filterHotels, (hotel) => {
        if (hotelStatus === hotel.hotelStatus) {
          return {
            _id: hotel._id,
            brand: hotel.brandId.name,
            city: hotel.city,
            hotelName: hotel.hotelName,
            status: hotel.status,
            country: hotel.country,
            hotelStatus: hotel.hotelStatus,
            externalId: hotel.externalId,
            brandId: hotel.brandId._id,
            auditStatus: hotel.auditStatus,
          };
        }
      });
    } else {
      temData = _.filter(hotels.filterHotels, (hotel) => {
        if (HotelStatus.archived !== hotel.hotelStatus) {
          return {
            _id: hotel._id,
            brand: hotel.brandId.name,
            city: hotel.city,
            hotelName: hotel.hotelName,
            status: hotel.status,
            country: hotel.country,
            hotelStatus: hotel.hotelStatus,
            externalId: hotel.externalId,
            brandId: hotel.brandId._id,
            auditStatus: hotel.auditStatus,
          };
        }
      });
    }

    setData(temData);
  }, [hotels.filterHotels, hotelStatus]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const columns = [
    {
      name: "Hotel",
      selector: (row) => row.hotelName,
    },
    {
      name: "City",
      selector: (row) => row.city,
    },
    {
      name: "Country",
      selector: (row) => row.country,
    },
    {
      name: "Status",
      cell: (row) => {
        let color = "";
        if (row.hotelStatus === HotelStatus.pending) color = $mysaYellow;
        if (row.hotelStatus === HotelStatus.approved) color = $mysaTeal;
        if (row.hotelStatus === HotelStatus.awaiting)
          color = $mysaMegenta;
        if (row.hotelStatus === HotelStatus.review) color = $mysaYellow;
        if (row.hotelStatus === HotelStatus.archived) color = $mysaRed;
        return (
          <span
            style={{ color: color, fontWeight: "bold" }}
            className="text-capitalize"
          >
            {row.hotelStatus}
          </span>
        );
      },
    },
    {
      name: "Action",
      button: true,
      cell: (row: any) => {
        // let active = (row.status === 'active') ? 1 : 0;

        return (
          <>
            <OverlayTrigger
              placement="top"
              delay={overlayTriggerDelay}
              overlay={<Tooltip id="tooltip-table-top">View / Edit</Tooltip>}
            >
              <img
                className="iconSizes"
                src={ViewEdit}
                alt=""
                onClick={() => {
                  loadForm(row._id, row.externalId);
                  setStorage("myo_selected_hotel", row);
                }}
              />
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              delay={overlayTriggerDelay}
              overlay={<Tooltip id="tooltip-table-top">Archive</Tooltip>}
            >
              <img
                className="iconSizes"
                src={Archive}
                alt=""
                onClick={() => {
                  const data = {
                    id: row?._id,
                    hotelStatus: HotelStatus.archived,
                  };
                  setHotelArchive(data);
                  setArchivePopop(true);
                }}
              />
            </OverlayTrigger>
          </>
        );
      },
    },
  ];

  return (
    <>
      <div className="mt-2">
        { (
          <>
            <Row className="btn_wrapper mb-2">
              <Col xs={2}>
              </Col>
              <Col xs={8}></Col>
              <Col xs={2}>
                { (
                    <Button
                      hidden={formOpen}
                      style={routingButton}
                      id="routing-button"
                      onClick={() => {
                        setFormOpen(true);
                        setHotelId(null);
                      }}
                      className="float-right mb-3"
                    >
                      <b>Create New Hotel</b>
                    </Button>
                  )}
              </Col>
            </Row>
          </>
        )}
        <DataTable
          columns={columns}
          data={data}
          pagination
          striped={true}
          customStyles={tableStyles}
          theme="mysaTable"
          fixedHeader={false}
          noDataComponent={"Updating records"}
          paginationPerPage={50}
          paginationRowsPerPageOptions={[10, 50, 100, 200]}
        />
      </div>

      <SweetAlert
        show={archivePopop}
        warning
        showCancel={true}
        focusCancelBtn={true}
        confirmBtnText="Yes archive it!"
        confirmBtnBsStyle="danger"
        title="Are you sure you wish to archive this hotel?"
        onConfirm={() => {
          onHotelArchive(hotelArchive);
          setArchivePopop(false);
        }}
        onCancel={() => {
          setArchivePopop(false);
        }}
      ></SweetAlert>
    </>
  );
};

export default HotelList;
