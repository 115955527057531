import { FC, useCallback, useEffect, useState } from "react";
import RFPBreadCrumbPage from "../../../common/RFPBredcrumPage";
import { Col, Row, Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { formButton } from "../../../../common/components-style";
import { InviteInput } from "../../../../common/interfaces";
import { getStorage, setStorage } from "../../../../common/storage";



const InviteParameters: FC<any> = ({ rfpColor, rfpStatus, rfpName, programmeId, rfpProcessId, selectedClient, selectedProgramme, selectedClientNames }) => {
    const [selectedClientName, setSelectedClientName] = useState<string>("");
    const [selectedProgrammeName, setSelectedProgrammeName] = useState<string>("");

    // const [inviteOption, setInviteOption] = useState<Array<any>>([]);

    // const [selectInviteType, setSelectInviteType] = useState<any>();

    const { register, watch, handleSubmit } = useForm<InviteInput>();

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (selectedProgramme) {
            setSelectedProgrammeName(selectedProgramme?.programmeName);
        }
        if (selectedClientNames) {
            setSelectedClientName(selectedClientNames);
        }
    }, [selectedClientNames, selectedProgramme]);


    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {

        // const invite: any = [
        //     {
        //         value: 'All_Brands',
        //         label: <p style={{ fontWeight: 'bold', fontSize: '20px' }}>Add all properties relevant to the 'Accommodation Type' for all locations included in the RFP. </p>,
        //     },
        //     {
        //         value: 'Current_programme_only',
        //         label: <p style={{ fontWeight: 'bold', fontSize: '20px' }}>Add all properties from current Programme </p>,
        //     },
        // ];

        // setInviteOption(invite);
    }, []);

    /////////////////////////////////////////////////
    const onChangeInvite = (event) => {
        // console.log(event.target.value, 'value');
        // if (event.target.value) {
        //     setSelectInviteType(event.target.value);
        // } else {
        //     setSelectInviteType("");
        // }
    };

    const onSubmit = useCallback(() => {
        setStorage('selectInviteType', watch('inviteType'));
    }, [watch])

    // const renderType = useCallback(() => {
    //     if (inviteOption) {

    //         return inviteOption.map((at: any, key: number) => {
    //             return (
    //                 <Row className="mt-3" key={key}>
    //                     <Col md={2}>

    //                     </Col>
    //                     <Col md={10} >
    //                         <div style={{ marginTop: "2%", marginLeft: '-21%' }}>
    //                             <Radio value={`${at.value}`}>{at.label}</Radio>
    //                         </div>
    //                     </Col>
    //                 </Row>
    //             );
    //         });
    //     }
    // }, [inviteOption]);

    return (
        <>
            {rfpProcessId &&



                <div className="flex justify-between">
                    <RFPBreadCrumbPage isPageName="invite" name={'Invite Selection'} selectedClient={selectedClient} selectedProgramme={selectedProgramme} selectedClientName={selectedClientName} selectedProgrammeName={selectedProgrammeName} rfpProcessId={rfpProcessId} programmeId={programmeId} startDate={rfpName}></RFPBreadCrumbPage>

                    <span className={rfpColor} style={{ marginTop: '-20px', marginRight: '110px' }}>{rfpStatus}</span>
                </div>
            }


            {<Row className="mt-5">
                <p style={{ fontWeight: 'bold', fontSize: '24px' }}>Please select the Parameters below to determine Which properties should be added to the RFP. </p>
                <p style={{ fontWeight: 'bold', fontSize: '24px' }}>Individual properties can be removed via 'Properties to Invite' once displayed or added via 'Other Properties'. </p>
            </Row>}



            <div>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Row >
                        <Col md={8} className="mt-3" style={{ textAlign: 'left', fontWeight: 'bold', fontSize: '20px', }}>

                            <div style={{
                                marginBottom: '25px',
                                padding: '10px',
                                backgroundColor: '#F2F2F2', // Light grey background color
                                borderRadius: '5px', // Rounded corners
                            }}>
                                {<Form.Check {...register("inviteType")} defaultChecked={getStorage('selectInviteType') === 'All_Brands' ? true : false} onChange={onChangeInvite}
                                    type="radio" value={'All_Brands'} label="Add all properties relevant to the ‘Accommodation Type’ for all locations included in the RFP" />}
                            </div>
                            <div style={{
                                marginBottom: '25px',
                                padding: '10px',
                                backgroundColor: '#F2F2F2', // Light grey background color
                                borderRadius: '5px', // Rounded corners
                            }}>
                                {<Form.Check defaultChecked={getStorage('selectInviteType') === 'Current_programme_only' ? true : false} {...register("inviteType")} onChange={onChangeInvite} type="radio" value={'Current_programme_only'} label="Add all properties from the current programme" />}
                            </div>
                        </Col>
                    </Row>

                    <Row className="mt-5">
                        <Button
                            type="submit"
                            id="approve-button"
                            style={formButton}
                        >
                            Save
                        </Button>
                    </Row>
                </Form>

            </div>
        </>
    );

}

export default InviteParameters