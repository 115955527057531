import { FC, useCallback, useEffect, useState } from "react";
import RFPBreadCrumbPage from "../../../common/RFPBredcrumPage";
import DataTable from "react-data-table-component";
import { tableStyles, $mysaTeal, $mysaMegenta, selectStyles, $mysaYellow, overlayTriggerDelay, $mysaRed } from "../../../../common/components-style";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { useStoreActions, useStoreState } from "easy-peasy";
import { PropertyStatus } from "../../../../common/constants";
import Select from 'react-select';
import { toast } from "react-toastify";
import ViewEdit from '../../../../images/icons/svg/outside/Icon-Function-View-Edit.svg';
import CircleIcon from '../../../../images/icons/svg/circle.svg';
import { getStorage } from "../../../../common/storage";
import SweetAlert from "react-bootstrap-sweetalert";


const PropertyNotInvite: FC<any> = ({ rfpColor, rfpStatus, rfpName, programmeId, rfpProcessId, selectedClient, selectedProgramme, selectedClientNames }) => {
    const [selectedClientName, setSelectedClientName] = useState<string>("");
    const [selectedProgrammeName, setSelectedProgrammeName] = useState<string>("");
    const [propertyType, setPropertyTypes] = useState<Array<any>>([]);
    const [statusOption, setStatusOption] = useState<Array<any>>([]);
    // const [inviteOption, setInviteOption] = useState<Array<any>>([]);
    const [countryOption, setCountryOption] = useState<Array<any>>([]);
    const [cityOption, setCityOption] = useState<Array<any>>([]);
    // const [selectionOption, setSelectionOption] = useState<Array<any>>([]);
    const [data, setData] = useState<Array<any>>([]);
    const [selectCityName, setSelectCityName] = useState<any>();
    const [selectCountryCode, setSelectCountryCode] = useState<any>();
    const [selectStatus, setSelectStatus] = useState<any>();
    const [selectInviteType, setSelectInviteType] = useState<any>();
    const [selectPropertyType, setSelectPropertyType] = useState<any>();
    const [rfpProcess, setRfpProcess] = useState<any>();
    const [propertyData, setPropertyData] = useState<any>();

    const [archivePopop, setArchivePopop] = useState<boolean>(false);

    ///////////////////////////////////////////////////////////////////////////////////////

    const {
        getPropertyTypes,
        getRfpProcessById,
        getHotelPropertyTypes,
        getBrandsAndPropertiesForInvite,
        updateProperty
    } = useStoreActions<any>((actions) => ({
        getPropertyTypes: actions.property.getPropertyTypes,
        getHotelPropertyTypes: actions.hotel.getHotelPropertyTypes,
        getRfpProcessById: actions.rfpProcess.getRfpProcessById,
        getBrandsAndPropertiesForInvite: actions.rfpProcess.getBrandsAndPropertiesForInvite,
        updateProperty: actions.property.updateProperty,

    }));

    const {
        getPropertyTypesSuccess,
        rfpProcessByIdSuccess,
        getBrandsAndPropertiesForInviteSuccess,
        getBrandsAndPropertiesForInviteError,
        getHotelPropertyTypesSuccess,
        updatePropertySuccess,
        updatePropertyError
    } = useStoreState<any>((state) => ({
        getPropertyTypesSuccess: state.property.getPropertyTypesSuccess,
        rfpProcessByIdSuccess: state.rfpProcess.rfpProcessByIdSuccess,
        getBrandsAndPropertiesForInviteSuccess: state.rfpProcess.getBrandsAndPropertiesForInviteSuccess,
        getBrandsAndPropertiesForInviteError: state.rfpProcess.getBrandsAndPropertiesForInviteError,
        getHotelPropertyTypesSuccess: state.hotel.getHotelPropertyTypesSuccess,
        updatePropertyError: state.property.updatePropertyError,
        updatePropertySuccess: state.property.updatePropertySuccess,

    }));


    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (getStorage('selectInviteType')) {
            setSelectInviteType(getStorage('selectInviteType'));
        }

        if (selectedProgramme) {
            setSelectedProgrammeName(selectedProgramme?.programmeName);
        }
        if (selectedClientNames) {
            setSelectedClientName(selectedClientNames);
        }
    }, [selectedClientNames, selectedProgramme]);

    useEffect(() => {
        if (selectInviteType) {
            const payload = {
                rfpProcessId: rfpProcessId,
                programmeId: programmeId,
                inviteType: selectInviteType,
                propertyType: 'All',
                propertyStatus: 'All',
                propertyCountryCode: 'All',
                propertyCity: 'All'
            };
            getBrandsAndPropertiesForInvite(payload);
        }
    }, [getBrandsAndPropertiesForInvite, programmeId, rfpProcessId, selectInviteType]);


    const callApi = useCallback(() => {

        const payload = {
            rfpProcessId: rfpProcessId,
            programmeId: selectInviteType !== 'All_Brands' ? programmeId : '',
            inviteType: selectInviteType ? selectInviteType : '',
            propertyType: selectPropertyType ? selectPropertyType : 'All',
            propertyStatus: selectStatus ? selectStatus : 'All',
            propertyCountryCode: selectCountryCode ? selectCountryCode : 'All',
            propertyCity: selectCityName ? selectCityName : 'All'
        };
        getBrandsAndPropertiesForInvite(payload);


    }, [getBrandsAndPropertiesForInvite, programmeId, rfpProcessId, selectCityName, selectCountryCode, selectInviteType, selectPropertyType, selectStatus]);

    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        getPropertyTypes();
        getHotelPropertyTypes();
        if (rfpProcessId) {
            getRfpProcessById(rfpProcessId);
        }

    }, [getPropertyTypes, getRfpProcessById, rfpProcessId, getHotelPropertyTypes]);

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {

        if (getBrandsAndPropertiesForInviteSuccess) {
            if (getBrandsAndPropertiesForInviteSuccess?.data) {
                const list = getBrandsAndPropertiesForInviteSuccess?.data.filter((ele) => ele.propertySelection === true);
                setData(list);
            } else {
                setData([]);
            }
        }
        if (getBrandsAndPropertiesForInviteError) {

            toast.error(getBrandsAndPropertiesForInviteError
                .message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }
    }, [getBrandsAndPropertiesForInviteError, getBrandsAndPropertiesForInviteSuccess, getBrandsAndPropertiesForInviteSuccess?.data]);



    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        if (rfpProcessByIdSuccess?.data) {
            setRfpProcess(rfpProcessByIdSuccess?.data);
            let locations: any = [];
            let countries: any = [];
            rfpProcessByIdSuccess?.data?.locations.forEach((location) => {
                location?.cities?.forEach(element => {
                    let data = {
                        value: element.cityName,
                        label: (
                            <div className="flex">


                                {element.cityName}

                            </div>
                        ),
                    };
                    locations.push(data);
                });

                let data = {
                    value: location.countryCode,
                    label: (
                        <div className="flex">


                            {location.countryName}

                        </div>
                    ),
                };
                countries.push(data);
            });

            setCountryOption(countries);
            setCityOption(locations);
        }
    }, [rfpProcessByIdSuccess?.data]);



    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        const hotelType: any = []
        const proType: any = []
        if (getPropertyTypesSuccess) {
            const types = getPropertyTypesSuccess.data;

            if (types) {
                types.forEach((propertyType) => {
                    if (propertyType.name.trim() === 'Aparthotel') {
                        const type = {
                            value: propertyType._id,
                            label: propertyType.name
                        }
                        proType.push(type);
                    }
                    if (propertyType.name.trim() === 'Apartments - building fully managed by our brand') {
                        // if (ids?.length === 0) {
                        const type = {
                            value: propertyType._id,
                            label: `Fully Managed`
                        }
                        proType.push(type);
                        // }
                        //
                    }
                    if (propertyType.name.trim() === 'Apartments - building managed by a third party') {
                        // if (ids?.length === 0) {
                        const type = {
                            value: propertyType._id,
                            label: `3rd Party Managed`
                        }
                        proType.push(type);
                        // }
                        // ids.push(propertyType._id);
                    }


                    if (propertyType.name.trim() === 'Self contained single building') {
                        const type = {
                            value: propertyType._id,
                            label: `Self Contained`
                        }
                        proType.push(type);
                    }
                    // ids.push(propertyType._id);
                });
            }
            // setPropertyTypeIds(ids);
            // setPropertyTypes(proType);
        }
        if (getHotelPropertyTypesSuccess) {
            const types = getHotelPropertyTypesSuccess?.data;

            if (types) {
                types.forEach((propertyType) => {
                    if (propertyType.name.trim() === 'Hotel Only') {
                        const type = {
                            value: propertyType._id,
                            label: propertyType.name
                        }
                        hotelType.push(type);
                    }
                    if (propertyType.name.trim() === 'Hotel with extended stay') {
                        // if (ids?.length === 0) {
                        const type = {
                            value: propertyType._id,
                            label: propertyType.name
                        }
                        hotelType.push(type);
                        // }
                        //
                    }
                    // ids.push(propertyType._id);
                });
            }
            // setPropertyTypeIds(ids);

            // setPropertyTypes(hotelType);
        }
        if (rfpProcess?.accommodationType === 'both') {
            hotelType.map(ele => {
                proType.push(ele);
            })
            setPropertyTypes(proType);
        }
        // else if (rfpProcess?.accommodationType === 'serviced_accommodation') {
        //     setPropertyTypes(proType);
        // } else if (rfpProcess?.accommodationType === 'hotel') {
        //     setPropertyTypes(hotelType);
        // }

        const status: any = [{
            value: 'incomplete',
            label: "Incomplete",
        },
        {
            value: 'pending',
            label: "Pending",
        },
        {
            value: 'complete',
            name: "Complete",
        },
        {
            value: 'review',
            label: "Review",
        },
        {
            value: 'archived',
            label: "Archived",
        },
        ];
        setStatusOption(status);


        // const invite: any = [{
        //     value: 'All_Brands',
        //     label: "All Brands",
        // },
        // {
        //     value: 'Current_programme_only',
        //     label: "Current Programme only",
        // },
        // {
        //     value: 'Current_programme_plus_selected',
        //     label: "Current programme plus selected",
        // },
        // {
        //     value: 'Just_selected',
        //     label: "Just selected",
        // },

        // ];

        // setInviteOption(invite);


        // const selection: any = [{
        //     value: 'selected',
        //     label: "Selected",
        // },
        // {
        //     value: 'Not_selected',
        //     label: "Not Selected",
        // },

        // ];
        // setSelectionOption(selection);

    }, [getHotelPropertyTypesSuccess, getPropertyTypesSuccess, rfpProcess?.accommodationType]);

    /////////////////////////////////////////////////
    // const onChangeInvite = (value) => {
    //     if (value?.value) {
    //         setSelectInviteType(value?.value);
    //     } else {
    //         setSelectInviteType("");
    //     }
    // };

    /////////////////////////////////////////////////
    const onChangePropertyType = (value) => {
        if (value?.value) {
            setSelectPropertyType(value?.value);
        } else {
            setSelectPropertyType("");
        }
    };

    /////////////////////////////////////////////////
    const onChangeStatus = (value) => {
        if (value?.value) {
            setSelectStatus(value?.value);
        } else {
            setSelectStatus("");
        }
    };

    const onChangeCity = (value) => {
        setSelectCityName(value.value);
    }

    const onChangeCountry = (value) => {
        setSelectCountryCode(value.value);
    }


    const onActivateProperty = useCallback(() => {
        updateProperty(propertyData);
    }, [propertyData, updateProperty])


    useEffect(() => {
        if (updatePropertySuccess) {
            toast.success("Property added to 'Properties To Invite' successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });
            callApi();
        }

        if (updatePropertyError) {
            toast.error(updatePropertyError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: "foo-bar",
            });
            callApi();
        }
    }, [callApi, updatePropertyError, updatePropertySuccess]);

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const columns = [
        {
            name: 'Country',
            selector: row => row.propertyCountryName,
        },
        {
            name: 'City',
            selector: row => row.propertyCity,
        },
        {
            name: 'Brand',
            selector: row => row.brandName,
        },
        {
            name: 'Property',
            selector: row => row.propertyName,
        },
        {
            name: 'Type',
            selector: row => row.propertyTypeName,
        },
        // {
        //     name: 'Client Location',
        //     selector: row => row.city_name,
        // },
        {
            name: 'Status',
            cell: (row) => {
                let color = "";
                if (row.propertyStatus === PropertyStatus.pending) color = $mysaYellow;
                if (row.propertyStatus === PropertyStatus.approved) color = $mysaTeal;
                if (row.propertyStatus === PropertyStatus.awaiting)
                    color = $mysaMegenta;
                if (row.propertyStatus === PropertyStatus.review) color = $mysaYellow;
                if (row.propertyStatus === PropertyStatus.archived) color = $mysaRed;

                return (
                    <span style={{ color: color, fontWeight: 'bold' }} className='text-capitalize'>{row.propertyStatus}</span>
                )
            }
        },

        {
            name: 'Action',
            button: true,
            cell: (row: any) => {
                return (<>
                    {/* <OverlayTrigger
                        placement="top"
                        delay={overlayTriggerDelay}
                        overlay={<Tooltip id='tooltip-table-top'>Selected for invite</Tooltip>}
                    >
                        <Form.Check
                            inline
                            
                            value={row.propertySelection}
                          
                            onChange={() => {
                               
                            }}
                            checked={row.propertySelection === true ? true : false}
                        />
                    </OverlayTrigger> */}


                    <OverlayTrigger
                        placement="top"
                        delay={overlayTriggerDelay}
                        overlay={<Tooltip id='tooltip-table-top'>View / Edit</Tooltip>}
                    >
                        <img className='iconSizes' src={ViewEdit} alt="" onClick={() => {
                            //  setStorage('myo_selected_brand', row); loadForm(row.key, row.extId) 
                        }} />
                    </OverlayTrigger>

                    {
                        <OverlayTrigger
                            placement="top"
                            delay={overlayTriggerDelay}
                            overlay={<Tooltip id='tooltip-table-top'>Add Property</Tooltip>}
                        >
                            <img className='iconSizes5' src={CircleIcon} alt="" onClick={() => {
                                const data = {
                                    id: row?.propertyId,
                                    propertySelection: false
                                }
                                setPropertyData(data);
                                setArchivePopop(true);
                            }} />
                        </OverlayTrigger>
                    }

                </>)
            },
        },

    ];


    return (
        <>
            {rfpProcessId &&
                <div className="flex justify-between">
                    <RFPBreadCrumbPage isPageName="invite" name={'Properties Not Invited'} selectedClient={selectedClient} selectedProgramme={selectedProgramme} selectedClientName={selectedClientName} selectedProgrammeName={selectedProgrammeName} rfpProcessId={rfpProcessId} programmeId={programmeId} startDate={rfpName}></RFPBreadCrumbPage>

                    <span className={rfpColor} style={{ marginTop: '-20px', marginRight: '110px' }}>{rfpStatus}</span>
                </div>
            }

            {data?.length > 0 && <Row style={{ marginBottom: '2%', marginTop: '2%', paddingLeft: "10px" }}>
                {/* <Col md={2}>
                    <Select
                        className="select_btn search_bar"
                        isClearable
                        placeholder={<div>Invite Selection</div>}
                        options={inviteOption}
                        styles={selectStyles}
                        isSearchable={true}
                        onChange={onChangeInvite}
                    />
                </Col> */}
                <Col md={2}>
                    <Select
                        className="select_btn search_bar"
                        isClearable
                        placeholder={<div>Country - All</div>}
                        options={countryOption}
                        styles={selectStyles}
                        isSearchable={true}
                        onChange={onChangeCountry}
                    />
                </Col>
                <Col md={2}>
                    <Select
                        className="select_btn search_bar"
                        isClearable
                        placeholder={<div>City - All</div>}
                        options={cityOption}
                        styles={selectStyles}
                        isSearchable={true}
                        onChange={onChangeCity}
                    />
                </Col>
                <Col md={2}>
                    <Select
                        className="select_btn search_bar"
                        isClearable
                        placeholder={<div>Property Status - All</div>}
                        options={statusOption}
                        styles={selectStyles}
                        isSearchable={true}
                        onChange={onChangeStatus}
                    />
                </Col>
                {rfpProcess?.accommodationType === 'both' && <Col md={2}>
                    <Select
                        className="select_btn search_bar"
                        isClearable
                        placeholder={<div>Property Type - All</div>}
                        options={propertyType}
                        styles={selectStyles}
                        isSearchable={true}
                        onChange={onChangePropertyType}
                    />
                </Col>}

                <Col md={4}></Col>
            </Row>}

            {/* <Row style={{ marginBottom: '2%', marginTop: '2%', paddingLeft: "10px" }}>
                <Col md={2}>
                    <Select
                        className="select_btn search_bar"
                        isClearable
                        placeholder={<div>Selection-View all</div>}
                        options={selectionOption}
                        styles={selectStyles}
                        isSearchable={true}
                        onChange={onChangeInvite}
                    />
                </Col>
                <Col md={2}>
                    <Select
                        className="select_btn search_bar"
                        isClearable
                        placeholder={<div>Country - All</div>}
                        options={countryOption}
                        styles={selectStyles}
                        isSearchable={true}
                        onChange={onChangeCountry}
                    />
                </Col>
                <Col md={2}>
                    <Select
                        className="select_btn search_bar"
                        isClearable
                        placeholder={<div>City - All</div>}
                        options={cityOption}
                        styles={selectStyles}
                        isSearchable={true}
                        onChange={onChangeCity}
                    />
                </Col>
                <Col md={4}></Col>
            </Row> */}
            {/* <Row style={{ marginBottom: '2%', marginTop: '-7%', paddingLeft: "210px" }}>
                <Col md={2}>

                </Col>
                <Col md={2}>

                </Col>
                <Col md={2}>

                </Col>
                <Col md={4}>
                    <Button id='edit-button' style={formButton} onClick={callApi} >Select</Button>
                </Col>
            </Row> */}

            {data?.length === 0 && <Row className="mt-5">

                <p style={{ fontWeight: 'bold', fontSize: '24px' }}>There are currently no properties added. Please select invite parameters </p>
            </Row>}
            <DataTable
                columns={columns}
                data={data}
                pagination
                responsive
                customStyles={tableStyles}
                striped={true}
                theme='mysaTable'
                fixedHeader={true}


            />

            <SweetAlert
                show={archivePopop}
                warning
                showCancel={true}
                focusCancelBtn={true}
                confirmBtnText="Yes archive it!"
                confirmBtnBsStyle="danger"
                title="Are you sure you wish to add this property?"
                onConfirm={() => {
                    onActivateProperty();

                    setArchivePopop(false);
                }}
                onCancel={() => {
                    setArchivePopop(false);
                }}
            ></SweetAlert>
        </>
    );

}

export default PropertyNotInvite