import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Table, Image, message } from 'antd';
import { useStoreActions, useStoreState } from 'easy-peasy';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import AmenitiesForm from './AmenitiesForm';

const AmenitiesList: React.FC<any> = (): JSX.Element => {
  const [formOpen, setFormOpen] = useState<boolean>(false);
  const [amenityList, setAmenityList] = useState<any>([]);
  const [amenityDetails, setAmenityDetails] = useState<any>(null);
  const [amenityId, setAmenityId] = useState<number | null>(null);

  ////////////////////////////////////////////////////////////////////////////////////////////
  const loadForm = useCallback((data) => {
    setFormOpen(true);
    setAmenityDetails(data);
    if (data !== null) {
      setAmenityId(data.key);
    } else {
      setAmenityId(null);
    }
  }, []);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const { getAmenityList, updateAmenity } = useStoreActions<any>((actions) => ({
    getAmenityList: actions.property.getAmenityList,
    updateAmenity: actions.property.updateAmenity,
  }));

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const { getAmenityListSuccess, getAmenityListError, updateAmenityError, removeAmenitySuccess } = useStoreState<any>((state) => ({
    getAmenityListSuccess: state.property.getAmenityListSuccess,
    getAmenityListError: state.property.getAmenityListError,
    updateAmenityError: state.property.updateAmenityError,
    removeAmenitySuccess: state.property.removeAmenitySuccess,
  }));

  ////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    getAmenityList();
  }, [getAmenityList]);

  //////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (getAmenityListSuccess) {
      setAmenityList(getAmenityListSuccess.data);
    }
    if (getAmenityListError) {
      message.error(getAmenityListError.message)
    }


    if (removeAmenitySuccess) {
      message.success('Amenity removed successfully');
      getAmenityList();
    }

    if (updateAmenityError) {
      message.error(updateAmenityError.message)
    }

  }, [getAmenityListError, getAmenityListSuccess, removeAmenitySuccess, updateAmenityError, getAmenityList]);

  //////////////////////////////////////////////////////////////////////////////////////////////////////////
  const removeData = useCallback((values) => {
    const data = {
      id: values.key,
      isDeleted: true
    }
    updateAmenity(data);

  }, [updateAmenity]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const columns = [
    {
      title: 'Icon',
      dataIndex: 'iconLocation',
      key: 'iconLocation',
      render: (iconLocation: string) => {

        return (<div>
          <Image style={{ width: '100%', height: '72px' }} className="object-contan" fallback={iconLocation} src={iconLocation} />
        </div>)
      },
    },
    { title: 'Name', dataIndex: 'name', key: 'name', outerWidth: '75px' },
    { title: 'Property Type', dataIndex: 'propertyType', key: 'propertyType' },
    { title: 'Type', dataIndex: 'type', key: 'type' },
    {
      title: 'Status',
      dataIndex: '',
      key: 'status  ',
      render: (data: any) => {

        return (<div className={`${data.status === 'active' ? 'text-green-500' : 'text-red-500'}`}>
          {data.status}
        </div>)
      },
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'status',
      render: (data: any) => {
        return (<div>
          <Button onClick={() => loadForm(data)} className="border-none" ><EditOutlined /></Button>
          <Button onClick={() => removeData(data)} className="border-none" ><DeleteOutlined /></Button>
          {/* <Switch size="small" checked={data.status === 'active' ? true : false} /> */}
        </div>)
      },
    },
  ];

  /////////////////////////////////////////////////////////////////////////////////////////////////////
  const data = useMemo(() => {
    return _.map(amenityList, amenity => {
      return {
        key: amenity._id,
        name: amenity.name,
        iconLocation: amenity.iconLocation,
        propertyType: amenity.propertyType,
        type: amenity.type,
        status: amenity.status
      }
    })
  }, [amenityList]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <div>
      <div className="flex justify-between">
        <p className="text-2xl mb-10">Amenity Details</p>
        <Button hidden={formOpen} onClick={() => { loadForm(null) }} className="btn_design m-1 text-white">Create New</Button>
        <Button hidden={!formOpen} onClick={() => setFormOpen(false)} className="btn_design m-1 text-white">Show List</Button>
      </div>
      {formOpen && <AmenitiesForm setFormOpen={setFormOpen} amenityDetails={amenityDetails} amenityId={amenityId} />}
      <div className="mb-1" hidden={formOpen}>
        <Table columns={columns} dataSource={data} />
      </div>

    </div>
  )
};

export default AmenitiesList;