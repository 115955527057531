import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { RfpProcessStatus, UserGroups } from "../../../common/constants";
import moment from "moment";
import DataTable from "react-data-table-component";
import { overlayTriggerDelay, tableStyles } from "../../../common/components-style";
import { OverlayTrigger, Stack, Tooltip, Spinner } from "react-bootstrap";
import ViewEdit from '../../../images/icons/svg/eyeIcon.svg';
import DeleteIcon from '../../../images/icons/svg/deleteIcon.svg';
import StatusIcon from '../../../images/icons/svg/correct.svg';
import CircleIcon from '../../../images/icons/svg/circle.svg';
import { getLoggedUserType } from "../../../common/functions";
import { useStoreActions, useStoreState } from "easy-peasy";
import { toast } from "react-toastify";


const RfpProcessList: FC<any> = ({ setRfpStatus, rfpProcessesList, loading, loadForm, loadData, setRefreshRfpProcessesList }): JSX.Element => {
	const today = moment(new Date());
	const [temStatus, setTemStatus] = useState<any>('');
	const [temData, setTemData] = useState<any>('');
	const [deleteData, setDeleteData] = useState<any>('');
	const [loadingId, setLoadingId] = useState<any>('randomText');



	const {
		deleteRfpProcess,
		surveyStatusCheck,
		deleteCertificateTemplate
	} = useStoreActions<any>((actions) => ({
		deleteRfpProcess: actions.rfpProcess.deleteRfpProcess,
		surveyStatusCheck: actions.rfpProcess.surveyStatusCheck,
		deleteCertificateTemplate: actions.myoManagementModel.deleteCertificateTemplate
	}));


	const {
		// deleteRfpProcessLoading,
		deleteRfpProcessError,
		deleteRfpProcessSuccess,
		getSurveyStatusCheckSuccess,
		getSurveyStatusCheckError,
	} = useStoreState<any>((state) => ({
		deleteRfpProcessSuccess: state.rfpProcess.deleteRfpProcessSuccessResponse,
		deleteRfpProcessLoading: state.rfpProcess.deleteRfpProcessLoadingResponse,
		deleteRfpProcessError: state.rfpProcess.deleteRfpProcessErrorResponse,
		getSurveyStatusCheckSuccess: state.rfpProcess.getSurveyStatusCheckSuccess,
		getSurveyStatusCheckError: state.rfpProcess.getSurveyStatusCheckError,
	}));





	// ///////////////////////////////////////////////////////////////////////////////////
	const memoizedRfpProcessData = useMemo(() => {


		return rfpProcessesList.map((list: any) => {
			// certificateTemplateId, startDate, endDate, questionSubmitDate, locations, rfpStatus, _id, isActivated, rfpSurveyStatus, overviewForInterview
			const cities = list?.rfp?.locations.map((item: any) => item.cities)
			const citiesArray = [].concat.apply([], cities);
			const cityNames = citiesArray.map((item: any) => item.cityName);
			return {
				certificateTemplateId: list?.rfp?.certificateTemplateId,
				key: list?.rfp?._id,
				startDate: list?.rfp?.startDate,
				endDate: list?.rfp?.endDate,
				questionSubmitDate: list?.rfp?.questionSubmitDate,
				locations: cityNames?.length,
				status: list?.rfp?.rfpStatus,
				isActivated: list?.rfp?.isActivated,
				overviewForInterview: list?.rfp?.overviewForInterview,
				rfpSurveyStatus: list?.rfp?.rfpSurveyStatus,
				isPassed: (list?.rfp?.isActivated === false && moment(list?.rfp?.startDate).isSameOrBefore(today)),
				rfpType: list?.rfp?.rfpType,
				rfpName: list?.rfp?.rfpName,
				accommodationType: list?.rfp?.accommodationType,
			}
		})
	}, [rfpProcessesList, today]);

	const onCancelRfp = useCallback((data) => {
		setDeleteData(data);
		deleteRfpProcess({
			id: data?.key
		});
	}, [deleteRfpProcess]);

	useEffect(() => {
		if (deleteRfpProcessError) {
			toast.success(deleteRfpProcessError?.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
		}
		if (deleteRfpProcessSuccess) {
			deleteCertificateTemplate({
				security: {
					apiKey: process.env.REACT_APP_AUDIT_API_KEY,
					user: "superadmin@mysa.global"
				},
				id: deleteData?.certificateTemplateId
			})
			setRefreshRfpProcessesList(true);
			toast.success("RFP process archived successfully", {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});


		}

		if (getSurveyStatusCheckSuccess) {
			loadData(temData);
			setRfpStatus(temStatus);
			setLoadingId('randomText');
		}

		if (getSurveyStatusCheckError) {
			toast.error(getSurveyStatusCheckError.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
			setLoadingId('randomText');
		}
	}, [deleteCertificateTemplate, deleteData?.certificateTemplateId, deleteRfpProcessError, deleteRfpProcessSuccess, getSurveyStatusCheckError, getSurveyStatusCheckSuccess, loadData, setRefreshRfpProcessesList, setRfpStatus, temData, temStatus]);




	const columns1 = [
		{
			name: 'Name',
			selector: row => row.rfpName,
		},
		{
			name: 'Start date',
			selector: row => moment(row.startDate).format("D MMM YYYY"),
		},
		{
			name: 'End date',
			selector: row => moment(row.endDate).format("D MMM YYYY"),
		},
		{
			name: 'Question date',
			selector: row => moment(row.questionSubmitDate).format("D MMM YYYY"),
		},
		{
			name: 'Accommodation',
			selector: (row) => {
				let status = "";
				if (row.accommodationType === 'both') status = 'Both';
				if (row.accommodationType === 'serviced_apartments') status = 'Serviced Accommodation';
				if (row.accommodationType === 'hotel') status = 'Hotel';
				return (
					<span >{status}</span>
				)
			}
		},
		{
			name: 'Type',

			selector: (row) => {
				let status = "";
				if (row.rfpType === 'primary') status = 'Primary';
				if (row.rfpType === 'secondary') status = 'Secondary';
				return (
					<span >{status}</span>
				)
			}
		},
		{
			name: 'No of Locations',
			selector: row => row.locations,
		},
		{
			name: "Status",
			// key: 'status',

			selector: (row) => {
				let color = " text-pink-500 uppercase font-bold";
				if (row.status === RfpProcessStatus.INPROGRESS) color = 'font-bold uppercase text-yellow-500';
				if (row.status === RfpProcessStatus.COMPLETED) color = 'font-bold uppercase text-green-500';
				if (row.status === RfpProcessStatus.CANCELLED) color = 'font-bold uppercase text-red-500';
				return (
					<span className={color}>{row.status}</span>
				)
			}
		},

		{
			name: "Action",
			button: true,
			key: 'action',
			wrap: true,
			minWidth: '300px',
			cell: (data: any) => {
				return (
					<Stack direction="horizontal" gap={3}>
						{data?.status === RfpProcessStatus.COMPLETED && <OverlayTrigger

							placement="top"
							delay={overlayTriggerDelay}
							overlay={<Tooltip id='tooltip-table-top'>View / Edit</Tooltip>}
						>
							<img className='iconSizes mr-2' style={(data?.status === RfpProcessStatus.COMPLETED && data?.isActivated === true) ? { marginLeft: '50px' } : { marginLeft: '0px' }} src={ViewEdit} alt="" onClick={() => {
								loadForm(data);
								setRfpStatus(data?.status);
							}} />
						</OverlayTrigger>}

						{(data?.status !== RfpProcessStatus.COMPLETED || data?.status === RfpProcessStatus.INPROGRESS || data?.status === RfpProcessStatus.NOTSTARTED || data?.status === RfpProcessStatus.CANCELLED
						) && <OverlayTrigger
							placement="top"
							delay={overlayTriggerDelay}
							overlay={<Tooltip id='tooltip-table-top'>View / Edit</Tooltip>}
						>
								<img className='iconSizes mr-2' style={(data?.status === RfpProcessStatus.INPROGRESS && data?.isActivated === true) ? { marginLeft: '50px' } : { marginLeft: '110px' }} src={ViewEdit} alt="" onClick={() => {
									loadForm(data);
									setRfpStatus(data?.status);
								}} />
							</OverlayTrigger>}
						{
							(data?.status !== RfpProcessStatus.COMPLETED) && ((data?.status !== RfpProcessStatus.INPROGRESS && data?.isActivated === false) || (data?.status === RfpProcessStatus.NOTSTARTED && data?.isActivated === true) || (data?.status === RfpProcessStatus.CANCELLED && data?.isActivated === false)) &&
							(getLoggedUserType() === UserGroups.super_admin) &&
							<OverlayTrigger
								placement="top"
								delay={overlayTriggerDelay}
								overlay={<Tooltip id='tooltip-table-top'>Archive</Tooltip>}
							>
								<img className='iconSizes mr-2' src={DeleteIcon} alt="" onClick={() => onCancelRfp(data)} />
							</OverlayTrigger>
						}
						{
							loadingId === data?.key && <Spinner size={`sm`} animation="border" variant="warning" />
						}
						{
							(loadingId !== data?.key && (data?.status !== RfpProcessStatus.COMPLETED) && (data?.status !== RfpProcessStatus.CANCELLED) && (data?.status !== RfpProcessStatus.INPROGRESS) && (data?.isActivated === false)) &&
							<OverlayTrigger
								placement="top"
								delay={overlayTriggerDelay}
								overlay={<Tooltip id='tooltip-table-top'>{'Activate'}</Tooltip>}
							>
								<img className='mr-2 iconSizes2' src={CircleIcon} alt=""
									onClick={() => {
										if (data?.rfpSurveyStatus.toString() === 'not_started') {
											toast.error("Survey management is not complete", {
												position: toast.POSITION.BOTTOM_RIGHT,
												className: 'foo-bar'
											});
										} else {
											if (data?.rfpSurveyStatus.toString() === 'no') {
												loadData(data);
												setRfpStatus(data?.status);
											}
											if (data?.rfpSurveyStatus.toString() === 'yes') {
												setLoadingId(data?.key);
												setTemStatus(data?.status);
												setTemData(data);
												const payload = {
													templateId: data?.certificateTemplateId
												}
												setLoadingId(data?.key);
												surveyStatusCheck(payload);
											}
										}
									}}
								/>
							</OverlayTrigger>}

						{
							data?.isActivated === true &&
							<OverlayTrigger
								placement="top"
								delay={overlayTriggerDelay}
								overlay={<Tooltip id='tooltip-table-top'>{'Activated'}</Tooltip>}
							>
								<img className='mr-2 iconSize' src={StatusIcon} alt="" />
							</OverlayTrigger>}
					</Stack>
				)
			}
		}
	];

	return (
		<div>
			<DataTable
				columns={columns1}
				data={memoizedRfpProcessData}
				pagination
				responsive
				customStyles={tableStyles}
				striped={true}
				theme='mysaTable'
				fixedHeader={true}
				noDataComponent={'Updating records'}
			/>
		</div>
	)
}

export default RfpProcessList;