import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react"
import { Button, Card, Col, Form, Row, Image, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import "swiper/modules/navigation/navigation.scss";
import { formButton } from "../../../common/components-style";
import CrossIcon from "../../../images/icons/svg/cross.svg";
import { getStorage } from "../../../common/storage";
import { useStoreActions, useStoreState } from "easy-peasy";
import { toast } from "react-toastify";

////////////// booking request form type ////////
type BookingRequestFormInput = {
    travelerName: String,
    messageText: String
};

/////////////// type for component ///////
type BookingRequestComponentType = {
    setRequestAvailability: Dispatch<SetStateAction<boolean>>,
    setRequestData: Dispatch<SetStateAction<Array<any>>>,
    requestData: Array<any>,
    onRemoveList: (data: any) => void,
    noOfNights: any;
    showShareProperty: boolean;
};

type requestedInventoryType = {
    brandId: String,
    propertyId: String,
    inventoryId: String
};

type requestedBookingPayloadType = {
    clientId: String,
    programmeId: String,
    requestUserId: String,
    travelerName: String,
    checkInDate: String,
    checkOutDate: String,
    messageText: String,
    requestedInventory: Array<requestedInventoryType>,
    location: {
        locationId: String,
        countryISO: String,
        countryName: String,
        locationName: String,
        programmeType: String
    }
};

const BookingRequestComponent: React.FunctionComponent<BookingRequestComponentType> = ({ setRequestAvailability, setRequestData, requestData, onRemoveList, noOfNights, showShareProperty }) => {
    //////////// booking request submit //////////////
    const { register, handleSubmit, formState: { errors } } = useForm<BookingRequestFormInput>();
    const clientID = getStorage('programmeData') ? getStorage('programmeData')?.clientId?._id : getStorage('makeBookingClient');
    const program = getStorage('programmeData') ? getStorage('programmeData') : getStorage('makeBookingProgram') ? getStorage('makeBookingProgram')?.prog : getStorage('programmeData');
    const bookingProgramSearch = getStorage('makeBookingProgramSearch');
    const checkInDate = getStorage('makeBookingCheckInDate');
    const checkOutDate = getStorage('makeBookingCheckOutDate');
    const userId = getStorage('myo_logged_user').userData._id;

    ////////////////////// global action ///////////////
    const { createBookingRequest, bookingReset } = useStoreActions<any>((actions) => ({
        createBookingRequest: actions.booking.createBookingRequest,
        bookingReset: actions.booking.bookingReset,
    }));

    //////////////// global state ///////////////////
    const { getBookingRequestResponseSuccess, getBookingRequestResponseError } = useStoreState<any>((state) => ({
        getBookingRequestResponseSuccess: state.booking.getBookingRequestResponseSuccess,
        getBookingRequestResponseError: state.booking.getBookingRequestResponseError
    }));

    ///////////// local state ///////////
    const [loading, setLoading] = useState<boolean>(false);
    const [requestMinimizeHide, setRequestMinimizeHide] = useState<boolean>(false);


    ////////////// react life cycle ////////
    useEffect(() => {
        if (getBookingRequestResponseSuccess) {
            setLoading(false);
            toast.success("Booking requested successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            setRequestData([]);
            setRequestAvailability(false);
            bookingReset();
        }

        if (getBookingRequestResponseError) {
            setLoading(false);
            toast.error(getBookingRequestResponseError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }
    }, [bookingReset, getBookingRequestResponseError, getBookingRequestResponseSuccess, setRequestAvailability, setRequestData])

    const onSubmit = useCallback((bookingRequestForm: BookingRequestFormInput) => {
        const requestedInventory: Array<requestedInventoryType> = requestData.map(({ requestData, query }) => {
            return {
                brandId: requestData.property.brandId._id,
                propertyId: requestData.property._id,
                inventoryId: requestData.propertyInventory._id,
                rateAndCurrency: {
                    currency: requestData.property?.currency,
                    rate: (Number(requestData.rateAndPolicy?.finalRate) / noOfNights).toFixed(0),
                },
                query: query
            };
        });

        const payload: requestedBookingPayloadType = {
            clientId: clientID,
            programmeId: program?._id,
            requestUserId: userId,
            travelerName: bookingRequestForm.travelerName,
            messageText: bookingRequestForm.messageText,
            checkInDate: checkInDate,
            checkOutDate: checkOutDate,
            requestedInventory: requestedInventory,
            location: {
                programmeType: program?.programmeType?.name,
                locationId: bookingProgramSearch?.locationId ? bookingProgramSearch?.locationId : bookingProgramSearch?.placeId,
                countryISO: bookingProgramSearch?.location?.countryCode ? bookingProgramSearch?.location?.countryCode : bookingProgramSearch?.country?.countryCode,
                countryName: bookingProgramSearch?.location?.country ? bookingProgramSearch?.location?.country : bookingProgramSearch?.country?.country,
                locationName: bookingProgramSearch?.location?.locationName ? bookingProgramSearch?.location?.locationName : bookingProgramSearch?.placeId
            }
        }
        setLoading(true);
        createBookingRequest(payload);
    }, [bookingProgramSearch?.country?.country, bookingProgramSearch?.country?.countryCode, bookingProgramSearch?.location?.country, bookingProgramSearch?.location?.countryCode, bookingProgramSearch?.location?.locationName, bookingProgramSearch?.locationId, bookingProgramSearch?.placeId, checkInDate, checkOutDate, clientID, createBookingRequest, noOfNights, program, requestData, userId]);

    return (<>
        {
            !requestMinimizeHide && <div className="sharePopertyBox" style={{ width: '85%' }}>
                <Row>
                    <Col sm={12}>
                        <div style={{
                            backgroundColor: "#908A8A",
                            color: "#fff",
                            padding: "10px",
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center"
                        }}>                         <h6
                            style={{
                                flexGrow: 1,
                                textAlign: "left",
                                color: "#fff",
                                margin: "0",
                            }}
                        >
                                Request Property {requestData.length}
                            </h6><span style={{
                                cursor: "pointer",
                                fontSize: "45px",
                                lineHeight: "0",
                                margin: "0",
                                width: "20px",
                                height: "20px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                marginBottom: "8px",
                                marginRight: "20px"
                            }} onClick={() => { setRequestMinimizeHide(true); }}>-</span><span style={{ cursor: 'pointer', marginRight: '10px', fontSize: '20px' }} onClick={() => { setRequestAvailability(false); setRequestData([]); }}>X</span></div>
                        <div style={{
                            backgroundColor: "#D9D9D9",
                            height: '300px'
                        }}>
                            <Row>
                                <Col sm={9}>
                                    <div style={{ marginLeft: '30px', marginTop: '25px' }}>
                                        <Swiper
                                            slidesPerView={4}
                                            centeredSlides={false}
                                            spaceBetween={2}
                                            navigation={true}
                                            virtual
                                        >
                                            {
                                                requestData?.map(({ requestData }, key: number) => (
                                                    <SwiperSlide key={key} virtualIndex={key}>
                                                        <Card style={{
                                                            height: '250px',
                                                            borderBottomRightRadius: '50px',
                                                            borderBottomLeftRadius: '50px'
                                                        }}>
                                                            <Image
                                                                style={{
                                                                    width: "100%",
                                                                    height: "65%",
                                                                    overflow: "hidden",
                                                                }}
                                                                src={requestData.propertyImage?.image[0]?.url}
                                                                alt="avatar"
                                                                className="shareAvilBoxImage"
                                                            ></Image>
                                                            <div className="">
                                                                <div
                                                                    style={{
                                                                        position: "absolute",
                                                                        top: "15px",
                                                                        right: "15px",
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={CrossIcon}
                                                                        alt=""
                                                                        onClick={() => onRemoveList(requestData)}
                                                                        style={{ cursor: "pointer" }}
                                                                    />
                                                                </div>
                                                                <Card.Body>
                                                                    <div
                                                                        className="font-bold"
                                                                        style={{
                                                                            fontSize: "18px",
                                                                            textAlign: "start",
                                                                            marginBottom: "10px",
                                                                        }}
                                                                    >
                                                                        {requestData.property.propertyName}
                                                                    </div>
                                                                    <div>
                                                                        <div
                                                                            className="text-left font-bold price"
                                                                            style={{ fontSize: "18px" }}
                                                                        >
                                                                            {requestData.property?.currency}{" "}
                                                                            {(
                                                                                Number(requestData.rateAndPolicy?.finalRate) / noOfNights
                                                                            ).toFixed(0)}
                                                                        </div>
                                                                        <div
                                                                            className="text-left flex align-items-center"
                                                                            style={{
                                                                                fontSize: "14px",
                                                                                marginBottom: "12px",
                                                                                fontWeight: 400,
                                                                            }}
                                                                        >
                                                                            {"Total "}
                                                                            {requestData.property?.currency}{" "}
                                                                            {Number(requestData.rateAndPolicy?.finalRate)}
                                                                        </div>
                                                                    </div>
                                                                </Card.Body>
                                                            </div>
                                                        </Card>
                                                    </SwiperSlide>
                                                ))
                                            }
                                        </Swiper>
                                    </div>
                                </Col>
                                <Col sm={3}>
                                    <div style={{ marginRight: '30px', marginTop: '10px' }}>
                                        <Form onSubmit={handleSubmit(onSubmit)}>
                                            <Row className='formSpace'>
                                                <Col md={12} style={{ textAlign: 'left' }}>
                                                    <Form.Control placeholder="Name of traveller (this is not supplied to operator)" className={`${errors.travelerName ? 'is-invalid' : ''}`} {...register("travelerName", { required: true })} />
                                                    <div className="invalid-feedback">Please input traveler name!</div>
                                                </Col>
                                            </Row>
                                            <Row className='formSpace'>
                                                <Col md={12} style={{ textAlign: 'left' }}>
                                                    <Form.Control
                                                        className={`${errors.messageText ? 'is-invalid' : ''}`}
                                                        {...register("messageText", { required: true })}
                                                        as="textarea"
                                                        rows={3}
                                                        placeholder="Message"
                                                    />
                                                    <div className="invalid-feedback">Please input message!</div>
                                                </Col>
                                            </Row>
                                            <Row className='formSpace'>
                                                <Col md={12} style={{ textAlign: 'left' }}>
                                                    {
                                                        !loading && <Button type="submit" id="approve-button" style={formButton}>
                                                            Send
                                                        </Button>
                                                    }

                                                    {
                                                        loading && <Spinner animation="border" variant="warning" />
                                                    }
                                                </Col>
                                            </Row>
                                        </Form>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        }

        {
            requestMinimizeHide && <div className="d-flex flex-row-reverse fixed-bottom">
                <div style={{
                    backgroundColor: "#908A8A",
                    color: "#fff",
                    padding: "10px",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    ...showShareProperty && { marginRight: '305px' }
                }}>
                    <h6
                        style={{
                            flexGrow: 1,
                            textAlign: "left",
                            color: "#fff",
                            margin: "0",
                            marginRight: '73px'
                        }}
                    >
                        Request Property {requestData.length}
                    </h6><span style={{
                        cursor: "pointer",
                        fontSize: "45px",
                        lineHeight: "0",
                        margin: "0",
                        width: "20px",
                        height: "20px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginBottom: "8px",
                        marginRight: "20px"
                    }} onClick={() => { setRequestMinimizeHide(false); }}>-</span><span style={{ cursor: 'pointer', marginRight: '10px', fontSize: '20px' }} onClick={() => { setRequestAvailability(false); setRequestData([]); }}>X</span></div>
            </div>
        }
    </>)
}

export default BookingRequestComponent;