import { FC, useState, useEffect } from "react";
import { UserAccess } from "../../../../common/constants";
import { isCorporateProgrammeModuleAvailable } from "../../../../common/functions";
import AccessDenied from "../../../common/AccessDenied";
import ProgrammeSpendDetails from "./ProgrammeSpendDetails";
import { Tab, Row, Col, Nav } from 'react-bootstrap';
import { navContentStyle, navStyle } from "../../../../common/components-style";
import UploadPropertyData from "./UploadPropertyData";
import UnmatchedPropertyData from "./UnmatchedPropertyData";
import ReportDataFields from "./ReportDataFields";
import LocationOverviewReport from "./LocationOverviewReport";
import { useHistory } from 'react-router-dom';
import UploadData from "./UploadData";
import { getStorage } from '../../../../common/storage';


const ProgrammeSpendDataForm: FC<any> = ({ reportId, programmeId, setFormOpen, reload, selectedProgramme, selectedClient, startDate, endDate, location, selectedClientName, accommodationType, reportName, associateProgrammes, currency, data, hotelList }): JSX.Element => {
	const [tabKey, setTabKey] = useState<any>(location?.state?.tabKey ? location?.state?.tabKey : 'createReport');
	const [selectedProgrammeLocation, setSelectedProgramme] = useState<any>(null);
	const [selectedClientLocation, setSelectClient] = useState<String>("");
	const [reportNameLocation, setReportName] = useState<String>("");
	const [reportIdLocation, setReportId] = useState<String>("");
	const [programmeIdLocation, setProgrammeId] = useState<String>("");
	const [accommodationTypeLocation, setAccommodationTypeLocation] = useState<String>("");
	const [associatedProgrammeLocation, setAssociatedProgrammeLocation] = useState<String>("");
	const history = useHistory();

	useEffect(() => {
		if (location?.state?.defaultKey) {
			setTabKey(location?.state?.defaultKey);
		}
	}, [location?.state?.defaultKey]);

	useEffect(() => {
		if (location?.state?.selectedProgramme) {
			setSelectedProgramme(location?.state?.selectedProgramme);
		}
	}, [location]);

	useEffect(() => {
		if (location?.state?.selectedClient) {
			setSelectClient(location?.state?.selectedClient)
		}
	}, [location]);

	useEffect(() => {
		const storedData = getStorage(`programmeSpendData_${reportId}`);
		if (storedData) {
			setReportName(storedData?.reportName);
			setReportId(storedData?.key);
			setProgrammeId(storedData?.programmeId?._id);
			setAccommodationTypeLocation(storedData?.accommodationType);
			setAssociatedProgrammeLocation(storedData?.associatedProgramme);
			setSelectClient(storedData?.selectedClient);
			setSelectedProgramme(storedData?.selectedProgramme);
		}
	}, [reportId]);

	const redirectLocationOverviewData = () => {
		history.push('/admin/location-overview-data', { programmeId: programmeIdLocation ? programmeIdLocation : programmeId, selectedClient: selectedClientLocation ? selectedClientLocation : selectedClient, reportId: reportIdLocation ? reportIdLocation : reportId, reportName: reportNameLocation ? reportNameLocation : reportName, currency: currency, accommodationType: accommodationTypeLocation ? accommodationTypeLocation : accommodationType, associateProgrammes: associatedProgrammeLocation ? associatedProgrammeLocation : associateProgrammes })
	}

	return (
		<div style={{ marginLeft: '-40px', marginTop: '-38px' }} >
			{reportIdLocation || reportId ? <Tab.Container defaultActiveKey="createReport" key={tabKey} activeKey={tabKey} onSelect={(tab) => setTabKey(tab)}>
				<Row >
					<Col sm={2}>
						<Nav variant="pills" className="flex-column secondry-menu" style={navStyle}>
							<div style={{ marginTop: '0%' }}>
								<Nav.Item>
									<Nav.Link eventKey="createReport">Create Report</Nav.Link>
								</Nav.Item>
								{(reportIdLocation || reportId) && (
									<Nav.Item>
										<Nav.Link eventKey="uploadProperties">Upload Properties</Nav.Link>
									</Nav.Item>
								)}
								{(reportIdLocation || reportId) && (<Nav.Item><Nav.Link eventKey="unmatchedProperties">Unmatched Properties</Nav.Link></Nav.Item>)}
								{(reportIdLocation || reportId) && (<Nav.Item><Nav.Link eventKey="reportDataFields">Report Data Fields</Nav.Link></Nav.Item>)}
								{(reportIdLocation || reportId) && (<Nav.Item><Nav.Link eventKey="uploadData">Upload Data</Nav.Link></Nav.Item>)}
								{(reportIdLocation || reportId) && (<Nav.Item><Nav.Link eventKey="locationTableColumns">Location Table Columns</Nav.Link></Nav.Item>)}
								{(reportIdLocation || reportId) && (<Nav.Item><Nav.Link eventKey="reports" onClick={redirectLocationOverviewData}>Reports</Nav.Link></Nav.Item>)}
							</div>
						</Nav>
					</Col>
					<Col sm={10}>
						<Tab.Content >
							<Tab.Pane eventKey="createReport">
								<div style={navContentStyle}>
									{isCorporateProgrammeModuleAvailable(selectedProgrammeLocation?.clientId ? selectedProgrammeLocation?.clientId : selectedProgramme?.clientId, selectedProgrammeLocation?.key ? selectedProgrammeLocation?.key : selectedProgramme?.key, UserAccess.programme_access.programmeAdmin.code) ? <ProgrammeSpendDetails programmeId={programmeIdLocation ? programmeIdLocation : programmeId} reportId={reportIdLocation ? reportIdLocation : reportId} setFormOpen={setFormOpen} reload={reload} selectedClient={selectedClientLocation ? selectedClientLocation : selectedClient} selectedProgramme={selectedProgrammeLocation ? selectedProgrammeLocation : selectedProgramme} /> : <AccessDenied />}
								</div>
							</Tab.Pane>
							{(reportIdLocation || reportId) && <Tab.Pane eventKey="uploadProperties">
								<div style={navContentStyle}>
									{isCorporateProgrammeModuleAvailable(selectedProgrammeLocation?.clientId ? selectedProgrammeLocation?.clientId : selectedProgramme?.clientId, selectedProgrammeLocation?.key ? selectedProgrammeLocation?.key : selectedProgramme?.key, UserAccess.programme_access.programmeAdmin.code) ? <UploadPropertyData programmeId={programmeIdLocation ? programmeIdLocation : programmeId} reportId={reportIdLocation ? reportIdLocation : reportId} setFormOpen={setFormOpen} reload={reload} selectedClient={selectedClientLocation ? selectedClientLocation : selectedClient} selectedProgramme={selectedProgrammeLocation ? selectedProgrammeLocation : selectedProgramme} accommodationType={accommodationTypeLocation ? accommodationTypeLocation : accommodationType} reportName={reportNameLocation ? reportNameLocation : reportName} /> : <AccessDenied />}
								</div>
							</Tab.Pane>}
							{(reportIdLocation || reportId) && <Tab.Pane eventKey="unmatchedProperties">
								<div>
									{isCorporateProgrammeModuleAvailable(selectedProgrammeLocation?.clientId ? selectedProgrammeLocation?.clientId : selectedProgramme?.clientId, selectedProgrammeLocation?.key ? selectedProgrammeLocation?.key : selectedProgramme?.key, UserAccess.programme_access.programmeAdmin.code) ? <UnmatchedPropertyData programmeId={programmeIdLocation ? programmeIdLocation : programmeId} reportId={reportIdLocation ? reportIdLocation : reportId} setFormOpen={setFormOpen} reload={reload} selectedClient={selectedClientLocation ? selectedClientLocation : selectedClient} selectedProgramme={selectedProgrammeLocation ? selectedProgrammeLocation : selectedProgramme} accommodationType={accommodationTypeLocation ? accommodationTypeLocation : accommodationType} reportName={reportNameLocation ? reportNameLocation : reportName} /> : <AccessDenied />}
								</div>
							</Tab.Pane>}
							{(reportIdLocation || reportId) && <Tab.Pane eventKey="reportDataFields">
								<div style={navContentStyle}>
									{isCorporateProgrammeModuleAvailable(selectedProgrammeLocation?.clientId ? selectedProgrammeLocation?.clientId : selectedProgramme?.clientId, selectedProgrammeLocation?.key ? selectedProgrammeLocation?.key : selectedProgramme?.key, UserAccess.programme_access.programmeAdmin.code) ? <ReportDataFields programmeId={programmeIdLocation ? programmeIdLocation : programmeId} reportId={reportIdLocation ? reportIdLocation : reportId} setFormOpen={setFormOpen} reload={reload} selectedClient={selectedClientLocation ? selectedClientLocation : selectedClient} selectedProgramme={selectedProgrammeLocation ? selectedProgrammeLocation : selectedProgramme} accommodationType={accommodationTypeLocation ? accommodationTypeLocation : accommodationType} reportName={reportNameLocation ? reportNameLocation : reportName} associateProgrammes={associatedProgrammeLocation ? associatedProgrammeLocation : associateProgrammes} /> : <AccessDenied />}
								</div>
							</Tab.Pane>}
							{(reportIdLocation || reportId) && <Tab.Pane eventKey="uploadData">
								<div style={navContentStyle}>
									{isCorporateProgrammeModuleAvailable(selectedProgrammeLocation?.clientId ? selectedProgrammeLocation?.clientId : selectedProgramme?.clientId, selectedProgrammeLocation?.key ? selectedProgrammeLocation?.key : selectedProgramme?.key, UserAccess.programme_access.programmeAdmin.code) ? <UploadData programmeId={programmeIdLocation ? programmeIdLocation : programmeId} reportId={reportIdLocation ? reportIdLocation : reportId} setFormOpen={setFormOpen} reload={reload} selectedClient={selectedClientLocation ? selectedClientLocation : selectedClient} selectedProgramme={selectedProgrammeLocation ? selectedProgrammeLocation : selectedProgramme} accommodationType={accommodationTypeLocation ? accommodationTypeLocation : accommodationType} reportName={reportNameLocation ? reportNameLocation : reportName} hotelList={hotelList} /> : <AccessDenied />}
								</div>
							</Tab.Pane>}
							{(reportIdLocation || reportId) && <Tab.Pane eventKey="locationTableColumns">
								<div style={navContentStyle}>
									{isCorporateProgrammeModuleAvailable(selectedProgrammeLocation?.clientId ? selectedProgrammeLocation?.clientId : selectedProgramme?.clientId, selectedProgrammeLocation?.key ? selectedProgrammeLocation?.key : selectedProgramme?.key, UserAccess.programme_access.programmeAdmin.code) ? <LocationOverviewReport programmeId={programmeIdLocation ? programmeIdLocation : programmeId} reportId={reportIdLocation ? reportIdLocation : reportId} setFormOpen={setFormOpen} reload={reload} selectedClient={selectedClientLocation ? selectedClientLocation : selectedClient} selectedProgramme={selectedProgrammeLocation ? selectedProgrammeLocation : selectedProgramme} accommodationType={accommodationTypeLocation ? accommodationTypeLocation : accommodationType} reportName={reportNameLocation ? reportNameLocation : reportName} associateProgrammes={associatedProgrammeLocation ? associatedProgrammeLocation : associateProgrammes} /> : <AccessDenied />}
								</div>
							</Tab.Pane>}
						</Tab.Content>
					</Col>
				</Row>
			</Tab.Container> :
				isCorporateProgrammeModuleAvailable(selectedProgrammeLocation?.clientId ? selectedProgrammeLocation?.clientId : selectedProgramme?.clientId, selectedProgrammeLocation?.key ? selectedProgrammeLocation?.key : selectedProgramme?.key, UserAccess.programme_access.programmeAdmin.code) ? <ProgrammeSpendDetails programmeId={programmeIdLocation ? programmeIdLocation : programmeId} reportId={reportIdLocation ? reportIdLocation : reportId} setFormOpen={setFormOpen} reload={reload} selectedClient={selectedClientLocation ? selectedClientLocation : selectedClient} selectedProgramme={selectedProgrammeLocation ? selectedProgrammeLocation : selectedProgramme} /> : <AccessDenied />
			}
		</div>
	)
}


export default ProgrammeSpendDataForm;
