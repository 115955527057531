import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Row, Col, Image, OverlayTrigger, Tooltip, Button, Modal, Form } from 'react-bootstrap';
import Select from 'react-select';
import DataTable, { createTheme } from 'react-data-table-component';
import { useStoreActions, useStoreState } from 'easy-peasy';
import _ from 'lodash';
import { BrandStatus, UserGroups } from '../../../common/constants';
import { getLoggedUserType } from '../../../common/functions';
import { setStorage } from '../../../common/storage';
import { mysaTableTheme, tableStyles, overlayTriggerDelay, $mysaTeal, $mysaYellow, $mysaMegenta, selectStylesLittleRect, routingButton, $mysaRed, tableUserStyles } from '../../../common/components-style';
import Archive from '../../../images/icons/svg/outside/Icon-Function-Archive.svg';
import ViewEdit from '../../../images/icons/svg/outside/Icon-Function-View-Edit.svg';
import Upload from '../../../images/icons/svg/outside/up-loading.svg';
import SweetAlert from 'react-bootstrap-sweetalert';
import CircleIcon from '../../../images/icons/svg/circle.svg';
import { useForm } from "react-hook-form";
import { useDropzone } from 'react-dropzone';
import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import NewBrand from './NewBrand';
import { BrandRegisterFormInput } from '../../../common/interfaces';



const selectStyles = {
	control: (base, state) => ({
		...base,
		background: "#FBC91D",
		height: "50px",
		paddingLeft: "10px",
		color: "#212121",
		borderRadius: 15,
		borderColor: "#F0F0F0",
		boxShadow: state.isFocused ? null : null,
		"&:hover": {
			borderColor: "#212121",
		},
	}),
	menu: (base) => ({
		...base,
		hyphens: "auto",
		marginTop: 0,
		borderRadius: "14px",
		textAlign: "left",
		zIndex: 3
	}),
	menuList: (base) => ({
		...base,
		padding: "10px",
		color: "#000000",
	}),
	dropdownIndicator: base => ({
		...base,
		color: "#000000"
	})
};

////////////////////// table theme setting ////////////////////////////////////
createTheme('mysaTable', mysaTableTheme, 'light');

const fallbackUrl = 'https://res.cloudinary.com/mysa/image/upload/v1613451891/common/no-image-1771002-1505134_sqlz99.webp';

const BrandList: React.FC<any> = ({ loadForm, manageBrandInvitationDisplay }): JSX.Element => {
	const [options, setOptions] = useState<any>([]);
	const [data, setData] = useState<any>([]);
	const [brandId, setBrandId] = useState<String>("");
	const [brandStatus, setBrandStatus] = useState<String>("");
	// const [propertyStatus, setPropertyStatus] = useState<String>("");
	const [archivePopop, setArchivePopop] = useState<boolean>(false);
	const [activatePopop, setActivatePopop] = useState<boolean>(false);
	const [brandArchive, setBrandArchive] = useState<any>("");
	const [userData, setUserData] = useState<any>(null);
	const [myoManagementReference, setMyoManagementReference] =
		useState<string>();
	const [brandModelShow, setBrandModelShow] = useState<boolean>(false);
	const [openModal, setOpenModal] = useState<boolean>(false);
	const [openHotelModal, setOpenHotelModal] = useState<boolean>(false);
	const [openBothModal, setOpenBothModal] = useState<boolean>(false);
	const [uploadModal, setUploadModal] = useState<boolean>(false);
	// const [programmerUserDomains, setProgrammerUserDomains] = useState<Array<string>>([]);
	const [finalModal, setFinalModal] = useState<boolean>(false);
	const [errorRecord, setErrorRecord] = useState<Array<any>>([]);
	const [brandData, setBrandData] = useState<any>();
	const [bookerImport, setBookerImport] = useState<boolean>(false);
	const [propertyData, setPropertyData] = useState<any>();
	const [accommodationTypeList, setAccommodationTypeList] = useState<any>([]);
	const [type, setType] = useState<any>();

	const {
		register,

		handleSubmit,
		formState: { errors },
		control,

	} = useForm<BrandRegisterFormInput>();

	const { createHotel, createProperty, createPropertyInformation, getPropertyInformation, getBrandListNewVersion, onApprovalBrand, updateMYOManagementClient, getUserMapping, } = useStoreActions<any>((actions) => ({
		getBrandListNewVersion: actions.brand.getBrandListNewVersion,
		onApprovalBrand: actions.brand.onApprovalBrand,
		updateMYOManagementClient: actions.myoManagementModel.updateMYOManagementClient,
		getUserMapping: actions.user.getUserMapping,

		createPropertyInformation: actions.brand.createPropertyInformation,
		getPropertyInformation: actions.brand.getPropertyInformation,
		createProperty: actions.property.createProperty,
		createHotel: actions.hotel.createHotel,
		// onloadBrand: actions.brand.onloadBrand,
	}));

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const { createHotelSuccess, createHotelError, createPropertySuccess, createPropertyError, createPropertyInformationSuccess, getPropertyInformationSuccess, getBrandListNewVersionSuccess, getBrandListNewVersionError, approvalBrandSuccess, getUserMappingSuccess, } = useStoreState<any>((state) => ({
		getBrandListNewVersionSuccess: state.brand.getBrandListNewVersionSuccess,
		getBrandListNewVersionError: state.brand.getBrandListNewVersionError,
		approvalBrandSuccess: state.brand.approvalBrandSuccess,
		getUserMappingSuccess: state.user.getUserMappingSuccess,
		createHotelSuccess: state.hotel.createHotelSuccess,
		createHotelError: state.hotel.createHotelError,
		// brandListSuccess: state.brand.brandListSuccess,
		// brandListError: state.brand.brandListError,
		// loadBrandSuccess: state.brand.loadBrandSuccess,

		createPropertyInformationSuccess: state.brand.createPropertyInformationSuccess,
		getPropertyInformationSuccess: state.brand.getPropertyInformationSuccess,
		createPropertySuccess: state.property.createPropertySuccess,
		createPropertyError: state.property.createPropertyError,
	}));

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const callBrandList = useCallback(() => {
		const payload = {
			brand_id: brandId,
			brand_status: brandStatus,
			// property_status: propertyStatus,
		}
		getBrandListNewVersion(payload)
	}, [brandId, brandStatus, getBrandListNewVersion]);

	useEffect(() => {
		callBrandList();
	}, [brandId, brandStatus, callBrandList]);

	useEffect(() => {
		if (brandId) {
			setTimeout(() => getUserMapping({ brandId }), 1000);

		}
	}, [brandId, getUserMapping]);


	useEffect(() => {
		if (getUserMappingSuccess) {
			const { result } = getUserMappingSuccess.data;
			const mappedData = _.map(result, (val) => {
				const firstUser =
					_.filter(val.brands, (a) => a.brandId === brandId).shift()
						?.isFirstUser || false;
				if (val.user) {
					return {
						userId: val.user._id,
						firstName: val.user.firstName,
						lastName: val.user.lastName,
						email: val.user.email,
						phone: val.user.phone,
						mobile: val.user.mobile,
						position: val.user.position,
						data: val,
						isFirstUser: firstUser,
					};
				}
			});

			let filterData = _.first(
				_.filter(mappedData, (list) => list?.isFirstUser === true)
			);
			if (filterData) {
				setUserData(filterData);
			}
		}
	}, [brandId, getUserMappingSuccess]);

	////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (getBrandListNewVersionError) {
			toast.error(getBrandListNewVersionError.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
		}
		if (getBrandListNewVersionSuccess) {

			const mappedData: any = _.map(getBrandListNewVersionSuccess.data, (val) => {

				if (brandStatus !== 'archive') {
					if (val?.brandStatus !== 'archive') {
						return {
							key: val._id,
							extId: null,
							image: val.image,
							name: val.name,
							brandStatus: val.brandStatus,
							countPending: val.countPending,
							countIncomplete: val.countIncomplete,
							myoManagementReference: val?.myoManagementReference,
							parentAccommodationCompany: val.parentAccommodationCompany,
							accommodationType: val.accommodationType

						}
					}
				} else {
					if (val.brandStatus === 'archive') {
						return {
							key: val._id,
							extId: null,
							image: val.image,
							name: val.name,
							brandStatus: val.brandStatus,
							countPending: val.countPending,
							countIncomplete: val.countIncomplete,
							myoManagementReference: val?.myoManagementReference,
							parentAccommodationCompany: val.parentAccommodationCompany,
							accommodationType: val.accommodationType
						}
					}
				}

			});
			var filtered = mappedData.filter(function (el) {
				return el != null;
			});
			if (filtered) {
				setData(filtered);
			}

		}

	}, [brandStatus, getBrandListNewVersionError, getBrandListNewVersionSuccess]);

	//////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		// if (partnerListSuccess) {
		const locationListTemp: any = [{
			_id: 'hotel',
			name: "Hotel",
		},
		{
			_id: 'serviced_apartments',
			name: "Extended Stay/Apart hotel",
		},
		{
			_id: 'both',
			name: "Both",
		},
		];

		setAccommodationTypeList(locationListTemp);
		// }

	}, []);

	////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {

		if (data) {
			if (options.length === 0 && getLoggedUserType() === UserGroups.super_admin) {
				const brandListTemp: any = [];
				_.map(data, (element) =>
					brandListTemp.push(
						{
							value: element?.key,
							label: element?.name,
						}
					)
				);
				setOptions(brandListTemp);
			}

		}
	}, [data, options, options.length]);

	////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {

		if (approvalBrandSuccess) {

			if (userData) {
				updateMYOManagementClient({
					security: {
						apiKey: process.env.REACT_APP_MYO_MANAGEMENT_API_KEY,
						user: "superadmin@mysa.global",
					},
					client: {
						id: myoManagementReference,
						parentID: "",
						type: "Brand Client",
						name: approvalBrandSuccess?.data?.name,
						brandStatus: approvalBrandSuccess?.data?.brandStatus,
						contact: userData?.firstName + " " + userData?.lastName,
						email: userData?.email,
						phone: approvalBrandSuccess?.data?.headOfficeTelephone,
						externalID: approvalBrandSuccess?.data?._id,
						managedExternally: false,
						contactInfo: {
							name: userData?.firstName + " " + userData?.lastName,
							email: userData?.email,
							// name: responsiblePersonName,
							// email: responsiblePersonEmail,
						},
					},
				});
			}


			callBrandList();
		}
	}, [approvalBrandSuccess, callBrandList, myoManagementReference, updateMYOManagementClient, userData]);



	useEffect(() => {
		if (getPropertyInformationSuccess) {
			const data = getPropertyInformationSuccess.data;
			setPropertyData(data);

		}
	}, [getPropertyInformationSuccess]);


	useEffect(() => {
		if (createPropertyInformationSuccess) {
			if (createPropertyInformationSuccess?.data) {
				if (createPropertyInformationSuccess?.data?.brandId) {
					getPropertyInformation(createPropertyInformationSuccess?.data?.brandId)
				}
				setOpenModal(false);
				setOpenBothModal(false);
				setOpenHotelModal(false);
				setUploadModal(true);
			}
		}

	}, [createPropertyInformationSuccess, getPropertyInformation]);



	////////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		// user create success
		if (bookerImport) {
			if (createPropertySuccess) {
			}
		}

		if (createPropertyError) {
			toast.error(createPropertyError.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
		}

	}, [bookerImport, createPropertyError, createPropertySuccess]);


	const callApi = useCallback((user) => {

		if (user) {

			let newData = user.map(obj => {
				let newObj = {};
				for (let key in obj) {
					let newKey = key.replace(/\s+/g, '');
					newObj[newKey] = obj[key];
				}
				return newObj;
			});

			const matchingProperty = newData.reduce((result, item) => {
				if ("propertyName" in item) {
					result.push(item);
				}
				return result;
			}, []);

			const matchingHotel = newData.reduce((result, item) => {
				if ("hotelName" in item) {
					result.push(item);
				}
				return result;
			}, []);


			let payload;
			if (matchingProperty?.length > 0) {
				payload = {
					brandId: brandData?.key,
					type: 'property',
					property: matchingProperty
				}
			}
			if (matchingHotel?.length > 0) {
				payload = {
					brandId: brandData?.key,
					type: 'hotel',
					hotel: matchingHotel
				}
			}

			setTimeout(() => createPropertyInformation(payload), 1000)
		}

	}, [brandData?.key, createPropertyInformation])

	const onDrop = useCallback((acceptedFiles) => {
		const file = acceptedFiles[0];
		const reader = new FileReader();

		reader.onload = () => {
			const binaryStr = reader.result;

			if (file.name.endsWith('.xlsx') || file.name.endsWith('.xls')) {
				const workbook = XLSX.read(binaryStr, { type: 'binary' });
				const sheetName = workbook.SheetNames[0];
				const worksheet = workbook.Sheets[sheetName];
				const json = XLSX.utils.sheet_to_json(worksheet);
				callApi(json);
			} else if (file.name.endsWith('.csv')) {
				Papa.parse(binaryStr, {
					header: true,
					complete: (results) => {

						callApi(results.data);
					}
				});

			} else {
				console.error('Unsupported file format');
			}


		};
		reader.readAsBinaryString(file);

	}, [callApi]);

	const { open } = useDropzone({ onDrop });

	//////////////////////////////////////////////////////////////////////////////////////////////////////////////
	const insertMultipleRecords = useCallback(async (records) => {

		for (let i = 0; i < records.length; i++) {
			if (type === 'serviced_apartments') {

				await createProperty(records[i]);
			} else if (type === 'hotel') {

				await createHotel(records[i]);
			}

		}
		setUploadModal(false);
		setFinalModal(true);

	}, [createHotel, createProperty, type]);

	////////////////////////////////////////////////////////////////
	const handleInsertRecords = useCallback(async (propertyList) => {
		const records: any = [];
		for (let i = 0; i < propertyList.length; i++) {
			if (type === 'serviced_apartments') {
				records.push({ propertyName: propertyList[i].propertyName, country: propertyList[i].country, city: propertyList[i].city, brandId: propertyList[i]?.brandId });
			} else if (type === 'hotel') {
				records.push({ hotelName: propertyList[i].hotelName, country: propertyList[i].country, city: propertyList[i].city, brandId: propertyList[i]?.brandId });
			}

		}

		await insertMultipleRecords(records);
	}, [insertMultipleRecords, type]);


	///////////////////////////////////////////////////////////////////////////////////////////////////////
	const startUpload = useCallback(() => {
		// setStorage('bookerImport', true);
		setBookerImport(true);
		const userDetails: any = [];
		const errorData: any = [];
		if (propertyData) {
			if (type === 'serviced_apartments') {


				propertyData?.properties.forEach(record => {
					// const emailDomain = record.email.split('@')[1];
					// console.log(domain)
					// let emailDomain = record?.email.substring(record?.email.indexOf('@') + 1);
					// const isDomainMatch = programmerUserDomains.includes(emailDomain);
					const isNameEmpty = !record.propertyName || !record.country || !record.city;

					if (!isNameEmpty) {
						userDetails.push({ brandId: propertyData?.brandId, propertyName: record.propertyName, country: record.country, city: record.city });
					} else {
						errorData.push(record);
					}
				});
			} else if (type === 'hotel') {
				propertyData?.hotels.forEach(record => {
					// const emailDomain = record.email.split('@')[1];
					// console.log(domain)
					// let emailDomain = record?.email.substring(record?.email.indexOf('@') + 1);
					// const isDomainMatch = programmerUserDomains.includes(emailDomain);
					const isNameEmpty = !record.hotelName || !record.country || !record.city;

					if (!isNameEmpty) {
						userDetails.push({ brandId: propertyData?.brandId, hotelName: record.hotelName, country: record.country, city: record.city });
					} else {
						errorData.push(record);
					}
				});
			}
		}

		handleInsertRecords(userDetails);
		setErrorRecord(errorData);
	}, [handleInsertRecords, propertyData, type]);


	const uploadData = useCallback((row) => {
		if (row?.accommodationType === 'serviced_apartments') {
			setOpenModal(true);
			setOpenBothModal(false);
			setOpenHotelModal(false);
		} else if (row?.accommodationType === 'hotel') {
			setOpenHotelModal(true);
			setOpenModal(false);
			setOpenBothModal(false);
		} else if (row?.accommodationType === 'both') {
			setOpenBothModal(true);
			setOpenHotelModal(false);
			setOpenModal(false);
		}
		setBrandData(row);
	}, []);

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const columns = [
		{
			name: 'Logo',
			cell: (row) => (
				<Image width={60} height={60} src={row.image} onError={(e: any) => { e.target.src = fallbackUrl; }} />
			),
		},
		{
			name: 'Name',
			selector: row => row.name,
		},
		{
			name: 'Parent',
			selector: row => row.parentAccommodationCompany,
		},
		{
			name: 'Property Type',
			cell: (row) => {
				let text = "";
				if (row?.accommodationType === 'serviced_apartments') {
					text = 'Serviced Accom'
				} else if (row?.accommodationType === 'hotel') {
					text = 'Hotel'
				} else if (row?.accommodationType === 'both') {
					text = 'Both'
				}
				return (
					<span style={{}} className='text-capitalize'>{text}</span>
				)
			}
		},



		{
			name: 'Status',
			cell: (row) => {
				let color = "";
				if (row.brandStatus === BrandStatus.incomplete) color = $mysaMegenta;
				if (row.brandStatus === BrandStatus.pending) color = $mysaYellow;
				if (row.brandStatus === BrandStatus.accepted) color = $mysaTeal;
				if (row.brandStatus === BrandStatus.archive) color = $mysaMegenta;
				if (row.brandStatus === BrandStatus.review) color = $mysaYellow;
				if (row.brandStatus === BrandStatus.inviteSent) color = $mysaMegenta;
				if (row.brandStatus === BrandStatus.not_started) color = $mysaRed;
				return (
					<span style={{ color: color, fontWeight: 'bold' }} className='text-capitalize'>{row.brandStatus}</span>
				)
			}
		},
		{
			name: 'Pending Properties',
			selector: row => row.countPending,

		},
		{
			name: 'Incomplete Properties',
			selector: row => row.countIncomplete,

		},
		{
			name: 'Action',
			button: true,
			cell: (row: any) => {
				return (<>
					{(getLoggedUserType() === UserGroups.super_admin) && <OverlayTrigger
						placement="top"
						delay={overlayTriggerDelay}
						overlay={<Tooltip id='tooltip-table-top'>Upload</Tooltip>}
					>
						<img className='iconSizes' src={Upload} alt="" onClick={() => { uploadData(row); }} />
					</OverlayTrigger>}

					<OverlayTrigger
						placement="top"
						delay={overlayTriggerDelay}
						overlay={<Tooltip id='tooltip-table-top'>View / Edit</Tooltip>}
					>
						<img className='iconSizes' src={ViewEdit} alt="" onClick={() => { setStorage('myo_selected_brand', row); loadForm(row.key, row.extId) }} />
					</OverlayTrigger>

					{
						getLoggedUserType() === UserGroups.super_admin && <OverlayTrigger
							placement="top"
							delay={overlayTriggerDelay}
							overlay={<Tooltip id='tooltip-table-top'>Archive</Tooltip>}
						>
							<img className='iconSizes' src={Archive} alt="" onClick={() => {
								const data = {
									id: row?.key,
									brandStatus: BrandStatus.archive,
									myoManagementReference: row?.myoManagementReference
								}
								setBrandArchive(data);
								setArchivePopop(true);
							}} />
						</OverlayTrigger>
					}

					{
						row.brandStatus === BrandStatus.archive && <OverlayTrigger
							placement="top"
							delay={overlayTriggerDelay}
							overlay={<Tooltip id='tooltip-table-top'>Activate</Tooltip>}
						>
							<img className='iconSizes' src={CircleIcon} alt="" onClick={() => {
								const data = {
									id: row?.key,
									brandStatus: BrandStatus.reActivate,
									myoManagementReference: row?.myoManagementReference
								}
								setBrandArchive(data);
								setActivatePopop(true);
							}} />
						</OverlayTrigger>
					}


				</>)
			},
		},
	];

	//////////////////////////////////////////////////////////////////////////////////////
	const onChangeBrand = useCallback((data: any) => {

		if (data?.value) {
			setBrandId(data.value);
		} else {
			setBrandId('');
		}

	}, [])

	const onChangeBrandStatus = useCallback((data: any) => {
		if (data?.value) {
			setBrandStatus(data.value);
		} else {
			setBrandStatus('');
		}

	}, [])

	const onChangeType = useCallback((data: any) => {
		if (data?.value) {
			setType(data?.value);
		} else {
			setType('');
		}

	}, [])


	const onApproveArchiveBrand = useCallback((data: any) => {
		if (data) {
			setBrandId(data?.id);
			setMyoManagementReference(data?.myoManagementReference);
			setTimeout(() => getUserMapping({ brandId: data?.id }), 1000);
			setTimeout(() => onApprovalBrand(data), 2000);
		}


	}, [getUserMapping, onApprovalBrand])


	const columns1 = [
		{ name: 'Property name', selector: row => row.propertyName, },
		{ name: 'Country', selector: row => row.country, },
		{ name: 'City', selector: row => row.city, },


	];

	////////////////////////////////////////////////////////////////////////////////////////////////////
	return (
		<>
			<div className="content-title mysa-blue-text" style={{ marginBottom: "16px" }}>
				Brand Management
			</div>
			{getLoggedUserType() === UserGroups.super_admin && <>
				<Row className="text-center mt-5">
					<Col xs={2}>
						<Select
							inputId='searchBrandId'
							className='search_bar select_btn placeholder_bold'
							isClearable
							placeholder={<div className='text-center'>Search Brand</div>}
							options={options}
							styles={selectStylesLittleRect}
							isSearchable={true}
							onChange={onChangeBrand}

						/>
					</Col>
					<Col xs={2}>
						{/* <div style={{ textAlign: 'right', marginTop: 10 }}>
							<b>Brand Status</b>
						</div> */}
					</Col>
					<Col xs={2}>
						<Select
							inputId='searchBrandStatusId'
							className='select_btn placeholder_bold'
							isClearable
							placeholder={<div>Select Brand Status</div>}
							options={[
								{
									value: BrandStatus.not_started,
									label: 'Not Started',
								},
								{
									value: BrandStatus.inviteSent,
									label: 'Invite Sent',
								},
								{
									value: BrandStatus.incomplete,
									label: 'Incomplete',
								},
								{
									value: BrandStatus.pending,
									label: 'Pending',
								},
								{
									value: BrandStatus.review,
									label: 'Review',
								},
								{
									value: BrandStatus.accepted,
									label: 'Accepted',
								},
								{
									value: BrandStatus.archive,
									label: 'Archive',
								},
							]}
							styles={selectStyles}
							isSearchable={false}
							onChange={onChangeBrandStatus}
						/>
					</Col>
					{/* <div> */}
					<Col xs={2}>
						{/* <div style={{ textAlign: 'right', marginTop: 10 }}>
							<b>Property Status</b>
						</div> */}
					</Col>
					{/* <Col xs={2}>
						<Select
							className='select_btn'
							isClearable
							placeholder={<div>Select</div>}
							options={[
								{
									value: PropertyStatus.awaiting,
									label: 'Incomplete',
								},
								{
									value: PropertyStatus.pending,
									label: 'Pending',
								}
							]}
							styles={selectStyles}
							isSearchable={false}
							onChange={onChangePropertyStatus}
						/>
					</Col> */}
					{/* </div> */}
					<Col xs={2} >
						<Button style={routingButton} id='routing-button-blue-brand' className='w-100' onClick={() => setBrandModelShow(true)}><b> Create Brand</b></Button>
					</Col>
				</Row>

			</>}
			<div className='brnd_tbl' style={{ marginTop: '50px' }}>
				<DataTable
					columns={columns}
					data={data}
					pagination
					striped={true}
					customStyles={tableStyles}
					theme='mysaTable'
					fixedHeader={false}
					noDataComponent={'Updating records'}
					paginationPerPage={50}
					paginationRowsPerPageOptions={[10, 50, 100, 200]}
				/>
			</div>

			<SweetAlert
				show={archivePopop}
				warning
				showCancel={true}
				focusCancelBtn={true}
				confirmBtnText="Yes archive it!"
				confirmBtnBsStyle="danger"
				title="Are you sure you wish to archive this brand?"
				onConfirm={() => {
					onApproveArchiveBrand(brandArchive);

					setArchivePopop(false);
				}}
				onCancel={() => {
					setArchivePopop(false);
				}}
			>
			</SweetAlert>

			<SweetAlert
				show={activatePopop}
				warning
				showCancel={true}
				focusCancelBtn={true}
				confirmBtnText="Yes activate it!"
				confirmBtnBsStyle="danger"
				title="Are you sure you wish to re-activate’ this brand"
				onConfirm={() => {
					onApproveArchiveBrand(brandArchive);
					// onApprovalBrand(brandArchive);
					setActivatePopop(false);
				}}
				onCancel={() => {
					setActivatePopop(false);
				}}
			>
			</SweetAlert>
			{brandModelShow && <NewBrand showModal={brandModelShow} list={accommodationTypeList} />}

			<Modal
				show={openModal}
				onHide={() => { setOpenModal(false); }}
				width={750}
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header closeButton>
				</Modal.Header>
				<Modal.Body>
					<Row style={{ textAlign: 'center' }}>
						<h3>
							Import file from computer
						</h3>
						<div>
							<p>
								Upload any CSV,XLS or XLSX files with property name, address1, address2, city; postcode, county and province.
							</p>
						</div>
						<Col >

							<Button style={routingButton} id='routing-button' onClick={open}><b>Upload file</b></Button>
						</Col>
					</Row>
				</Modal.Body>
			</Modal>

			<Modal
				show={openBothModal}
				onHide={() => { setOpenBothModal(false); }}
				// width={1000}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header closeButton>
				</Modal.Header>
				<Modal.Body>

					<Row  >
						<Col md={6}>
							<div style={{ textAlign: 'right', marginTop: 10 }}>
								<p>Please choose types of properties/hotels to
									upload for {brandData?.name}
								</p>
							</div>

						</Col>
						<Col md={6}>
							<Select
								className='select_btn'
								isClearable
								placeholder={<div>Select</div>}
								options={[
									{
										value: 'serviced_apartments',
										label: 'Serviced Accommodation',
									},
									{
										value: 'hotel',
										label: 'Hotel',
									},

								]}
								styles={selectStyles}
								isSearchable={false}
								onChange={onChangeType}
							/>
						</Col>
					</Row>
					<Row style={{ textAlign: 'center' }}>
						<Col >

							<Button style={routingButton} id='routing-button' onClick={open}><b>Upload file</b></Button>
						</Col>
					</Row>
				</Modal.Body>
			</Modal>

			<Modal
				show={openHotelModal}
				onHide={() => { setOpenHotelModal(false); }}
				width={750}
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header closeButton>
				</Modal.Header>
				<Modal.Body>
					<Row style={{ textAlign: 'center' }}>
						<h3>
							Import file from computer
						</h3>
						<div>
							<p>
								Upload any CSV,XLS or XLSX files with hotel name, address1, address2, city; postcode, county and province.
							</p>
						</div>
						<Col >

							<Button style={routingButton} id='routing-button' onClick={open}><b>Upload file</b></Button>
						</Col>
					</Row>
				</Modal.Body>
			</Modal>


			<Modal
				show={uploadModal}
				onHide={() => { setUploadModal(false); }}
				width={750}
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header closeButton>
				</Modal.Header>
				<Modal.Body>
					<Row style={{ textAlign: 'center' }}>
						<h3 >
							Start Upload
						</h3>
						<div>
							<p>
								We will notify you when the upload is complete
							</p>
						</div>
						<Col >

							<Button style={routingButton} id='routing-button' onClick={() => { startUpload(); }} ><b>Start</b></Button>
						</Col>
					</Row>

				</Modal.Body>
			</Modal>


			<Modal
				show={finalModal}
				onHide={() => { setFinalModal(false); }}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header closeButton>
				</Modal.Header>
				<Modal.Body>
					{
						errorRecord?.length === 0 ?
							<Row style={{ textAlign: 'center' }}>
								<h3 >
									Your upload is complete.
								</h3>
								<div>
									<p>
										There were 0 errors.
									</p>
								</div>
								<Col >

									<Button style={routingButton} id='routing-button' onClick={() => { setFinalModal(false); }} ><b>Close</b></Button>
								</Col>
							</Row> :
							<Row style={{ textAlign: 'center' }}>
								<h3 >
									Your upload is complete.
								</h3>
								<div>
									<p>
										There were {errorRecord.length} errors.
									</p>
								</div>
								<Col >

									<Button style={routingButton} id='routing-button' onClick={() => { setFinalModal(false); }} ><b>Close</b></Button>
								</Col>


								<div className='mt-4 brandUser_table'>
									<DataTable
										columns={columns1}
										data={errorRecord}
										pagination
										striped={true}
										customStyles={tableUserStyles}
										theme='mysaTable'
										fixedHeader={true}
										noDataComponent={'Updating records'}
									/>
								</div>
							</Row>}
				</Modal.Body>
			</Modal>
		</>
	)
};

export default BrandList;