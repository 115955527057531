import { useStoreActions, useStoreState } from 'easy-peasy';
import React, { useCallback, useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';


const BrandSustainabilityView: React.FC<any> = ({ brand }): JSX.Element => {
    const [considerationData, setConsiderationData] = useState<any>([]);

    /////////////////////////// global state manage (actions)//////////////////////////////////////////////////////
    const { getBrandSustainabilityByBrandId } = useStoreActions<any>((actions) => ({
        getBrandSustainabilityByBrandId: actions.brand.getBrandSustainabilityByBrandId,
    }));

    /////////////////////////// global state manage (state)//////////////////////////////////////////////////////
    const {
        getBrandSustainabilityByBrandIdSuccess,
    } = useStoreState<any>((state) => ({
        getBrandSustainabilityByBrandIdSuccess: state.brand.getBrandSustainabilityByBrandIdSuccess,
    }));


    //////////////////////////////// call apis using actions //////////////////////////////////////////////////////
    useEffect(() => {
        if (brand) {
            getBrandSustainabilityByBrandId(brand?._id);
        }

    }, [getBrandSustainabilityByBrandId, brand]);


    //////////////////////////////// get api response using state //////////////////////////////////////////////////////
    useEffect(() => {
        if (getBrandSustainabilityByBrandIdSuccess) {
            if (getBrandSustainabilityByBrandIdSuccess.data) {
                const data = getBrandSustainabilityByBrandIdSuccess?.data;

                const energyListTemp: any = [
                    data?.havePolicyInYourPropertiesForReplaceAppliances && {
                        label: 'Do you have a policy in place to replace old appliances with energy saving appliances in your properties?',
                        value: data?.havePolicyInYourPropertiesForReplaceAppliances,
                    },

                    data?.havePolicyInYourPropertiesForReplaceAppliances === 'Yes' && data?.whichOfFollowingRecentlyReplaced?.length > 0 ? {
                        label: 'Which of the following have you recently replaced?',
                        value: data?.whichOfFollowingRecentlyReplaced,
                    } : null,

                    data?.doYouUseProductsInSustainablePackage && {
                        label: 'Do you use biodegradable, non-toxic products in sustainable packaging?',
                        value: data?.doYouUseProductsInSustainablePackage,
                    },

                    data?.doYouUseProductsInSustainablePackage === 'Yes' && data?.whichOfFollowingProductPurchased?.length > 0 ? {
                        label: 'Which of the following biodegradable, non-toxic products are you purchasing in sustainable packaging?',
                        value: data?.whichOfFollowingProductPurchased,
                    } : null,

                    data?.doYouUtilizeConferenceCallForStaffInterviews && {
                        label: 'Do you utilise conference calls for initial staff interviews when recruiting?',
                        value: data?.doYouUtilizeConferenceCallForStaffInterviews,
                    },

                    data?.doYouProvideElectronicContractsForStaff && {
                        label: 'Do you provide electronic contracts for staff rather than providing paper contracts?',
                        value: data?.doYouProvideElectronicContractsForStaff,
                    },

                    data?.doYouWorkWithAnyLocalCharities && {
                        label: 'Do you work with any local charities?',
                        value: data?.doYouWorkWithAnyLocalCharities,
                    },

                    data?.doYouWorkWithAnyLocalCharities === 'Yes' && data?.pleaseAdviceWhichCharitiesYouWork ? {
                        label: 'Please advise which charities you work with?',
                        value: data?.pleaseAdviceWhichCharitiesYouWork,
                    } : null
                ];

                const filteredData = energyListTemp.filter(item => item !== null && item !== undefined);

                const energyListWithOddEven = filteredData.map((item, index) => ({
                    ...item,
                    number: index % 2 === 0 ? 'odd' : 'even',
                }));

                setConsiderationData(energyListWithOddEven);

            }
        }
    }, [getBrandSustainabilityByBrandId, getBrandSustainabilityByBrandIdSuccess]);

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const renderShowTransport = useCallback((data) => {

        if (data) {

            return data.map((at: any, key: number) => {
                return (
                    <>

                        <ul>

                            <Col >

                                <span style={{ marginLeft: '2%' }}>{at}</span>

                            </Col>
                        </ul>

                    </>
                );
            });
        }
    }, []);

    return (
        <>
            <ul className=''>
                {
                    considerationData.map((data, index) => {
                        return <div>{data?.value && <li className={data?.number === 'odd' ? "list-group-item list-group-item-dark d-flex justify-content-between align-items-center" : "list-group-item d-flex justify-content-between align-items-center"} style={{ padding: "1rem" }}>{data?.label}
                            {typeof (data.value) === 'object' ?
                                <div>
                                    <Col style={{ textAlign: "right" }}>
                                        {renderShowTransport(data?.value)}
                                    </Col>
                                </div>


                                : <span>{data?.value}</span>}
                        </li>}
                        </div>
                    })}
            </ul>
        </>


    );

}

export default BrandSustainabilityView;