import React, { useLayoutEffect, useCallback, useEffect, useState } from 'react'
import { Tree } from 'antd';
import TreeNode from '../../../../common/TreeNode'
import { UserAccess } from '../../../../../common/constants';
import { getCheckedStatus, setCheckedValues, getUserRole, compareAccess } from '../../../../../common/functions'
import { useStoreActions } from 'easy-peasy';
import _ from 'lodash';
import { setTimeout } from 'timers';
import { Button, Modal } from 'react-bootstrap';
import { formButton } from '../../../../../common/components-style';

const initTreeData = [
	{
		title: <p className="font-bold">Brand management</p>,
		key: 'BA',
		children: [
			{
				title: <TreeNode text={UserAccess.brand_access.details.text} code={UserAccess.brand_access.details.code} />,
				key: `${UserAccess.brand_access.details.code}`
			},
			{
				title: <TreeNode text={UserAccess.brand_access.users.text} code={UserAccess.brand_access.users.code} />,
				key: `${UserAccess.brand_access.users.code}`
			},
			// {
			// 	title: <TreeNode text={UserAccess.brand_access.audit.text} code={UserAccess.brand_access.audit.code} />,
			// 	key: `${UserAccess.brand_access.audit.code}`
			// },
			{
				title: <TreeNode text={UserAccess.brand_access.tenders.text} code={UserAccess.brand_access.tenders.code} />,
				key: `${UserAccess.brand_access.tenders.code}`
			},
			// {
			// 	title: <TreeNode text={UserAccess.brand_access.bookings.text} code={UserAccess.brand_access.bookings.code} />,
			// 	key: `${UserAccess.brand_access.bookings.code}`
			// },
			{
				title: <TreeNode text={UserAccess.brand_access.reporting.text} code={UserAccess.brand_access.reporting.code} />,
				key: `${UserAccess.brand_access.reporting.code}`
			},
		]
	},
];

const PermissionModel: React.FC<any> = ({ hideUpdate = true, onClose, selectedUser, userMappingData, brandProperties, brandId }): JSX.Element => {
	const [treeData, setTreeData] = useState(initTreeData);
	const [checkArr, setCheckArr] = useState<any>([]);
	const [checkedKeys, setCheckedKeys] = useState<any>([])
	const [oldAccess, setOldAccess] = useState<any>([]); // Store old access

	const updateAccess = useStoreActions<any>((actions) => actions.user.updateAccess);

	useEffect(() => {
		if (brandProperties.length) {

			const tmpArr: any = [...initTreeData];
			const propArr: any = [];

			_.each(brandProperties, (a) => {
				const { propertyName, _id, city } = a;
				propArr.push({
					title: <TreeNode hide text={propertyName + ' - ' + city} code={_id} />,
					key: `${_id}`,
					children: [
						{
							title: <TreeNode text={UserAccess.property_access.details.text} code={`${_id}_${UserAccess.property_access.details.code}`} />,
							key: `${_id}_${UserAccess.property_access.details.code}`
						},
						{
							title: <TreeNode text={UserAccess.property_access.users.text} code={`${_id}_${UserAccess.property_access.users.code}`} />,
							key: `${_id}_${UserAccess.property_access.users.code}`
						},
						{
							title: <TreeNode text={UserAccess.property_access.audit.text} code={`${_id}_${UserAccess.property_access.audit.code}`} />,
							key: `${_id}_${UserAccess.property_access.audit.code}`
						},
						{
							title: <TreeNode text={UserAccess.property_access.tenders.text} code={`${_id}_${UserAccess.property_access.tenders.code}`} />,
							key: `${_id}_${UserAccess.property_access.tenders.code}`
						},
						{
							title: <TreeNode text={UserAccess.property_access.bookings.text} code={`${_id}_${UserAccess.property_access.bookings.code}`} />,
							key: `${_id}_${UserAccess.property_access.bookings.code}`
						},
						{
							title: <TreeNode text={UserAccess.property_access.reporting.text} code={`${_id}_${UserAccess.property_access.reporting.code}`} />,
							key: `${_id}_${UserAccess.property_access.reporting.code}`
						},
					]
				}
				)
			});

			tmpArr[0].children.push({
				title: <TreeNode hide text="Property Management" code="PM" />,
				key: "PM",
				children: propArr
			})


			setTreeData(tmpArr)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [brandProperties, initTreeData]);


	useLayoutEffect(() => {
		if (userMappingData) {
			const arr: any = [];
			const userMapping = _.filter(userMappingData, a => a.userId === selectedUser?.userId);
			const access = _.filter(userMapping[0]?.brands, (a) => a.brandId === brandId).shift()?.access || [];

			setOldAccess(access); // Store old access
			_.each(access, a => {
				setCheckedValues(a);
				arr.push(a.module);
			});

			_.each(access, a => {
				setCheckedValues(a);
				arr.push(a.module)
			});
			setCheckedKeys(arr);
			setCheckArr(arr);

		}


	}, [brandId, brandProperties, selectedUser, userMappingData])




	const close = useCallback(() => {
		const tmpArr: any = [...initTreeData];
		tmpArr[0].children.pop();
	}, []);

	const onUpdate = useCallback(() => {
		const updatedAccess = getCheckedStatus(checkArr);
		const changesInAccess = compareAccess(oldAccess, updatedAccess);

		updateAccess({
			id: selectedUser.arrId,
			userId: selectedUser.userId,
			accessType: "brand",
			access: getCheckedStatus(checkArr),
			userData: getUserRole(brandId),
			tab: "Brand Users",
			oldAccess: oldAccess,
			changesInAccess: changesInAccess,
			brandId: brandId,
		});
		setTimeout(() => {
			onClose();
			close()
		}, 1000);

	}, [checkArr, oldAccess, updateAccess, selectedUser.arrId, selectedUser.userId, brandId, onClose, close])

	const onExpand = useCallback(() => {

		setTimeout(() => {
			if (userMappingData) {
				// const arr: any = [];
				const userMapping = _.filter(userMappingData, a => a.userId === selectedUser.userId);

				const access = _.filter(userMapping[0]?.brands, (a) => a.brandId === brandId).shift()?.access || [];

				const filterAccess = access.filter(val => !val.module.startsWith('BA'))

				_.each(filterAccess, a => setCheckedValues(a));
				// _.each(access, a =>
				// newData.push([...checkArr,a.module])
				// arr.push(a.module)
				// );
				// setCheckedKeys(arr);
				// setCheckArr(arr);
			}
		}, 1000)

	}, [brandId, selectedUser, userMappingData]);


	const onSelect = useCallback((opt) => {


	}, [])

	const onCheckAccess = useCallback((data, opt) => {

		setCheckArr(data);
		setCheckedKeys(data);
		const RadioEle: any = document.getElementsByName(`r_${opt.node.key}`);

		const readElm = RadioEle[0]?.parentElement;
		const writeElm = RadioEle[1]?.parentElement;

		if (!opt.checked) {
			readElm.classList.remove('ant-radio-checked');
			writeElm.classList.remove('ant-radio-checked');
		} else {
			readElm.classList.add('ant-radio-checked');
			writeElm.classList.remove('ant-radio-checked');
		}

	}, [])


	return (
		<div>
			<Modal
				show={true}
				onHide={() => { onClose(); close() }}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title id="contained-modal-title-vcenter">
						User Permissions
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Tree
						checkable
						defaultExpandAll
						checkedKeys={checkedKeys}
						onExpand={onExpand}
						onCheck={(data, opt) => { onCheckAccess(data, opt); onSelect(opt); }}
						treeData={treeData}
					/>
				</Modal.Body>
				{
					hideUpdate && <Modal.Footer>
						<Button type="submit" id='approve-button' style={formButton} onClick={() => onUpdate()}>Update</Button>
						<Button id='edit-button' style={formButton} onClick={() => { onClose(); close() }}>Close</Button>
					</Modal.Footer>
				}

			</Modal>
		</div>
	)
}

export default PermissionModel
