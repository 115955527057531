import React, { useCallback, useEffect, useState } from 'react';
import { Select, Checkbox, TimePicker } from 'antd';
import { canBrandHotelModuleWrite } from '../../../../common/functions';
import { UserAccess, AmenityType, CommonAnswer } from '../../../../common/constants';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Row, Col, Container, Form, Button, Spinner, Image } from 'react-bootstrap';
import { formButton } from '../../../../common/components-style';
import { Controller, useForm } from 'react-hook-form';
import { HotelGuestComfortFormInput } from '../../../../common/interfaces';
import { ErrorMessage } from '@hookform/error-message';
import _ from 'lodash';
import { toast } from 'react-toastify';
import BreadCrumbPage from '../../../common/BreadCrumbPage';
import moment from 'moment';
const { Option } = Select;
const format = 'HH:mm';
const GuestComfortDetailForm: React.FC<any> = ({ allInOneHotelBreadCrumb, hotelId, selectedBrand, setFormOpen, tab }): JSX.Element => {

    ///////////////////// initialize form ////////////////////////////////////// 
    const { register, handleSubmit, formState: { errors }, control, watch, setValue } = useForm<HotelGuestComfortFormInput>();

    ///////////////////////// local state manage ////////////////////////////////////
    const [hotelData, setHotelData] = useState<any>();
    const [guestComfortData, setGuestComfortData] = useState<any>();
    const [guestComfortCreate, setGuestComfortCreate] = useState<boolean>(false);
    const [amenityId, setAmenityId] = useState<any>();
    const [amenitiesShowList, setAmenitiesShowList] = useState<any>();
    const [amenitiesList, setAmenitiesList] = useState<any>();
    const [editEnable, setEditEnable] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [guestComfortId, setGuestComfort] = useState<boolean>();
    const [currency, setCurrency] = useState<any>();
    const [checkIn, setCheckIn] = useState<any>('');
    const [checkOut, setCheckOut] = useState<any>('');
    const [frontDeskCheckIn, setFrontDeskCheckIn] = useState<any>('');
    const [frontDeskCheckOut, setFrontDeskCheckOut] = useState<any>('');
    const [breakFastTypeList, setBreakFastTypeList] = useState<any>();
    const [isRoomServiceAvailable, setIsRoomServiceAvailable] = useState<boolean>(false);
    const [fitnessAvailableOnSite, setFitnessAvailableOnSite] = useState<boolean>(false);
    const [isCost, setIsCost] = useState<boolean>(false);
    const [isCostToGuest, setIsCostToGuest] = useState<boolean>(false);
    const [isStorageSpace, setIsStorageSpace] = useState<boolean>(false);



    const resetTimeFields = () => {
        setValue("hoursOfOperation.checkInTime", '');
        setValue("hoursOfOperation.checkOutTime", '');
    };
    const resetTimeFrontDeskFields = () => {
        setValue("frontDeskHours.checkInTime", '');
        setValue("frontDeskHours.checkOutTime", '');
    };

    /////////////////////////// global state manage (actions)//////////////////////////////////////////////////////
    const { createGuestComfort, getGuestComfort, updateGuestComfort, getHotel, getAmenityList, getGuestComfortBreakFastTypes } = useStoreActions<any>((actions) => ({
        createGuestComfort: actions.hotel.createGuestComfort,
        getGuestComfort: actions.hotel.getGuestComfort,
        updateGuestComfort: actions.hotel.updateGuestComfort,
        getHotel: actions.hotel.getHotel,
        getAmenityList: actions.hotel.getAmenityList,
        getGuestComfortBreakFastTypes: actions.hotel.getGuestComfortBreakFastTypes
    }));

    /////////////////////////// global state manage (state)//////////////////////////////////////////////////////
    const { createGuestComfortSuccess, createGuestComfortError, getGuestComfortSuccess, updateGuestComfortSuccess, updateGuestComfortError, getHotelSuccess, getAmenityListSuccess, getGuestComfortBreakFastTypesSuccess, getGuestComfortBreakFastTypesError } = useStoreState<any>((state) => ({
        createGuestComfortSuccess: state.hotel.createGuestComfortSuccess,
        createGuestComfortError: state.hotel.createGuestComfortError,
        getGuestComfortSuccess: state.hotel.getGuestComfortSuccess,
        updateGuestComfortSuccess: state.hotel.updateGuestComfortSuccess,
        updateGuestComfortError: state.hotel.updateGuestComfortError,
        getHotelSuccess: state.hotel.getHotelSuccess,
        getAmenityListSuccess: state.hotel.getAmenityListSuccess,
        getGuestComfortBreakFastTypesSuccess: state.hotel.getGuestComfortBreakFastTypesSuccess,
        getGuestComfortBreakFastTypesError: state.hotel.getGuestComfortBreakFastTypesError
    }));

    //////////////////////////////// call apis using actions //////////////////////////////////////////////////////
    useEffect(() => {
        getAmenityList();
        getGuestComfortBreakFastTypes();
        getGuestComfort(hotelId);
        getHotel(hotelId);
    }, [getAmenityList, getGuestComfort, getHotel, hotelId, getGuestComfortBreakFastTypes]);



    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    //////////////////////////////// get api response using state //////////////////////////////////////////////////////
    useEffect(() => {
        if (getAmenityListSuccess) {
            if (getAmenityListSuccess.data === null) {
                toast.warning("No Data found", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'foo-bar'
                });
            }
            else {
                const amenity = getAmenityListSuccess.data;
                setAmenitiesList(amenity);
            }
        }

        if (getGuestComfortSuccess) {
            if (getGuestComfortSuccess.data) {
                const inventoryAmenity: Array<Object> = [];
                if (getGuestComfortSuccess.data?.amenities) {
                    const amenity = getGuestComfortSuccess.data?.amenities;
                    _.map(amenity, element =>
                        inventoryAmenity.push(element._id)
                    );
                    setAmenitiesShowList(amenity);
                    setAmenityId(inventoryAmenity);
                }
                setGuestComfortCreate(false);
                setGuestComfort(getGuestComfortSuccess.data._id);
                setGuestComfortData(getGuestComfortSuccess.data);
                setCheckIn(moment(getGuestComfortSuccess.data?.hoursOfOperation?.checkInTime, format));
                setCheckOut(moment(getGuestComfortSuccess.data?.hoursOfOperation?.checkOutTime, format));
                setFrontDeskCheckIn(moment(getGuestComfortSuccess.data?.frontDeskHours?.checkInTime, format));
                setFrontDeskCheckOut(moment(getGuestComfortSuccess.data?.frontDeskHours?.checkOutTime, format));
                if (getGuestComfortSuccess.data.isCostToGuest === CommonAnswer.YES) {
                    setIsCostToGuest(true);
                } else {
                    setIsCostToGuest(false);
                }

                if (getGuestComfortSuccess.data.isCost === CommonAnswer.YES) {
                    setIsCost(true);
                } else {
                    setIsCost(false);
                }
                if (getGuestComfortSuccess.data.fitnessAvailableOnSite === CommonAnswer.YES) {
                    setFitnessAvailableOnSite(true);
                } else {
                    setFitnessAvailableOnSite(false);
                }

                if (getGuestComfortSuccess.data.isRoomServiceAvailable === CommonAnswer.YES) {
                    setIsRoomServiceAvailable(true);
                } else {
                    setIsRoomServiceAvailable(false);
                }
                if (getGuestComfortSuccess.data.isStorageSpace === CommonAnswer.YES) {
                    setIsStorageSpace(true);
                } else {
                    setIsStorageSpace(false);
                }

            } else {
                setGuestComfortCreate(true)
            }
        }

        if (createGuestComfortSuccess) {
            setLoading(false);
            toast.success("Guest comfort created successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            setEditEnable(false);
            scrollToTop();
            getGuestComfort(hotelId);
        }

        if (createGuestComfortError) {
            setLoading(false);
            toast.error(createGuestComfortError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }

        if (updateGuestComfortSuccess) {
            setLoading(false);
            toast.success('Guest comfort update successfully', {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            setEditEnable(false);
            scrollToTop();
            getGuestComfort(hotelId);
        }

        if (updateGuestComfortError) {
            setLoading(false);
            toast.error(updateGuestComfortError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }

        if (getHotelSuccess) {
            const data = getHotelSuccess.data;
            setCurrency(data.currency);
            setHotelData(data);

        }
        if (getGuestComfortBreakFastTypesSuccess?.data?.length) {
            const typeOfBreakFast = getGuestComfortBreakFastTypesSuccess?.data?.map((val: any, key: number) => {
                return (
                    <Option key={key} data={val} value={`${val._id}`} >{val.name}</Option>
                )
            });
            setBreakFastTypeList(typeOfBreakFast)
        }
    }
        , [createGuestComfortError, createGuestComfortSuccess, getAmenityListSuccess, getGuestComfort, getGuestComfortSuccess, getHotelSuccess, hotelId, updateGuestComfortError, updateGuestComfortSuccess, getGuestComfortBreakFastTypesSuccess, getGuestComfortBreakFastTypesError]);

    const onFinish = useCallback((values) => {
        if (!hotelId) {
            toast.error('Please create an hotel', {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }
        setLoading(true);
        const createUpdateValues = {

            hotelId: hotelId,
            theHotelFullOrLimitedService: values.theHotelFullOrLimitedService,
            localAndNationalDisabilityLaw: values.localAndNationalDisabilityLaw,
            areFacilitiesAndPublicAreaAccessible: values.areFacilitiesAndPublicAreaAccessible,
            carParkingBays: values.carParkingBays,
            isRestaurantOnSite: values.isRestaurantOnSite,
            isRoomServiceAvailable: values.isRoomServiceAvailable,
            hoursOfOperation: {
                value: values.hoursOfOperation?.value || '',
                checkInTime: values.hoursOfOperation?.checkInTime ? moment(values.hoursOfOperation?.checkInTime).format('HH:mm') : '',
                checkOutTime: values.hoursOfOperation?.checkOutTime ? moment(values.hoursOfOperation?.checkOutTime).format('HH:mm') : ''
            },
            isFoodOutletTakeAway: values.isFoodOutletTakeAway,
            frontDeskHours: {
                value: values.frontDeskHours?.value || '',
                checkInTime: values.frontDeskHours?.checkInTime ? moment(values.frontDeskHours?.checkInTime).format('HH:mm') : '',
                checkOutTime: values.frontDeskHours?.checkOutTime ? moment(values.frontDeskHours?.checkOutTime).format('HH:mm') : ''
            },
            typeOfBreakFast: values.typeOfBreakFast,
            doYouChangeMattress: values.doYouChangeMattress,
            fitnessAvailableOnSite: values.fitnessAvailableOnSite,
            isCostToGuest: values.isCostToGuest,
            charge: values.charge,
            isStorageSpace: values.isStorageSpace,
            isCost: values.isCost,
            fee: values.fee,
            renewPillowsMonths: values.renewPillowsMonths,
            detailsOfThePillows: values.detailsOfThePillows,
            convenienceFoodStore: values.convenienceFoodStore,
            nearestGym: values.nearestGym,
            gymShortTermMember: values.gymShortTermMember,
            gymPayAsYouGo: values.gymPayAsYouGo,
            amenities: values.amenities
        }
        if (guestComfortId) {
            Object.assign(createUpdateValues, { guestComfortId });
            delete createUpdateValues.hotelId;
            updateGuestComfort(createUpdateValues)
        } else {
            createGuestComfort(createUpdateValues);
        }


    }, [createGuestComfort, guestComfortId, hotelId, updateGuestComfort]);

    const changeFormEditable = useCallback(() => {
        scrollToTop();
        if (editEnable) {
            setEditEnable(false);
        } else {
            setEditEnable(true);
        }
    }, [editEnable]);

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const onCheckInTimeChange = useCallback((timeString) => {
        setCheckIn(timeString);
    }, []);

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const onCheckOutTimeChange = useCallback((timeString) => {
        setCheckOut(timeString);
    }, []);


    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const onCheckInTimeFrontDeskChange = useCallback((timeString) => {
        setFrontDeskCheckIn(timeString);
    }, []);

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const onCheckOutTimeFrontDeskChange = useCallback((timeString) => {
        setFrontDeskCheckOut(timeString);
    }, []);


    const onChangeIsRoomServiceAvailable = useCallback((value) => {
        if (value === CommonAnswer.YES) {
            setIsRoomServiceAvailable(true);
        } else {
            setIsRoomServiceAvailable(false);
        }
    }, []);


    const onChangeFitnessAvailableOnSite = useCallback((value) => {
        if (value === CommonAnswer.YES) {
            setFitnessAvailableOnSite(true);
        } else {
            setFitnessAvailableOnSite(false);
        }
    }, []);

    const onChangeIsCost = useCallback((value) => {
        if (value === CommonAnswer.YES) {
            setIsCost(true);
        } else {
            setIsCost(false);
        }
    }, []);

    const onChangeIsCostToGuest = useCallback((value) => {
        if (value === CommonAnswer.YES) {
            setIsCostToGuest(true);
        } else {
            setIsCostToGuest(false);
        }

    }, []);

    const onChangeIsStorageSpace = useCallback((value) => {
        if (value === CommonAnswer.YES) {
            setIsStorageSpace(true);
        } else {
            setIsStorageSpace(false);
        }

    }, []);


    /////////////// amenities /////////////////
    const renderAmenities = useCallback(() => {
        if (amenitiesList) {
            const data = _.filter(amenitiesList, user => (user.type === AmenityType.all) || (user.type === AmenityType.guest_comfort));
            return data.map((at: any, key: number) => {
                return (
                    <Row className="mt-3" key={key}>
                        <Col md={2}>
                            <div style={{ textAlign: "right" }}>
                                <Image src={at.iconLocation} alt="" width={40} />
                            </div>
                        </Col>
                        <Col md={10} >
                            <div style={{ marginTop: "2%", marginLeft: '-9%' }}>
                                <Checkbox value={`${at._id}`}></Checkbox> <span style={{ marginLeft: '3%' }}>{at.name}</span>
                            </div>
                        </Col>
                    </Row>
                );
            });
        }
    }, [amenitiesList]);

    const renderShowAmenities = useCallback(() => {
        if (amenitiesShowList) {
            const data = _.filter(
                amenitiesShowList,
                (user) =>
                    user.type === AmenityType.all || user.type === AmenityType.guest_comfort
            );
            return data.map((at: any, key: number) => {
                return (
                    <>
                        <Row className="mt-3" key={key}>
                            <Col md={2}>
                                <div style={{ textAlign: "right" }}>
                                    <Image src={at.iconLocation} alt="" width={40} />
                                </div>
                            </Col>
                            <Col md={10}>
                                <div style={{ marginTop: "2%", marginLeft: '-9%' }}>
                                    <span style={{ marginLeft: '2%' }}>{at.name}</span>
                                </div>
                            </Col>
                        </Row>
                    </>
                );
            });
        }
    }, [amenitiesShowList]);

    return (
        <>
            <div className="content-title fixed_title">
                {
                    <BreadCrumbPage allInOneHotelBreadCrumb={allInOneHotelBreadCrumb} isPageName="Hotel" tab={tab} selectedBrand={selectedBrand} hotelData={hotelData} setFormOpen={setFormOpen} ></BreadCrumbPage>
                }
                <div className='edit_btn_wpr'>
                    <div className='d-flex align-items-center justify-content-end gx-2'>
                        {
                            !loading && !editEnable && hotelId && canBrandHotelModuleWrite(selectedBrand.key ? selectedBrand.key : selectedBrand._id, hotelId, UserAccess.hotel_access.details.code) && <Button id='edit-button' className='mb-0' style={formButton} onClick={changeFormEditable} >Edit</Button>
                        }
                    </div>
                </div>
            </div>
            <Container fluid className="p-0">
                <div className="jumbotron">
                    <Form onSubmit={handleSubmit(onFinish)}>
                        <Row>
                            <Col md={12}>
                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right', color: '#E80F8B' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Is the hotel Full Service or Limited Service?</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{guestComfortData?.theHotelFullOrLimitedService}</p>}
                                        {editEnable &&
                                            <Controller
                                                name="theHotelFullOrLimitedService"
                                                defaultValue={guestComfortData?.theHotelFullOrLimitedService}
                                                control={control}
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field }) =>
                                                    <>
                                                        {
                                                            guestComfortData?.theHotelFullOrLimitedService === 'Full'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'Full'}
                                                                    name="theHotelFullOrLimitedService"
                                                                    label={'Full'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value) }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'Full'}
                                                                    name="theHotelFullOrLimitedService"
                                                                    label={'Full'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value) }}
                                                                />
                                                        }

                                                        {
                                                            guestComfortData?.theHotelFullOrLimitedService === 'Limited'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'Limited'}
                                                                    name="theHotelFullOrLimitedService"
                                                                    label={'Limited'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value) }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'Limited'}
                                                                    name="theHotelFullOrLimitedService"
                                                                    label={'Limited'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value) }}
                                                                />
                                                        }

                                                    </>
                                                }
                                            />}
                                        {editEnable &&
                                            <ErrorMessage
                                                errors={errors}
                                                name="theHotelFullOrLimitedService"
                                                render={({ message }) => <div className="invalid-feedback-custom">Please select your answer!</div>} />}
                                    </Col>
                                </Row>
                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Does the hotel comply with all local and national disability law?</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{guestComfortData?.localAndNationalDisabilityLaw}</p>}
                                        {editEnable &&
                                            <Controller
                                                name="localAndNationalDisabilityLaw"
                                                defaultValue={guestComfortData?.localAndNationalDisabilityLaw}
                                                control={control}
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field }) =>
                                                    <>
                                                        {
                                                            guestComfortData?.localAndNationalDisabilityLaw === 'Yes'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="localAndNationalDisabilityLaw"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value) }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="localAndNationalDisabilityLaw"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value) }}
                                                                />
                                                        }

                                                        {
                                                            guestComfortData?.localAndNationalDisabilityLaw === 'No'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="localAndNationalDisabilityLaw"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value) }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="localAndNationalDisabilityLaw"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value) }}
                                                                />
                                                        }

                                                    </>
                                                }
                                            />}
                                        {editEnable &&
                                            <ErrorMessage
                                                errors={errors}
                                                name="localAndNationalDisabilityLaw"
                                                render={({ message }) => <div className="invalid-feedback-custom">Please select your answer!</div>} />}
                                    </Col>
                                </Row>
                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Are facilities and public areas accessible by people with physical disabilities?</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{guestComfortData?.areFacilitiesAndPublicAreaAccessible}</p>}
                                        {editEnable &&
                                            <Controller
                                                name="areFacilitiesAndPublicAreaAccessible"
                                                defaultValue={guestComfortData?.areFacilitiesAndPublicAreaAccessible}
                                                control={control}
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field }) =>
                                                    <>
                                                        {
                                                            guestComfortData?.areFacilitiesAndPublicAreaAccessible === 'Yes'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="areFacilitiesAndPublicAreaAccessible"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value) }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="areFacilitiesAndPublicAreaAccessible"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value) }}
                                                                />
                                                        }

                                                        {
                                                            guestComfortData?.areFacilitiesAndPublicAreaAccessible === 'No'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="areFacilitiesAndPublicAreaAccessible"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value) }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="areFacilitiesAndPublicAreaAccessible"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value) }}
                                                                />
                                                        }

                                                    </>
                                                }
                                            />}
                                        {editEnable &&
                                            <ErrorMessage
                                                errors={errors}
                                                name="areFacilitiesAndPublicAreaAccessible"
                                                render={({ message }) => <div className="invalid-feedback-custom">Please select your answer!</div>} />}
                                    </Col>
                                </Row>
                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Does the hotel provide disabled car parking bays?</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{guestComfortData?.carParkingBays}</p>}
                                        {editEnable &&
                                            <Controller
                                                name="carParkingBays"
                                                defaultValue={guestComfortData?.carParkingBays}
                                                control={control}
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field }) =>
                                                    <>
                                                        {
                                                            guestComfortData?.carParkingBays === 'Yes'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="carParkingBays"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value) }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="carParkingBays"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value) }}
                                                                />
                                                        }

                                                        {
                                                            guestComfortData?.carParkingBays === 'No'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="carParkingBays"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value) }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="carParkingBays"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value) }}
                                                                />
                                                        }

                                                    </>
                                                }
                                            />}
                                        {editEnable &&
                                            <ErrorMessage
                                                errors={errors}
                                                name="carParkingBays"
                                                render={({ message }) => <div className="invalid-feedback-custom">Please select your answer!</div>} />}
                                    </Col>
                                </Row>


                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Is there restaurant on site?</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{guestComfortData?.isRestaurantOnSite}</p>}
                                        {editEnable &&
                                            <Controller
                                                name="isRestaurantOnSite"
                                                defaultValue={guestComfortData?.isRestaurantOnSite}
                                                control={control}
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field }) =>
                                                    <>
                                                        {
                                                            guestComfortData?.isRestaurantOnSite === 'Yes'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="isRestaurantOnSite"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value) }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="isRestaurantOnSite"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value) }}
                                                                />
                                                        }

                                                        {
                                                            guestComfortData?.isRestaurantOnSite === 'No'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="isRestaurantOnSite"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value) }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="isRestaurantOnSite"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value) }}
                                                                />
                                                        }

                                                    </>
                                                }
                                            />}
                                        {editEnable &&
                                            <ErrorMessage
                                                errors={errors}
                                                name="isRestaurantOnSite"
                                                render={({ message }) => <div className="invalid-feedback-custom">Please select your answer!</div>} />}
                                    </Col>
                                </Row>

                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Is room service available?</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{guestComfortData?.isRoomServiceAvailable}</p>}
                                        {editEnable &&
                                            <Controller
                                                name="isRoomServiceAvailable"
                                                defaultValue={guestComfortData?.isRoomServiceAvailable}
                                                control={control}
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field }) =>
                                                    <>
                                                        {
                                                            guestComfortData?.isRoomServiceAvailable === 'Yes'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="isRoomServiceAvailable"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangeIsRoomServiceAvailable(value.currentTarget.value); }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="isRoomServiceAvailable"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangeIsRoomServiceAvailable(value.currentTarget.value); }}
                                                                />
                                                        }

                                                        {
                                                            guestComfortData?.isRoomServiceAvailable === 'No'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="isRoomServiceAvailable"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangeIsRoomServiceAvailable(value.currentTarget.value); }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="isRoomServiceAvailable"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangeIsRoomServiceAvailable(value.currentTarget.value); }}
                                                                />
                                                        }

                                                    </>
                                                }
                                            />}
                                        {editEnable &&
                                            <ErrorMessage
                                                errors={errors}
                                                name="isRoomServiceAvailable"
                                                render={({ message }) => <div className="invalid-feedback-custom">Please select your answer!</div>} />}
                                    </Col>
                                </Row>
                                {isRoomServiceAvailable &&
                                    <Row className='formSpace'>
                                        <Col md={6} style={{ textAlign: 'right' }}>
                                            <Form.Label>{editEnable && <span className='requiredMark'>*</span>} What are hours of operation?</Form.Label>
                                        </Col>
                                        <Col md={6} style={{ textAlign: 'left' }}>
                                            {!editEnable && <p className="lead">{guestComfortData?.hoursOfOperation?.value}</p>}

                                            {editEnable && (
                                                <Controller
                                                    name="hoursOfOperation.value"
                                                    defaultValue={guestComfortData?.hoursOfOperation?.value || ''}
                                                    control={control}
                                                    rules={{
                                                        required: true,
                                                    }}
                                                    render={({ field }) => {
                                                        return (
                                                            <>
                                                                <Form.Check
                                                                    inline
                                                                    value="24hr"
                                                                    label="24 Hr"
                                                                    type="radio"
                                                                    checked={field.value === '24hr'}
                                                                    onChange={(value) => {
                                                                        field.onChange(value.currentTarget.value);
                                                                        resetTimeFields();
                                                                    }}
                                                                />
                                                                <Form.Check
                                                                    inline
                                                                    value="specific"
                                                                    type="radio"
                                                                    checked={field.value === 'specific'}
                                                                    onChange={(value) => field.onChange(value.currentTarget.value)}
                                                                />
                                                            </>
                                                        );
                                                    }}
                                                />
                                            )}

                                            {editEnable && errors.hoursOfOperation?.value && (
                                                <div className="invalid-feedback-custom">Please select hours of operation!</div>
                                            )}

                                            {editEnable && (
                                                <>
                                                    <div style={{ display: 'inline-flex', gap: '10px', alignItems: 'center' }}>
                                                        <Controller
                                                            name="hoursOfOperation.checkInTime"
                                                            defaultValue={guestComfortData?.hoursOfOperation?.checkInTime || ''}
                                                            control={control}
                                                            rules={{
                                                                validate: (value) => {
                                                                    const hoursOfOperationValue = watch("hoursOfOperation.value");
                                                                    if (hoursOfOperationValue === "specific") {
                                                                        return !!value || "Please select operational from!";
                                                                    }
                                                                    return true;
                                                                }
                                                            }}
                                                            render={({ field }) => (
                                                                <TimePicker
                                                                    showNow={false}
                                                                    defaultValue={guestComfortData?.hoursOfOperation?.checkInTime ? moment(guestComfortData.hoursOfOperation.checkInTime, format) : undefined}
                                                                    autoFocus={true}
                                                                    disabled={watch("hoursOfOperation.value") === "24hr"}
                                                                    format={format}
                                                                    placeholder='Operational from'
                                                                    onChange={(value) => {
                                                                        onCheckInTimeChange(value);
                                                                        field.onChange(value);
                                                                    }}
                                                                />
                                                            )}
                                                        />

                                                        <Controller
                                                            name="hoursOfOperation.checkOutTime"
                                                            defaultValue={guestComfortData?.hoursOfOperation?.checkOutTime || ''}
                                                            control={control}
                                                            rules={{
                                                                validate: (value) => {
                                                                    const hoursOfOperationValue = watch("hoursOfOperation.value");
                                                                    if (hoursOfOperationValue === "specific") {
                                                                        return !!value || "Please select operational to!";
                                                                    }
                                                                    return true;
                                                                }
                                                            }}
                                                            render={({ field }) => (
                                                                <TimePicker
                                                                    showNow={false}
                                                                    defaultValue={guestComfortData?.hoursOfOperation?.checkOutTime ? moment(guestComfortData.hoursOfOperation.checkOutTime, format) : undefined}
                                                                    autoFocus={true}
                                                                    disabled={watch("hoursOfOperation.value") === "24hr"}
                                                                    format={format}
                                                                    placeholder='Operational to'
                                                                    onChange={(value) => {
                                                                        onCheckOutTimeChange(value);
                                                                        field.onChange(value);
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    </div>

                                                    <Row>
                                                        <Col md={6} style={{ textAlign: 'left' }}>
                                                            {(errors.hoursOfOperation?.checkInTime || errors.hoursOfOperation?.checkOutTime) &&
                                                                <div className="invalid-feedback-custom">{errors?.hoursOfOperation?.checkInTime?.message || errors?.hoursOfOperation?.checkOutTime?.message}</div>
                                                            }
                                                        </Col>
                                                    </Row>
                                                </>
                                            )}
                                        </Col>
                                    </Row>
                                }
                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Is there a food outlet for take away?</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{guestComfortData?.isFoodOutletTakeAway}</p>}
                                        {editEnable &&
                                            <Controller
                                                name="isFoodOutletTakeAway"
                                                defaultValue={guestComfortData?.isFoodOutletTakeAway}
                                                control={control}
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field }) =>
                                                    <>
                                                        {
                                                            guestComfortData?.isFoodOutletTakeAway === 'Yes'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="isFoodOutletTakeAway"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value) }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="isFoodOutletTakeAway"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value) }}
                                                                />
                                                        }

                                                        {
                                                            guestComfortData?.isFoodOutletTakeAway === 'No'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="isFoodOutletTakeAway"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value) }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="isFoodOutletTakeAway"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value) }}
                                                                />
                                                        }

                                                    </>
                                                }
                                            />}
                                        {editEnable &&
                                            <ErrorMessage
                                                errors={errors}
                                                name="isFoodOutletTakeAway"
                                                render={({ message }) => <div className="invalid-feedback-custom">Please select your answer!</div>} />}
                                    </Col>
                                </Row>

                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Front desk hours</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{guestComfortData?.frontDeskHours?.value}</p>}

                                        {editEnable && (
                                            <Controller
                                                name="frontDeskHours.value"
                                                defaultValue={guestComfortData?.frontDeskHours?.value || ''}
                                                control={control}
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field }) => {
                                                    return (
                                                        <>
                                                            <Form.Check
                                                                inline
                                                                value="24hr"
                                                                label="24 Hr"
                                                                type="radio"
                                                                checked={field.value === '24hr'}
                                                                onChange={(value) => {
                                                                    field.onChange(value.currentTarget.value);
                                                                    resetTimeFrontDeskFields();
                                                                }}
                                                            />
                                                            <Form.Check
                                                                inline
                                                                value="specific"
                                                                type="radio"
                                                                checked={field.value === 'specific'}
                                                                onChange={(value) => field.onChange(value.currentTarget.value)}
                                                            />
                                                        </>
                                                    );
                                                }}
                                            />
                                        )}

                                        {editEnable && errors.frontDeskHours?.value && (
                                            <div className="invalid-feedback-custom">Please select front desk hours!</div>
                                        )}

                                        {editEnable && (
                                            <>
                                                <div style={{ display: 'inline-flex', gap: '10px', alignItems: 'center' }}>
                                                    <Controller
                                                        name="frontDeskHours.checkInTime"
                                                        defaultValue={guestComfortData?.frontDeskHours?.checkInTime || ''}
                                                        control={control}
                                                        rules={{
                                                            validate: (value) => {
                                                                const frontDeskHoursValue = watch("frontDeskHours.value");
                                                                if (frontDeskHoursValue === "specific") {
                                                                    return !!value || "Please select operational from!";
                                                                }
                                                                return true;
                                                            }
                                                        }}
                                                        render={({ field }) => (
                                                            <TimePicker
                                                                showNow={false}
                                                                defaultValue={guestComfortData?.frontDeskHours?.checkInTime ? moment(guestComfortData.frontDeskHours.checkInTime, format) : undefined}
                                                                autoFocus={true}
                                                                disabled={watch("frontDeskHours.value") === "24hr"}
                                                                format={format}
                                                                placeholder='Operational from'
                                                                onChange={(value) => {
                                                                    onCheckInTimeFrontDeskChange(value);
                                                                    field.onChange(value);
                                                                }}
                                                            />
                                                        )}
                                                    />

                                                    <Controller
                                                        name="frontDeskHours.checkOutTime"
                                                        defaultValue={guestComfortData?.frontDeskHours?.checkOutTime || ''}
                                                        control={control}
                                                        rules={{
                                                            validate: (value) => {
                                                                const frontDeskHoursValue = watch("frontDeskHours.value");
                                                                if (frontDeskHoursValue === "specific") {
                                                                    return !!value || "Please select operational to!";
                                                                }
                                                                return true;
                                                            }
                                                        }}
                                                        render={({ field }) => (
                                                            <TimePicker
                                                                showNow={false}
                                                                defaultValue={guestComfortData?.frontDeskHours?.checkOutTime ? moment(guestComfortData.frontDeskHours.checkOutTime, format) : undefined}
                                                                autoFocus={true}
                                                                disabled={watch("frontDeskHours.value") === "24hr"}
                                                                format={format}
                                                                placeholder='Operational to'
                                                                onChange={(value) => {
                                                                    onCheckOutTimeFrontDeskChange(value);
                                                                    field.onChange(value);
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </div>

                                                <Row>
                                                    <Col md={6} style={{ textAlign: 'left' }}>
                                                        {(errors.frontDeskHours?.checkInTime || errors.frontDeskHours?.checkOutTime) &&
                                                            <div className="invalid-feedback-custom">{errors?.frontDeskHours?.checkInTime?.message || errors?.frontDeskHours?.checkOutTime?.message}</div>
                                                        }
                                                    </Col>
                                                </Row>
                                            </>
                                        )}
                                    </Col>
                                </Row>


                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} What type of breakfast is offered?</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{guestComfortData?.typeOfBreakFast?.name}</p>}
                                        {editEnable &&
                                            <Controller
                                                name="typeOfBreakFast"
                                                defaultValue={guestComfortData?.typeOfBreakFast?._id}
                                                control={control}
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field }) =>
                                                    <Select defaultValue={guestComfortData?.typeOfBreakFast?._id} className={`${errors.typeOfBreakFast ? 'is-invalid' : ''}`} style={{ width: '100%' }} onChange={(value, option) => {

                                                        field.onChange(value);

                                                    }} showSearch>
                                                        {breakFastTypeList}
                                                    </Select>
                                                }
                                            />}
                                    </Col>
                                </Row>

                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} How often do you change the mattress (years)?</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{guestComfortData?.doYouChangeMattress}</p>}
                                        {editEnable && <Form.Control type='number' min={0} className={`${errors.doYouChangeMattress ? 'is-invalid' : ''}`} defaultValue={guestComfortData?.doYouChangeMattress} {...register("doYouChangeMattress", { required: true })} />}
                                        {editEnable && <div className="invalid-feedback">Please input your answer!</div>}
                                    </Col>
                                </Row>


                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Please provide details of the pillows you provide</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{
                                            guestComfortData?.detailsOfThePillows.map((value) => {
                                                return (
                                                    <span key={value}>{value}, </span>
                                                );
                                            })
                                        }</p>}
                                        {editEnable &&
                                            <Controller
                                                name="detailsOfThePillows"
                                                defaultValue={guestComfortData?.detailsOfThePillows}
                                                control={control}
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field }) =>
                                                    <>
                                                        {guestComfortData?.detailsOfThePillows
                                                            ?
                                                            <Select defaultValue={Object(guestComfortData?.detailsOfThePillows)} onChange={(value) => {
                                                                field.onChange(value);
                                                            }} mode="multiple" style={{ width: '100%' }}>
                                                                <Option key={`FEATHER`} value={`Feather`} >Feather</Option>
                                                                <Option key={`DOWN`} value={`Down`} >Down</Option>
                                                                <Option key={`MEMORY_FOAM`} value={`Memory foam`} >Memory foam</Option>
                                                                <Option key={`KAPOK`} value={`Kapok`} >Kapok</Option>
                                                                <Option key={`POLYFILL`} value={`Polyfill`} >Polyfill</Option>
                                                                <Option key={`MICRO_BEAD`} value={`Micro-bead`} >Micro-bead</Option>
                                                                <Option key={`BUCKWHEAT`} value={`Buckwheat`} >Buckwheat</Option>
                                                                <Option key={`Hypoallergenic`} value={`Hypoallergenic`} >Hypoallergenic</Option>
                                                            </Select>
                                                            :
                                                            <Select onChange={(value) => {
                                                                field.onChange(value);
                                                            }} mode="multiple" style={{ width: '100%' }}>
                                                                <Option key={`FEATHER`} value={`Feather`} >Feather</Option>
                                                                <Option key={`DOWN`} value={`Down`} >Down</Option>
                                                                <Option key={`MEMORY_FOAM`} value={`Memory foam`} >Memory foam</Option>
                                                                <Option key={`KAPOK`} value={`Kapok`} >Kapok</Option>
                                                                <Option key={`POLYFILL`} value={`Polyfill`} >Polyfill</Option>
                                                                <Option key={`MICRO_BEAD`} value={`Micro-bead`} >Micro-bead</Option>
                                                                <Option key={`Hypoallergenic`} value={`Hypoallergenic`} >Hypoallergenic</Option>
                                                            </Select>
                                                        }

                                                    </>
                                                }
                                            />}
                                        {editEnable &&
                                            <ErrorMessage
                                                errors={errors}
                                                name="detailsOfThePillows"
                                                render={({ message }) => <div className="invalid-feedback-custom">Please select your answer!</div>} />}
                                    </Col>
                                </Row>

                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} How long would it take to walk to nearest convenience food store (mins)?</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{guestComfortData?.convenienceFoodStore}</p>}
                                        {editEnable && <Form.Control type='number' min={0} className={`${errors.convenienceFoodStore ? 'is-invalid' : ''}`} defaultValue={guestComfortData?.convenienceFoodStore} {...register("convenienceFoodStore", { required: true })} />}
                                        {editEnable && <div className="invalid-feedback">Please input your answer!</div>}
                                    </Col>
                                </Row>
                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Do you have a fitness centre available on site?</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{guestComfortData?.fitnessAvailableOnSite}</p>}
                                        {editEnable &&
                                            <Controller
                                                name="fitnessAvailableOnSite"
                                                defaultValue={guestComfortData?.fitnessAvailableOnSite}
                                                control={control}
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field }) =>
                                                    <>
                                                        {
                                                            guestComfortData?.fitnessAvailableOnSite === 'Yes'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="fitnessAvailableOnSite"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangeFitnessAvailableOnSite(value.currentTarget.value); }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="fitnessAvailableOnSite"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangeFitnessAvailableOnSite(value.currentTarget.value); }}
                                                                />
                                                        }

                                                        {
                                                            guestComfortData?.fitnessAvailableOnSite === 'No'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="fitnessAvailableOnSite"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangeFitnessAvailableOnSite(value.currentTarget.value); }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="fitnessAvailableOnSite"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangeFitnessAvailableOnSite(value.currentTarget.value); }}
                                                                />
                                                        }

                                                    </>
                                                }
                                            />}
                                        {editEnable &&
                                            <ErrorMessage
                                                errors={errors}
                                                name="fitnessAvailableOnSite"
                                                render={({ message }) => <div className="invalid-feedback-custom">Please select your answer!</div>} />}
                                    </Col>
                                </Row>
                                {fitnessAvailableOnSite &&
                                    <Row className='formSpace'>
                                        <Col md={6} style={{ textAlign: 'right' }}>
                                            <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Is there a cost to guests?</Form.Label>
                                        </Col>
                                        <Col md={6} style={{ textAlign: 'left' }}>
                                            {!editEnable && <p className="lead">{guestComfortData?.isCostToGuest}</p>}
                                            {editEnable &&
                                                <Controller
                                                    name="isCostToGuest"
                                                    defaultValue={guestComfortData?.isCostToGuest}
                                                    control={control}
                                                    rules={{
                                                        required: true,
                                                    }}
                                                    render={({ field }) =>
                                                        <>
                                                            {
                                                                guestComfortData?.isCostToGuest === 'Yes'
                                                                    ?
                                                                    <Form.Check
                                                                        inline
                                                                        value={'Yes'}
                                                                        name="isCostToGuest"
                                                                        label={'Yes'}
                                                                        type="radio"
                                                                        defaultChecked
                                                                        onChange={(value) => { field.onChange(value.currentTarget.value); onChangeIsCostToGuest(value.currentTarget.value); }}
                                                                    />
                                                                    :
                                                                    <Form.Check
                                                                        inline
                                                                        value={'Yes'}
                                                                        name="isCostToGuest"
                                                                        label={'Yes'}
                                                                        type="radio"
                                                                        onChange={(value) => { field.onChange(value.currentTarget.value); onChangeIsCostToGuest(value.currentTarget.value); }}
                                                                    />
                                                            }

                                                            {
                                                                guestComfortData?.isCostToGuest === 'No'
                                                                    ?
                                                                    <Form.Check
                                                                        inline
                                                                        value={'No'}
                                                                        name="isCostToGuest"
                                                                        label={'No'}
                                                                        type="radio"
                                                                        defaultChecked
                                                                        onChange={(value) => { field.onChange(value.currentTarget.value); onChangeIsCostToGuest(value.currentTarget.value); }}
                                                                    />
                                                                    :
                                                                    <Form.Check
                                                                        inline
                                                                        value={'No'}
                                                                        name="isCostToGuest"
                                                                        label={'No'}
                                                                        type="radio"
                                                                        onChange={(value) => { field.onChange(value.currentTarget.value); onChangeIsCostToGuest(value.currentTarget.value); }}
                                                                    />
                                                            }

                                                        </>
                                                    }
                                                />}
                                            {editEnable &&
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="isCostToGuest"
                                                    render={({ message }) => <div className="invalid-feedback-custom">Please select your answer!</div>} />}
                                        </Col>
                                    </Row>
                                }
                                {isCostToGuest && fitnessAvailableOnSite &&
                                    <Row className='formSpace'>
                                        <Col md={6} style={{ textAlign: 'right' }}>
                                            <Form.Label>{editEnable && <span className='requiredMark'>*</span>}{' What is the charge (' + currency + ')?'} </Form.Label>
                                        </Col>
                                        <Col md={6} style={{ textAlign: 'left' }}>
                                            {!editEnable && <p className="lead">{guestComfortData?.charge}</p>}
                                            {editEnable && <Form.Control type='number' min={0} className={`${errors.charge ? 'is-invalid' : ''}`} defaultValue={guestComfortData?.charge} {...register("charge", { required: true })} />}
                                            {editEnable && <div className="invalid-feedback">Please input your answer!</div>}
                                        </Col>
                                    </Row>
                                }

                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} How far is the nearest external gymnasium (mins)? </Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{guestComfortData?.nearestGym}</p>}
                                        {editEnable && <Form.Control type='number' min={0} className={`${errors.charge ? 'is-invalid' : ''}`} defaultValue={guestComfortData?.nearestGym} {...register("nearestGym", { required: true })} />}
                                        {editEnable && <div className="invalid-feedback">Please input your answer!</div>}
                                    </Col>
                                </Row>

                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Does this gymnasium offer short term membership?</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{guestComfortData?.gymShortTermMember}</p>}
                                        {editEnable &&
                                            <Controller
                                                name="gymShortTermMember"
                                                defaultValue={guestComfortData?.gymShortTermMember}
                                                control={control}
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field }) =>
                                                    <>
                                                        {
                                                            guestComfortData?.gymShortTermMember === 'Yes'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="gymShortTermMember"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="gymShortTermMember"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value) }}
                                                                />
                                                        }

                                                        {
                                                            guestComfortData?.gymShortTermMember === 'No'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="gymShortTermMember"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="gymShortTermMember"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); }}
                                                                />
                                                        }

                                                    </>
                                                }
                                            />}
                                        {editEnable &&
                                            <ErrorMessage
                                                errors={errors}
                                                name="gymShortTermMember"
                                                render={({ message }) => <div className="invalid-feedback-custom">Please select your answer!</div>} />}
                                    </Col>
                                </Row>
                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Does this gymnasium offer pay-as-you-go?</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{guestComfortData?.gymPayAsYouGo}</p>}
                                        {editEnable &&
                                            <Controller
                                                name="gymPayAsYouGo"
                                                defaultValue={guestComfortData?.gymPayAsYouGo}
                                                control={control}
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field }) =>
                                                    <>
                                                        {
                                                            guestComfortData?.gymPayAsYouGo === 'Yes'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="gymPayAsYouGo"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="gymPayAsYouGo"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value) }}
                                                                />
                                                        }

                                                        {
                                                            guestComfortData?.gymPayAsYouGo === 'No'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="gymPayAsYouGo"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="gymPayAsYouGo"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); }}
                                                                />
                                                        }

                                                    </>
                                                }
                                            />}
                                        {editEnable &&
                                            <ErrorMessage
                                                errors={errors}
                                                name="gymPayAsYouGo"
                                                render={({ message }) => <div className="invalid-feedback-custom">Please select your answer!</div>} />}
                                    </Col>
                                </Row>
                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Do you provide storage space (not in the room)</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{guestComfortData?.isStorageSpace}</p>}
                                        {editEnable &&
                                            <Controller
                                                name="isStorageSpace"
                                                defaultValue={guestComfortData?.isStorageSpace}
                                                control={control}
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field }) =>
                                                    <>
                                                        {
                                                            guestComfortData?.isStorageSpace === 'Yes'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="isStorageSpace"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangeIsStorageSpace(value.currentTarget.value); }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="isStorageSpace"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangeIsStorageSpace(value.currentTarget.value); }}
                                                                />
                                                        }

                                                        {
                                                            guestComfortData?.isStorageSpace === 'No'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="isStorageSpace"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangeIsStorageSpace(value.currentTarget.value); }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="isStorageSpace"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangeIsStorageSpace(value.currentTarget.value); }}
                                                                />
                                                        }

                                                    </>
                                                }
                                            />}
                                        {editEnable &&
                                            <ErrorMessage
                                                errors={errors}
                                                name="isStorageSpace"
                                                render={({ message }) => <div className="invalid-feedback-custom">Please select your answer!</div>} />}
                                    </Col>
                                </Row>
                                {isStorageSpace &&
                                    <Row className='formSpace'>
                                        <Col md={6} style={{ textAlign: 'right' }}>
                                            <Form.Label>{editEnable && <span className='requiredMark'>*</span>}{' Is there a cost? (' + currency + ')'}</Form.Label>
                                        </Col>
                                        <Col md={6} style={{ textAlign: 'left' }}>
                                            {!editEnable && <p className="lead">{guestComfortData?.isCost}</p>}
                                            {editEnable &&
                                                <Controller
                                                    name="isCost"
                                                    defaultValue={guestComfortData?.isCost}
                                                    control={control}
                                                    rules={{
                                                        required: true,
                                                    }}
                                                    render={({ field }) =>
                                                        <>
                                                            {
                                                                guestComfortData?.isCost === 'Yes'
                                                                    ?
                                                                    <Form.Check
                                                                        inline
                                                                        value={'Yes'}
                                                                        name="isCost"
                                                                        label={'Yes'}
                                                                        type="radio"
                                                                        defaultChecked
                                                                        onChange={(value) => { field.onChange(value.currentTarget.value); onChangeIsCost(value.currentTarget.value); }}
                                                                    />
                                                                    :
                                                                    <Form.Check
                                                                        inline
                                                                        value={'Yes'}
                                                                        name="isCost"
                                                                        label={'Yes'}
                                                                        type="radio"
                                                                        onChange={(value) => { field.onChange(value.currentTarget.value); onChangeIsCost(value.currentTarget.value); }}
                                                                    />
                                                            }

                                                            {
                                                                guestComfortData?.isCost === 'No'
                                                                    ?
                                                                    <Form.Check
                                                                        inline
                                                                        value={'No'}
                                                                        name="isCost"
                                                                        label={'No'}
                                                                        type="radio"
                                                                        defaultChecked
                                                                        onChange={(value) => { field.onChange(value.currentTarget.value); onChangeIsCost(value.currentTarget.value); }}
                                                                    />
                                                                    :
                                                                    <Form.Check
                                                                        inline
                                                                        value={'No'}
                                                                        name="isCost"
                                                                        label={'No'}
                                                                        type="radio"
                                                                        onChange={(value) => { field.onChange(value.currentTarget.value); onChangeIsCost(value.currentTarget.value); }}
                                                                    />
                                                            }

                                                        </>
                                                    }
                                                />}
                                            {editEnable &&
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="isCost"
                                                    render={({ message }) => <div className="invalid-feedback-custom">Please select your answer!</div>} />}
                                        </Col>
                                    </Row>
                                }

                                {isCost && isStorageSpace &&
                                    <Row className='formSpace'>
                                        <Col md={6} style={{ textAlign: 'right' }}>
                                            <Form.Label>{editEnable && <span className='requiredMark'>*</span>} What is the fee? </Form.Label>
                                        </Col>
                                        <Col md={6} style={{ textAlign: 'left' }}>
                                            {!editEnable && <p className="lead">{guestComfortData?.fee}</p>}
                                            {editEnable && <Form.Control type='number' min={0} className={`${errors.charge ? 'is-invalid' : ''}`} defaultValue={guestComfortData?.fee} {...register("fee", { required: true })} />}
                                            {editEnable && <div className="invalid-feedback">Please input your answer!</div>}
                                        </Col>
                                    </Row>
                                }
                                <Row className="formSpace">
                                    <Col md={6} style={{ textAlign: "right" }}>
                                        <Form.Label className="mt-3">
                                            {editEnable && <>
                                                Please indicate what amenities you offer within the building </>}
                                            {!editEnable && <span > </span>}
                                        </Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: "left" }}>
                                        {!editEnable && renderShowAmenities()}
                                        {editEnable && (
                                            <Controller
                                                name="amenities"
                                                defaultValue={amenityId}
                                                control={control}
                                                render={({ field }) => (
                                                    <>
                                                        <Checkbox.Group defaultValue={amenityId} style={{ width: '100%' }} onChange={(value) => { field.onChange(value); }}>
                                                            {renderAmenities()}
                                                        </Checkbox.Group>
                                                    </>
                                                )}
                                            />
                                        )}
                                        {editEnable && errors.amenities && (
                                            <div className="invalid-feedback-custom">
                                                Please select a amenities!
                                            </div>
                                        )}
                                    </Col>
                                </Row>
                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!loading && editEnable && hotelId && canBrandHotelModuleWrite(selectedBrand.key ? selectedBrand.key : selectedBrand._id, hotelId, UserAccess.hotel_access.details.code) &&
                                            <Button type="submit" id='approve-button' style={formButton}> {guestComfortCreate ? 'Update' : 'Update'} </Button>
                                        }

                                        {
                                            loading && <div style={{ textAlign: 'center' }}><Spinner animation="border" variant="warning" /></div>
                                        }
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Container>
        </>
    );
};

export default GuestComfortDetailForm;