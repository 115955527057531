import { useStoreActions, useStoreState } from 'easy-peasy';
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { setTimeout } from 'timers';
import logo from '../../images/logoNew.png';
import { emptyStorage, setStorage } from '../../common/storage';
import { Card, Container, Form, Button, Row, Col, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { LoginForm } from '../../common/interfaces';
import { loginButton } from '../../common/components-style';
import { toast } from 'react-toastify';
import { BrandStatus, UserGroups } from '../../common/constants';

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

const Login: React.FC = (): JSX.Element => {
	const history = useHistory();
	const query = useQuery();
	const url = query.get('redirectUrl');
	const [loading, setLoading] = useState<boolean>(false);
	const [userId, setUserId] = useState<any>();
	const { register, handleSubmit, formState: { errors } } = useForm<LoginForm>();


	const { loginAction, getUserMapping, resetAuth, resetUser, onUpdateBrand } = useStoreActions((actions: any) => ({
		resetAuth: actions.auth.resetAuth,
		loginAction: actions.auth.onLogin,
		getUserMapping: actions.user.getUserMapping,
		resetUser: actions.user.resetUser,
		onUpdateBrand: actions.brand.onUpdateBrand
	}));

	const { loginError, loginSuccess, getUserMappingSuccess } = useStoreState((state: any) => ({
		loginError: state.auth.loginError,
		loginSuccess: state.auth.loginSuccess,
		getUserMappingSuccess: state.user.getUserMappingSuccess
	}));

	///////////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		setLoading(false);
		if (loginError) {
			// message.error(loginError.message.message);
			if (loginError.message.message === 'User is disabled.') {
				toast.error('You have been disabled as a user. Please contact your administrator', {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'foo-bar'
				});
			} else {
				toast.error(loginError.message.message, {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'foo-bar'
				});
			}
		}
		if (loginSuccess) {
			// setLoading(false);
			if (loginSuccess.data.ChallengeName === "NEW_PASSWORD_REQUIRED") {
				setStorage('myo_reset_session_data', loginSuccess.data);
				history.push('/reset-password');
				// getUserMapping({ userId: loginSuccess.data.userData._id });
			} else {
				// message.success('Login success. Redirecting .....');
				resetAuth();
				setUserId(loginSuccess.data.userData._id);
				getUserMapping({ userId: loginSuccess.data.userData._id });
				setStorage('myo_logged_user', loginSuccess.data);

			}
		}
		// setLoading(false);

	}, [getUserMapping, history, loginError, loginSuccess, resetAuth]);

	///////////////////////////////////////////////////////////////////////////////////////////////////////

	useEffect(() => {
		if (userId) {
			if (getUserMappingSuccess) {
				const { result } = getUserMappingSuccess.data;
				const brandData = result?.[0]?.brandData;
				const brand = result?.[0]?.brands;

		
				if (brandData?.length !== 0) {
					if (brandData?.[0]?.brandStatus === BrandStatus.archive) {
						emptyStorage();
						resetUser();
						history.push('/');
						toast.error("Your brand has been archived. Contact the Mysa team for support", {
							position: toast.POSITION.BOTTOM_RIGHT,
							className: 'foo-bar'
						});
						return;
					}
					if (brand?.[0]?.isFirstUser === true && brand?.[0]?.role === UserGroups.chief_brand_admin && result[0]?.user?.userStatus === 'CONFIRMED') {
						const payload = {
							id: brandData?.[0]?._id,
							brandStatus: 'incomplete'
						}
						onUpdateBrand(payload);

					}



				}

				setTimeout(() => {
					setStorage('myo_logged_user_permission', result.length !== 0 ? result.shift() : {});
					if (url) {
						history.push('/' + url);
					} else {
						history.push('/admin');
					}

				}, 1000);

				toast.success("Login success. Redirecting .....", {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'foo-bar'
				});

				resetUser();
			}
		}
	}, [getUserMappingSuccess, history, onUpdateBrand, resetUser, url, userId])

	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	const onFinish = useCallback((values) => {
		setLoading(true);
		loginAction(values);
	}, [loginAction]);

	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	return (
		<div className="mysa-login flex-col flex h-screen items-center justify-center" >

			<Card className="px-5 shadow-2xl" style={{
				position: 'absolute',
				width: '458px',
				height: '411px',
				left: '731px',
				top: '338px',
				background: '#FFFFFF',
				// boxShadow: ' 0px 8px 8px rgba(0, 0, 0, 0.6)',
				borderRadius: '33px'
			}}>
				<div className="w-64 m-auto " style={{
					paddingBottom: '0rem',
					paddingTop: '0rem'
				}}>
					<img src={logo} alt="logo" />
				</div>
				<>

					<Container>
						<div  >
							<Form onSubmit={handleSubmit(onFinish)} style={{
								paddingBottom: '4rem',
								paddingTop: '0rem'
							}}>
								<Row className='formSpaceLogin'>

									<Col md={12} style={{ textAlign: 'left' }}>
										<Form.Control type="email" placeholder="Email" className={`${errors.email ? 'is-invalid' : ''}`}  {...register("email", { required: true })} />
										<div className="invalid-feedback">Please input your email!</div>
									</Col>
								</Row>

								<Row className='formSpaceLogin '>

									<Col md={12} style={{ textAlign: 'left' }} >
										<Form.Control type='password' placeholder="Password" className={`${errors.password ? 'is-invalid' : ''}`}  {...register("password", { required: true })} />
										<div className="invalid-feedback" >Please input your Password!</div>
									</Col>
								</Row>
								<Row className='formSpaceLogin '>

									<Col md={12} style={{ textAlign: 'center' }} >
										{!loading && <Button type="submit" id='login-button' style={loginButton} >Sign In</Button>}
									</Col>
									<a className="block" style={{ textAlign: 'right', marginLeft: '-15px', marginTop: '25px' }} href="/forgot-password">Forgot password?</a>

									{
										loading && <div style={{ textAlign: 'center' }}><Spinner animation="border" variant="warning" /></div>
									}
								</Row>

							</Form>
						</div>
					</Container>


					{/* <Form
						onFinish={onFinish}
					>
						<Form.Item
							className="mb-2"
							name="email"
							hasFeedback
							rules={[
								{ required: true, message: 'Please input your email!' },
								{ type: "email", message: 'Please input valid email!' }
							]}
						>
							<Input placeholder="Email" />
						</Form.Item>
						<Form.Item
							hasFeedback
							name="password"
							rules={[{ required: true, message: 'Please input your Password!' }]}
						>
							<Input
								type="password"
								placeholder="Password"
							/>
						</Form.Item>
						<Form.Item>
							<Button loading={loading} block type="primary" htmlType="submit" className="login-btn">
								Sign in
							</Button>
							<a className="block float-right mt-2 " href="/forgot-password">Forgot password?</a>
						</Form.Item>
					</Form>  */}
				</>
			</Card>
		</div>
	);
};



export default Login;
