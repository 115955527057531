import { Form, Col, Row, Button, OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import { FC, useEffect, useState } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { UserGroups } from "../../../../common/constants";
import { getLoggedUserType } from "../../../../common/functions";
import { LocationOverviewReportFormInput, LocationDropdown, NewDataField } from "../../../../common/interfaces";
import { toast } from 'react-toastify';
import RFPBreadCrumbPage from "../../../common/RFPBredcrumPage";
import Archive from '../../../../images/icons/svg/outside/Icon-Function-Archive.svg';
import { overlayTriggerDelay } from "../../../../common/components-style";
import { Select } from "antd";
const { Option } = Select;

const LocationOverviewReport: FC<any> = ({ reportId, programmeId, setFormOpen, reload, selectedClient, selectedProgramme, associateProgrammes, reportName, accommodationType }): JSX.Element => {
    const [loading, setLoading] = useState<boolean>(false);
    const { handleSubmit, control, watch, register, trigger, getValues, clearErrors, setValue, reset, formState: { errors } } = useForm<LocationOverviewReportFormInput>();
    const [reportNameList, setReportNameList] = useState<any>([]);
    const [editEnable, setEditEnable] = useState<boolean>(false);
    const [getReportName, setGetReportName] = useState<any>([]);
    const [selectedFields, setSelectedFields] = useState<any[]>([]);
    const [locationOverviewData, setLocationOverviewData] = useState<any>();
    const [getSepndNightDataName, setSepndNightDataName] = useState<any>([]);
    const [dataModelShow, setDataModelShow] = useState<boolean>(false);
    const [isSubmitClicked, setIsSubmitClicked] = useState(false);
    const [dataTypeList, setDataTypeList] = useState<any>([]);
    const [fieldsModified, setFieldsModified] = useState(false);
    const [editButtonShow, setEditButtonShow] = useState(false);
    const [activeEditIndex, setActiveEditIndex] = useState<number | null>(null);
    const [dataTypeCount, setDataTypeCount] = useState<boolean>(false);
    const [hasChanges, setHasChanges] = useState(false);
    const [editingField, setEditingField] = useState<Field | null>(null);
    const [getReportDataFieldName, setReportDataFieldName] = useState<any>([]);

    interface Field {
        fieldName: string;
        isFromData?: boolean;
        newFieldId?: string;
        isEditable?: boolean;
        reportDataFieldName: string;
    }


    const formulaOption = watch("newDataFields.0.formulaOption");


    const [fields, setFields] = useState([
        { id: 'static-1', fieldName: 'Country', reportDataFieldName: 'Country', newFieldId: '', isFromData: false, isEditable: false, isUnsaved: false },
        { id: 'static-2', fieldName: 'City', reportDataFieldName: 'City', newFieldId: '', isFromData: false, isEditable: false, isUnsaved: false },
        { id: 'static-3', fieldName: '', reportDataFieldName: '', newFieldId: '', isFromData: false, isEditable: false, isUnsaved: false },
    ]);

    const [dropdowns, setDropdowns] = useState<LocationDropdown[]>([
        { id: 1, selectedValue: null },
        { id: 2, selectedValue: null },
    ]);

    const {
        createLocationOverviewReport,
        getLocationOverviewReport,
        updateLocationOverviewReport,
    } = useStoreActions<any>((actions) => ({
        createLocationOverviewReport: actions.hotel.createLocationOverviewReport,
        getLocationOverviewReport: actions.hotel.getLocationOverviewReport,
        updateLocationOverviewReport: actions.hotel.updateLocationOverviewReport,
    }))

    const {
        createLocationOverviewReportDataSuccess,
        createLocationOverviewReportDataError,
        getLocationOverviewReportSuccess,
        updateLocationOverviewReportDataSuccess,
        updateLocationOverviewReportDataError,
        getReportDataFieldsSuccess,

    } = useStoreState<any>((state) => ({
        createLocationOverviewReportDataSuccess: state.hotel.createLocationOverviewReportDataSuccess,
        createLocationOverviewReportDataError: state.hotel.createLocationOverviewReportDataError,
        getLocationOverviewReportSuccess: state.hotel.getLocationOverviewReportSuccess,
        updateLocationOverviewReportDataSuccess: state.hotel.updateLocationOverviewReportDataSuccess,
        updateLocationOverviewReportDataError: state.hotel.updateLocationOverviewReportDataError,
        getReportDataFieldsSuccess: state.hotel.getReportDataFieldsSuccess,
    }));

    const handleAddDropdown = () => {
        setDropdowns([...dropdowns, { id: dropdowns.length + 1, selectedValue: null }]);
    };

    const handleDropdownChange = (value: string, index: number) => {
        const updatedDropdowns = [...dropdowns];

        updatedDropdowns[index].selectedValue = value;

        if (index === 0) {
            const resetDropdowns = updatedDropdowns.slice(0, 2);

            resetDropdowns[1].selectedValue = null;

            setDropdowns(resetDropdowns);

            setValue(`newDataFields.0.spendNightOptions.0`, value);
            setValue(`newDataFields.0.spendNightOptions.1`, null);

            for (let i = 2; i < updatedDropdowns.length; i++) {
                setValue(`newDataFields.0.spendNightOptions.${i}`, null);
            }
        } else {
            setDropdowns(updatedDropdowns);
            setValue(`newDataFields.0.spendNightOptions.${index}`, value);
        }
    };


    const filterOptions = (index: number) => {
        if (!formulaOption) return getSepndNightDataName;

        const selectedValues = dropdowns.map((d) => d.selectedValue);
        const currentSelection = dropdowns[index]?.selectedValue;

        const firstSelection = dropdowns[0]?.selectedValue
            ? getSepndNightDataName.find((item) => item._id === dropdowns[0]?.selectedValue)
            : null;

        const firstSelectionType = firstSelection?.dataType;
        const firstSelectionProgramme = firstSelection?.associateProgramme;


        return getSepndNightDataName.filter((option) => {
            if (selectedValues.includes(option._id) && option._id !== currentSelection) return false;

            if (formulaOption === "Total") {
                if (index === 0) return true;
                return option.dataType === firstSelectionType;
            }

            if (formulaOption === "Average") {
                if (index === 0) return true;
                return option.dataType !== firstSelectionType &&
                    option.associateProgramme === firstSelectionProgramme;
            }

            return true;
        });
    };

    useEffect(() => {
        const dataTypeListTemp: any = [{
            _id: 'Total',
            name: "Total",
        },
        {
            _id: 'Average',
            name: "Average",
        },
            // {
            //     _id: 'Percentage Difference',
            //     name: "Percentage Difference",
            // },
            // {
            //     _id: 'Percentage of',
            //     name: "Percentage of",
            // },
        ];

        const typeOfDataType = dataTypeListTemp?.map((val: any, key: number) => {
            return (
                <Option key={key} data={val} value={`${val._id}`} >{val.name}</Option>
            )
        });

        setDataTypeList(typeOfDataType);
    }, []);

    useEffect(() => {
        if (createLocationOverviewReportDataError) {
            setLoading(false);
            toast.error(createLocationOverviewReportDataError?.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }
        if (createLocationOverviewReportDataSuccess) {
            setLoading(false);
            toast.success("Report created successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }
        setLoading(false);
    }, [createLocationOverviewReportDataError, createLocationOverviewReportDataSuccess]);


    useEffect(() => {
        if (updateLocationOverviewReportDataError) {
            setLoading(false);
            toast.error(updateLocationOverviewReportDataError?.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }
        if (updateLocationOverviewReportDataSuccess) {
            setLoading(false);
            toast.success("Report updated successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }
        setLoading(false);
    }, [updateLocationOverviewReportDataError, updateLocationOverviewReportDataSuccess]);


    useEffect(() => {
        if (reportId) {
            getLocationOverviewReport(reportId);
            setFields((prevFields) =>
                prevFields.map((field, index) =>
                    index < 2 ? { ...field, isEditable: false } : { ...field, isEditable: true }
                )
            );
        }
    }, [getLocationOverviewReport, reportId]);


    useEffect(() => {
        if (getReportDataFieldsSuccess?.data) {
            setLoading(false);
            const fetchedFields = getReportDataFieldsSuccess?.data?.fields || [];
            const filteredFields = fetchedFields.filter(field =>
                field.dataType === 'spend' || field.dataType === 'nights'
            );

            const getReportNames = filteredFields?.map((field) => ({
                _id: field.fieldName,
                name: field.fieldName,
                dataType: field.dataType,
                associateProgramme: field.associateProgramme
            }));
            if (!editEnable) {
                setGetReportName(getReportNames);
            }
            setSepndNightDataName(getReportNames);
            setReportDataFieldName(getReportNames);
        }
    }, [getReportDataFieldsSuccess?.data, editEnable]);

    useEffect(() => {
        if (getLocationOverviewReportSuccess?.data) {
            setLoading(false);
            const fetchedFields = getLocationOverviewReportSuccess.data.fields || [];
            setLocationOverviewData(getLocationOverviewReportSuccess?.data);

            const formattedFields = fetchedFields?.map((field, index) => ({
                id: `static-${index}`,
                fieldName: field.fieldName || '',
                reportDataFieldName: field.reportDataFieldName || '',
                isFromData: field.isFromData,
                newFieldId: field.newFieldId,
            }));
            const fetchReportData = new Set(fetchedFields?.map(item => item.reportDataFieldName));
            const reportFields = getReportDataFieldName || [];
            const result = getReportName?.filter(item => !fetchReportData.has(item.name));

            const getReportNames = result?.map((field) => ({
                _id: field.name || field.reportDataFieldName,
                name: field.name || field.reportDataFieldName,
                dataType: field.dataType,
                associateProgramme: field.associateProgramme
            }));

            const filteredReportFields = reportFields.filter(item => fetchReportData.has(item._id));
            const spendCount = filteredReportFields?.filter(field => field.dataType === 'spend').length || 0;
            const nightsCount = filteredReportFields?.filter(field => field.dataType === 'nights').length || 0;
            const addStaticValue = spendCount >= 2 || nightsCount >= 2 || (spendCount >= 1 && nightsCount >= 1);

            setFields(formattedFields);
            setGetReportName(getReportNames);
            setEditEnable(true);
            setDataTypeCount(addStaticValue);
        }
    }, [getLocationOverviewReportSuccess?.data]);


    useEffect(() => {

        if (getReportName) {
            const options = getReportName?.map((val: any, key: number) => {
                const isFieldSelected = selectedFields.includes(val._id);
                return !isFieldSelected && (
                    <Option key={key} value={val._id}>{val.name}</Option>
                );
            });
            if (dataTypeCount) {
                options.push(
                    <Option key="data" value="Data">Data</Option>
                );
            }
            setReportNameList(options);
        }
    }, [dataTypeCount, getReportName, selectedFields]);

    //////////////////////////////////////////////////////////////////////////////////////////////////////////

    const addField = () => {
        setFields((prevFields) => [
            ...prevFields,
            {
                id: `dynamic-${prevFields.length}`,
                fieldName: '',
                reportDataFieldName: '',
                newFieldId: '',
                isEditable: true,
                isUnsaved: true,
                isFromData: false
            },
        ]);
    };

    //////////////////////////////////////////////////////////////////////////////////////////////////
    const handleFieldChange = (index: number, field: string, value: string) => {
        const normalizedValue = value.trim().toLowerCase();
        setHasChanges(true);
        if (field === "fieldName") {
            const isDuplicate = fields.some((item, idx) => {
                return idx !== index && item.fieldName.trim().toLowerCase() === normalizedValue;
            });

            if (isDuplicate) {
                alert("This field name is already in use.");
                return;
            }

            handleFieldChangeInFields(index, field, value);
            return;
        }
        if (field === "reportDataFieldName") {
            const updatedSelectedFields = [...selectedFields];
            const previousValue = fields[index]?.reportDataFieldName;
            updatedSelectedFields[index] = value;

            if (previousValue) {

                const isOldValueInGetReportName = getReportName.some(
                    (item: any) => item.name === previousValue
                );
                if (!isOldValueInGetReportName) {
                    setGetReportName((prev) => [
                        ...prev,
                        { _id: previousValue, name: previousValue },
                    ]);
                }
            }
            setSelectedFields(updatedSelectedFields);
            handleFieldChangeInFields(index, field, value);
        }
    };


    const handleFieldChangeInFields = (index, field, value) => {
        const updatedFields = [...fields];
        updatedFields[index] = {
            ...updatedFields[index],
            [field]: value,
        };
        setFields(updatedFields);
    };
    const onSubmit: SubmitHandler<LocationOverviewReportFormInput> = async (values) => {

        const hasErrors = fields.some((field, index) => {
            if (index < 2) {
                return false;
            }
            return !field.fieldName || !field.reportDataFieldName;
        });

        if (hasErrors) {
            alert('Please fill the required fields.');
            return;
        }

        const payload = {
            clientId: selectedClient.key ? selectedClient.key : selectedClient._id,
            programmeId: programmeId,
            reportId: reportId,
            fields: fields.map((field) => {
                const newField = {
                    fieldName: field.fieldName,
                    reportDataFieldName: field.reportDataFieldName,
                    isFromData: field.isFromData,
                    newFieldId: field.newFieldId
                };

                if (field.isFromData) {
                    const existingFieldInNewData = locationOverviewData?.newDataFields?.find(
                        (newField) => newField.newFieldId === field.newFieldId
                    );
                    if (existingFieldInNewData) {
                        existingFieldInNewData.formulaOption = field.reportDataFieldName;
                        existingFieldInNewData.fieldName = field.fieldName;
                    } else {
                        locationOverviewData.newDataFields.push({
                            fieldName: field.fieldName,
                            formulaOption: field.reportDataFieldName
                        });
                    }
                }
                return newField;
            }),

            newDataFields: locationOverviewData?.newDataFields || []
        };
        setLoading(true);

        if (editEnable) {
            payload.reportId = reportId;
            updateLocationOverviewReport(payload);
            setEditButtonShow(false);
            setHasChanges(false);
        } else {
            createLocationOverviewReport(payload);
        }

        getLocationOverviewReport(reportId);
    };

    const removeField = (index: number) => {
        const updatedFields = [...fields];
        const removedField = updatedFields[index].reportDataFieldName;
        updatedFields.splice(index, 1);
        setFields(updatedFields);
        setHasChanges(true);

        const updatedSelectedFields = [...selectedFields];
        updatedSelectedFields.splice(index, 1);
        setSelectedFields(updatedSelectedFields);

        if (removedField) {
            setGetReportName((prev) => {
                if (!prev.some((item: any) => item.name === removedField)) {
                    return [...prev, { _id: removedField, name: removedField }];
                }
                return prev;
            });
        }
    };


    const saveField = (index: number) => {
        setFieldsModified(true);
        const updatedFields = [...fields];

        const anyUnsavedField = updatedFields.some((field) => field.isUnsaved);
        if (!anyUnsavedField) {
            setFieldsModified(true);
        }

        updatedFields[index].isEditable = false;
        updatedFields[index].isUnsaved = false;
        setFields(updatedFields);
        setHasChanges(true);
    };

    const enableEdit = (index: number, field: any) => {
        if (field.isFromData) {
            handleOpenModal(field.newFieldId);
        }
        setEditingField(field);
        setActiveEditIndex(index);
        const updatedFields = [...fields];
        updatedFields[index].isEditable = true;
        setFields(updatedFields);
    };

    const disableEdit = (index: number) => {
        const updatedFields = [...locationOverviewData?.fields];
        updatedFields[index].isEditable = false;
        setFields(updatedFields);
    };

    const onSave = async () => {
        setIsSubmitClicked(true);
        const isValid = await trigger('newDataFields');
        const modalData = getValues();

        if (!isValid) {
            alert('Please fill the required fields.');
            return;
        }

        const newFieldName = modalData.newDataFields[0]?.fieldName?.trim().toLowerCase();

        const existingFieldNames = fields
            .filter(field => field.fieldName.trim().toLowerCase() !== (editingField?.fieldName || '').trim().toLowerCase())
            .map(field => field.fieldName.trim().toLowerCase());

        if (existingFieldNames.includes(newFieldName)) {
            alert("This field name is already in use.");
            return;
        }

        setLoading(true);

        let newFieldId;
        if (editEnable && editingField) {
            newFieldId = editingField.newFieldId;
        } else {
            newFieldId = `${new Date().getTime()}-${Math.random().toString(36).substr(2, 9)}`;
        }

        const newDataField: NewDataField = {
            newFieldId: newFieldId,
            fieldName: modalData.newDataFields[0]?.fieldName,
            formulaOption: modalData.newDataFields[0]?.formulaOption,
            spendNightOptions: dropdowns?.map(dropdown => dropdown.selectedValue),
        };

        const newField: Field = {
            newFieldId: newFieldId,
            fieldName: modalData.newDataFields[0]?.fieldName,
            reportDataFieldName: modalData.newDataFields[0]?.formulaOption,
            isFromData: true
        };

        let existingNewDataFields: NewDataField[] = [];
        let existingFields: Field[] = [];
        if (locationOverviewData?.newDataFields) {
            existingNewDataFields = locationOverviewData.newDataFields;
        }
        if (locationOverviewData?.fields) {
            existingFields = locationOverviewData.fields;
        }

        let updatedNewDataFields: NewDataField[] = [...existingNewDataFields];
        let updatedFields: Field[] = [...existingFields];

        if (editEnable && editingField) {
            updatedNewDataFields = updatedNewDataFields.map(field =>
                field.newFieldId === editingField.newFieldId ? newDataField : field
            );
            updatedFields = updatedFields.map(field =>
                field.newFieldId === editingField.newFieldId ? newField : field
            );
        } else {
            updatedNewDataFields.push(newDataField);
            updatedFields.push(newField);
        }

        const payload = {
            clientId: selectedClient.key || selectedClient._id,
            reportId,
            programmeId,
            newDataFields: updatedNewDataFields,
            fields: updatedFields
        };

        setLoading(true);
        if (editEnable) {
            updateLocationOverviewReport(payload);
            getLocationOverviewReport(reportId);
        } else {
            createLocationOverviewReport(payload);
        }

        setDataModelShow(false);
    };


    const handleOpenModal = (newFieldId = null) => {
        if (newFieldId) {
            const fieldToEdit = locationOverviewData?.newDataFields?.find(field => field.newFieldId === newFieldId);
            if (fieldToEdit) {
                reset({
                    newDataFields: [
                        {
                            fieldName: fieldToEdit.fieldName,
                            formulaOption: fieldToEdit.formulaOption,
                            spendNightOptions: fieldToEdit.spendNightOptions,
                        },
                    ],
                });

                setDropdowns(
                    fieldToEdit.spendNightOptions?.map((selectedValue, index) => ({
                        id: index + 1,
                        selectedValue,
                    })) || []
                );
            }
        } else {
            reset({
                newDataFields: [
                    {
                        fieldName: '',
                        formulaOption: '',
                        spendNightOptions: dropdowns?.map(() => null),
                    },
                ],
            });

            setDropdowns([{ id: 1, selectedValue: null }, { id: 2, selectedValue: null }]);
        }

        setDataModelShow(true);
    };


    const enableEditButton = () => {
        setEditButtonShow(true);
        setHasChanges(false);
    };

    const hasUnsavedField = fields.some((field) => field.isUnsaved);
    const hasEditableField = fields.some((field) => field.isEditable);
    const addColumnDisabled =
        !editEnable ?
            !(fieldsModified && !hasUnsavedField) :
            !(editButtonShow && !hasUnsavedField)

    const addCancelButtonDisabled =
        !editEnable ?
            (fields.length > 3 && hasEditableField) : (hasEditableField);

    // 	---------------------------------------------------------------------------------------------------
    return (
        <>

            {reportId &&
                <RFPBreadCrumbPage isPageName="programmeSpendReport" selectedClient={selectedClient} selectedProgramme={selectedProgramme} selectedClientName={selectedClient?.companyName} selectedProgrammeName={selectedProgramme?.programmeName} reportId={reportId} programmeId={programmeId} startDate={reportName} accommodationType={accommodationType}></RFPBreadCrumbPage>
            }
            <div className="create-update-wrapper mysa-create-form" style={{ marginLeft: "40px" }}>
                <Form onSubmit={handleSubmit(onSubmit)} >
                    <Row className="formSpace d-flex align-items-center">
                        <Col md={3}>
                        </Col>
                        <Col md={3}>
                            <h5 style={{ fontWeight: '700', marginBottom: '30px' }}>Report Data Field</h5>
                        </Col>
                        <Col md={3}>
                            <h5 style={{ fontWeight: '700', marginBottom: '30px' }}>Column Name</h5>
                        </Col>
                    </Row>
                    <>
                        {fields.map((field, index) => (
                            <Row className="formSpace d-flex align-items-center" key={field.id}>
                                <Col md={3}>
                                    <Form.Label className=" mt-0">{<span className='requiredMark'>*</span>} {`Column field ${index + 1}`}</Form.Label>
                                </Col>
                                <Col md={3}>
                                    {!field.isEditable ? (
                                        <p className="lead m-0">{field.reportDataFieldName}</p>
                                    ) : (
                                        <Controller
                                            name={`fields.${index}.reportDataFieldName`}
                                            defaultValue={field.reportDataFieldName}
                                            control={control}
                                            render={({ field: controllerField }) => (
                                                <Select
                                                    value={field.reportDataFieldName || 'Select Report Data Fields'}
                                                    className="mysa-select"
                                                    style={{ width: '100%' }}
                                                    onChange={(value) => {
                                                        if (value === 'Data') {
                                                            handleOpenModal();
                                                        }
                                                        handleFieldChange(index, 'reportDataFieldName', value);
                                                    }}
                                                    disabled={!field.isEditable || field.isFromData}
                                                    showSearch
                                                >
                                                    {(field.isFromData ? dataTypeList : reportNameList)}
                                                </Select>
                                            )}
                                        />
                                    )}
                                </Col>
                                <Col md={3}>
                                    {!field.isEditable ? (
                                        <p className="lead m-0">{field.fieldName}</p>
                                    ) : (
                                        <Form.Control
                                            name={`name-${field.id}`}
                                            value={field.fieldName}
                                            onChange={(e) => handleFieldChange(index, 'fieldName', e.target.value)}
                                            className="mysa-form-control"
                                        />
                                    )}
                                </Col>
                                {index >= 2 && (
                                    <>
                                        <Col md={1}>
                                            {field.isEditable ? (
                                                !field.fieldName || !field.reportDataFieldName ? null : (
                                                    <Button
                                                        id="save-button"
                                                        type="button"
                                                        onClick={() => saveField(index)}
                                                        className="customSaveBtn"
                                                    >
                                                        Save
                                                    </Button>
                                                )
                                            ) : ((!editEnable || (editEnable && editButtonShow)) &&
                                                <Button
                                                    id='customEditBtn'
                                                    type="button"
                                                    onClick={() => enableEdit(index, field)}
                                                > Edit
                                                </Button>
                                            )}

                                        </Col>
                                        {index === fields.length - 1 && !field.isEditable && (!editEnable || (editEnable && editButtonShow)) && (
                                            <Col md={1}>
                                                {
                                                    <OverlayTrigger
                                                        placement="top"
                                                        delay={overlayTriggerDelay}
                                                        overlay={<Tooltip id='tooltip-table-top'>Remove</Tooltip>}
                                                    >
                                                        <img className='iconSizes' src={Archive} alt="" onClick={() => removeField(fields.length - 1)} />
                                                    </OverlayTrigger>
                                                }
                                            </Col>
                                        )}
                                    </>
                                )}
                            </Row>
                        ))}
                        <Row style={{ paddingTop: '30px' }} className='formSpace'>
                            <Col md={10}>
                                <div className="d-flex justify-content-between">
                                    {getLoggedUserType() === UserGroups.super_admin && !loading && reportId && (
                                        <>
                                            {!editEnable ? (!hasUnsavedField && fieldsModified && <Button
                                                className="h-10 text-white mt-15 ml-12"
                                                id="create-button"
                                                type="submit"
                                            >
                                                {'Create Location Report'}
                                            </Button>) : (editButtonShow && !hasEditableField && hasChanges ?
                                                <Button
                                                    className="mt-15 ml-12"
                                                    type="submit" id='create-button'
                                                >
                                                    {'Update Location Report'}
                                                </Button> :
                                                !editButtonShow && <button
                                                    className="btn btn-primary mt-15 ml-12"
                                                    id='edit-row-button'
                                                    type="button"
                                                    onClick={() => enableEditButton()}
                                                >
                                                    {'Edit'}
                                                </button>)}
                                            {(!addColumnDisabled) && <Button id="add-button"
                                                className="mt-15 ml-12"
                                                type="button"
                                                onClick={addField}
                                            >
                                                {'Add column'}
                                            </Button>
                                            }
                                            {(addCancelButtonDisabled) &&
                                                <Button
                                                    className="mt-15 ml-12"
                                                    id='cancel-button'
                                                    type="button"
                                                    onClick={() => {
                                                        setHasChanges(false);

                                                        if (!editEnable || (hasUnsavedField && activeEditIndex === null)) {
                                                            removeField(fields.length - 1);
                                                        } else if (activeEditIndex !== null) {
                                                            disableEdit(activeEditIndex);
                                                            setActiveEditIndex(null);
                                                        }
                                                    }}
                                                >
                                                    {'Cancel'}
                                                </Button>}
                                            {(!addColumnDisabled && editButtonShow && (!hasEditableField && !hasUnsavedField)) &&
                                                <Button
                                                    className="mt-15 ml-12"
                                                    id='close-button'
                                                    type="button"
                                                    onClick={() => {
                                                        setEditButtonShow(false);
                                                    }}
                                                >
                                                    {'Close'}
                                                </Button>}
                                        </>
                                    )}
                                </div>
                            </Col>
                        </Row>
                        <Modal
                            show={dataModelShow}
                            onHide={() => setDataModelShow(false)}
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">
                                    {reportName}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Row>
                                    <Col md={10}>
                                        <div className="inputFuild mb-4">
                                            <span className='requiredMark'>*</span>
                                            <Controller
                                                name="newDataFields.0.formulaOption"
                                                defaultValue={locationOverviewData?.formulaOption || null}
                                                control={control}
                                                rules={{ required: true }}
                                                render={({ field: controllerField }) => (
                                                    <Select
                                                        {...controllerField}
                                                        value={controllerField.value || null}
                                                        className={`mysa-select ${errors.newDataFields && errors.newDataFields[0]?.formulaOption ? 'is-invalid' : ''}`}
                                                        style={{ width: "100%" }}
                                                        showSearch
                                                        placeholder="Select formula"
                                                        onChange={(value) => {
                                                            controllerField.onChange(value);
                                                            clearErrors("newDataFields.0.formulaOption");
                                                        }}
                                                    >
                                                        {dataTypeList}
                                                    </Select>
                                                )}
                                            />
                                            {isSubmitClicked && errors?.newDataFields?.[0]?.formulaOption && (
                                                <span className="invalid-feedback">{'Please select formula option'}</span>
                                            )}
                                        </div>
                                    </Col>
                                    {dropdowns?.map((dropdown, index) => (
                                        <div key={dropdown.id || index} className="row p-0 m-0">
                                            <Col md={10}>
                                                <div className="inputFuild mb-4">
                                                    {<span className="requiredMark">*</span>}
                                                    <Controller
                                                        key={`controller-${dropdown.id}`}
                                                        name={`newDataFields.0.spendNightOptions.${index}`}
                                                        defaultValue={locationOverviewData?.[`spendNightOption${index + 1}`]}
                                                        control={control}
                                                        rules={{ required: 'Please select option' }}
                                                        render={({ field: controllerField }) => (
                                                            <Select
                                                                {...controllerField}
                                                                className={`mysa-select ${errors?.newDataFields?.[0]?.spendNightOptions?.[index] ? 'is-invalid' : ''}`}
                                                                style={{ width: '100%' }}
                                                                showSearch
                                                                placeholder={`Select option ${index + 1}`}
                                                                value={dropdown.selectedValue}
                                                                onChange={(value) => {
                                                                    handleDropdownChange(value, index);
                                                                    clearErrors(`newDataFields.0.spendNightOptions.${index}`);
                                                                }}
                                                            >
                                                                {filterOptions(index).map((val) => (
                                                                    <Option key={val._id} value={val._id}>
                                                                        {val.name}
                                                                    </Option>
                                                                ))}
                                                            </Select>
                                                        )}
                                                    />
                                                    {isSubmitClicked && errors?.newDataFields?.[0]?.spendNightOptions?.[index] && (
                                                        <span className="invalid-feedback">{errors?.newDataFields?.[0]?.spendNightOptions?.[index]?.message}</span>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col md={2}>
                                                {index + 1 === dropdowns.length && ['Total', 'Average'].includes(formulaOption) && (
                                                    <Button
                                                        className="yellow_shadow_btn mb-4"
                                                        onClick={handleAddDropdown}
                                                        type="button"
                                                        disabled={filterOptions(index + 1).length === 0}
                                                    >
                                                        Add
                                                    </Button>
                                                )}
                                            </Col>
                                        </div>
                                    ))}
                                    <Col md={10}>
                                        <div className="inputFuild mb-4">
                                            <span className="requiredMark">*</span>
                                            <Form.Control
                                                placeholder="Enter column name"
                                                defaultValue={locationOverviewData?.newDataFields[0]?.fieldName || ''}
                                                {...register("newDataFields.0.fieldName", {
                                                    validate: value => value?.trim() !== "" || "Column name is required",
                                                    onChange: (e) => {
                                                        if (e.target.value.trim() !== "") {
                                                            clearErrors("newDataFields.0.fieldName");
                                                        }
                                                    },
                                                })}
                                            />
                                            {isSubmitClicked && errors?.newDataFields?.[0]?.fieldName && (
                                                <span className="text-danger">{'Please input column name'}</span>
                                            )}
                                        </div>

                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="w-100 d-flex justify-content-between">
                                    <Button type="button" style={{ width: '150px' }} onClick={onSave}>Save</Button>
                                    <Button type="button" className="yellow_shadow_btn" style={{ width: '150px' }} onClick={() => setDataModelShow(false)}>Close</Button>
                                </div>
                            </Modal.Footer>
                        </Modal>
                    </>
                </Form>
            </div>
        </>
    )
}

export default LocationOverviewReport;
