
import React, { } from 'react'
import { Row, Col, Breadcrumb, } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const RFPBreadCrumbPage: React.FC<any> = ({ isPageName, name, isPageSub, selectedClientName, selectedProgramme, rfpProcessId, selectedClient, programmeId, selectedProgrammeName, startDate, templateEnable, reportId, accommodationType }): JSX.Element => {


	return (
		<div>
			{isPageName === 'RFP' &&
				<Row>
					<Col xs={12}><div className="">
						<Breadcrumb className='mb-10' >
							<Breadcrumb.Item className='font-light' >
								<Link to={{
									pathname: '/admin/programme-management',
									state: {
										clientId: selectedClient?.key
									}
								}} >
									{selectedClientName}
								</Link>
							</Breadcrumb.Item>
							{/* <Breadcrumb.Item>
								<Link to="" onClick={(e) => { e.preventDefault(); setFormOpen(false); refreshProgrammes(); setSelectedProgramme(null) }} >{selectedClient.companyName}</Link>
							</Breadcrumb.Item> */}
							<Breadcrumb.Item className='font-light' >
								<Link to={{
									pathname: '/admin/programme-management',
									state: {
										selectedProgramme,
										selectedClient
									}
								}} >
									{selectedProgrammeName}
								</Link>
							</Breadcrumb.Item>

							<Breadcrumb.Item className='font-light'  >
								{

									<Link to={{
										pathname: '/admin/rfp-management',
										state: {
											selectedProgramme,
											selectedClient,
											programmeId
										}
									}} >RFP Management</Link>
								}
							</Breadcrumb.Item>


							{rfpProcessId && isPageSub === 'RFPReview' ? <Breadcrumb.Item active style={{ cursor: 'pointer' }} onClick={() => {
								templateEnable(false);
							}}>
								{startDate}
							</Breadcrumb.Item> : <Breadcrumb.Item active >
								{startDate}
							</Breadcrumb.Item>}
						</Breadcrumb>
					</div>
					</Col>
				</Row>
			}


			{isPageName === 'programmeSpendReport' &&
				<Row>
					<Col xs={12}><div className="">
						<Breadcrumb className='mb-10' >
							<Breadcrumb.Item className='font-light' >
								<Link to={{
									pathname: '/admin/programme-management',
									state: {
										clientId: selectedClient?.key
									}
								}} >
									{selectedClientName}
								</Link>
							</Breadcrumb.Item>
							<Breadcrumb.Item className='font-light' >
								<Link to={{
									pathname: '/admin/programme-management',
									state: {
										selectedProgramme,
										selectedClient
									}
								}} >
									{selectedProgrammeName}
								</Link>
							</Breadcrumb.Item>

							<Breadcrumb.Item className='font-light'>
								{

									<Link to={{
										pathname: '/admin/programme-management',
										state: {
											selectedProgramme,
											selectedClient,
											programmeId,
											tabKey: 'programmeSpendData',
										}
									}} >Spend Programme Management</Link>
								}
							</Breadcrumb.Item>
							<Breadcrumb.Item active>
								{startDate}
							</Breadcrumb.Item>
						</Breadcrumb>
					</div>
					</Col>
				</Row>
			}


			{isPageName === 'invite' &&
				<Row>
					<Col xs={12}><div className="">
						<Breadcrumb className='mb-10' >
							<Breadcrumb.Item className='font-light' >
								<Link to={{
									pathname: '/admin/programme-management',
									state: {
										clientId: selectedClient?.key
									}
								}} >
									{selectedClientName}
								</Link>
							</Breadcrumb.Item>
							<Breadcrumb.Item className='font-light' >
								<Link to={{
									pathname: '/admin/programme-management',
									state: {
										selectedProgramme,
										selectedClient
									}
								}} >
									{selectedProgrammeName}
								</Link>
							</Breadcrumb.Item>


							<Breadcrumb.Item >
								{startDate}
							</Breadcrumb.Item>

							<Breadcrumb.Item active>
								{name}
							</Breadcrumb.Item>
						</Breadcrumb>
					</div>
					</Col>
				</Row>
			}

			{isPageName === 'locationOverviewReport' &&
				<Row>
					<Col xs={12}><div className="">
						<Breadcrumb className='mb-10' >
							<Breadcrumb.Item className='font-light' >
								<Link to={{
									pathname: '/admin/programme-management',
									state: {
										clientId: selectedClient?.key
									}
								}} >
									{selectedClientName}
								</Link>
							</Breadcrumb.Item>
							<Breadcrumb.Item className='font-light' >
								<Link to={{
									pathname: '/admin/programme-management',
									state: {
										selectedProgramme,
										selectedClient
									}
								}} >
									{selectedProgrammeName}
								</Link>
							</Breadcrumb.Item>

							<Breadcrumb.Item className='font-light'>
								{

									<Link to={{
										pathname: '/admin/programme-spend-data',
										state: {
											programmeId,
											reportId,
											formOpen: true,
											selectedClient,
											selectedProgramme,
											tabKey: 'locationTableColumns',
											reportName: startDate,
											accommodationType: accommodationType
										}
									}} >Location Overview Management</Link>
								}
							</Breadcrumb.Item>
							<Breadcrumb.Item active>
								{startDate}
							</Breadcrumb.Item>
						</Breadcrumb>
					</div>
					</Col>
				</Row>
			}
		</div>
	)
}

export default RFPBreadCrumbPage;
