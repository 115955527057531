import React, { useEffect, useState } from 'react';
import AuditQuestions from './audit/AuditQuestions';
import BuildingImages from './BuildingImages';
import BuildingAmenities from './BuildingAmenities';
import { useHistory } from 'react-router-dom';
import PropertyUsersList from './property-users/PropertyUsersList';
import { getLoggedUserType, isPropertyModuleAvailable } from '../../../../common/functions';
import AccessDenied from '../../../../components/common/AccessDenied';
import { UserAccess, UserGroups, PropertyStatus } from '../../.././../common/constants';
import PropertyCheckInProcess from './PropertyCheckInProcess';
import PropertyFinance from './PropertyFinance';
import TransportDetailForm from './TransportDetailForm';
import GuestComfortDetailForm from './GuestComfortDetailForm';
import { Col, Nav, Row, Tab } from 'react-bootstrap';
import { navContentStyle, navStyle, tabDivStyle } from '../../../../common/components-style';
import PropertySustainability from './PropertySustainability';
import PropertyDetailNewForm from './PropertyDetailNewForm';

const PropertyForm: React.FC<any> = ({ allInOnePropertyBreadCrumb, propertyId, extId, setFormOpen, selectedBrand, loadData, tab, location, selectedProperty }): JSX.Element => {
	const [tabKey, setTabKey] = useState<any>('details');
	const history = useHistory();
	const [isAccess, setIsAccess] = useState<boolean>(false);

	useEffect(() => {
		if (location?.state?.defaultKey) {
			setTabKey(location?.state?.defaultKey);
		}
	}, [location?.state]);

	const redirectProperty = () => {
		if (selectedProperty?.propertyStatus === PropertyStatus.inprogress || !isPropertyModuleAvailable(selectedBrand.key ? selectedBrand.key : selectedBrand?._id, propertyId, UserAccess.property_access.details.code)) {
			setIsAccess(true);
		} else {
			history.push('/admin/inventory-management', {
				propertyId: propertyId,
				selectedBrand: selectedBrand,
				allInOnePropertyBreadCrumb: allInOnePropertyBreadCrumb,
			});
		}
	};


	return (
		<>
			<div style={tabDivStyle}>
				<Tab.Container defaultActiveKey="details" key={tabKey} activeKey={tabKey} onSelect={(tab) => setTabKey(tab)}>
					<Row >
						<Col sm={2}>
							<Nav variant="pills" className="flex-column secondry-menu" style={navStyle}>
								<div style={{ marginTop: '0%' }}>
									<Nav.Item><Nav.Link eventKey="details">Property Details</Nav.Link></Nav.Item>
									{selectedProperty?.propertyStatus !== PropertyStatus.inprogress && (
										<>
											{propertyId && (
												<>
													<Nav.Item><Nav.Link eventKey="propertyUsers">Property Users</Nav.Link></Nav.Item>
													<Nav.Item><Nav.Link eventKey="transport">Transport</Nav.Link></Nav.Item>
													<Nav.Item><Nav.Link eventKey="guestComfort">Guest Comfort</Nav.Link></Nav.Item>
													<Nav.Item><Nav.Link eventKey="propertySustainability">Property Sustainability</Nav.Link></Nav.Item>
													<Nav.Item><Nav.Link eventKey="propertyFinance">Property Finance</Nav.Link></Nav.Item>
													{getLoggedUserType() === UserGroups.super_admin && (<Nav.Item><Nav.Link eventKey="audit">Property Audit</Nav.Link></Nav.Item>)}
													<Nav.Item><Nav.Link eventKey="images">Building Images</Nav.Link></Nav.Item>
													<Nav.Item><Nav.Link eventKey="amenities">Building Amenities</Nav.Link></Nav.Item>
													<Nav.Item><Nav.Link eventKey="checkIn">Check-in</Nav.Link></Nav.Item>
													<Nav.Item><Nav.Link eventKey="inventory" onClick={redirectProperty}>Inventory Management</Nav.Link></Nav.Item>
												</>
											)}
										</>
									)}
								</div>
							</Nav>
						</Col>
						<Col sm={10}>
							<Tab.Content>
								<Tab.Pane eventKey="details" >
									<div style={navContentStyle}>
										{isPropertyModuleAvailable(selectedBrand.key ? selectedBrand.key : selectedBrand?._id, propertyId, UserAccess.property_access.details.code) ? <PropertyDetailNewForm allInOnePropertyBreadCrumb={allInOnePropertyBreadCrumb} propertyId={propertyId} selectedBrand={selectedBrand} setFormOpen={setFormOpen} loadData={loadData} tab={tab} /> : <AccessDenied />}
									</div>
								</Tab.Pane>
								{propertyId && <Tab.Pane eventKey="transport">
									<div style={navContentStyle}>
										{selectedProperty?.propertyStatus === PropertyStatus.inprogress || !isPropertyModuleAvailable(selectedBrand.key ? selectedBrand.key : selectedBrand?._id, propertyId, UserAccess.property_access.details.code) ? <AccessDenied /> : <TransportDetailForm allInOnePropertyBreadCrumb={allInOnePropertyBreadCrumb} propertyId={propertyId} selectedBrand={selectedBrand} setFormOpen={setFormOpen} tab={tab} />}
									</div>
								</Tab.Pane>}
								{propertyId && <Tab.Pane eventKey="guestComfort">
									<div style={navContentStyle}>
										{selectedProperty?.propertyStatus === PropertyStatus.inprogress || !isPropertyModuleAvailable(selectedBrand.key ? selectedBrand.key : selectedBrand?._id, propertyId, UserAccess.property_access.details.code) ? <AccessDenied /> : <GuestComfortDetailForm allInOnePropertyBreadCrumb={allInOnePropertyBreadCrumb} propertyId={propertyId} selectedBrand={selectedBrand} setFormOpen={setFormOpen} tab={tab} />}
									</div>
								</Tab.Pane>}
								{propertyId && <Tab.Pane eventKey="propertySustainability">
									<div style={navContentStyle}>
										{selectedProperty?.propertyStatus === PropertyStatus.inprogress || !isPropertyModuleAvailable(selectedBrand.key ? selectedBrand.key : selectedBrand?._id, propertyId, UserAccess.property_access.details.code) ? <AccessDenied /> : <PropertySustainability allInOnePropertyBreadCrumb={allInOnePropertyBreadCrumb} brandId={selectedBrand.key ? selectedBrand.key : selectedBrand?._id} propertyId={propertyId} selectedBrand={selectedBrand} setFormOpen={setFormOpen} tab={tab} />}
									</div>
								</Tab.Pane>}
								{propertyId && <Tab.Pane eventKey="propertyUsers">
									<div style={navContentStyle}>
										{selectedProperty?.propertyStatus === PropertyStatus.inprogress || !isPropertyModuleAvailable(selectedBrand.key ? selectedBrand.key : selectedBrand?._id, propertyId, UserAccess.property_access.users.code) ? <AccessDenied /> : <PropertyUsersList allInOnePropertyBreadCrumb={allInOnePropertyBreadCrumb} brandId={selectedBrand.key ? selectedBrand.key : selectedBrand?._id} propertyId={propertyId} selectedBrand={selectedBrand} setFormOpen={setFormOpen} tab={tab} />}
									</div>
								</Tab.Pane>}
								{propertyId && <Tab.Pane eventKey="propertyFinance">
									<div style={navContentStyle}>
										{selectedProperty?.propertyStatus === PropertyStatus.inprogress || !isPropertyModuleAvailable(selectedBrand.key ? selectedBrand.key : selectedBrand?._id, propertyId, UserAccess.property_access.details.code) ? <AccessDenied /> : <PropertyFinance allInOnePropertyBreadCrumb={allInOnePropertyBreadCrumb} brandId={selectedBrand.key ? selectedBrand.key : selectedBrand?._id} propertyId={propertyId} setFormOpen={setFormOpen} selectedBrand={selectedBrand} tab={tab} />}
									</div>
								</Tab.Pane>}
								{propertyId && <Tab.Pane eventKey="audit">
									<div style={navContentStyle}>
										{/* {isPropertyModuleAvailable(selectedBrand.key, propertyId, UserAccess.property_access.audit.code) ? <AuditHome allInOnePropertyBreadCrumb={allInOnePropertyBreadCrumb} extId={extId} /> : <AccessDenied />} */}
										{selectedProperty?.propertyStatus === PropertyStatus.inprogress || !isPropertyModuleAvailable(selectedBrand.key ? selectedBrand.key : selectedBrand?._id, propertyId, UserAccess.property_access.audit.code) ? <AccessDenied /> : <AuditQuestions allInOnePropertyBreadCrumb={allInOnePropertyBreadCrumb} selectedBrand={selectedBrand} extId={extId} tab={tab} />}
									</div>
								</Tab.Pane>}
								{propertyId && <Tab.Pane eventKey="images">
									<div style={navContentStyle}>
										{selectedProperty?.propertyStatus === PropertyStatus.inprogress || !isPropertyModuleAvailable(selectedBrand.key ? selectedBrand.key : selectedBrand?._id, propertyId, UserAccess.property_access.details.code) ? <AccessDenied /> : <BuildingImages allInOnePropertyBreadCrumb={allInOnePropertyBreadCrumb} brandId={selectedBrand.key ? selectedBrand.key : selectedBrand?._id} propertyId={propertyId} setFormOpen={setFormOpen} selectedBrand={selectedBrand} tab={tab} />}
									</div>
								</Tab.Pane>}
								{propertyId && <Tab.Pane eventKey="amenities">
									<div style={navContentStyle}>
										{selectedProperty?.propertyStatus === PropertyStatus.inprogress || !isPropertyModuleAvailable(selectedBrand.key ? selectedBrand.key : selectedBrand?._id, propertyId, UserAccess.property_access.details.code) ? <AccessDenied /> : <BuildingAmenities allInOnePropertyBreadCrumb={allInOnePropertyBreadCrumb} brandId={selectedBrand.key ? selectedBrand.key : selectedBrand?._id} propertyId={propertyId} setFormOpen={setFormOpen} selectedBrand={selectedBrand} tab={tab} />}
									</div>
								</Tab.Pane>}
								{propertyId && <Tab.Pane eventKey="checkIn">
									<div style={navContentStyle}>
										{selectedProperty?.propertyStatus === PropertyStatus.inprogress || !isPropertyModuleAvailable(selectedBrand.key ? selectedBrand.key : selectedBrand?._id, propertyId, UserAccess.property_access.details.code) ? <AccessDenied /> : <PropertyCheckInProcess allInOnePropertyBreadCrumb={allInOnePropertyBreadCrumb} brandId={selectedBrand.key ? selectedBrand.key : selectedBrand?._id} propertyId={propertyId} setFormOpen={setFormOpen} selectedBrand={selectedBrand} tab={tab} />}
									</div>
								</Tab.Pane>}


								{propertyId && isAccess && <Tab.Pane eventKey="inventory">
									<div style={navContentStyle}>
										<AccessDenied />
									</div>
								</Tab.Pane>}
							</Tab.Content>
						</Col>
					</Row>
				</Tab.Container>
			</div>
		</>

	)
};

export default PropertyForm;