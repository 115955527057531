import React, { useCallback, useEffect, useState } from 'react';
// import { Card, Typography } from 'antd';
import GoogleMap from '../../../../../common/GoogleMap';
import { Col, Row } from 'react-bootstrap';
// import social from '../../../../../../images/icons/svg/social-icon.svg'
import locations from '../../../../../../images/icons/svg/location.svg';
import global from '../../../../../../images/icons/svg/global.svg';

import calculator from '../../../../../../images/calculator.png'
import building from '../../../../../../images/building.png';
import calender from '../../../../../../images/calender.png';
import moment from 'moment';
import { Link } from 'react-router-dom';
import OperatorGoogleMap from '../../../../../common/OperatorGoogleMap';

// const { Text } = Typography

// const cardStyle = {
//     width: 300,
//     textAlign: 'center' as const,
//     background: '#F2F2F2',
//     borderRadius: '30px',
// };


const ClientAboutDetails: React.FC<any> = ({ brand }): JSX.Element => {
    /////////// local state manage /////////
    const [location, setLocation] = useState<any>();

    useEffect(() => {
        if (brand) {
            // console.log(brand);
            setLocation({ lat: Number(brand?.latitude), lng: Number(brand?.longitude), brandName: brand?.name });
        }
    }, [brand]);

    // const apartment = useCallback(() => {
    //     let apartmentCounting = 0;
    //     brand?.properties?.forEach(property => {
    //         property?.inventories?.forEach((inventory) => {
    //             apartmentCounting += inventory.noOfApartments;
    //         });
    //     });

    //     return apartmentCounting;
    // }, [brand]);

    return (
        <>
            {/* Stats Cards */}
            <div className='row gtr-3 about_stats'>
                <div className='col-md-4 col-sm-6'>
                    <div className='stat_crd_op stat_prg'>
                        <img src={calender} alt='icon' />
                        <div className='stat_ctnt_op'>
                            <div className='nmbr'>
                                {moment(brand?.yearCommencedTrading).format('YYYY')}
                            </div>
                            <h6>Commenced Trading</h6>
                        </div>
                    </div>
                </div>
                {/* <div className='col-md-4 col-sm-6'>
                    <div className='stat_crd_op stat_prg'>
                        <img src={building} alt='icon' />
                        <div className='stat_ctnt'>
                            <div className='nmbr'>
                                {brand?.properties?.length}
                            </div>
                            <h6>Total Properties</h6>
                        </div>
                    </div>
                </div> */}
                {/* <div className='col-md-4 col-sm-6'>
                    <div className='stat_crd_op stat_prg'>
                        <img src={calculator} alt='icon' />
                        <div className='stat_ctnt'>
                            <div className='nmbr'>
                                {apartment()}
                            </div>
                            <h6>Total Apartments</h6>
                        </div>
                    </div>
                </div> */}
            </div>

            <Row className="mt-4">
                <Col md={6}>
                    <div className='about_box'>
                        <div className='about_txt'>
                            <h3 className="fz-24 mb-4">About us</h3>
                            <div style={{ fontSize: '18px', fontWeight: 'bold' }} dangerouslySetInnerHTML={{ __html: brand?.description }} />
                        </div>
                        <div className='cntct_box mt-3'>
                            <div className='cntct_box_ctnt'>
                                <ul className='cntct_box_list'>
                                    <li>
                                        <img src={locations} alt="location" />
                                        <div>{`${brand?.address1}, ${brand?.address2}, ${brand?.country}, ${brand?.postcode}`}</div>
                                    </li>
                                    <li>
                                        <Link to={`${brand?.website}`} target={`_blank`}>
                                            <img src={global} alt="global" />
                                            <div>{`${brand?.website ? brand.website : ''}`}</div>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col md={6}>
                    <h3 className="fz-24 mb-4">Head Office</h3>
                    {
                        location && <OperatorGoogleMap
                            googleMapURL={process.env.REACT_APP_MAP_URL}
                            loadingElement={<div style={{ height: `100%` }} />}
                            containerElement={<div style={{ height: `500px` }} />}
                            mapElement={<div style={{ height: `100%` }} />}
                            location={location}
                        />
                    }

                </Col>
            </Row>
        </>
    );

}

export default ClientAboutDetails;