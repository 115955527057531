import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useStoreState } from 'easy-peasy';
import { Link } from "react-router-dom";

const StatsCardsSuperAdmin: React.FC<any> = ({ activeTab }): JSX.Element => {
    const [outstandingInvitesCount, setOutstandingInvitesCount] = useState<number>(0);
    const [awaitApprovalInvitesCount, setAwaitApprovalInvitesCount] = useState<number>(0);
    const [incompleteBrandsCount, setIncompleteBrandsCount] = useState<number>(0);
    const [awaitApprovalBrandsCount, setAwaitApprovalBrandsCount] = useState<number>(0);
    const [reviewBrandsCount, setReviewBrandsCount] = useState<number>(0);
    const [incompletePropertiesCount, setIncompletePropertiesCount] = useState<number>(0);
    const [awaitApprovalPropertiesCount, setAwaitApprovalPropertiesCount] = useState<number>(0);
    const [reviewPropertiesCount, setReviewPropertiesCount] = useState<number>(0);
    const [notStartedAuditCount, setNotStartedAuditCount] = useState<number>(0);
    const [inProgressAuditsCount, setInProgressAuditCount] = useState<number>(0);
    const [awaitApprovalAuditsCount, setAwaitApprovalAuditsCount] = useState<number>(0);
    const [todayCountStartDate, setTodayCountStartDate] = useState<number>(0);
    const [todayCountEndDate, setTodayCountEndDate] = useState<number>(0);
    const [tomorrowCount, setTomorrowCount] = useState<number>(0);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [next5DaysCount, setNext5DaysCount] = useState<number>(0);
    const [pendingCount, setPendingCount] = useState<number>(0);
    const [completedCount, setCompletedCount] = useState<number>(0);
    const [readyToReleaseCount, setReadyToReleaseCount] = useState<number>(0);
    const [releaseCount, setReleaseCount] = useState<number>(0);
    const [outstandingCount, setOutstandingCount] = useState<number>(0);
    const [completeProposalCount, setCompleteProposalCount] = useState<number>(0);
    const [reviewProposalCount, setReviewProposalCount] = useState<number>(0);
    const [bookingPendingCount, setBookingPendingCount] = useState<number>(0);
    const [bookingDueCount, setBookingDueCount] = useState<number>(0);
    const [bookingOverDueCount, setBookingOverDueCount] = useState<number>(0);
    const [offeredCount, setOfferedCount] = useState<number>(0);
    const [expireDueCount, setExpireDueCount] = useState<number>(0);
    const [clientRequestCount, setClientRequestCount] = useState<number>(0);
    const [requestsReceivedAmend, setRequestsReceivedAmend] = useState<number>(0);
    const [outstandingAmend, setOutstandingAmend] = useState<number>(0);
    const [overdueAmend, setOverdueAmend] = useState<number>(0);
    const [requestsReceivedCancel, setRequestsReceivedCancel] = useState<number>(0);
    const [outstandingCancel, setOutstandingCancel] = useState<number>(0);
    const [overdueCancel, setOverdueCancel] = useState<number>(0);

    const { superAdminDashboardSuccessOnboarding, superAdminDashboardErrorOnboarding, superAdminDashboardSuccessRfpManagement, superAdminDashboardErrorRfpManagement, superAdminDashboardSuccessProposalManagement, superAdminDashboardErrorProposalManagement, superAdminDashboardSuccessBookingManagement, superAdminDashboardErrorBookingManagement } = useStoreState<any>((state) => ({
        superAdminDashboardSuccessOnboarding: state.DashboardModel.superAdminDashboardSuccessOnboarding,
        superAdminDashboardErrorOnboarding: state.DashboardModel.superAdminDashboardErrorOnboarding,
        superAdminDashboardSuccessRfpManagement: state.DashboardModel.superAdminDashboardSuccessRfpManagement,
        superAdminDashboardErrorRfpManagement: state.DashboardModel.superAdminDashboardErrorRfpManagement,
        superAdminDashboardSuccessProposalManagement: state.DashboardModel.superAdminDashboardSuccessProposalManagement,
        superAdminDashboardErrorProposalManagement: state.DashboardModel.superAdminDashboardErrorProposalManagement,
        superAdminDashboardSuccessBookingManagement: state.DashboardModel.superAdminDashboardSuccessBookingManagement,
        superAdminDashboardErrorBookingManagement: state.DashboardModel.superAdminDashboardErrorBookingManagement
    }));
    useEffect(() => {
        if (activeTab === 'Onboarding') {
            if (superAdminDashboardErrorOnboarding) {
                toast.error(superAdminDashboardErrorOnboarding.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'foo-bar'
                });
            }
            if (superAdminDashboardSuccessOnboarding) {
                setOutstandingInvitesCount(superAdminDashboardSuccessOnboarding?.data?.getBrandSetUpCount?.notStartedCount);
                setAwaitApprovalInvitesCount(superAdminDashboardSuccessOnboarding?.data?.getBrandSetUpCount?.inviteSentCount);
                setIncompleteBrandsCount(superAdminDashboardSuccessOnboarding?.data?.getAllBrandCount?.incompleteCount);
                setAwaitApprovalBrandsCount(superAdminDashboardSuccessOnboarding?.data?.getAllBrandCount?.pendingCount);
                setReviewBrandsCount(superAdminDashboardSuccessOnboarding?.data?.getAllBrandCount?.reviewCount);
                setIncompletePropertiesCount(superAdminDashboardSuccessOnboarding?.data?.getAllPropertiesCount?.incompleteCount);
                setAwaitApprovalPropertiesCount(superAdminDashboardSuccessOnboarding?.data?.getAllPropertiesCount?.awaitApprovalCount);
                setReviewPropertiesCount(superAdminDashboardSuccessOnboarding?.data?.getAllPropertiesCount?.reviewCount);
                setNotStartedAuditCount(superAdminDashboardSuccessOnboarding?.data?.getAllAuditPropertiesCount?.notStartedCount);
                setInProgressAuditCount(superAdminDashboardSuccessOnboarding?.data?.getAllAuditPropertiesCount?.inProgressCount);
                setAwaitApprovalAuditsCount(superAdminDashboardSuccessOnboarding?.data?.getAllAuditPropertiesCount?.awaitingApprovalCount);
            }
        }
        else if (activeTab === 'RFP Management') {
            if (superAdminDashboardErrorRfpManagement) {
                toast.error(superAdminDashboardErrorRfpManagement.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'foo-bar'
                });
            }
            if (superAdminDashboardSuccessRfpManagement) {
                setTodayCountStartDate(superAdminDashboardSuccessRfpManagement?.data?.getAllReleaseDueRFPCount?.todayCountStartDate);
                setNext5DaysCount(superAdminDashboardSuccessRfpManagement?.data?.getAllReleaseDueRFPCount?.next5DaysCount);
                setTodayCountEndDate(superAdminDashboardSuccessRfpManagement?.data?.getAllInProgressRFPCount?.todayCountEndDate);
                setTomorrowCount(superAdminDashboardSuccessRfpManagement?.data?.getAllInProgressRFPCount?.tomorrowCount);
                setTotalCount(superAdminDashboardSuccessRfpManagement?.data?.getAllInProgressRFPCount?.totalCount);
                setPendingCount(superAdminDashboardSuccessRfpManagement?.data?.getAllRFPSurveyCount?.pendingCount);
                setCompletedCount(superAdminDashboardSuccessRfpManagement?.data?.getAllRFPSurveyCount?.completedCount);
                setReadyToReleaseCount(superAdminDashboardSuccessRfpManagement?.data?.getRFPAdminReviewCount?.readyToReleaseCount);
                setOutstandingCount(superAdminDashboardSuccessRfpManagement?.data?.getRFPAdminReviewCount?.outstandingCount);
            }
        }

        else if (activeTab === 'Proposal Management') {
            if (superAdminDashboardErrorProposalManagement) {
                toast.error(superAdminDashboardErrorProposalManagement.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'foo-bar'
                });
            }
            if (superAdminDashboardSuccessProposalManagement) {
                setReadyToReleaseCount(superAdminDashboardSuccessProposalManagement?.data?.getRFPAdminReviewCount?.readyToReleaseCount);
                setReleaseCount(superAdminDashboardSuccessProposalManagement?.data?.getRFPReportDataCount?.releaseStatusCount);
                setOutstandingCount(superAdminDashboardSuccessProposalManagement?.data?.getRFPAdminReviewCount?.outstandingCount);
                setCompleteProposalCount(superAdminDashboardSuccessProposalManagement?.data?.getRFPProposalDataCount?.completedCount);
                setReviewProposalCount(superAdminDashboardSuccessProposalManagement?.data?.getReviewPropertyDataCount?.reviewedCount);
            }
        }
        else if (activeTab === 'Booking Management') {
            if (superAdminDashboardErrorBookingManagement) {
                toast.error(superAdminDashboardErrorRfpManagement.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    className: 'foo-bar'
                });
            }
            if (superAdminDashboardSuccessBookingManagement) {
                setBookingPendingCount(superAdminDashboardSuccessBookingManagement?.data?.getRequestBookingCount?.bookingPendingCount);
                setBookingDueCount(superAdminDashboardSuccessBookingManagement?.data?.getRequestBookingCount?.bookingDueCount);
                setBookingOverDueCount(superAdminDashboardSuccessBookingManagement?.data?.getRequestBookingCount?.bookingOverDueCount);
                setOfferedCount(superAdminDashboardSuccessBookingManagement?.data?.getRequestBookingOffersCount?.offeredCount);
                setExpireDueCount(superAdminDashboardSuccessBookingManagement?.data?.getRequestBookingOffersCount?.expireDueCount);
                setClientRequestCount(superAdminDashboardSuccessBookingManagement?.data?.getRequestBookingOffersCount?.clientRequestCount);
                setRequestsReceivedAmend(superAdminDashboardSuccessBookingManagement?.data?.getAmendmentsBookingCount?.requestsReceivedAmend);
                setOutstandingAmend(superAdminDashboardSuccessBookingManagement?.data?.getAmendmentsBookingCount?.outstandingAmend);
                setOverdueAmend(superAdminDashboardSuccessBookingManagement?.data?.getAmendmentsBookingCount?.overdueAmend);
                setRequestsReceivedCancel(superAdminDashboardSuccessBookingManagement?.data?.getCancellationBookingCount?.requestsReceivedCancel);
                setOutstandingCancel(superAdminDashboardSuccessBookingManagement?.data?.getCancellationBookingCount?.outstandingCancel);
                setOverdueCancel(superAdminDashboardSuccessBookingManagement?.data?.getCancellationBookingCount?.overdueCancel);
            }
        }
    }, [activeTab, superAdminDashboardErrorBookingManagement, superAdminDashboardErrorOnboarding, superAdminDashboardErrorProposalManagement, superAdminDashboardErrorRfpManagement, superAdminDashboardSuccessBookingManagement, superAdminDashboardSuccessOnboarding, superAdminDashboardSuccessProposalManagement, superAdminDashboardSuccessRfpManagement]);


    const getClassNames = (iscolorRequired, count, ...otherCounts) => {
        let classNames = '';
        if (iscolorRequired) {
            if (count > 0) classNames += 'mysa-pink-text ';
        }
        if ([count, ...otherCounts].every(c => c < 10)) classNames += 'single-number';

        return classNames.trim();
    };
    return (
        <>
            <div className='tabsection-superadmin'>
                <ul className='tabcontent'>
                    {activeTab === 'Onboarding' && (
                        <li className={activeTab === 'Onboarding' ? 'active' : ''}>
                            <div className='gtr-3 mt-5 brand_card_sec row  brand_overview'>
                                <div className="card_box_sec col-md-3" >
                                    <Link className="stat_crd stat_prg stat_crd_charcoal" to={{ pathname: "/admin/brand-management", state: { tabKey: 'brand' } }} >
                                        <h6>Set-Up</h6>
                                        <div className="stat_ctnt">
                                            <ul className="booking_request_sec">
                                                <li><span className={getClassNames(false, outstandingInvitesCount, awaitApprovalInvitesCount)}>{outstandingInvitesCount}</span> Not started </li>
                                                <li className={awaitApprovalInvitesCount > 0 ? 'mysa-pink-text' : ''} ><span className={getClassNames(true, awaitApprovalInvitesCount, outstandingInvitesCount)}>{awaitApprovalInvitesCount}</span> Invite sent </li>
                                            </ul>
                                        </div>

                                    </Link>
                                </div>
                                <div className="card_box_sec col-md-3">
                                    <Link className="stat_crd stat_prg stat_crd_charcoal" to={{ pathname: "/admin/brand-management", state: { tabKey: 'brand' } }} >
                                        <h6>Brands</h6>
                                        <div className="stat_ctnt">
                                            <ul className="booking_request_sec">
                                                <li><span className={getClassNames(false, incompleteBrandsCount, awaitApprovalBrandsCount, reviewBrandsCount)}>{incompleteBrandsCount}</span> Incomplete </li>
                                                <li className={awaitApprovalBrandsCount > 0 ? 'mysa-pink-text' : ''}><span className={getClassNames(true, awaitApprovalBrandsCount, incompleteBrandsCount, reviewBrandsCount)}>{awaitApprovalBrandsCount}</span> Await approval </li>
                                                <li className={reviewBrandsCount > 0 ? 'mysa-pink-text' : ''}><span className={getClassNames(true, reviewBrandsCount, awaitApprovalBrandsCount, incompleteBrandsCount)}>{reviewBrandsCount}</span> Review </li>
                                            </ul>
                                        </div>
                                    </Link>
                                </div>
                                <div className="card_box_sec col-md-3">
                                    <Link className="stat_crd stat_prg stat_crd_charcoal" to={{ pathname: "/admin/brand-management", state: { tabKey: 'properties' } }} >
                                        <h6>Properties</h6>
                                        <div className="stat_ctnt">
                                            <ul className="booking_request_sec">
                                                <li><span className={getClassNames(false, incompletePropertiesCount, awaitApprovalPropertiesCount, reviewPropertiesCount)}>{incompletePropertiesCount}</span> Incomplete </li>
                                                <li className={awaitApprovalPropertiesCount > 0 ? 'mysa-pink-text' : ''}><span className={getClassNames(true, awaitApprovalPropertiesCount, incompletePropertiesCount, reviewPropertiesCount)}>{awaitApprovalPropertiesCount}</span> Await approval </li>
                                                <li className={reviewPropertiesCount > 0 ? 'mysa-pink-text' : ''}><span className={getClassNames(true, reviewPropertiesCount, incompletePropertiesCount, awaitApprovalPropertiesCount)}>{reviewPropertiesCount}</span> Review </li>
                                            </ul>
                                        </div>
                                    </Link>
                                </div>
                                <div className="card_box_sec col-md-3">
                                    <Link className="stat_crd stat_prg stat_crd_charcoal" to={{ pathname: "/admin/brand-management", state: { tabKey: 'properties' } }} >
                                        <h6>Audits</h6>
                                        <div className="stat_ctnt">
                                            <ul className="booking_request_sec">
                                                <li><span className={getClassNames(false, notStartedAuditCount, inProgressAuditsCount, awaitApprovalAuditsCount)}>{notStartedAuditCount}</span> Not started </li>
                                                <li><span className={getClassNames(false, inProgressAuditsCount, awaitApprovalAuditsCount, notStartedAuditCount)}>{inProgressAuditsCount}</span> In progress </li>
                                                <li className={awaitApprovalAuditsCount > 0 ? 'mysa-pink-text' : ''}><span className={getClassNames(true, awaitApprovalAuditsCount, inProgressAuditsCount, notStartedAuditCount)}>{awaitApprovalAuditsCount}</span> Await approval </li>
                                            </ul>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </li>
                    )}
                    {activeTab === 'RFP Management' && (
                        <li className={activeTab === 'RFP Management' ? 'active' : ''}>
                            <div className='gtr-3 mt-5 brand_card_sec row  brand_overview'>
                                <div className="card_box_sec col-md-3" >
                                    <Link className="stat_crd stat_prg stat_crd_charcoal" to={{ pathname: "/admin/client-management", state: { defaultKey: 'activeRFPs' } }} >
                                        <h6>Release Due</h6>
                                        <div className="stat_ctnt">
                                            <ul className="booking_request_sec">
                                                <li><span className={getClassNames(false, next5DaysCount, todayCountStartDate)}>{next5DaysCount}</span> Next 5 days  </li>
                                                <li className={todayCountStartDate > 0 ? 'mysa-pink-text' : ''}><span className={getClassNames(true, todayCountStartDate, next5DaysCount)}>{todayCountStartDate}</span> Today </li>
                                            </ul>
                                        </div>

                                    </Link>
                                </div>
                                <div className="card_box_sec col-md-3">
                                    <Link className="stat_crd stat_prg stat_crd_charcoal" to={{ pathname: "/admin/client-management", state: { defaultKey: 'activeRFPs' } }} >
                                        <h6>RFP Surveys</h6>
                                        <div className="stat_ctnt">
                                            <ul className="booking_request_sec">
                                                <li className={pendingCount > 0 ? 'mysa-pink-text' : ''}><span className={getClassNames(true, pendingCount, completedCount)}>{pendingCount}</span> Outstanding </li>
                                                <li className={completedCount > 0 ? 'mysa-pink-text' : ''}><span className={getClassNames(true, completedCount, pendingCount)}>{completedCount}</span> Await activation </li>
                                            </ul>
                                        </div>
                                    </Link>
                                </div>
                                <div className="card_box_sec col-md-3">
                                    <Link className="stat_crd stat_prg stat_crd_charcoal" to={{ pathname: "/admin/client-management", state: { tabKey: 'activeRFPs' } }} >
                                        <h6>In Progress</h6>
                                        <div className="stat_ctnt">
                                            <ul className="booking_request_sec">
                                                <li><span className={getClassNames(false, totalCount, tomorrowCount, todayCountEndDate)}>{totalCount}</span> Live RFPs </li>
                                                <li className={tomorrowCount > 0 ? 'mysa-pink-text' : ''}><span className={getClassNames(true, tomorrowCount, totalCount, todayCountEndDate)}>{tomorrowCount}</span> End tomorrow </li>
                                                <li className={todayCountEndDate > 0 ? 'mysa-pink-text' : ''}><span className={getClassNames(true, todayCountEndDate, totalCount, tomorrowCount)}>{todayCountEndDate}</span> End today </li>
                                            </ul>
                                        </div>
                                    </Link>
                                </div>
                                <div className="card_box_sec col-md-3">
                                    <Link className="stat_crd stat_prg stat_crd_charcoal" to={{ pathname: "/admin/client-management", state: { tabKey: 'activeRFPs' } }} >
                                        <h6>RFP Reports</h6>
                                        <div className="stat_ctnt">
                                            <ul className="booking_request_sec">
                                                <li className={outstandingCount > 0 ? 'mysa-pink-text' : ''}><span className={getClassNames(true, outstandingCount, readyToReleaseCount)}>{outstandingCount}</span> Outstanding </li>
                                                <li className={readyToReleaseCount > 0 ? 'mysa-pink-text' : ''}><span className={getClassNames(true, readyToReleaseCount, outstandingCount)}>{readyToReleaseCount}</span> Ready to release </li>
                                            </ul>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </li>
                    )}

                    {activeTab === 'Proposal Management' && (
                        <li className={activeTab === 'Proposal Management' ? 'active' : ''}>
                            <div className='gtr-3 mt-5 brand_card_sec row  brand_overview'>
                                <div className="card_box_sec col-md-3">
                                    <Link className="stat_crd stat_prg stat_crd_charcoal" to={{ pathname: "/admin/client-management", state: { tabKey: 'activeRFPs' } }} >
                                        <h6>RFP Reports</h6>
                                        <div className="stat_ctnt">
                                            <ul className="booking_request_sec">
                                                <li className={outstandingCount > 0 ? 'mysa-pink-text' : ''}><span className={getClassNames(true, outstandingCount, readyToReleaseCount)}>{outstandingCount}</span> Outstanding </li>
                                                <li className={readyToReleaseCount > 0 ? 'mysa-pink-text' : ''}><span className={getClassNames(true, readyToReleaseCount, outstandingCount)}>{readyToReleaseCount}</span> Ready to release </li>
                                            </ul>
                                        </div>
                                    </Link>
                                </div>
                                <div className="card_box_sec col-md-3" >
                                    <Link className="stat_crd stat_prg stat_crd_charcoal" to={{ pathname: "/admin/client-management", state: { defaultKey: 'activeRFPs' } }} >
                                        <h6>Active Proposals</h6>
                                        <div className="stat_ctnt">
                                            <ul className="booking_request_sec">
                                                <li><span className={getClassNames(false, releaseCount, completeProposalCount)}>{releaseCount}</span> <strong>On going </strong></li>
                                                <li className={completeProposalCount > 0 ? 'mysa-green-text' : ''}><span className={getClassNames(true, completeProposalCount, releaseCount)}>{completeProposalCount}</span> Complete </li>
                                            </ul>
                                        </div>

                                    </Link>
                                </div>
                                <div className="card_box_sec col-md-3">
                                    <Link className="stat_crd stat_prg stat_crd_charcoal" to={{ pathname: "/admin/client-management", state: { defaultKey: 'activeRFPs' } }} >
                                        <h6>Review</h6>
                                        <div className="stat_ctnt">
                                            <ul className="booking_request_sec">
                                                <li><span className={getClassNames(false, reviewProposalCount)}>{reviewProposalCount}</span> Awaiting Review </li>
                                            </ul>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </li>
                    )}
                    {activeTab === 'Booking Management' && (
                        <li className={activeTab === 'Booking Management' ? 'active' : ''}>
                            <div className='gtr-3 mt-5 brand_card_sec row  brand_overview'>
                                <div className="card_box_sec col-md-3" >
                                    <Link className="stat_crd stat_prg stat_crd_charcoal" to={{ pathname: "/admin/super-admin-booking-reports", state: { defaultKey: 'requestBooking' } }} >
                                        <h6>On Request</h6>
                                        <div className="stat_ctnt">
                                            <ul className="booking_request_sec">
                                                <li><span className={getClassNames(false, bookingPendingCount, bookingDueCount, bookingOverDueCount)}>{bookingPendingCount}</span> New requests  </li>
                                                <li className={bookingDueCount > 0 ? 'mysa-pink-text' : ''}><span className={getClassNames(true, bookingDueCount, bookingPendingCount, bookingOverDueCount)}>{bookingDueCount}</span> Due (15 mins) </li>
                                                <li className={bookingOverDueCount > 0 ? 'mysa-pink-text' : ''}><span className={getClassNames(true, bookingOverDueCount, bookingDueCount, bookingPendingCount)}>{bookingOverDueCount}</span> Overdue </li>
                                            </ul>
                                        </div>
                                    </Link>
                                </div>
                                <div className="card_box_sec col-md-3">
                                    <Link className="stat_crd stat_prg stat_crd_charcoal" to={{ pathname: "/admin/super-admin-booking-reports", state: { defaultKey: 'requestBooking' } }} >
                                        <h6>Booking Offers</h6>
                                        <div className="stat_ctnt">
                                            <ul className="booking_request_sec">
                                                <li><span className={getClassNames(false, offeredCount, clientRequestCount, expireDueCount)}>{offeredCount}</span> Await decision </li>
                                                <li className={expireDueCount > 0 ? 'mysa-pink-text' : ''}><span className={getClassNames(true, expireDueCount, offeredCount, clientRequestCount)}>{expireDueCount}</span> Expire due (3hrs) </li>
                                            </ul>
                                        </div>
                                    </Link>
                                </div>
                                <div className="card_box_sec col-md-3">
                                    <Link className="stat_crd stat_prg stat_crd_charcoal" to={{ pathname: "/admin/super-admin-booking-reports", state: { defaultKey: 'bookings' } }} >
                                        <h6>Amendments</h6>
                                        <div className="stat_ctnt">
                                            <ul className="booking_request_sec">
                                                <li><span className={getClassNames(false, requestsReceivedAmend, outstandingAmend, overdueAmend)}>{requestsReceivedAmend}</span> Requests received </li>
                                                <li className={outstandingAmend > 0 ? 'mysa-pink-text' : ''}><span className={getClassNames(true, outstandingAmend, requestsReceivedAmend, overdueAmend)}>{outstandingAmend}</span> Outstanding (24hr) </li>
                                                <li className={overdueAmend > 0 ? 'mysa-pink-text' : ''}><span className={getClassNames(true, overdueAmend, requestsReceivedAmend, outstandingAmend)}>{overdueAmend}</span> Overdue (48hr) </li>
                                            </ul>
                                        </div>
                                    </Link>
                                </div>
                                <div className="card_box_sec col-md-3">
                                    <Link className="stat_crd stat_prg stat_crd_charcoal" to={{ pathname: "/admin/super-admin-booking-reports", state: { defaultKey: 'bookings' } }} >
                                        <h6>Cancellations</h6>
                                        <div className="stat_ctnt">
                                            <ul className="booking_request_sec">
                                                <li><span className={getClassNames(false, requestsReceivedCancel, outstandingCancel, overdueCancel)}>{requestsReceivedCancel}</span> Requests received </li>
                                                <li className={outstandingCancel > 0 ? 'mysa-pink-text' : ''}><span className={getClassNames(true, outstandingCancel, requestsReceivedCancel, overdueCancel)}>{outstandingCancel}</span> Outstanding (3hr) </li>
                                                <li className={overdueCancel > 0 ? 'mysa-pink-text' : ''}><span className={getClassNames(true, overdueCancel, requestsReceivedCancel, outstandingCancel)}>{overdueCancel}</span> Overdue (24hr) </li>
                                            </ul>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </li>
                    )}
                </ul>
            </div>
        </>
    )
}

export default StatsCardsSuperAdmin;