import { useStoreActions, useStoreState } from 'easy-peasy';
import React, { useCallback, useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';


const ConsiderationView: React.FC<any> = ({ propertyId }): JSX.Element => {
    const [considerationData, setConsiderationData] = useState<any>([]);

    /////////////////////////// global state manage (actions)//////////////////////////////////////////////////////
    const { getPropertySustainabilityByPropertyId } = useStoreActions<any>((actions) => ({
        getPropertySustainabilityByPropertyId: actions.property.getPropertySustainabilityByPropertyId,
    }));

    /////////////////////////// global state manage (state)//////////////////////////////////////////////////////
    const {
        getPropertySustainabilityByPropertyIdSuccess,
    } = useStoreState<any>((state) => ({
        getPropertySustainabilityByPropertyIdSuccess: state.property.getPropertySustainabilityByPropertyIdSuccess,
    }));


    //////////////////////////////// call apis using actions //////////////////////////////////////////////////////
    useEffect(() => {
        if (propertyId) {
            getPropertySustainabilityByPropertyId(propertyId);
        }

    }, [getPropertySustainabilityByPropertyId, propertyId]);


    //////////////////////////////// get api response using state //////////////////////////////////////////////////////
    useEffect(() => {
        if (getPropertySustainabilityByPropertyIdSuccess) {
            if (getPropertySustainabilityByPropertyIdSuccess.data) {
                const data = getPropertySustainabilityByPropertyIdSuccess?.data;
                const energyListTemp: any = [
                    { label: 'Approved or accredited for sustainability by an independent body ', value: data?.isPropertyApprovedOrAccreditedForSustainabilityByIndependentBody },
                    data?.isPropertyApprovedOrAccreditedForSustainabilityByIndependentBody === 'Yes' && data?.pleaseAdviceWhichIndependentBody ? { label: 'Details', value: data?.pleaseAdviceWhichIndependentBody } : null,
                    { label: 'Options to recycle waste products', value: data?.doYouDisplayProminentOptions },
                    { label: 'Travel to another building to collect keys', value: data?.areGuestsToTravelAnotherBuilding },
                    data?.areGuestsToTravelAnotherBuilding === 'Yes' && data?.wouldGuestAbleToWalkToBuilding ? { label: 'walk to this building in less then 2 minutes', value: data?.wouldGuestAbleToWalkToBuilding } : null,
                    { label: 'Building close to public transport', value: data?.isYourBuildingClosePublicTransport },
                    data?.isYourBuildingClosePublicTransport === 'Yes' && data?.whatIsPublicTransportEasyReach ? { label: 'Transport options', value: data?.whatIsPublicTransportEasyReach } : null,
                ];
                const filteredData = energyListTemp.filter(item => item !== null && item !== undefined);

                const energyListWithOddEven = filteredData.map((item, index) => ({
                    ...item,
                    number: index % 2 === 0 ? 'odd' : 'even',
                }));
                setConsiderationData(energyListWithOddEven);
            }
        }


    }, [getPropertySustainabilityByPropertyId, getPropertySustainabilityByPropertyIdSuccess]);

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const renderShowTransport = useCallback((data) => {

        if (data) {

            return data.map((at: any, key: number) => {
                return (
                    <>

                        <ul>

                            <Col >

                                <span style={{ marginLeft: '2%' }}>{at}</span>

                            </Col>
                        </ul>

                    </>
                );
            });
        }
    }, []);

    return (
        <>
            {/* <ul className="">
                <li className="list-group-item list-group-item-dark d-flex justify-content-between align-items-center" style={{ height: "50px", padding: "1rem" }}><p >Approved or accredited for sustainability by an independent body </p>
                    <span>Yes</span></li>
                <li className="list-group-item d-flex justify-content-between align-items-center" style={{ height: "50px", padding: "1rem" }}>Details
                    <span>Yes</span>
                </li>
                <li className="list-group-item list-group-item-dark d-flex justify-content-between align-items-center" style={{ height: "50px", padding: "1rem" }}>Options to recycle waste products
                    <span>Yes</span></li>
                <li className="list-group-item d-flex justify-content-between align-items-center" style={{ height: "50px", padding: "1rem" }}>Travel to another building to collect keys
                    <span>No</span></li>
                <li className="list-group-item list-group-item-dark d-flex justify-content-between align-items-center" style={{ height: "50px", padding: "1rem" }}>Building close to public transport
                    <span>Yes</span></li>
                <li className="list-group-item d-flex justify-content-between align-items-center" style={{ height: "50px", padding: "1rem" }}>Transport options
                    <div style={{ marginTop: '40px' }}>
                        <Col style={{ textAlign: "right" }}><span >Bus</span></Col>
                        <Col style={{ textAlign: "right" }}><span >UndergroundSubway</span></Col>
                        <Col style={{ textAlign: "right" }}><span >Train</span></Col>
                    </div>

                </li>
            </ul> */}

            <ul className=''>
                {
                    considerationData.map((data, index) => {
                        return <div>{data?.value && <li className={data?.number === 'odd' ? "list-group-item list-group-item-dark d-flex justify-content-between align-items-center" : "list-group-item d-flex justify-content-between align-items-center"} style={{ padding: "1rem", gap: 15 }}><span>{data?.label}</span>
                            {typeof (data.value) === 'object' ?
                                <div>
                                    <Col style={{ textAlign: "right" }}>
                                        {renderShowTransport(data?.value)}
                                    </Col>
                                </div>


                                : <span>{data?.value}</span>}
                        </li>}
                        </div>
                    })}
            </ul>
        </>


    );

}

export default ConsiderationView;