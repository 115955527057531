import { message } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import ProgrammeList from './ProgrammeList';
import ProgrammeForm from './form/ProgrammeForm';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { getStorage } from '../../../common/storage';
import { Link } from 'react-router-dom';
import { getLoggedUserType } from '../../../common/functions';
import { UserGroups } from '../../../common/constants';
import { Breadcrumb, Button } from 'react-bootstrap';
import { $mysaBlack, loginButton } from '../../../common/components-style';
import { toast } from 'react-toastify';

const ButtonStyle = {
	...loginButton,
	color: $mysaBlack,
	fontWeight: 'bold'
} as React.CSSProperties;

const ProgrammeHome: React.FC<any> = ({ location }): JSX.Element => {
	const [formOpen, setFormOpen] = useState<boolean>(false);
	const [programmes, setProgrammes] = useState<Array<Object> | null>([]);
	const [programmeId, setProgrammeId] = useState<any>(null);
	const [selectedClient, setSelectedClient] = useState<any>(null);
	const [selectedProgramme, setSelectedProgramme] = useState<any>(null);
	const [clientId, setClientId] = useState<any>(null);
	const [programmeName, setProgrammeName] = useState<any>(null);
	// const [loading, setLoading] = useState<boolean>(false);


	////////////////////////////////////////////////////////////////////////////////////////////////////
	const { getCorporateProgrammeByClient, activateCorporateProgramme, createUser, createChiefUserMapping, updateChiefUserMapping, sendProgrammeAdminWelcomeEmail } = useStoreActions<any>((actions) => ({
		getCorporateProgrammeByClient: actions.client.getCorporateProgrammeByClient,
		activateCorporateProgramme: actions.client.activateCorporateProgramme,
		createUser: actions.user.createUser,
		createChiefUserMapping: actions.user.createUserMapping,
		updateChiefUserMapping: actions.user.updateUserMapping,
		sendProgrammeAdminWelcomeEmail: actions.client.sendProgrammeAdminWelcomeEmail
	}));

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const { sendProgrammeAdminWelcomeEmailSuccess, getCorporateProgrammeByClientSuccess, getCorporateProgrammeByClientError, activateCorporateProgrammeSuccess, chiefUserCreate, createChiefUserMappingSuccess, updateChiefUserMappingSuccess } = useStoreState<any>((state) => ({
		getCorporateProgrammeByClientSuccess: state.client.getCorporateProgrammeByClientSuccess,
		getCorporateProgrammeByClientError: state.client.getProgrammeCorporateByClientError,
		activateCorporateProgrammeSuccess: state.client.activateCorporateProgrammeSuccess,
		chiefUserCreate: state.user.userCreate,
		createChiefUserMappingSuccess: state.user.createUserMappingSuccess,
		updateChiefUserMappingSuccess: state.user.updateUserMappingSuccess,
		sendProgrammeAdminWelcomeEmailSuccess: state.client.sendProgrammeAdminWelcomeEmailSuccess
	}));

	/////////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		const client = getStorage('myo_selected_client');
		if (client) {
			setSelectedClient(client);
		}
	}, [])

	////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (!location.state?.selectedProgramme) {
			const params: any = {};
			if (location) {

				params.clientId = location.state?.clientId?._id ? location.state?.clientId?._id : location.state?.clientId;
			}
			getCorporateProgrammeByClient(params);
		}
	}, [getCorporateProgrammeByClient, location]);


	//////////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (getCorporateProgrammeByClientSuccess) {
			setProgrammes(getCorporateProgrammeByClientSuccess.data);
		}

		if (getCorporateProgrammeByClientError) {
			message.success(getCorporateProgrammeByClientError.message);
		}

	}, [location, getCorporateProgrammeByClientSuccess, getCorporateProgrammeByClientError])


	////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (activateCorporateProgrammeSuccess) {
			// setLoading(false);
			const params: any = {};
			if (activateCorporateProgrammeSuccess) {

				params.clientId = activateCorporateProgrammeSuccess?.data?.clientId;
			}
			if (activateCorporateProgrammeSuccess?.data?.programmeStatus === 'accepted' && activateCorporateProgrammeSuccess?.data?.isActivated) {
				const values: any = {};
				values.userGroup = UserGroups.user
				values.email = activateCorporateProgrammeSuccess?.data?.email;
				values.firstName = activateCorporateProgrammeSuccess?.data?.clientContact.split(' ').slice(0, -1).join(' ');
				values.lastName = activateCorporateProgrammeSuccess?.data?.clientContact.split(' ').slice(-1).join(' ');
				if (getStorage('activateProgramme')) {
					createUser(values);
				}
			}
			getCorporateProgrammeByClient(params);
			// toast.success("Corporate programme activated successfully", {
			// 	position: toast.POSITION.BOTTOM_RIGHT,
			// 	className: 'foo-bar'
			// });
			// getCorporateProgrammeById(updateCorporateProgrammeSuccess?.data?._id);
		}
	}, [createUser, getCorporateProgrammeByClient, activateCorporateProgrammeSuccess]);

	////////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {

		// user create success
		if (getStorage('activateProgramme')) {
			if (chiefUserCreate) {
				if (chiefUserCreate.data.data) {

					// create mapping
					createChiefUserMapping({
						userId: chiefUserCreate.data.user._id,
						email: chiefUserCreate.data.user.email,
						clientId,
						programmeId,
						role: UserGroups.CHIEF_PROGRAMME_ADMIN
					})
					if (programmeName) {
						const payload = {
							firstName: chiefUserCreate.data.user?.firstName,
							lastName: chiefUserCreate.data.user?.lastName,
							programmeName: programmeName,
							email: chiefUserCreate.data.user.email
						}

						sendProgrammeAdminWelcomeEmail(payload);
					}
				} else {
					//update mapping
					if (chiefUserCreate.data.user._id) {
						updateChiefUserMapping({
							userId: chiefUserCreate.data.user._id,
							client: {
								clientId,
							},
							corporateProgramme: {
								programmeId,
								role: UserGroups.CHIEF_PROGRAMME_ADMIN
							}

						})
					}


					if (programmeName) {
						const payload = {
							firstName: chiefUserCreate.data.user?.firstName,
							lastName: chiefUserCreate.data.user?.lastName,
							programmeName: programmeName,
							email: chiefUserCreate.data.user.email
						}

						sendProgrammeAdminWelcomeEmail(payload);
					}
				}

			}
		}

		// update user mapping success 
		if (updateChiefUserMappingSuccess || createChiefUserMappingSuccess) {
			toast.success("User created successfully", {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});

		}

	}, [clientId, createChiefUserMapping, createChiefUserMappingSuccess, programmeId, programmeName, sendProgrammeAdminWelcomeEmail, updateChiefUserMapping, updateChiefUserMappingSuccess, chiefUserCreate]);



	////////////////////////////////////////////////////////////////////////////////////////////////////
	const loadForm = useCallback((data) => {
		setFormOpen(true);
		if (data) {
			setProgrammeId(data.key ? data?.key : data._id);
			setClientId(data.clientId ? data?.clientId : data.clientId?._id);
		}
	}, []);


	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	const activateProgramme = useCallback((data) => {
		if (data) {
			setProgrammeId(data.key);
			setClientId(data.clientId);
			setProgrammeName(data?.programmeName);
			if (data.status === 'inactive') {
				const value = {
					id: data.key,
					status: 'active'
				}
				activateCorporateProgramme(value);
			} else {
				const value = {
					id: data.key,
					isActivated: true
				}
				activateCorporateProgramme(value);
			}
		}

	}, [activateCorporateProgramme]);

	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	const archiveProgramme = useCallback((data) => {
		// setLoading(true);
		if (data) {
			const value = {
				id: data.key,
				status: 'inactive'
			}
			activateCorporateProgramme(value);
		}

	}, [activateCorporateProgramme]);

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const loadData = useCallback((id) => {
		setProgrammeId(id);
	}, []);

	////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (location.state?.selectedProgramme && location.state?.isBack) {
			const params: any = {};
			if (location.state?.selectedProgramme) {
				params.clientId = location.state?.selectedProgramme?.clientId?._id ? location.state?.selectedProgramme?.clientId?._id : location.state?.selectedProgramme?.clientId;
			}
			getCorporateProgrammeByClient(params);
		} else {
			if (location.state?.selectedProgramme) {
				setSelectedProgramme(location.state?.selectedProgramme);

				loadForm(location.state?.selectedProgramme);
			}
			if (location.state?.selectedClient) {
				setSelectedClient(location.state?.selectedClient);
			} else {
				const client = getStorage('myo_selected_client');
				setSelectedClient(client);
			}

		}
	}, [getCorporateProgrammeByClient, loadForm, location])

	//////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	const refreshProgrammes = useCallback(() => {
		const params: any = {};
		if (location.state?.clientId) {
			params.clientId = location.state?.clientId;
		}
		if (location?.state?.selectedProgramme) {
			params.clientId = location.state?.selectedProgramme?.clientId?._id ? location.state?.selectedProgramme?.clientId?._id : location.state?.selectedProgramme?.clientId;
		}
		getCorporateProgrammeByClient(params);
	}, [getCorporateProgrammeByClient, location]);



	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	return (
		<div className="programme-home">
			<div className="flex justify-between">
				{!selectedClient && <p className="text-2xl mb-10"></p>}
				{selectedClient && !formOpen && <Breadcrumb className="mb-10">
					<Breadcrumb.Item>
						<Link to={{ pathname: "/admin/client-management", state: { selectedClient: selectedClient } }}>Client Management</Link>
					</Breadcrumb.Item>
					<Breadcrumb.Item active>
						<Link to="" onClick={(e) => { e.preventDefault(); setFormOpen(false); refreshProgrammes(); setSelectedProgramme(null) }} >{selectedClient.companyName}</Link>
					</Breadcrumb.Item>
				</Breadcrumb>}
				{getLoggedUserType() === UserGroups.super_admin && <Button style={ButtonStyle} hidden={formOpen} onClick={() => { setFormOpen(true); setProgrammeId(null); setClientId(null) }} className="btn_design m-1">Create New Programme</Button>}
			</div>
			{!formOpen && <ProgrammeList loadForm={loadForm} programmes={programmes} onProgrammeSelect={(data) => { setSelectedProgramme(data) }} activateProgramme={activateProgramme} archiveProgramme={archiveProgramme} />}
			{formOpen && <ProgrammeForm programmeId={programmeId} selectedClient={selectedClient} clientId={clientId} selectedProgramme={selectedProgramme} setFormOpen={setFormOpen} refreshProgrammes={refreshProgrammes} setSelectedProgramme={setSelectedProgramme} loadData={loadData} location={location} />}
		</div>
	)
};

export default ProgrammeHome;