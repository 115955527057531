import { Button } from "antd";
import moment from "moment";
import { OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap'
import { FC, useCallback, useEffect, useState } from "react";

import { RfpProcessStatus, UserAccess } from "../../../common/constants";
import { canBrandModuleWrite, isBrandAdmin } from "../../../common/functions";
import DataTable from 'react-data-table-component';
import { tableStyles, overlayTriggerDelay } from '../../../common/components-style';
import ViewEdit from '../../../images/icons/svg/outside/Icon-Function-View-Edit.svg';
import { useStoreActions, useStoreState } from "easy-peasy";
import { getStorage } from "../../../common/storage";

const BrandRFPCorporateList: FC<any> = ({ loadForm, handleRfpProcessClick, isLoggedInUserBrandAdmin, shouldDataReload, setShouldDataReload, filteredBrandList }) => {
	// const [declinedRfpProcess, setDeclinedRfpProcess] = useState<string>("");
	const [filteredRfpProcessesList, setFilteredRfpProcessesList] = useState<any>([]);
	const [declineRfpProcessList, setDeclineRfpProcessList] = useState<any>([]);
	const [memoizedRfpProcessData, setMemoizedRfpProcessData] = useState<any>([]);
	// const [addToMyRfpId, setAddToMyRfpId] = useState<string>("");
	const [rfpProcessesList, setRfpProcessesList] = useState<any>([]);
	const myoSelectedBrand = getStorage("myo_selected_my_rfp_brand");
	// const [myoManagementClientId, setMyoManagementClientId] = useState<any>("");
	//actions
	const {
		createBrandRfpProcess,
		updateRfpProcess,
		getAllTheCorporateRfpProcessList,
		declineOpenBrandRfp,
		getMyRfpProcessList
		// onloadBrand,
		// createCertificate
		// updateBrandRFPData
	} = useStoreActions<any>((actions) => ({
		createBrandRfpProcess: actions.rfpProcess.createBrandRfpProcess,
		updateRfpProcess: actions.rfpProcess.updateRfpProcess,
		getAllTheCorporateRfpProcessList: actions.rfpProcess.getRfpProcessList,
		declineOpenBrandRfp: actions.rfpProcess.declineOpenBrandRfp,
		getMyRfpProcessList: actions.rfpProcess.getMyRfpProcessList,
		// createCertificate: actions.myoManagementModel.createCertificate,
		// onloadBrand: actions.brand.onloadBrand,
		// updateBrandRFPData: actions.rfpProcess.setBrandRfpDetails,
	}));

	//state
	const {
		createBrandRfpProcessSuccessResponse,
		createBrandRfpProcessLoadingResponse,
		createBrandRfpProcessErrorResponse,
		getAllTheCorporateRfpProcessListSuccess,
		getAllTheCorporateRfpProcessListError,
		getDeclineOpenBrandRfpSuccess,
		getDeclineOpenBrandRfpError,
		myRfpProcessListSuccess
		// getAllTheCorporateRfpProcessListLording,
		// brandRfpDetails,
		// loadBrandSuccess

	} = useStoreState<any>((state) => ({

		createBrandRfpProcessSuccessResponse: state.rfpProcess.createBrandRfpProcessSuccessResponse,
		createBrandRfpProcessLoadingResponse: state.rfpProcess.createBrandRfpProcessLoadingResponse,
		createBrandRfpProcessErrorResponse: state.rfpProcess.createBrandRfpProcessErrorResponse,
		myRfpProcessListSuccess: state.rfpProcess.myRfpProcessListSuccess,
		getAllTheCorporateRfpProcessListSuccess: state.rfpProcess.getRfpProcessListSuccess,
		getAllTheCorporateRfpProcessListError: state.rfpProcess.getRfpProcessListError,
		getAllTheCorporateRfpProcessListLording: state.rfpProcess.getRfpProcessListLoading,
		getDeclineOpenBrandRfpSuccess: state.rfpProcess.getDeclineOpenBrandRfpSuccess,
		getDeclineOpenBrandRfpError: state.rfpProcess.getDeclineOpenBrandRfpError,

		// brandRfpDetails: state.rfpProcess.brandRfpDetails,

		// loadBrandSuccess: state.brand.loadBrandSuccess,
	}));

	useEffect(() => {

		if (myoSelectedBrand) {
			getMyRfpProcessList(myoSelectedBrand);
		}

	}, [getMyRfpProcessList, myoSelectedBrand]);


	// ////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (shouldDataReload) {
			if (myoSelectedBrand) {
				getMyRfpProcessList(myoSelectedBrand);
			}
		}
		setShouldDataReload(false);
	}, [getMyRfpProcessList, myoSelectedBrand, setShouldDataReload, shouldDataReload]);

	//////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {

		if (getAllTheCorporateRfpProcessListSuccess?.data) {
			// console.log(getAllTheCorporateRfpProcessListSuccess?.data, 'getAllTheCorporateRfpProcessListSuccess?.data')
			const rfpList: any = [];

			if (declineRfpProcessList.length > 0) {

				for (const rfp of declineRfpProcessList) {

					for (const corporateRfp of getAllTheCorporateRfpProcessListSuccess?.data) {
						if (rfp.rfpId._id === corporateRfp._id) {
							Object.assign(corporateRfp, { brandRfpStatus: rfp.brandRfpStatus });
							rfpList.push(corporateRfp);
						} else {
							Object.assign(corporateRfp, { brandRfpStatus: 'not' });
							rfpList.push(corporateRfp);
						}
					}
					break;
				}
			} else {
				for (const corporateRfp of getAllTheCorporateRfpProcessListSuccess?.data) {
					Object.assign(corporateRfp, { brandRfpStatus: 'not' });
					rfpList.push(corporateRfp);
				}
			}
			// console.log(rfpList, 'rfpList')
			const filteredRfpProcessList = rfpList.filter(process => (process.rfpStatus === RfpProcessStatus.INPROGRESS));


			setMemoizedRfpProcessData([]);
			if (filteredRfpProcessList.length > 0) {
				const data = filteredRfpProcessList
					// .filter((process) => !process.declinedBy.includes(reduxBrandDetail?.selectedBrandId))
					.filter((process) => !process.addedBy.includes(myoSelectedBrand))
					.map(({ endDate, locations, _id, clientId, programmeId, overviewForInterview, startDate,rfpName, questionSubmitDate, isDeclined, certificateTemplateId, brandRfpStatus }) => {
						const cities = locations.map((item: any) => item.cities)
						const citiesArray = [].concat.apply([], cities);
						const cityNames = citiesArray.map((item: any) => item.cityName);
						return {
							key: _id,
							endDate,
							locations: cityNames?.length,
							locationsDetails: locations,
							companyName: clientId?.companyName,
							clientId: clientId?._id,
							programmeName: programmeId?.programmeName,
							overviewForInterview,
							startDate,
							rfpName,
							questionSubmitDate,
							isDeclined,
							certificateTemplateId,
							brandRfpStatus

						}
					});

				setMemoizedRfpProcessData(data);
			}


		}


		if (getDeclineOpenBrandRfpSuccess) {
			if (myoSelectedBrand) {
				getMyRfpProcessList(myoSelectedBrand);
			}
		}

		if (myRfpProcessListSuccess?.data) {
			// console.log(myRfpProcessListSuccess?.data, 'myRfpProcessListSuccess?.data')
			const getOnlyDeclineRfp: any = [];

			for (const rfp of myRfpProcessListSuccess?.data.rfpIds) {
				if (rfp.brandRfpStatus === "declined") {
					getOnlyDeclineRfp.push(rfp);
				}
			}

			setDeclineRfpProcessList(getOnlyDeclineRfp);
			// console.log('sddsdfs')
			getAllTheCorporateRfpProcessList();
		}

	}, [declineRfpProcessList, getAllTheCorporateRfpProcessList, getAllTheCorporateRfpProcessListSuccess?.data, getDeclineOpenBrandRfpSuccess, getMyRfpProcessList, myRfpProcessListSuccess?.data, myoSelectedBrand]);

	///// get rfp process list //////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (isLoggedInUserBrandAdmin) {
			if (myoSelectedBrand) {
				getMyRfpProcessList(myoSelectedBrand);
			}
			getAllTheCorporateRfpProcessList();
		}
		// onloadBrand(myoSelectedBrand);
	}, [getAllTheCorporateRfpProcessList, getMyRfpProcessList, isLoggedInUserBrandAdmin, myoSelectedBrand]); ///// get rfp process list

	//////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		const filteredRfpProcessList = rfpProcessesList.filter(process => (process.rfpStatus === RfpProcessStatus.INPROGRESS));
		if (filteredRfpProcessList.length > 0) {
			setFilteredRfpProcessesList(filteredRfpProcessList);
		}
	}, [rfpProcessesList]);

	////////////////////////////////////////////////////////////////////////////////////
	// const memoizedRfpProcessData = useMemo(() => {
	// 	return filteredRfpProcessesList
	// 		// .filter((process) => !process.declinedBy.includes(reduxBrandDetail?.selectedBrandId))
	// 		.filter((process) => !process.addedBy.includes(myoSelectedBrand))
	// 		.map(({ endDate, locations, _id, clientId, programmeId, overviewForInterview, startDate, questionSubmitDate, isDeclined, certificateTemplateId, brandRfpStatus }) => {
	// 			const cities = locations.map((item: any) => item.cities)
	// 			const citiesArray = [].concat.apply([], cities);
	// 			const cityNames = citiesArray.map((item: any) => item.cityName);
	// 			return {
	// 				key: _id,
	// 				endDate,
	// 				locations: cityNames?.length,
	// 				locationsDetails: locations,
	// 				companyName: clientId?.companyName,
	// 				clientId: clientId?._id,
	// 				programmeName: programmeId?.programmeName,
	// 				overviewForInterview,
	// 				startDate,
	// 				questionSubmitDate,
	// 				isDeclined,
	// 				certificateTemplateId,
	// 				brandRfpStatus

	// 			}
	// 		})
	// }, [filteredRfpProcessesList, myoSelectedBrand]);


	// // ////////////////////////////////////////////////////////////////////////////////////////////
	// useEffect(() => {
	// 	if (getAllTheCorporateRfpProcessListSuccess?.data) {
	// 		const checkForStatusChange1 = changeRfpProcessStatusToInProgress(getAllTheCorporateRfpProcessListSuccess?.data);
	// 		const checkForStatusChange2 = changeRfpProcessStatusToCompleted(getAllTheCorporateRfpProcessListSuccess?.data);
	// 		if (checkForStatusChange1?.length > 0) {
	// 			for (let process of checkForStatusChange1) {
	// 				updateRfpProcess(process);
	// 			}
	// 			getAllTheCorporateRfpProcessList(myoSelectedBrand);
	// 		}
	// 		if (checkForStatusChange2?.length > 0) {
	// 			for (let process of checkForStatusChange2) {
	// 				updateRfpProcess(process);
	// 			}
	// 			getAllTheCorporateRfpProcessList();
	// 		}
	// 	}
	// }, [myoSelectedBrand, getAllTheCorporateRfpProcessList, getAllTheCorporateRfpProcessListSuccess?.data, updateRfpProcess])

	////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (createBrandRfpProcessSuccessResponse?.data) {
			setShouldDataReload(true);
		}
		if (createBrandRfpProcessErrorResponse) {
		}
	}, [createBrandRfpProcessErrorResponse, createBrandRfpProcessSuccessResponse?.data, setShouldDataReload]);

	// when decline button clicked ///////////////////////////////////////////////////////////////////////////////////////////////////
	// useEffect(() => {
	// 	if (declinedRfpProcess) {
	// 		setFilteredRfpProcessesList(filteredRfpProcessesList.filter((item) => item._id !== declinedRfpProcess));
	// 		setDeclinedRfpProcess("");
	// 	}
	// }, [declinedRfpProcess, filteredRfpProcessesList]);

	// when decline button clicked ///////////////////////////////////////////////////////////////////////////////////////////////////
	// const handleDecline = useCallback((data) => {
	// 	if (data?.key) {
	// 		// setDeclinedRfpProcess(data.key);
	// 		updateRfpProcess({ id: data.key, brandIdToDecline: myoSelectedBrand, isDeclined: true });
	// 		// updateBrandRFPData({ ...reduxBrandDetail, showRfpDetailFormFirstPage: false, showRfpDetailFormSecondPage: false, showAvailableRfpList: true, showHomePage: true, })
	// 		getAllTheCorporateRfpProcessList();
	// 		setShouldDataReload(true);
	// 	}

	// }, [getAllTheCorporateRfpProcessList, myoSelectedBrand, setShouldDataReload, updateRfpProcess]);

	// const handleReOpen = useCallback((data) => {
	// 	if (data?.key) {
	// 		updateRfpProcess({ id: data.key, isDeclined: false });
	// 		// updateBrandRFPData({ ...reduxBrandDetail, showRfpDetailFormFirstPage: false, showRfpDetailFormSecondPage: false, showAvailableRfpList: true, showHomePage: true, })
	// 		getAllTheCorporateRfpProcessList();
	// 		setShouldDataReload(true);
	// 	}
	// }, [getAllTheCorporateRfpProcessList, setShouldDataReload, updateRfpProcess]);

	const declineOpenBrandRfpFunc = useCallback((data) => {
		const payload = {
			brandId: myoSelectedBrand,
			rfpId: data.key
		}
		declineOpenBrandRfp(payload);
	}, [declineOpenBrandRfp, myoSelectedBrand])


	// when add to my rfp button clicked ///////////////////////////////////////////////////////////////////////////////////////////////////
	const handleAddToMyRfp = useCallback((data) => {
		if (data?.key && myoSelectedBrand) {
			// setAddToMyRfpId(data.key);
			createBrandRfpProcess({ brandId: myoSelectedBrand, rfpProcessId: data.key, templateID: data?.certificateTemplateId });
		}

		// createCertificate()
	}, [createBrandRfpProcess, myoSelectedBrand]);


	// need to verify
	// // //  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// const handleRfpProcessClick = useCallback((data) => {
	// 	// updateBrandRFPData({ ...reduxBrandDetail, showRfpDetailFormFirstPage: true, formData: data, showAvailableRfpList: false });

	// }, [])

	//Table columns/////////////////////////////////////////////////////////////////////////////////////////
	const columns1 = [
		{
			name: 'Company',
			selector: row => row.companyName,
		},
		{
			name: 'Programme',
			selector: row => {
				return (
					<OverlayTrigger

						placement="top"
						delay={overlayTriggerDelay}
						overlay={<Tooltip id='tooltip-table-top'>{row.programmeName}</Tooltip>}
					>
						<p style={{ marginBottom: '0px' }} >{String(row.programmeName).slice(0, 10) + "..."}</p>
					</OverlayTrigger>
				)
			}
		},
		{
			name: 'Name of Rfp',
			selector: row => row.rfpName,
			minWidth: '300px',
		},
		{
			name: 'End date',
			selector: row => moment(row.endDate).format("D MMM YYYY"),
		},
		{
			name: 'No. of Locations',
			selector: row => row.locations,
		},
		{
			name: 'Action',
			button: true,
			wrap: true,
			minWidth: '300px',
			cell: (data: any) => {
				return (<>
					{data?.brandRfpStatus !== "declined" && <OverlayTrigger

						placement="top"
						delay={overlayTriggerDelay}
						overlay={<Tooltip id='tooltip-table-top'>View</Tooltip>}
					>
						<img className='iconSizes mr-2' src={ViewEdit} alt="" onClick={() => handleRfpProcessClick(data)} />
					</OverlayTrigger>}

					{data?.brandRfpStatus === "declined" &&
						<OverlayTrigger

							placement="top"
							delay={overlayTriggerDelay}
							overlay={<Tooltip id='tooltip-table-top'>View</Tooltip>}
						>
							<img className='iconSizes mr-2' style={{ marginLeft: '-130px' }} src={ViewEdit} alt="" onClick={() => handleRfpProcessClick(data)} />
						</OverlayTrigger>
					}
					{data?.brandRfpStatus !== "declined" && <Button style={{
						background: '#FBC91D'
					}} className='border-none rounded-xl mx-1 border-0' onClick={() => { declineOpenBrandRfpFunc(data) }} hidden={(!canBrandModuleWrite(myoSelectedBrand !== '' ? myoSelectedBrand : filteredBrandList[0]?._id, UserAccess.brand_access.tenders.code) && !isBrandAdmin(myoSelectedBrand !== '' ? myoSelectedBrand : filteredBrandList[0]?._id))} >Decline</Button >}

					{data?.brandRfpStatus === "declined" && <Button style={{
						background: '#FBC91D'
					}} className='border-none rounded-xl mx-1 border-0' onClick={() => { declineOpenBrandRfpFunc(data) }} hidden={(!canBrandModuleWrite(myoSelectedBrand !== '' ? myoSelectedBrand : filteredBrandList[0]?._id, UserAccess.brand_access.tenders.code) && !isBrandAdmin(myoSelectedBrand !== '' ? myoSelectedBrand : filteredBrandList[0]?._id))} >Re-open</Button >}

					{data?.brandRfpStatus !== "declined" && <Button style={{
						background: '#FBC91D'
					}} className='rounded-xl mx-1 border-0' onClick={() => { handleAddToMyRfp(data); setShouldDataReload(true) }} hidden={(!canBrandModuleWrite(myoSelectedBrand !== '' ? myoSelectedBrand : filteredBrandList[0]?._id, UserAccess.brand_access.tenders.code) && !isBrandAdmin(myoSelectedBrand !== '' ? myoSelectedBrand : filteredBrandList[0]?._id))} >{
							createBrandRfpProcessLoadingResponse ? <span>

								<Spinner
									as="span"
									animation="grow"
									size="sm"
									role="status"
									aria-hidden="true"
								/>
								...adding
							</span>
								: "Add to my RFP"
						}</Button >}
				</>)
			}
		},

	];

	return (
		<div>
			<h2 className="font-bold text-xl mt-3 mb-12" >
				Available RFPs for operator participation

			</h2>
			<DataTable
				columns={columns1}
				data={memoizedRfpProcessData}
				pagination
				responsive
				customStyles={tableStyles}
				theme='mysaTable'
				striped={true}
				fixedHeader={true}
			/>
		</div>
	)
}


export default BrandRFPCorporateList;