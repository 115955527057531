import { message } from "antd";
import { useStoreActions, useStoreState } from "easy-peasy";
import React, { useCallback, useEffect, useState } from "react";
import PropertyForm from "./form/PropertyForm";
import PropertyList from "./PropertyList";
import { getStorage, setStorage } from "../../../common/storage";
import { Link } from "react-router-dom";
import { Breadcrumb } from "react-bootstrap";
import { useHistory } from 'react-router-dom';

const PropertyHome: React.FC<any> = ({ location }): JSX.Element => {
  const [formOpen, setFormOpen] = useState<boolean>(false);
  const [allInOnePropertyBreadCrumb, setAllInOnePropertyBreadCrumb] = useState<boolean>(false);
  const [properties, setProperties] = useState<Array<Object> | null>([]);
  const [propertyId, setPropertyId] = useState<number | null>(null);
  const [extId, setExtId] = useState<number | null>(null);
  const [selectedBrand, setSelectedBrand] = useState<any>(null);
  const history = useHistory<any>();

  // const [selectedProperty, setSelectedProperty] = useState<any>(null);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const { getProperties } = useStoreActions<any>((actions) => ({
    getProperties: actions.property.getProperties,
  }));

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const { getPropertiesSuccess, getPropertiesError } = useStoreState<any>(
    (state) => ({
      getPropertiesSuccess: state.property.getPropertiesSuccess,
      getPropertiesError: state.property.getPropertiesError,
    })
  );
  ///////////location?.state/////////////////////////////////////////////////////////////////////////////////////////
  const loadForm = useCallback((id, extId) => {
    setExtId(extId);
    setFormOpen(true);
    setPropertyId(id);
  }, []);

  useEffect(() => {
    if (location?.state) {
      setFormOpen(location?.state?.formOpen);
      setAllInOnePropertyBreadCrumb(location?.state?.allInOnePropertyBreadCrumb);
      setPropertyId(location?.state?.propertyId);
    }
  }, [history, location?.state]);

  /**
   * special navigate
   */
  useEffect(() => {
    if (location.state?.allInOneProperty) {
      loadForm(
        location.state?.allInOneProperty?._id,
        location.state?.allInOneProperty?.externalId
      );
      setAllInOnePropertyBreadCrumb(true);
      setStorage("myo_selected_property", location.state?.allInOneProperty);
      const transformBrandData = (brandData) => ({
        key: brandData._id,
        extId: null,
        image: brandData.image,
        name: brandData.name,
        brandStatus: brandData.brandStatus,
        countPending: 0,
        countIncomplete: 0,
        myoManagementReference: brandData.myoManagementReference
      });
      setStorage("myo_selected_brand", transformBrandData(location.state?.allInOneProperty?.brand));
    } else {
      if (location.state?.allInOnePropertyBreadCrumb) {
        const property = getStorage("myo_selected_property");
        loadForm(property?._id, property?.externalId);
        setAllInOnePropertyBreadCrumb(
          location.state?.allInOnePropertyBreadCrumb
        );
      }
    }
  }, [loadForm, location.state?.allInOneProperty, location.state?.allInOnePropertyBreadCrumb]);
  ////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    const brand = getStorage("myo_selected_brand");
    setSelectedBrand(brand);
  }, []);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (!location.state?.isBack && !location.state?.selectedProperty) {
      const params: any = {};
      // if (getLoggedUserType() !== UserGroups.super_admin) {
      //   params.userEmail = getLoggedUserEmail();
      // }

      if (location.state?.brandId) {
        params.brandId = location.state?.brandId;
      }

      getProperties(params);
    }
  }, [getProperties, location]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (getPropertiesError) {
      message.success(getPropertiesError.message);
    }

    if (getPropertiesSuccess) {
      setProperties(getPropertiesSuccess.data);
    }
  }, [getPropertiesError, getPropertiesSuccess]);


  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const loadData = useCallback((id) => {
    setPropertyId(id);
  }, []);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (location.state?.isBack && location.state?.selectedProperty) {
      const params: any = {};
      if (location.state?.selectedProperty) {
        params.brandId = location.state?.selectedProperty?.brandId?._id;
      }
      getProperties(params);
    } else {
      if (location.state?.selectedProperty) {
        // setSelectedProperty(location.state?.selectedProperty);
        loadForm(
          location.state?.selectedProperty._id,
          location.state?.selectedProperty?.externalId
        );
      }
    }
  }, [loadForm, location, getProperties]);

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const refreshProperties = useCallback(() => {
    const params: any = {};
    // if (getLoggedUserType() !== UserGroups.super_admin) {
    //   params.userEmail = getLoggedUserEmail();
    // }

    if (location.state?.brandId) {
      params.brandId = location.state?.brandId;
    }
    if (location.state?.selectedProperty) {
      params.brandId = location.state?.selectedProperty.brandId?._id;;
    }
    getProperties(params);
  }, [getProperties, location]);
  return (
    <div className="property-home">
      <div className="flex justify-between" style={{ padding: "0 6px" }}>
        {!selectedBrand && <p className="text-2xl mb-10"></p>}
        {!formOpen && selectedBrand && (
          <Breadcrumb className="">
            {/* <Breadcrumb.Item>
						<Link to={{ pathname: "/admin/brand-management", state: { selectedBrand: selectedBrand } }}>Home</Link>
					</Breadcrumb.Item>
					<Breadcrumb.Item>
						<Link to={{ pathname: "/admin/brand-management", state: { selectedBrand: selectedBrand } }}>Brand Management</Link>
					</Breadcrumb.Item> */}
            <Breadcrumb.Item>
              <Link
                to={{
                  pathname: "/admin/brand-management",
                  state: { selectedBrand: selectedBrand },
                }}
              >
                {selectedBrand.name}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item active={formOpen ? false : true}>
              <Link
                to=""
                onClick={(e) => {
                  e.preventDefault();
                  setFormOpen(false);
                  refreshProperties();
                }}
              >
                Property Management
              </Link>
            </Breadcrumb.Item>
            {/* {selectedProperty && <Breadcrumb.Item >{selectedProperty.building} - {selectedProperty?.city}</Breadcrumb.Item>} */}
            {/* {(formOpen && !selectedProperty) && <Breadcrumb.Item active>New Property</Breadcrumb.Item>} */}
          </Breadcrumb>
        )}
      </div>
      {!formOpen && (
        <PropertyList
          setPropertyId={setPropertyId}
          setFormOpen={setFormOpen}
          formOpen={formOpen}
          selectedBrand={selectedBrand}
          propertyId={propertyId}
          properties={properties}
          loadForm={loadForm}
          refreshProperties={refreshProperties}
        />
      )}
      {formOpen && (
        <PropertyForm
          allInOnePropertyBreadCrumb={allInOnePropertyBreadCrumb}
          tab={"properties"}
          propertyId={propertyId}
          extId={extId}
          setFormOpen={setFormOpen}
          selectedBrand={selectedBrand}
          loadData={loadData}
          location={location}
          selectedProperty={getStorage("myo_selected_property")}
        />
      )}
    </div>
  );
};

export default PropertyHome;
