import React, { useCallback, useEffect, useState } from 'react';
import { canBrandHotelModuleWrite } from '../../../../common/functions';
import { UserAccess, CommonAnswer } from '../../../../common/constants';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Controller, useForm } from 'react-hook-form';
import { HotelTransportFormInput } from '../../../../common/interfaces';
import { Row, Col, Container, Form, Button, Spinner } from 'react-bootstrap';
import { formButton } from '../../../../common/components-style';
import { toast } from 'react-toastify';
import { ErrorMessage } from '@hookform/error-message';
import BreadCrumbPage from '../../../common/BreadCrumbPage';

const TransportDetailForm: React.FC<any> = ({ allInOneHotelBreadCrumb, hotelId, setFormOpen, selectedBrand, tab }): JSX.Element => {
    ///////////////////// initialize form ////////////////////////////////////// 
    const { register, handleSubmit, formState: { errors }, control } = useForm<HotelTransportFormInput>();

    ///////////////////////// local state manage ////////////////////////////////////
    const [editEnable, setEditEnable] = useState<boolean>(false);
    const [hotelData, setHotelData] = useState<any>();
    const [transportData, setTransportData] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);
    const [transportId, setTransportId] = useState<boolean>();
    const [doYouProvideOnSiteCarParking, setDoYouProvideOnSiteCarParking] = useState<boolean>();
    const [chargeForCarParking, setChargeForCarParking] = useState<boolean>(false);
    const [currency, setCurrency] = useState<any>();

    /////////////////////////// global state manage (actions)//////////////////////////////////////////////////////
    const { createTransport, getTransport, updateTransport, getHotel } = useStoreActions<any>((actions) => ({
        createTransport: actions.hotel.createTransport,
        getTransport: actions.hotel.getTransport,
        updateTransport: actions.hotel.updateTransport,
        getHotel: actions.hotel.getHotel,
    }));

    /////////////////////////// global state manage (state)//////////////////////////////////////////////////////
    const { createTransportSuccess, createTransportError, getTransportSuccess, updateTransportSuccess, updateTransportError, getHotelSuccess } = useStoreState<any>((state) => ({
        createTransportSuccess: state.hotel.createTransportSuccess,
        createTransportError: state.hotel.createTransportError,
        getTransportSuccess: state.hotel.getTransportSuccess,
        updateTransportSuccess: state.hotel.updateTransportSuccess,
        updateTransportError: state.hotel.updateTransportError,
        getHotelSuccess: state.hotel.getHotelSuccess
    }));

    //////////////////////////////// call apis using actions //////////////////////////////////////////////////////
    useEffect(() => {
        getTransport(hotelId);
        getHotel(hotelId);
    }, [getHotel, getTransport, hotelId]);

    //////////////////////////////// get api response using state //////////////////////////////////////////////////////
    useEffect(() => {
        if (getTransportSuccess) {
            if (getTransportSuccess.data) {
                setTransportId(getTransportSuccess.data._id);
                setTransportData(getTransportSuccess.data);

                if (getTransportSuccess.data?.doYouProvideOnSiteCarParking === CommonAnswer.YES) {
                    setDoYouProvideOnSiteCarParking(true);
                } else {
                    setDoYouProvideOnSiteCarParking(false);
                }
                if (getTransportSuccess.data?.chargeForCarParking === CommonAnswer.YES) {
                    setChargeForCarParking(true);
                } else {
                    setChargeForCarParking(false);
                }
            }
        }

        if (createTransportSuccess) {
            setLoading(false);
            toast.success("Transport created successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            setEditEnable(false);
            getTransport(hotelId);
        }

        if (createTransportError) {
            setLoading(false);
            toast.error(createTransportError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }

        if (updateTransportSuccess) {
            setLoading(false);
            toast.success("Transport update successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
            setEditEnable(false);
            getTransport(hotelId);
        }

        if (updateTransportError) {
            setLoading(false);
            toast.error(updateTransportError.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }

        if (getHotelSuccess) {
            const data = getHotelSuccess.data;
            setCurrency(data.currency);
            setHotelData(data);
        }
    }, [createTransportError, createTransportSuccess, getHotelSuccess, getTransport, getTransportSuccess, hotelId, updateTransportError, updateTransportSuccess]);

    ///////////////////////// validation manage /////////////////////////////////////
    const onChangeDoYouProvideOnSiteCarParking = useCallback((value) => {
        if (value === CommonAnswer.YES) {
            setDoYouProvideOnSiteCarParking(true);
        } else {
            setDoYouProvideOnSiteCarParking(false);
        }
    }, []);
    const onChangeChargeForCarParking = useCallback((value) => {
        if (value === CommonAnswer.YES) {
            setChargeForCarParking(true);
        } else {
            setChargeForCarParking(false);
        }
    }, []);


    const onFinish = (values) => {
        if (!hotelId) {
            toast.error("Please create an hotel", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }
        setLoading(true);
        const createUpdateValues = {
            hotelId: hotelId,
            unitMeasureForDistance: values.unitMeasureForDistance,
            doYouProvideOnSiteCarParking: values.doYouProvideOnSiteCarParking,
            ifYouDoNotProvideOnSiteCarParking: values.ifYouDoNotProvideOnSiteCarParking,
            nearestAirport: values.nearestAirport,
            distanceToTheAirport: values.distanceToTheAirport,
            airportCode: values.airportCode,
            averageCostOfTransportationFromAirport: values.averageCostOfTransportationFromAirport,
            chargeForCarParking: values.chargeForCarParking,
            nightlyCostCarParking: values.nightlyCostCarParking
        }

        if (transportId) {
            Object.assign(createUpdateValues, { transportId });
            delete createUpdateValues.hotelId;
            updateTransport(createUpdateValues)
        } else {
            createTransport(createUpdateValues);
        }


    };

    const changeFormEditable = useCallback(() => {
        if (editEnable) {
            setEditEnable(false);
        } else {
            setEditEnable(true);
        }
    }, [editEnable]);

    return (
        <>
            <div className="content-title fixed_title">
                {
                    <BreadCrumbPage allInOneHotelBreadCrumb={allInOneHotelBreadCrumb} tab={tab} isPageName="Hotel" selectedBrand={selectedBrand} hotelData={hotelData} setFormOpen={setFormOpen} ></BreadCrumbPage>
                }
                <div className='edit_btn_wpr'>
                    <div className='d-flex align-items-center justify-content-end gx-2'>
                        {
                            !loading && !editEnable && hotelId && canBrandHotelModuleWrite(selectedBrand.key ? selectedBrand.key : selectedBrand?._id, hotelId, UserAccess.hotel_access.details.code) && <Button id='edit-button' className='mb-0' style={formButton} onClick={changeFormEditable} >Edit</Button>
                        }
                    </div>
                </div>
            </div>
            <Container fluid className='p-0'>
                <div className="jumbotron">
                    <Form onSubmit={handleSubmit(onFinish)}>
                        <Row>
                            <Col md={1}></Col>
                            <Col md={10}>
                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Unit of measure for distance</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{transportData?.unitMeasureForDistance}</p>}
                                        {editEnable &&
                                            <Controller
                                                name="unitMeasureForDistance"
                                                defaultValue={transportData?.unitMeasureForDistance}
                                                control={control}
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field }) =>
                                                    <>
                                                        {
                                                            transportData?.unitMeasureForDistance === 'KMs'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'KMs'}
                                                                    name="unitMeasureForDistance"
                                                                    label={'KMs'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'KMs'}
                                                                    name="unitMeasureForDistance"
                                                                    label={'KMs'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); }}
                                                                />
                                                        }

                                                        {
                                                            transportData?.unitMeasureForDistance === 'Miles'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'Miles'}
                                                                    name="unitMeasureForDistance"
                                                                    label={'Miles'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'Miles'}
                                                                    name="unitMeasureForDistance"
                                                                    label={'Miles'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); }}
                                                                />
                                                        }

                                                    </>
                                                }
                                            />}
                                        {editEnable &&
                                            <ErrorMessage
                                                errors={errors}
                                                name="unitMeasureForDistance"
                                                render={({ message }) => <div className="invalid-feedback-custom">Please select your answer!</div>} />}
                                    </Col>
                                </Row>

                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Do you provide on-site car parking?</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{transportData?.doYouProvideOnSiteCarParking}</p>}
                                        {editEnable &&
                                            <Controller
                                                name="doYouProvideOnSiteCarParking"
                                                defaultValue={transportData?.doYouProvideOnSiteCarParking}
                                                control={control}
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field }) =>
                                                    <>
                                                        {
                                                            transportData?.doYouProvideOnSiteCarParking === 'Yes'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="doYouProvideOnSiteCarParking"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangeDoYouProvideOnSiteCarParking(value.currentTarget.value); }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'Yes'}
                                                                    name="doYouProvideOnSiteCarParking"
                                                                    label={'Yes'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangeDoYouProvideOnSiteCarParking(value.currentTarget.value); }}
                                                                />
                                                        }

                                                        {
                                                            transportData?.doYouProvideOnSiteCarParking === 'No'
                                                                ?
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="doYouProvideOnSiteCarParking"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    defaultChecked
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangeDoYouProvideOnSiteCarParking(value.currentTarget.value); }}
                                                                />
                                                                :
                                                                <Form.Check
                                                                    inline
                                                                    value={'No'}
                                                                    name="doYouProvideOnSiteCarParking"
                                                                    label={'No'}
                                                                    type="radio"
                                                                    onChange={(value) => { field.onChange(value.currentTarget.value); onChangeDoYouProvideOnSiteCarParking(value.currentTarget.value); }}
                                                                />
                                                        }

                                                    </>
                                                }
                                            />}
                                        {editEnable &&
                                            <ErrorMessage
                                                errors={errors}
                                                name="doYouProvideOnSiteCarParking"
                                                render={({ message }) => <div className="invalid-feedback-custom">Please select your answer!</div>} />}
                                    </Col>
                                </Row>
                                {
                                    doYouProvideOnSiteCarParking === false &&
                                    <Row className='formSpace'>
                                        <Col md={6} style={{ textAlign: 'right' }}>
                                            <Form.Label>{editEnable} How far is the nearest car park (mins)?</Form.Label>
                                        </Col>
                                        <Col md={6} style={{ textAlign: 'left' }}>
                                            {!editEnable && <p className="lead">{transportData?.ifYouDoNotProvideOnSiteCarParking} {transportData?.ifYouDoNotProvideOnSiteCarParking && <span>minutes</span>} </p>}
                                            {editEnable && <Form.Control type='number' min={0} defaultValue={transportData?.ifYouDoNotProvideOnSiteCarParking} {...register("ifYouDoNotProvideOnSiteCarParking")} />}
                                            {editEnable && <div className="invalid-feedback">Please select your answer!</div>}
                                        </Col>
                                    </Row>
                                }
                                {
                                    doYouProvideOnSiteCarParking &&
                                    <Row className='formSpace'>
                                        <Col md={6} style={{ textAlign: 'right' }}>
                                            <Form.Label>{editEnable} Is there a charge for car parking?</Form.Label>
                                        </Col>
                                        <Col md={6} style={{ textAlign: 'left' }}>
                                            {!editEnable && <p className="lead">{transportData?.chargeForCarParking}</p>}
                                            {editEnable &&
                                                <Controller
                                                    name="chargeForCarParking"
                                                    defaultValue={transportData?.chargeForCarParking}
                                                    control={control}
                                                    render={({ field }) =>
                                                        <>
                                                            {
                                                                transportData?.chargeForCarParking === 'Yes'
                                                                    ?
                                                                    <Form.Check
                                                                        inline
                                                                        value={'Yes'}
                                                                        name="chargeForCarParking"
                                                                        label={'Yes'}
                                                                        type="radio"
                                                                        defaultChecked
                                                                        onChange={(value) => { field.onChange(value.currentTarget.value); onChangeChargeForCarParking(value.currentTarget.value); }}
                                                                    />
                                                                    :
                                                                    <Form.Check
                                                                        inline
                                                                        value={'Yes'}
                                                                        name="chargeForCarParking"
                                                                        label={'Yes'}
                                                                        type="radio"
                                                                        onChange={(value) => { field.onChange(value.currentTarget.value); onChangeChargeForCarParking(value.currentTarget.value); }}
                                                                    />
                                                            }

                                                            {
                                                                transportData?.chargeForCarParking === 'No'
                                                                    ?
                                                                    <Form.Check
                                                                        inline
                                                                        value={'No'}
                                                                        name="chargeForCarParking"
                                                                        label={'No'}
                                                                        type="radio"
                                                                        defaultChecked
                                                                        onChange={(value) => { field.onChange(value.currentTarget.value); onChangeChargeForCarParking(value.currentTarget.value); }}
                                                                    />
                                                                    :
                                                                    <Form.Check
                                                                        inline
                                                                        value={'No'}
                                                                        name="chargeForCarParking"
                                                                        label={'No'}
                                                                        type="radio"
                                                                        onChange={(value) => { field.onChange(value.currentTarget.value); onChangeChargeForCarParking(value.currentTarget.value); }}
                                                                    />
                                                            }

                                                        </>
                                                    }
                                                />}
                                            {editEnable &&
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="doYouProvideOnSiteCarParking"
                                                    render={({ message }) => <div className="invalid-feedback-custom">Please select your answer!</div>} />}
                                        </Col>
                                    </Row>
                                }
                                {
                                    doYouProvideOnSiteCarParking && chargeForCarParking &&
                                    <Row className='formSpace'>
                                        <Col md={6} style={{ textAlign: 'right' }}>
                                            <Form.Label>{editEnable} {'What is the nightly cost of car parking (' + currency + ')?'}</Form.Label>
                                        </Col>
                                        <Col md={6} style={{ textAlign: 'left' }}>
                                            {!editEnable && <p className="lead">{transportData?.nightlyCostCarParking}</p>}
                                            {editEnable && <Form.Control type='number' min={0} defaultValue={transportData?.nightlyCostCarParking} {...register("nightlyCostCarParking")} />}
                                            {editEnable && <div className="invalid-feedback">Please select your answer!</div>}
                                        </Col>
                                    </Row>
                                }
                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} What is the nearest airport</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{transportData?.nearestAirport}</p>}
                                        {editEnable && <Form.Control className={`${errors.nearestAirport ? 'is-invalid' : ''}`} defaultValue={transportData?.nearestAirport} {...register("nearestAirport", { required: true })} />}
                                        {editEnable && <div className="invalid-feedback">Please select your answer!</div>}
                                    </Col>
                                </Row>
                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} Airport Code</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{transportData?.airportCode}</p>}
                                        {editEnable && <Form.Control className={`${errors.airportCode ? 'is-invalid' : ''}`}
                                            defaultValue={transportData?.airportCode} {...register("airportCode", { required: true })} />}
                                        {editEnable && <div className="invalid-feedback">Please select your answer!</div>}
                                    </Col>
                                </Row>
                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} What is the distance?</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{transportData?.distanceToTheAirport} {transportData?.distanceToTheAirport && <span>km</span>} </p>}
                                        {editEnable && <Form.Control type='number' min={0} className={`${errors.distanceToTheAirport ? 'is-invalid' : ''}`} defaultValue={transportData?.distanceToTheAirport} {...register("distanceToTheAirport", { required: true })} />}
                                        {editEnable && <div className="invalid-feedback">Please select your answer!</div>}
                                    </Col>
                                </Row>
                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                        <Form.Label>{editEnable && <span className='requiredMark'>*</span>} {'What is the average cost for transportation from the airport? (' + currency + ')'}</Form.Label>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!editEnable && <p className="lead">{transportData?.averageCostOfTransportationFromAirport} {transportData?.averageCostOfTransportationFromAirport && <span>{currency}</span>} </p>}
                                        {editEnable && <Form.Control type='number' className={`${errors.averageCostOfTransportationFromAirport ? 'is-invalid' : ''}`} min={0} defaultValue={transportData?.averageCostOfTransportationFromAirport} {...register("averageCostOfTransportationFromAirport", { required: true })} />}
                                        {editEnable && <div className="invalid-feedback">Please select your answer!</div>}
                                    </Col>
                                </Row>
                                <Row className='formSpace'>
                                    <Col md={6} style={{ textAlign: 'right' }}>
                                    </Col>
                                    <Col md={6} style={{ textAlign: 'left' }}>
                                        {!loading && !transportData && editEnable && hotelId && canBrandHotelModuleWrite(selectedBrand.key ? selectedBrand.key : selectedBrand?._id, hotelId, UserAccess.hotel_access.details.code) &&
                                            <Button type="submit" id='approve-button' style={formButton}>Create</Button>
                                        }
                                        {!loading && transportData && editEnable && hotelId && canBrandHotelModuleWrite(selectedBrand.key ? selectedBrand.key : selectedBrand?._id, hotelId, UserAccess.hotel_access.details.code) &&
                                            <Button type="submit" id='approve-button' style={formButton}>Update</Button>
                                        }

                                        {
                                            loading && <div style={{ textAlign: 'center' }}><Spinner animation="border" variant="warning" /></div>
                                        }
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={1}></Col>
                        </Row>
                    </Form>
                </div>
            </Container>

        </>
    );
};

export default TransportDetailForm;