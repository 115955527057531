import { Button, Form, Input, message, Select, Switch } from 'antd';
import { useStoreActions, useStoreState } from 'easy-peasy';
import React, { useCallback, useEffect, useState } from 'react';
import 'react-phone-input-2/lib/style.css';
import ImageUpload from '../../../common/imageUpload';

const { Option } = Select;
const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 16 },
};

const tailLayout = {
  wrapperCol: { offset: 4, span: 16 },
};

const AmenitiesForm: React.FC<any> = ({ setFormOpen, amenityDetails, amenityId }): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [icon, setIcon] = useState();
  const [iconUpdated, setIconUpdated] = useState(false);
  const [status, setStatus] = useState<string>('active');
  const [checkValue, setCheckValue] = useState<boolean>(true);

  ///////////////////////////////////////////////////////////////////////////////////////////
  const getImageBlob = useCallback((imageBlob) => {
    setIcon(imageBlob);
    setIconUpdated(true);
  }, []);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const statusChange = useCallback((value) => {
    setCheckValue(value);
    if (value) {
      setStatus('active');
    }
    else {
      setStatus('inactive');
    }
  }, []);

  ////////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (amenityId) {
      setCheckValue((amenityDetails.status === 'active') ? true : false);
      setStatus(amenityDetails.status)
      form.setFieldsValue({
        name: amenityDetails.name,
        propertyType: amenityDetails.propertyType,
        icon: amenityDetails.iconLocation,
        status: amenityDetails.status,
        type: amenityDetails.type

      })
    } else {
      setStatus('active');
      setCheckValue(true);
    }
  }, [amenityDetails, amenityId, form]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const { createAmenity, updateAmenity, getAmenityList } = useStoreActions<any>((actions) => ({
    createAmenity: actions.property.createAmenity,
    updateAmenity: actions.property.updateAmenity,
    getAmenityList: actions.property.getAmenityList,
  }));

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const {
    createAmenityError,
    createAmenitySuccess,
    updateAmenityError,
    updateAmenitySuccess,
    getAmenityListSuccess,
    getAmenityListError
  } = useStoreState<any>((state) => ({
    createAmenitySuccess: state.property.createAmenitySuccess,
    createAmenityError: state.property.createAmenityError,
    updateAmenityError: state.property.updateAmenityError,
    updateAmenitySuccess: state.property.updateAmenitySuccess,
    getAmenityListSuccess: state.property.getAmenityListSuccess,
    getAmenityListError: state.property.getAmenityListError,
  }));

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    getAmenityList();
  }, [getAmenityList]);

  //////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (getAmenityListSuccess) {

    }
    if (getAmenityListError) {
      message.error(getAmenityListError.message)
    }
  }, [getAmenityListError, getAmenityListSuccess]);

  ///////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (createAmenitySuccess) {
      message.success('Amenity created successfully');
      setTimeout(() => setFormOpen(false), 1000);
      getAmenityList();
    }

    if (updateAmenitySuccess) {
      message.success('Amenity updated successfully');
      setTimeout(() => setFormOpen(false), 1000);
      getAmenityList();
    }

    if (createAmenityError) {
      message.error(createAmenityError.message)
    }

    if (updateAmenityError) {
      message.error(updateAmenityError.message)
    }
    setLoading(false);
  }, [createAmenitySuccess, createAmenityError, updateAmenitySuccess, updateAmenityError, getAmenityList, setFormOpen]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  const renderType = useCallback(() => {
    return <Select  >
      <Option value="all">All</Option>
      <Option value="building">Building</Option>
      <Option value="accommodation">Accommodation</Option>
      <Option value="guest_comfort">Guest Comfort</Option>
    </Select>
  }, []);

  const renderPropertyType = useCallback(() => {
    return <Select  >
      <Option value="both">Both</Option>
      <Option value="serviced_accommodation">Serviced Accommodation</Option>
      <Option value="hotels">Hotels </Option>
    </Select>
  }, []);

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const onFinish = useCallback((values) => {
    setLoading(true);
    if (amenityId) {
      values.id = amenityId;
      const data = {
        id: amenityId,
        icon: iconUpdated ? icon : values.icon,
        name: values.name,
        propertyType: values.propertyType,
        type: values.type,
        status: values.status ? status : 'inactive'
      }
      updateAmenity(data);

    } else {
      values.icon = icon;
      values.status = status;
      createAmenity(values);
    }

  }, [amenityId, createAmenity, icon, iconUpdated, status, updateAmenity]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <div className="user-Form w-1/2">
      <Form
        {...layout}
        form={form}
        onFinish={onFinish}
      >
        <Form.Item
          label="Name"
          name="name"

          rules={[
            { required: true, message: 'Please input name' },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Property Type"
          name="propertyType"
          rules={[
            { required: true, message: 'Please select property type' },
          ]}
        >
          {renderPropertyType()}
        </Form.Item>
        <Form.Item
          label="Type"
          name="type"
          rules={[
            { required: true, message: 'Please select type' },
          ]}
        >
          {renderType()}
        </Form.Item>
        <Form.Item
          label="Icon"
          name="icon"
        >
          <ImageUpload getImageBlob={getImageBlob} type="image" value={icon} disabled={false} />
        </Form.Item>

        <Form.Item
          label="Status"
          name="status">
          <Switch size="small" checked={checkValue} onChange={statusChange} />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button loading={loading} type="primary" htmlType="submit">
            {amenityId ? 'Update' : 'Create'}
          </Button>
          {/* <Button onClick={() => setFormOpen(false)} className="ml-5">
            Close
          </Button> */}
        </Form.Item>

      </Form>
    </div>
  )
};

export default AmenitiesForm;