import { Checkbox } from "antd";
import { useStoreActions, useStoreState } from "easy-peasy";
import React, { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import { AmenityType } from "../../../../common/constants";
import { canBrandPropertyModuleWrite } from "../../../../common/functions";
import { UserAccess, CommonAnswer } from "../../../../common/constants";
import {
	Col,
	Form,
	Row,
	Button,
	Spinner,
	Image,
} from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { PropertyAssignAmenityFormInput } from "../../../../common/interfaces";
import { formButton } from "../../../../common/components-style";
import { toast } from 'react-toastify';
import BreadCrumbPage from '../../../common/BreadCrumbPage';
// import { HelpModal } from "../../../common/HelpModal";

// const { Option } = Select;

const BuildingAmenities: React.FC<any> = ({
	allInOnePropertyBreadCrumb,
	setFormOpen,
	propertyId,
	brandId,
	selectedBrand,
	tab
}): JSX.Element => {
	const {
		handleSubmit,
		formState: { errors },
		control,
	} = useForm<PropertyAssignAmenityFormInput>();
	// const [title, setTitle] = useState("");
	const [propertyData, setPropertyData] = useState<any>();
	// const [buildingAmenitiesData, setBuildingAmenitiesData] = useState<any>();
	const [editEnable, setEditEnable] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [amenityId, setAmenityId] = useState<Array<Object> | null>(null);
	const [amenitiesList, setAmenitiesList] = useState<any>();
	// const [amenitiesShowList, setAmenitiesShowList] = useState<any>();
	const [nearestPublicGymnasium, setNearestPublicGymnasium] = useState(false);
	const [whatAreTheCharges, setWhatAreTheCharges] = useState(false);

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const {
		getAmenityList,
		getPropertyAmenities,
		assignPropertyAmenity,
		resetProperty,
		getProperty,
	} = useStoreActions<any>((actions) => ({
		getAmenityList: actions.property.getAmenityList,
		getPropertyAmenities: actions.property.getPropertyAmenities,
		assignPropertyAmenity: actions.property.assignPropertyAmenity,
		resetProperty: actions.property.resetProperty,
		getProperty: actions.property.getProperty,
	}));

	////////////////////////////////////////////////////////////////////////////////////////////////////
	const {
		getAmenityListSuccess,
		getAmenityListError,
		getPropertyAmenitiesSuccess,
		getPropertyAmenitiesError,
		assignPropertyAmenitySuccess,
		assignPropertyAmenityError,
		getPropertySuccess,
	} = useStoreState<any>((state) => ({
		getAmenityListSuccess: state.property.getAmenityListSuccess,
		getAmenityListError: state.property.getAmenityListError,
		getPropertyAmenitiesSuccess: state.property.getPropertyAmenitiesSuccess,
		getPropertyAmenitiesError: state.property.getPropertyAmenitiesError,
		assignPropertyAmenitySuccess: state.property.assignPropertyAmenitySuccess,
		assignPropertyAmenityError: state.property.assignPropertyAmenityError,
		getPropertySuccess: state.property.getPropertySuccess,
	}));

	//////////////////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		getAmenityList();
	}, [getAmenityList]);

	///////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (propertyId) {
			getPropertyAmenities(propertyId);
			getProperty(propertyId);
		}
	}, [getProperty, getPropertyAmenities, propertyId]);

	//////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (assignPropertyAmenitySuccess) {
			setLoading(false);
			resetProperty();
			toast.success("Property amenity assigned successfully", {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
			setEditEnable(false);
			getPropertyAmenities(propertyId);
		}
		if (assignPropertyAmenityError) {
			setLoading(false);
			toast.error(assignPropertyAmenityError.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
		}
	}, [resetProperty, assignPropertyAmenitySuccess, assignPropertyAmenityError, getPropertyAmenities, propertyId]);

	//////////////////////////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		if (getAmenityListSuccess) {
			if (getAmenityListSuccess.data === null) {
				toast.warning("No data found", {
					position: toast.POSITION.BOTTOM_RIGHT,
					className: 'foo-bar'
				});
			} else {
				setAmenitiesList(getAmenityListSuccess.data);
			}
		}
		if (getAmenityListError) {
			toast.error(getAmenityListError.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
		}

		if (getPropertyAmenitiesSuccess) {
			if (getPropertyAmenitiesSuccess.data === null) {
				// message.warning('No assigned amenity data found');
			} else {
				// setBuildingAmenitiesData(getPropertyAmenitiesSuccess.data);
				const amenity = getPropertyAmenitiesSuccess.data.amenity;
				// setAmenitiesShowList(amenity);
				const propertyAmenity: Array<Object> = [];
				_.map(amenity, (element) => propertyAmenity.push(element._id));
				setAmenityId(propertyAmenity);
				// form.setFieldsValue({
				// 	amenity: propertyAmenity,
				// 	complimentaryWifi: getPropertyAmenitiesSuccess.data.complimentaryWifi,
				// 	doYouHaveAGymnasiumOnSite: getPropertyAmenitiesSuccess.data.doYouHaveAGymnasiumOnSite,
				// 	doesThePropertyHaveABusinessCenter: getPropertyAmenitiesSuccess.data.doesThePropertyHaveABusinessCenter,
				// 	doesThisGymnasiumOffer: getPropertyAmenitiesSuccess.data.doesThisGymnasiumOffer,
				// 	nearestConvenienceFoodStore: getPropertyAmenitiesSuccess.data.nearestConvenienceFoodStore
				// });

				if (
					getPropertyAmenitiesSuccess.data.doYouHaveAGymnasiumOnSite ===
					CommonAnswer.NO
				) {
					setNearestPublicGymnasium(true);
					// form.setFieldsValue({
					// 	nearestPublicGymnasium: getPropertyAmenitiesSuccess.data.nearestPublicGymnasium
					// });
				} else {
					setNearestPublicGymnasium(false);
					// form.setFieldsValue({
					// 	nearestPublicGymnasium: ''
					// });
				}

				if (
					getPropertyAmenitiesSuccess.data.complimentaryWifi === CommonAnswer.NO
				) {
					setWhatAreTheCharges(true);
					// form.setFieldsValue({
					// 	whatAreTheCharges: getPropertyAmenitiesSuccess.data.whatAreTheCharges
					// });
				} else {
					setWhatAreTheCharges(false);
					// form.setFieldsValue({
					// 	whatAreTheCharges: ''
					// });
				}
			}
		}
		if (getPropertyAmenitiesError) {
			toast.error(getPropertyAmenitiesError.message, {
				position: toast.POSITION.BOTTOM_RIGHT,
				className: 'foo-bar'
			});
		}

		if (getPropertySuccess) {
			setPropertyData(getPropertySuccess.data);
			// setTitle(getPropertySuccess.data?.propertyName);
		}
	}, [
		getAmenityListError,
		getAmenityListSuccess,
		getPropertyAmenitiesSuccess,
		getPropertyAmenitiesError,
		getPropertySuccess,
	]);

	/////////////////////////////////////////////////////////////////////////////////////////////////////
	const renderAmenities = useCallback(() => {
		if (amenitiesList) {
			const data = _.filter(
				amenitiesList,
				(user) =>
					user.type === AmenityType.all || user.type === AmenityType.building
			);
			return data.map((at: any, key: number) => {
				return (
					<Row className="mt-3" key={key}>
						<Col md={2}>
							<div style={{ textAlign: "right" }}>
								<Image src={at.iconLocation} alt="" width={40} />
							</div>
						</Col>
						<Col md={10}>
							<div style={{ marginTop: "2%", marginLeft: '-9%' }}>
								<Checkbox value={`${at._id}`}></Checkbox><span style={{ marginLeft: '3%' }}>{at.name}</span>
							</div>
						</Col>
					</Row>
				);
			});
		}
	}, [amenitiesList]);

	// /////////////////////////////////////////////////////////////////////////////////////////////////////
	// const renderShowAmenities = useCallback(() => {
	// 	if (amenitiesShowList) {
	// 		const data = _.filter(
	// 			amenitiesShowList,
	// 			(user) =>
	// 				user.type === AmenityType.all || user.type === AmenityType.building
	// 		);
	// 		return data.map((at: any, key: number) => {
	// 			return (
	// 				<>
	// 					<Row className="mt-3" key={key}>
	// 						<Col md={2}>
	// 							<div style={{ textAlign: "right" }}>
	// 								<Image src={at.iconLocation} alt="" width={40} />
	// 							</div>
	// 						</Col>
	// 						<Col md={10} >
	// 							<div style={{ marginTop: "2%",marginLeft: '-9%'}}>
	// 								<p>{at.name}</p>
	// 							</div>
	// 						</Col>
	// 					</Row>
	// 				</>
	// 			);
	// 		});
	// 	}
	// }, [amenitiesShowList]);

	// //////////////////////////////nearestPublicGymnasium validation manage////////////////////////////////
	// const onChangeDoYouHaveAGymnasiumOnSite = useCallback((value) => {
	// 	if (value === CommonAnswer.NO) {
	// 		setNearestPublicGymnasium(true);
	// 	} else {
	// 		setNearestPublicGymnasium(false);
	// 	}
	// }, []);

	// ////////////////////////////////whatAreTheCharges validation manage //////////////////////////////////
	// const onChangeComplimentaryWifi = useCallback((value) => {
	// 	if (value === CommonAnswer.NO) {
	// 		setWhatAreTheCharges(true);
	// 	} else {
	// 		setWhatAreTheCharges(false);
	// 	}
	// }, []);

	//////////////////////////////////////////////////////////////////////////////////////////////////////
	const onFinish = (values) => {
		setLoading(true);
		values.propertyId = propertyId;

		if (!nearestPublicGymnasium) {
			values.nearestPublicGymnasium = "";
		}

		if (!whatAreTheCharges) {
			values.whatAreTheCharges = "";
		}
		assignPropertyAmenity(values);
	};

	const changeFormEditable = useCallback(() => {
		if (editEnable) {
			setEditEnable(false);
		} else {
			setEditEnable(true);
		}
	}, [editEnable]);

	///////////////////////////////////////////////////////////////////////////////////////////////////////
	return (
		<>
			<div className="content-title fixed_title">
				{
					// title && (
					// 	<Row>
					// 		<Col xs={12}>
					// 			<div className="content-title">
					// 				{title} - {propertyData?.city}
					// 			</div>
					// 		</Col>
					// 	</Row>
					// )
					<BreadCrumbPage tab={tab} allInOnePropertyBreadCrumb={allInOnePropertyBreadCrumb} isPageName="Property" selectedBrand={selectedBrand} propertyData={propertyData} setFormOpen={setFormOpen} ></BreadCrumbPage>
				}
				<div className="edit_btn_wpr">
					<div className='d-flex align-items-center justify-content-end gx-2'>
						{/* <HelpModal /> */}
						{
							!loading && !editEnable && propertyId && canBrandPropertyModuleWrite(brandId, propertyId, UserAccess.property_access.details.code) && <Button id='edit-button' className="mb-0" style={formButton} onClick={changeFormEditable} >Edit</Button>
						}
					</div>
				</div>
			</div>
			<div>
				<div className="jumbotron">
					<Form onSubmit={handleSubmit(onFinish)}>
						<Row>
							<Col md={12}>
								<Row className="formSpace">
									<Col md={6} style={{ textAlign: "right" }}>
										<Form.Label>
											{editEnable && <span className="requiredMark">*</span>}
											Please indicate what amenities you offer within the building
										</Form.Label>
									</Col>
									<Col md={6} style={{ textAlign: "left" }}>
										{!editEnable && <>
											{
												amenityId ?
													<Checkbox.Group value={Object(amenityId)} style={{ width: '100%' }} >
														{renderAmenities()}
													</Checkbox.Group>
													:
													<Checkbox.Group value={[]} style={{ width: '100%' }} >
														{renderAmenities()}
													</Checkbox.Group>
											}
										</>

										}
										{editEnable && (
											<Controller
												name="amenity"
												defaultValue={Object(amenityId)}
												control={control}
												rules={{
													required: true,
												}}
												render={({ field }) => (
													<>
														{amenityId ?
															<Checkbox.Group name="amenity" defaultValue={Object(amenityId)} style={{ width: '100%' }} onChange={(value) => { field.onChange(value); }}>
																{renderAmenities()}
															</Checkbox.Group>
															:
															<Checkbox.Group name="amenity" style={{ width: '100%' }} onChange={(value) => { field.onChange(value); }}>
																{renderAmenities()}
															</Checkbox.Group>
														}

													</>
												)}
											/>
										)}
										{editEnable && errors.amenity && (
											<div className="invalid-feedback-custom">
												Please select a amenities!
											</div>
										)}
									</Col>
								</Row>
								{/* <Row className="formSpace">
									<Col md={6} style={{ textAlign: "right" }}>
										<Form.Label>
											{editEnable && <span className="requiredMark">*</span>}
											How long would it take to walk to the nearest convenience
											food store? (Minutes)
										</Form.Label>
									</Col>
									<Col md={6} style={{ textAlign: "left" }}>
										{!editEnable && (
											<p className="lead">
												{buildingAmenitiesData?.nearestConvenienceFoodStore}
											</p>
										)}
										{editEnable && (
											<Form.Control
												type="number"
												min={0}
												className={`${errors.nearestConvenienceFoodStore ? "is-invalid" : ""
													}`}
												defaultValue={
													buildingAmenitiesData?.nearestConvenienceFoodStore
												}
												{...register("nearestConvenienceFoodStore", {
													required: true,
												})}
											/>
										)}
										{editEnable && errors.nearestConvenienceFoodStore && (
											<div className="invalid-feedback">
												Please input your answer!
											</div>
										)}
									</Col>
								</Row>
								<Row className="formSpace">
									<Col md={6} style={{ textAlign: "right" }}>
										<Form.Label>
											{editEnable && <span className="requiredMark">*</span>} Do
											you have a gymnasium on site
										</Form.Label>
									</Col>
									<Col md={6} style={{ textAlign: "left" }}>
										{!editEnable && (
											<p className="lead">
												{buildingAmenitiesData?.doYouHaveAGymnasiumOnSite}
											</p>
										)}
										{editEnable && (
											<Controller
												name="doYouHaveAGymnasiumOnSite"
												defaultValue={buildingAmenitiesData?.doYouHaveAGymnasiumOnSite}
												control={control}
												rules={{
													required: true,
												}}
												render={({ field }) =>
													<>
														{
															buildingAmenitiesData?.doYouHaveAGymnasiumOnSite === 'YES'
																?
																<Form.Check
																	inline
																	value={'YES'}
																	name="doYouHaveAGymnasiumOnSite"
																	label={'Yes'}
																	type="radio"
																	defaultChecked
																	onChange={(value) => { field.onChange(value.currentTarget.value); onChangeDoYouHaveAGymnasiumOnSite(value.currentTarget.value); }}
																/>
																:
																<Form.Check
																	inline
																	value={'YES'}
																	name="doYouHaveAGymnasiumOnSite"
																	label={'Yes'}
																	type="radio"
																	onChange={(value) => { field.onChange(value.currentTarget.value); onChangeDoYouHaveAGymnasiumOnSite(value.currentTarget.value); }}
																/>
														}

														{
															buildingAmenitiesData?.doYouHaveAGymnasiumOnSite === 'NO'
																?
																<Form.Check
																	inline
																	value={'NO'}
																	name="doYouHaveAGymnasiumOnSite"
																	label={'No'}
																	type="radio"
																	defaultChecked
																	onChange={(value) => { field.onChange(value.currentTarget.value); onChangeDoYouHaveAGymnasiumOnSite(value.currentTarget.value); }}
																/>
																:
																<Form.Check
																	inline
																	value={'NO'}
																	name="doYouHaveAGymnasiumOnSite"
																	label={'No'}
																	type="radio"
																	onChange={(value) => { field.onChange(value.currentTarget.value); onChangeDoYouHaveAGymnasiumOnSite(value.currentTarget.value); }}
																/>
														}

													</>
												}
											/>)}
										{editEnable &&
											<ErrorMessage
												errors={errors}
												name="doYouHaveAGymnasiumOnSite"
												render={({ message }) => <div className="invalid-feedback-custom">Please select your answer!</div>} />}
									</Col>
								</Row>
								{nearestPublicGymnasium && (
									<Row className="formSpace">
										<Col md={6} style={{ textAlign: "right" }}>
											<Form.Label>
												{editEnable && <span className="requiredMark">*</span>}{" "}
												If not, how far is the property from the nearest public
												gymnasium
											</Form.Label>
										</Col>
										<Col md={6} style={{ textAlign: "left" }}>
											{!editEnable && (
												<p className="lead">
													{buildingAmenitiesData?.nearestPublicGymnasium}
												</p>
											)}
											{editEnable && (
												<Form.Control
													className={`${errors.nearestPublicGymnasium ? "is-invalid" : ""
														}`}
													defaultValue={
														buildingAmenitiesData?.nearestPublicGymnasium
													}
													{...register("nearestPublicGymnasium", {
														required: true,
													})}
												/>
											)}
											{editEnable && errors.nearestPublicGymnasium && (
												<div className="invalid-feedback">
													Please input your answer!
												</div>
											)}
										</Col>
									</Row>
								)}
								<Row className="formSpace">
									<Col md={6} style={{ textAlign: "right" }}>
										<Form.Label>
											{editEnable && <span className="requiredMark">*</span>}{" "}
											Does this gymnasium offer short term membership or
											pay-as-you-go
										</Form.Label>
									</Col>
									<Col md={6} style={{ textAlign: "left" }}>
										{!editEnable && (
											<p className="lead">
												{buildingAmenitiesData?.doesThisGymnasiumOffer}
											</p>
										)}
										{editEnable && (
											<Controller
												name="doesThisGymnasiumOffer"
												defaultValue={buildingAmenitiesData?.doesThisGymnasiumOffer}
												control={control}
												rules={{
													required: true,
												}}
												render={({ field }) =>
													<>
														{
															buildingAmenitiesData?.doesThisGymnasiumOffer === 'YES'
																?
																<Form.Check
																	inline
																	value={'YES'}
																	name="doesThisGymnasiumOffer"
																	label={'Yes'}
																	type="radio"
																	defaultChecked
																	onChange={(value) => { field.onChange(value.currentTarget.value); }}
																/>
																:
																<Form.Check
																	inline
																	value={'YES'}
																	name="doesThisGymnasiumOffer"
																	label={'Yes'}
																	type="radio"
																	onChange={(value) => { field.onChange(value.currentTarget.value); }}
																/>
														}

														{
															buildingAmenitiesData?.doesThisGymnasiumOffer === 'NO'
																?
																<Form.Check
																	inline
																	value={'NO'}
																	name="doesThisGymnasiumOffer"
																	label={'No'}
																	type="radio"
																	defaultChecked
																	onChange={(value) => { field.onChange(value.currentTarget.value); }}
																/>
																:
																<Form.Check
																	inline
																	value={'NO'}
																	name="doesThisGymnasiumOffer"
																	label={'No'}
																	type="radio"
																	onChange={(value) => { field.onChange(value.currentTarget.value); }}
																/>
														}

													</>
												}
											/>)}
										{editEnable &&
											<ErrorMessage
												errors={errors}
												name="doesThisGymnasiumOffer"
												render={({ message }) => <div className="invalid-feedback-custom">Please select your answer!</div>} />}
									</Col>
								</Row>
								<Row className="formSpace">
									<Col md={6} style={{ textAlign: "right" }}>
										<Form.Label>
											{editEnable && <span className="requiredMark">*</span>}{" "}
											Does the property have a business center
										</Form.Label>
									</Col>
									<Col md={6} style={{ textAlign: "left" }}>
										{!editEnable && (
											<p className="lead">
												{
													buildingAmenitiesData?.doesThePropertyHaveABusinessCenter
												}
											</p>
										)}
										{editEnable && (
											<Controller
												name="doesThePropertyHaveABusinessCenter"
												defaultValue={buildingAmenitiesData?.doesThePropertyHaveABusinessCenter}
												control={control}
												rules={{
													required: true,
												}}
												render={({ field }) =>
													<>
														{
															buildingAmenitiesData?.doesThePropertyHaveABusinessCenter === 'YES'
																?
																<Form.Check
																	inline
																	value={'YES'}
																	name="doesThePropertyHaveABusinessCenter"
																	label={'Yes'}
																	type="radio"
																	defaultChecked
																	onChange={(value) => { field.onChange(value.currentTarget.value); }}
																/>
																:
																<Form.Check
																	inline
																	value={'YES'}
																	name="doesThePropertyHaveABusinessCenter"
																	label={'Yes'}
																	type="radio"
																	onChange={(value) => { field.onChange(value.currentTarget.value); }}
																/>
														}

														{
															buildingAmenitiesData?.doesThePropertyHaveABusinessCenter === 'NO'
																?
																<Form.Check
																	inline
																	value={'NO'}
																	name="doesThePropertyHaveABusinessCenter"
																	label={'No'}
																	type="radio"
																	defaultChecked
																	onChange={(value) => { field.onChange(value.currentTarget.value); }}
																/>
																:
																<Form.Check
																	inline
																	value={'NO'}
																	name="doesThePropertyHaveABusinessCenter"
																	label={'No'}
																	type="radio"
																	onChange={(value) => { field.onChange(value.currentTarget.value); }}
																/>
														}

													</>
												}
											/>)}
										{editEnable &&
											<ErrorMessage
												errors={errors}
												name="doesThePropertyHaveABusinessCenter"
												render={({ message }) => <div className="invalid-feedback-custom">Please select your answer!</div>} />}
									</Col>
								</Row>
								<Row className="formSpace">
									<Col md={6} style={{ textAlign: "right" }}>
										<Form.Label>
											{editEnable && <span className="requiredMark">*</span>} Do
											you offer complimentary WIFI
										</Form.Label>
									</Col>
									<Col md={6} style={{ textAlign: "left" }}>
										{!editEnable && (
											<p className="lead">
												{buildingAmenitiesData?.complimentaryWifi}
											</p>
										)}
										{editEnable && (

											<Controller
												name="complimentaryWifi"
												defaultValue={buildingAmenitiesData?.complimentaryWifi}
												control={control}
												rules={{
													required: true,
												}}
												render={({ field }) =>
													<>
														{
															buildingAmenitiesData?.complimentaryWifi === 'YES'
																?
																<Form.Check
																	inline
																	value={'YES'}
																	name="complimentaryWifi"
																	label={'Yes'}
																	type="radio"
																	defaultChecked
																	onChange={(value) => { field.onChange(value.currentTarget.value); onChangeComplimentaryWifi(value.currentTarget.value); }}
																/>
																:
																<Form.Check
																	inline
																	value={'YES'}
																	name="complimentaryWifi"
																	label={'Yes'}
																	type="radio"
																	onChange={(value) => { field.onChange(value.currentTarget.value); onChangeComplimentaryWifi(value.currentTarget.value); }}
																/>
														}

														{
															buildingAmenitiesData?.complimentaryWifi === 'NO'
																?
																<Form.Check
																	inline
																	value={'NO'}
																	name="complimentaryWifi"
																	label={'No'}
																	type="radio"
																	defaultChecked
																	onChange={(value) => { field.onChange(value.currentTarget.value); onChangeComplimentaryWifi(value.currentTarget.value); }}
																/>
																:
																<Form.Check
																	inline
																	value={'NO'}
																	name="complimentaryWifi"
																	label={'No'}
																	type="radio"
																	onChange={(value) => { field.onChange(value.currentTarget.value); onChangeComplimentaryWifi(value.currentTarget.value); }}
																/>
														}

													</>
												}
											/>)}
										{editEnable &&
											<ErrorMessage
												errors={errors}
												name="complimentaryWifi"
												render={({ message }) => <div className="invalid-feedback-custom">Please select your answer!</div>} />}
									</Col>
								</Row>
								{whatAreTheCharges && (
									<Row className="formSpace">
										<Col md={6} style={{ textAlign: "right" }}>
											<Form.Label>
												{editEnable && <span className="requiredMark">*</span>}{" "}
												What are the charges
											</Form.Label>
										</Col>
										<Col md={6} style={{ textAlign: "left" }}>
											{!editEnable && (
												<p className="lead">
													{buildingAmenitiesData?.whatAreTheCharges}
												</p>
											)}
											{editEnable && (
												<Form.Control
													type="number"
													min={0}
													className={`${errors.whatAreTheCharges ? "is-invalid" : ""
														}`}
													defaultValue={
														buildingAmenitiesData?.whatAreTheCharges
													}
													{...register("whatAreTheCharges", { required: true })}
												/>
											)}
											{editEnable && errors.whatAreTheCharges && (
												<div className="invalid-feedback">
													Please input your answer!
												</div>
											)}
										</Col>
									</Row>
								)} */}
								<Row className='formSpace'>
									<Col md={6} style={{ textAlign: 'right' }}>
									</Col>
									<Col md={6} style={{ textAlign: 'left' }}>
										{!loading && editEnable && propertyId && canBrandPropertyModuleWrite(brandId, propertyId, UserAccess.property_access.details.code) &&
											<Button type="submit" id='approve-button' style={formButton}>Update</Button>
										}

										{
											loading && <div style={{ textAlign: 'center' }}><Spinner animation="border" variant="warning" /></div>
										}
									</Col>
								</Row>
							</Col>
						</Row>
					</Form>
				</div>
			</div>
		</>
	);
};

export default BuildingAmenities;
