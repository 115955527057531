import React, { useEffect, useMemo, useState } from 'react';
import { Card, Col, Container, Form, Row, Image, Button, Spinner } from 'react-bootstrap';
import { $mysaYellow, paymentScreen, routingButton, selectStylesLittleRect } from '../../../common/components-style';
import shareIcon from '../../../images/icons/svg/outside/share-icon.svg';
import cardIcon from '../../../images/icons/svg/outside/card-icon.svg';
import cardsIcon from '../../../images/icons/svg/outside/cards-icon.svg';
import masterCard from '../../../images/icons/svg/outside/master-card.svg';
import closeSvg from '../../../images/icons/svg/outside/close.svg';
import visaSvg from '../../../images/icons/svg/outside/visa-card.svg';
import TravellerProfilePaymentInformationEdit from './TravellerProfilePaymentInformationEdit';
import { useHistory } from 'react-router-dom';
import TravelerHeaderPage from '../booking-travelerPage/TravelerHeaderPage';
import TravellerProfile from '../booking-travelerPage/TravellerProfile';
import Select from 'react-select';
import { capitalizeRole } from '../../../common/functions';
import { getStorage } from '../../../common/storage';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { UserGroups } from '../../../common/constants';
import _ from 'lodash';
import TravellerProfileForm from '../booking-travelerPage/TravelerProfileForm';
import { TravelerInput } from '../../../common/interfaces';
import { Controller, useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';

const button = {
    ...routingButton,
    width: '100%',
    background: $mysaYellow,
    fontSize: '20px'
}

const TravellerProfilePayment: React.FC<any> = ({ location }): JSX.Element => {
    const [showPaymentDetails, setShowPaymentDetails] = useState<boolean>(false);
    const [showProfile, setShowProfile] = useState<boolean>(false);
    const { push } = useHistory();
    const [loading, setLoading] = useState<boolean>(false);
    const [termPdf, setTermPdf] = useState<any>();


    /**
     * form 
     */

    const { handleSubmit, formState: { errors }, control } = useForm<TravelerInput>();

    /**
     * global action
     */

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const { getUserMapping, addTravelerToSecureRateKey, createLiveBooking, getProperty } = useStoreActions<any>((actions) => ({
        getUserMapping: actions.user.getUserMapping,
        addTravelerToSecureRateKey: actions.booking.addTravelerToSecureRateKey,
        createLiveBooking: actions.booking.createLiveBooking,
        getProperty: actions.property.getProperty
    }));

    /**
     * global state
     */
    ////////////////////////////////////////////////////////////////////////////////////////////////////
    const { getUserMappingSuccess, getCreateLiveBookingSuccess, getCreateLiveBookingError, getPropertySuccess } = useStoreState<any>((state) => ({
        getUserMappingSuccess: state.user.getUserMappingSuccess,
        getCreateLiveBookingSuccess: state.booking.getCreateLiveBookingSuccess,
        getCreateLiveBookingError: state.booking.getCreateLiveBookingError,
        getPropertySuccess: state.property.getPropertySuccess,
    }));


    /**
     * storage
     */

    const clientId = getStorage('makeBookingClient') ? getStorage('makeBookingClient') : getStorage('selectedFilterClient')?.value;
    const programmeId = getStorage('makeBookingProgram')?.value ? getStorage('makeBookingProgram')?.value : getStorage('selectedProgramsOption')?.value ? getStorage('programmeId') : getStorage('programmeData')?._id;


    /**
     * react lifecycle side effect
     */

    useEffect(() => {
        const clientId = getStorage('makeBookingClient') ? getStorage('makeBookingClient') : getStorage('selectedFilterClient')?.value;
        getUserMapping({ clientId: clientId });
    }, [getUserMapping]);

    /**
     * user list
     */
    const data = useMemo(() => {
        if (getUserMappingSuccess) {
            if (getUserMappingSuccess.data) {
                const { result, poolUsers } = getUserMappingSuccess.data;

                const mappedData = _.map(result, (val) => {
                    const corporateProgramme = _.filter(val.clients, (a) => a.clientId === clientId).shift()?.corporateProgramme;
                    const userStatus = poolUsers[val?.user?.email]?.UserStatus
                    return {
                        key: val?.user?._id,
                        userId: val?.user?._id,
                        firstName: val?.user?.firstName,
                        lastName: val?.user?.lastName,
                        email: val?.user?.email,
                        phone: val?.user?.phone,
                        mobile: val?.user?.mobile,
                        position: val?.user?.position,
                        userProfile: val?.user?.userProfile,
                        userStatus: userStatus,
                        data: val,
                        corporateProgramme

                    }
                });

                let filterData: any = [];
                filterData = _.map(mappedData, (val) => {
                    const arrId = _.filter(val.corporateProgramme, (a) => a.programmeId === programmeId).shift()?._id || ''
                    const access = _.filter(val.corporateProgramme, (a) => a.programmeId === programmeId).shift()?.access || ''
                    const role = _.filter(val.corporateProgramme, (a) => a.programmeId === programmeId).shift()?.role || ''
                    const isFirstUser = _.filter(val.corporateProgramme, (a) => a.programmeId === programmeId).shift()?.isFirstUser
                    const corporateProgrammeId = _.filter(val.corporateProgramme, (a) => a.programmeId === programmeId).shift()?.programmeId

                    return {
                        key: val?.key,
                        userId: val.userId,
                        firstName: val.firstName,
                        lastName: val.lastName,
                        email: val.email,
                        phone: val.phone,
                        mobile: val.mobile,
                        position: val.position,
                        userProfile: val.userProfile,
                        userStatus: val.userStatus,
                        data: val.data,
                        role,
                        arrId,
                        access,
                        isFirstUser,
                        corporateProgramme: val.corporateProgramme,
                        corporateProgrammeId,
                        userType: capitalizeRole(role),

                    }
                })

                filterData = _.filter(filterData, (a) => a.corporateProgrammeId === programmeId && a.role === UserGroups.traveler);
                return filterData;
            }
        }
        return []
    }, [clientId, getUserMappingSuccess, programmeId]);

    useEffect(() => {
        if (getCreateLiveBookingSuccess?.data) {

            push('/admin/confirmation-page', { query: location?.state?.query, bookingID: getCreateLiveBookingSuccess?.data[0]?._id });
            setLoading(false);
        }

        if (getCreateLiveBookingError) {
            setLoading(false);
            toast.error(getCreateLiveBookingError?.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'foo-bar'
            });
        }

    }, [getCreateLiveBookingError, getCreateLiveBookingSuccess?.data, location?.state?.query, push]);

    /**
     * generate options
     */
    const options: any = [];

    if (data) {
        for (const option of data) {
            const lastName = (option.lastName !== undefined) ? option.lastName : '';
            const label = { value: `${option.userId}`, label: `${option.firstName} ${lastName}` };
            options.push(label);
        };
    }

    /**
     * @description show and hide
     */
    const showTravellerFormAndHide = (value: boolean) => {
        setShowProfile(value);
        const clientId = getStorage('makeBookingClient') ? getStorage('makeBookingClient') : getStorage('programmeData')?.clientId?._id;
        getUserMapping({ clientId: clientId });
    };

    /**
     * submit data
     */
    const onSubmit = (values) => {
        setLoading(true);

        const payload = {
            secureRateKey: location?.state?.secureRateKeyId
        }

        createLiveBooking(payload);

        // push('/admin/confirmation-page', { query: location?.state?.query, bookingID: '64c6bbf84a36b7007455481d' });
    }

    useEffect(() => {

        if (location?.state?.query?.propertyId) {
            getProperty(location?.state?.query?.propertyId)
        }
    }, [getProperty, location?.state?.query?.propertyId]);


    useEffect(() => {
        if (getPropertySuccess) {
            const data = getPropertySuccess?.data;
            setTermPdf(data?.termPdf);

        }


    }, [getPropertySuccess]);


    return (
        <>
            <Row>
                <TravelerHeaderPage bookingParams={location} />
            </Row>

            <Row style={{
                marginTop: '80px'
            }}>
                <hr style={{
                    width: '90vw'
                }} />
                <Col md={4}>
                    <TravellerProfile params={location} />
                </Col>
                <Col md={8} >
                    {
                        !showProfile && !showPaymentDetails && <Card style={paymentScreen}>
                            <Card.Body>
                                <Form onSubmit={handleSubmit(onSubmit)}>
                                    <Container style={{ marginLeft: '30px', }}>
                                        <Card.Title style={{ fontSize: '30px', fontWeight: 'bold', marginTop: '30px' }}>
                                            <Row>
                                                <Col md={1}>
                                                    <Image src={shareIcon} />
                                                </Col>
                                                <Col md={11} style={{ marginLeft: '-45px' }}>
                                                    Traveller Details
                                                </Col>
                                            </Row>
                                        </Card.Title>
                                        <div style={{ marginTop: '4%' }}>
                                            <Card.Subtitle className="mb-2 text-muted"><span className='requiredMark'>*</span><span style={{ color: '#000000' }}>Search Travellers</span></Card.Subtitle>
                                            <Row>
                                                <Col md={8}>
                                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                                        <Col >
                                                            <Controller
                                                                name="name"
                                                                control={control}
                                                                defaultValue={location?.state?.selectTraveller}
                                                                rules={{
                                                                    required: true,
                                                                }}
                                                                render={({ field }) =>
                                                                    <Select
                                                                        isClearable
                                                                        defaultValue={location?.state?.selectTraveller}
                                                                        placeholder={<div>Select a Traveller</div>}
                                                                        options={options}
                                                                        styles={selectStylesLittleRect}
                                                                        className={`${errors.name ? 'is-invalid' : ''} font-light`}
                                                                        onChange={(data) => {
                                                                            field.onChange(data);

                                                                            const payload = {
                                                                                secureRateKeyId: location?.state?.secureRateKeyId,
                                                                                travelerId: data.value
                                                                            };

                                                                            addTravelerToSecureRateKey(payload);
                                                                        }}
                                                                    />
                                                                }
                                                            />

                                                            <ErrorMessage
                                                                errors={errors}
                                                                name="name"
                                                                render={({ message }) => <div style={{ marginLeft: '5px' }} className="invalid-feedback-custom">Please select a Traveller!</div>} />
                                                        </Col>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={4}>
                                                    <Card.Subtitle className="mb-2" style={{ marginTop: '5%' }}><span style={{ color: '#000000', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => setShowProfile(true)}>+ Add a new traveller</span></Card.Subtitle>
                                                </Col>
                                            </Row>

                                        </div>
                                    </Container>
                                    <Container style={{ marginLeft: '30px' }}>
                                        {/* <Card.Title style={{ fontSize: '30px', fontWeight: 'bold', marginTop: '30px' }}>
                                            <Row>
                                                <Col md={1}>
                                                    <Image src={cardIcon} />
                                                </Col>
                                                <Col md={5} style={{ marginLeft: '-45px' }}>
                                                    Payment Information
                                                </Col>
                                                <Col md={6} >
                                                    <Image src={cardsIcon} />
                                                </Col>
                                            </Row>
                                        </Card.Title> */}
                                        {/* <div style={{ marginRight: '8%' }}>
                                            <div style={{ marginTop: '4%', border: '1px solid #FBC91D', borderRadius: '30px' }}>
                                                <div style={{ padding: '40px' }}>
                                                    <Row>
                                                        <Col md={1}>
                                                            <Form.Check
                                                                inline
                                                                name="group1"
                                                                type='radio'
                                                            />
                                                        </Col>
                                                        <Col md={2}>
                                                            <Image src={masterCard} />
                                                        </Col>
                                                        <Col md={4}>
                                                            <div>
                                                                <p>Emma Stone</p>
                                                                <div>Mastercard <span style={{ marginLeft: '10px' }}>********4567</span></div>
                                                            </div>
                                                        </Col>
                                                        <Col md={4}>
                                                            <p></p>
                                                            <div>Expires on 10/24</div>
                                                        </Col>
                                                        <Col md={1}>
                                                            <Image src={closeSvg} />
                                                        </Col>
                                                    </Row>
                                                    <Row style={{ marginTop: '10px' }}>
                                                        <Col md={1}>
                                                            <Form.Check
                                                                inline
                                                                name="group1"
                                                                type='radio'
                                                            />
                                                        </Col>
                                                        <Col md={2}>
                                                            <Image src={visaSvg} />
                                                        </Col>
                                                        <Col md={4}>
                                                            <div>
                                                                <p>Emma Stone</p>
                                                                <div>Visa Debit <span style={{ marginLeft: '10px' }}>********2345</span></div>
                                                            </div>
                                                        </Col>
                                                        <Col md={4}>
                                                            <p></p>
                                                            <div>Expires on 10/24</div>
                                                        </Col>
                                                        <Col md={1}>
                                                            <Image src={closeSvg} />
                                                        </Col>
                                                    </Row>
                                                    <Row style={{ textAlign: 'right' }}>
                                                        <Card.Subtitle className="mb-2" style={{ marginTop: '2%' }}><span style={{ color: '#000000', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => setShowPaymentDetails(true)} >+ Add new card</span></Card.Subtitle>
                                                    </Row>
                                                </div>
                                            </div>
                                        </div> */}
                                        <Row className='terms-band'>
                                            <div className='terms-band-desc'>
                                                Details of the booking will be sent directly to the property. The property will communicate with you directly in relation to credit card details to finalize the booking process.
                                                <div className="terms-band-link">
                                                    <a
                                                        href={termPdf}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        Operator Terms & Conditions
                                                    </a>
                                                </div>
                                            </div>
                                        </Row>
                                        <Row>
                                            <Col md={11}>
                                                {
                                                    !loading && <Button type='submit' style={button} id='routing-button' ><b>Complete Booking</b></Button>
                                                }

                                                {
                                                    loading && <div style={{ textAlign: 'center' }}><Spinner animation="border" variant="warning" /></div>
                                                }


                                            </Col>
                                        </Row>
                                    </Container>
                                </Form>
                            </Card.Body>
                        </Card>
                    }

                    {
                        showPaymentDetails && !showProfile && <TravellerProfilePaymentInformationEdit location={location} />

                    }

                    {showProfile && !showPaymentDetails && < TravellerProfileForm showTravellerFormAndHide={showTravellerFormAndHide} />}
                </Col>
            </Row>


        </>
    );

}

export default TravellerProfilePayment;