import { FC, useMemo, useState } from "react";
import moment from "moment";
import DataTable from "react-data-table-component";
import { overlayTriggerDelay, tableStyles } from "../../../common/components-style";
import { OverlayTrigger, Stack, Tooltip, Spinner } from "react-bootstrap";
import ViewEdit from '../../../images/icons/svg/eyeIcon.svg';
import { useHistory } from "react-router-dom";
import { setStorage } from '../../../common/storage';


const ProgrammeSpendDataList: FC<any> = ({ reportList, loading, loadForm, loadData, setRefreshReportList, selectedClient, programmeId, selectedProgramme, reportName }): JSX.Element => {
	const [loadingId, setLoadingId] = useState<any>('randomText');
	const history = useHistory<any>();

	// ///////////////////////////////////////////////////////////////////////////////////
	const memoizedReportData = useMemo(() => {

		return reportList.map((list: any) => {
			setLoadingId('randomText')
			return {
				key: list?._id,
				startDate: list?.startDate,
				endDate: list?.endDate,
				reportName: list?.reportName,
				accommodationType: list?.accommodationType,
				associatedProgramme: list?.associatedProgramme,
				selectedClient: list?.clientId,
				selectedProgramme: list?.programmeId,
				programmeId: list?.programmeId,
			}
		})
	}, [reportList]);


	const redirectProgrammeSpendData = (data) => {
		setStorage(`programmeSpendData_${data.key}`, data);
		history.push('/admin/programme-spend-data', { programmeId: programmeId, selectedClient: selectedClient, formOpen: true, reportId: data.key, startDate: data.startDate, endDate: data.endDate, currency: data.currency, selectedProgramme: selectedProgramme, reportName: data.reportName, accommodationType: data.accommodationType, associatedProgramme: data.associatedProgramme })
	}

	const columns1 = [
		{
			name: 'Accommodation',
			selector: (row) => {
				let status = "";
				if (row.accommodationType === 'both') status = 'Both';
				if (row.accommodationType === 'serviced_apartments') status = 'Serviced Accommodation';
				if (row.accommodationType === 'hotel') status = 'Hotel';
				return (
					<span >{status}</span>
				)
			}
		},
		{
			name: 'Report Name',
			selector: row => row.reportName,
		},
		{
			name: 'Start date',
			selector: row => moment(row.startDate).format("D MMM YYYY"),
		},
		{
			name: 'End date',
			selector: row => moment(row.endDate).format("D MMM YYYY"),
		},
		{
			name: 'Associated Programmes',
			minWidth: '500px',
			selector: row =>
				row.associatedProgramme
					? row.associatedProgramme.join(', ')
					: 'No Programmes',
		},
		{
			name: "Action",
			button: true,
			key: 'action',
			wrap: true,
			minWidth: '100px',
			cell: (data: any) => {
				return (
					<Stack direction="horizontal" gap={3}>
						{<OverlayTrigger

							placement="top"
							delay={overlayTriggerDelay}
							overlay={<Tooltip id='tooltip-table-top'>View / Edit</Tooltip>}
						>
							<img className='iconSizes mr-2' src={ViewEdit} alt="" onClick={() => {
								redirectProgrammeSpendData(data)
								loadForm(data);
							}} />
						</OverlayTrigger>}
						{
							loadingId === data?.key && <Spinner size={`sm`} animation="border" variant="warning" />
						}
					</Stack>
				)
			}
		}
	];

	return (
		<div>
			<DataTable
				columns={columns1}
				data={memoizedReportData}
				pagination
				responsive
				customStyles={tableStyles}
				striped={true}
				theme='mysaTable'
				fixedHeader={true}
				noDataComponent={'Updating records'}
			/>
		</div>
	)
}

export default ProgrammeSpendDataList;